import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule, NgZone } from '@angular/core';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '@environments/environment';
import { ApiBaseModule, ApiRequestManager } from '@library/api';
import { BaseModule, DeviceTypeManager } from '@library/base';
import { ButtonModule } from '@library/button';
import { CardModule } from '@library/card';
import { DesignBlocksModule } from '@library/design-blocks';
import { DialogService } from '@library/dialog';
import { IconModule } from '@library/icon';
import { IframeModule } from '@library/iframe';
import { ImageModule } from '@library/image';
import { LayoutModule } from '@library/layout';
import { UserAccountMenuModule } from '@library/user-account-menu';
import { UtilityModule } from '@library/utility';
import { WebsiteErrorManager, WebsiteLibraryModule } from '@library/website';
import { WidgetErrorManager, WidgetManualDeviceTypeManager } from '@library/widget';
import { ScaleImageUrlPipe } from '@shared/pipes/scale-image.pipe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const websiteDeviceTypeManagerFactory = (breakpointObserver: BreakpointObserver) => {
    if (document.getElementById('sb-bootstrap-websiteblock')) {
        return new WidgetManualDeviceTypeManager();
    } else {
        return new DeviceTypeManager(breakpointObserver);
    }
}

const websiteErrorManagerFactory = (ngZone: NgZone, dialogService: DialogService, apiRequestManager: ApiRequestManager) => {
    if (document.getElementById('sb-bootstrap-websiteblock')) {
        return new WidgetErrorManager(ngZone, dialogService, apiRequestManager);
    } else {
        return new WebsiteErrorManager();
    }
}

@NgModule({
    declarations: [
        AppComponent,
        ScaleImageUrlPipe
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        LayoutModule,
        UserAccountMenuModule,
        CommonModule,
        ButtonModule,
        RouterModule,
        BrowserAnimationsModule,
        IconModule,
        MatDialogModule,
        MatExpansionModule,
        WebsiteLibraryModule,
        UtilityModule,
        ApiBaseModule.setEnvironment(environment),
        DesignBlocksModule.setEnvironment(environment),
        CardModule,
        IframeModule,
        ImageModule,
        BaseModule,

        // Not sure why we have to do this, look at this later. https://github.com/angular/angular/issues/35759
        MatMenuModule,
        MatChipsModule,
        MatSnackBarModule
    ],
    providers: [
        MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
        MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
        { provide: ErrorHandler, useFactory: websiteErrorManagerFactory, deps: [NgZone, DialogService, ApiRequestManager] },
        { provide: DeviceTypeManager, useFactory: websiteDeviceTypeManagerFactory, deps: [BreakpointObserver] }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
