<ng-template>
    <ng-content></ng-content>
</ng-template>

<ng-container [ngSwitch]="kind">
    <ng-container *ngSwitchCase="SubmissionBarKind.DrawerBasic">
        <div class="d-flex w-100 background-base-0 scss-submission-drawer-border padding-y-16" [ngClass]="{'justify-content-between':altActionButton,'justify-content-end':!altActionButton, 'padding-x-16': isMobile, 'padding-x-24':!isMobile}">
            <ng-container *ngIf="isMobile">
                <div class="d-flex flex-column gap-space-16 w-100">
                    <div class="d-flex flex-column w-100 gap-space-8">
                        <div *ngIf="primaryButton">
                            <ng-container *ngTemplateOutlet="primaryButtonRef"></ng-container>
                        </div>
                        <div *ngIf="altActionButton">
                            <ng-container *ngTemplateOutlet="altActionButtonRef"></ng-container>
                        </div>
                    </div>
                    <div *ngIf="cancelButton">
                        <ng-container *ngTemplateOutlet="cancelButtonRef"></ng-container>
                    </div>
                </div>
            </ng-container>
        
            <ng-container *ngIf="!isMobile">
                <div *ngIf="altActionButton" class="scss-submission-button-width">
                    <ng-container *ngTemplateOutlet="altActionButtonRef"></ng-container>
                </div>
            
                <div class="d-flex gap-space-12">
                    <div  *ngIf="cancelButton" class="scss-submission-button-width">
                        <ng-container *ngTemplateOutlet="cancelButtonRef"></ng-container>
                    </div>
                
                    <div *ngIf="primaryButton" class="scss-submission-button-width">
                        <ng-container *ngTemplateOutlet="primaryButtonRef"></ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="SubmissionBarKind.DrawerWizard">
        <div class="d-flex w-100 background-base-0 scss-submission-drawer-border padding-y-16" [ngClass]="{'justify-content-between':cancelButton,'justify-content-end':!cancelButton, 'padding-x-16': isMobile, 'padding-x-24':!isMobile}">
            <ng-container *ngIf="isMobile">
                <div class="d-flex flex-column gap-space-16 w-100">
                    <div class="d-flex w-100 gap-space-8">
                        <div *ngIf="altActionButton" class="w-50">
                            <ng-container *ngTemplateOutlet="altActionButtonRef"></ng-container>
                        </div>
                        <div *ngIf="primaryButton" class="w-50">
                            <ng-container *ngTemplateOutlet="primaryButtonRef"></ng-container>
                        </div>
                    </div>
                    <div *ngIf="cancelButton">
                        <ng-container *ngTemplateOutlet="cancelButtonRef"></ng-container>
                    </div>
                </div>
            </ng-container>
        
            <ng-container *ngIf="!isMobile">
                <div  *ngIf="cancelButton" class="scss-submission-button-width">
                    <ng-container *ngTemplateOutlet="cancelButtonRef"></ng-container>
                </div>
            
                <div class="d-flex gap-space-12">
                    <div *ngIf="altActionButton" class="scss-submission-button-width">
                        <ng-container *ngTemplateOutlet="altActionButtonRef"></ng-container>
                    </div>
                    <div *ngIf="primaryButton" class="scss-submission-button-width">
                        <ng-container *ngTemplateOutlet="primaryButtonRef"></ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="SubmissionBarKind.DialogBasic">
        <div class="d-flex w-100 background-base-0" [ngClass]="{'justify-content-between':altActionButton,'justify-content-end':!altActionButton, 'padding-x-16 padding-top-24 padding-bottom-16': isMobile, 'padding-24':!isMobile}">
            <ng-container *ngIf="isMobile">
                <div class="d-flex flex-column gap-space-16 w-100">
                    <div class="d-flex flex-column w-100 gap-space-8">
                        <div *ngIf="primaryButton">
                            <ng-container *ngTemplateOutlet="primaryButtonRef"></ng-container>
                        </div>
                        <div *ngIf="altActionButton">
                            <ng-container *ngTemplateOutlet="altActionButtonRef"></ng-container>
                        </div>
                    </div>
                    <div *ngIf="cancelButton">
                        <ng-container *ngTemplateOutlet="cancelButtonRef"></ng-container>
                    </div>
                </div>
            </ng-container>
        
            <ng-container *ngIf="!isMobile">
                <div *ngIf="altActionButton" class="scss-submission-button-width">
                    <ng-container *ngTemplateOutlet="altActionButtonRef"></ng-container>
                </div>
            
                <div class="d-flex gap-space-12">
                    <div *ngIf="cancelButton" class="scss-submission-button-width">
                        <ng-container *ngTemplateOutlet="cancelButtonRef"></ng-container>
                    </div>
                
                    <div *ngIf="primaryButton" class="scss-submission-button-width">
                        <ng-container *ngTemplateOutlet="primaryButtonRef"></ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="SubmissionBarKind.DrawerAttendance">
        <div class="d-flex w-100 background-base-0 scss-submission-drawer-border padding-y-16" [ngClass]="{'justify-content-between':customActionRef,'justify-content-end':!customActionRef, 'padding-x-16': isMobile, 'padding-x-24':!isMobile}">
            <ng-container *ngIf="isMobile">
                <div class="d-flex flex-column gap-space-8 w-100">
                    <div class="d-flex flex-column w-100 gap-space-16">
                        <div *ngIf="primaryButton">
                            <ng-container *ngTemplateOutlet="primaryButtonRef"></ng-container>
                        </div>
                        <div *ngIf="customActionRef">
                            <ng-container *ngTemplateOutlet="customActionRef"></ng-container>
                        </div>
                    </div>
                    <div *ngIf="cancelButton">
                        <ng-container *ngTemplateOutlet="cancelButtonRef"></ng-container>
                    </div>
                </div>
            </ng-container>
        
            <ng-container *ngIf="!isMobile">
                <div *ngIf="customActionRef">
                    <ng-container *ngTemplateOutlet="customActionRef"></ng-container>
                </div>
            
                <div class="d-flex gap-space-12">
                    <div  *ngIf="cancelButton" class="scss-submission-button-width">
                        <ng-container *ngTemplateOutlet="cancelButtonRef"></ng-container>
                    </div>
                
                    <div *ngIf="primaryButton" class="scss-submission-button-width">
                        <ng-container *ngTemplateOutlet="primaryButtonRef"></ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

</ng-container>




<ng-template #primaryButtonRef>
    <lib-button
        [kind]="primaryButton!.kind"
        [disabled]="primaryButton!.disabled"
        [loading]="primaryButton!.loading"
        [size]="ButtonSize.Large"
        [stretch]="true"
        (clicked)="ButtonClicked(primaryButton!)">
        <ng-container label>{{primaryButton!.buttonLabel?.nativeElement!.textContent}}</ng-container>
    </lib-button>
</ng-template>

<ng-template #cancelButtonRef>
    <lib-button
        [kind]="isMobile ? ButtonKind.Tertiary : cancelButton!.kind"
        [disabled]="cancelButton!.disabled"
        [loading]="cancelButton!.loading"
        [size]="ButtonSize.Large"
        [stretch]="true"
        (clicked)="ButtonClicked(cancelButton!)">
        <ng-container label>{{cancelButton!.buttonLabel?.nativeElement!.textContent}}</ng-container>
    </lib-button>
</ng-template>

<ng-template #altActionButtonRef>    
    <lib-button
        [kind]="altActionButton!.kind"
        [disabled]="altActionButton!.disabled"
        [loading]="altActionButton!.loading"
        [size]="ButtonSize.Large"
        [stretch]="true"
        (clicked)="ButtonClicked(altActionButton!)">
            <ng-container label>{{altActionButton!.buttonLabel?.nativeElement!.textContent}}</ng-container>
    </lib-button>    
</ng-template>