<div [ngSwitch]="websiteMode" class="background-base-0">
    <ng-container *ngSwitchCase="WebsiteMode.Live">
        <div  class="d-flex position-relative design-block" [ngClass]="{'justify-content-center': !isMobile}">
            <ng-container *ngTemplateOutlet="blockContent; context: {$implicit: visiblePosts}"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.ReadOnly">
        <div  class="d-flex position-relative" [ngClass]="{'justify-content-center': !isMobile}">    
            <ng-container *ngTemplateOutlet="blockContent; context: {$implicit: visiblePosts}"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.Preview">
        <lib-design-blocks-action-menu
            [showEdit]="false"
            [disableMoveUp]="disableMoveUp"
            [disableMoveDown]="disableMoveDown" 
            (moveDownClicked)="MoveDownClicked()"
            (moveUpClicked)="MoveUpClicked()"
            (deleteClicked)="DeleteClicked()"
            (editClicked)="EditClicked()">
            <ng-container *ngTemplateOutlet="blockContent; context: {$implicit: visiblePosts}"></ng-container>
        </lib-design-blocks-action-menu>
    </ng-container>
</div>

<ng-template #blockContent let-posts>
        <div  *ngIf="(websiteMode === WebsiteMode.Preview || (websiteMode === WebsiteMode.ReadOnly && !inSidebar)) && allPostsLength !== null && allPostsLength === 0" class="w-100 scss-blog-block-spacing py-5">
            <lib-empty-state
                [emptyStateGraphic]="EmptyStateGraphic.News"
                [useBackground]="false">
                <ng-container emptyStateText i18n="@@CommonBlogBlockGoToNewsEmptyStateText">Go to News & Blog Posts in the main menu of your #PRODUCT# account to write posts for your website blog</ng-container>
                <ng-container emptyStateButton>
                    <lib-button 
                        (clicked)="GoToNewsPage()">
                        <ng-container label i18n="@@CommonBlogBlockGoToNewsEmptyStateButtonText">Go to News & Blog Posts</ng-container>
                    </lib-button>
                </ng-container>
            </lib-empty-state>
        </div>
        <div *ngIf="allPostsLength !== null && allPostsLength > 0" class="w-100 d-flex flex-column py-2 px-4 px-sm-3 align-items-center gap-2 blog-block"> 
            <div *ngFor="let post of posts; trackBy: TrackByID" class="w-100 d-flex p-2  align-items-center justify-content-center">
                <div class="d-flex flex-column  gap-4 w-100 align-items-center justify-content-center scss-spacing-text-media-block">
                    <div class="w-100 scss-background-blog-accent-line"></div>
                    <div class="position-relative w-100">
                        <div class="d-flex gap-2 position-absolute top-0 start-0">
                            <div [ngClass]="post | method: GetBackgroundColor" class="scss-blog-icon-container rounded" *ngLet="(post.Icon | postIcon) as postIcon">
                                <lib-icon
                                    [icon]="postIcon.Icon">
                                </lib-icon>
                            </div>
                            <div *ngIf="post.Sticky"  class="scss-blog-icon-container rounded background-info-100">
                                <lib-icon  class="info-700" [icon]="Icon.Common.KeepOutline"></lib-icon>
                            </div>
                        </div>
                        <div class="w-100" [ngClass]="{'text-center': !isMobile, 'pt-5': isMobile}">
                            <h3 class="typography-semibold base-300 " >{{post.Title}}</h3>
                            <p class="typography-semibold base-200 typography-caption-large pt-3">{{post.Author}} - {{post.PublishDate | formatDate: 'long'}}</p>
                            <lib-iframe [hidden]="websiteMode === WebsiteMode.ReadOnly" [src]="post.BodyHTML!"></lib-iframe>
                            <p class="scss-iframe-read-only"[hidden]="websiteMode !== WebsiteMode.ReadOnly">{{post.BodyHTML!}}</p>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center w-100 px-3 py-2" *ngIf="allPostsLength !== null && allPostsLength  > visiblePosts.length">
                <lib-button
                    [kind]="ButtonKind.Link" 
                    [suffixIcon]="Icon.Common.CaretDown"
                    (clicked)="GetNews()">
                    <ng-container label>Show More</ng-container>
                </lib-button>
            </div>
        </div> 

</ng-template>