import { ADEnvironment, DisableTelemetryAndHelpOverrides, Environment, MMSEnvironment, T443Environment, TBEnvironment } from "@library/base";

// Portal Specifics
export const MMSWebsiteEnvironment = new Environment({
    ...MMSEnvironment,
    ...DisableTelemetryAndHelpOverrides
});

export const TBWebsiteEnvironment = new Environment({
    ...TBEnvironment,
    ...DisableTelemetryAndHelpOverrides
});

export const ADWebsiteEnvironment = new Environment({
    ...ADEnvironment,
    ...DisableTelemetryAndHelpOverrides
});

export const T443WebsiteEnvironment = new Environment({
    ...T443Environment,
    ...DisableTelemetryAndHelpOverrides
});