import { Component} from '@angular/core';
import { DesignBlockBaseViewComponent } from '@library/base';

@Component({
    selector: 'lib-html-design-block',
    templateUrl: './html-design-block.component.html',
    styleUrls: ['./html-design-block.component.scss']
})
export class HtmlDesignBlockComponent extends DesignBlockBaseViewComponent{    
}
