export enum EmptyStateGraphic {
    AccountInvoices = 'assets/images/Account Invoices.svg',
    Agenda = 'assets/images/Agenda.svg',
    AttendanceNotes = 'assets/images/Attendance - Default.svg',
    AttendanceNoHistory = 'assets/images/Attendance-No-History.svg',
    AttendanceNoTemplates = 'assets/images/Attendance-No-Templates.svg',
    BackToLegacyWebsiteBuilder = 'assets/images/Back-To-Legacy.svg',
    BorrowingLibrary = 'assets/images/Lending Library.svg',
    Calendar = 'assets/images/Calendar No Events.svg',
    ChargeCategories = 'assets/images/Charge Categories.svg',
    ExpensesRevenue = 'assets/images/Expenses & Other Revenue.svg',
    FamilyAccounts = 'assets/images/Families & Invoices - Family Accounts.svg',
    Invoices = 'assets/images/Families & Invoices - Invoices.svg',
    HomeHeader = 'assets/images/Home-Header-Graphic.svg',
    LendingLibrary = 'assets/images/Lending Library.svg',
    LendingLibraryHistory = 'assets/images/Lending Library - Lending LibraryHistory.svg',
    Locations =  'assets/images/Locations.svg',
    Membership = 'assets/images/Payroll History.svg',
    MessageHistory = 'assets/images/Message History.svg',
    News = 'assets/images/News & Blog Posts.svg',
    NoResultsFound = 'assets/images/No Results Found.svg',
    Notifications = 'assets/images/Notifications.svg',
    OnlineResources = 'assets/images/Online Resources.svg',
    PaymentProcessor = 'assets/images/Families & Invoices - Recent Trasactions.svg',
    PayrollHistory = 'assets/images/Payroll History.svg', 
    PracticeLog = 'assets/images/Practice Log.svg',
    Repertoire = 'assets/images/Repertoire.svg',
    StudioReports = 'assets/images/Studio Reports.svg',
    Students = 'assets/images/Students.svg',
    Mileage = 'assets/images/Locations.svg',
    TrackMileage = 'assets/images/Mileage.svg',
    Instructors = 'assets/images/Instructors.svg',
    WebsiteNoMobile = 'assets/images/Website-No-Mobile.svg',
    WebsiteSiteFiles = 'assets/images/Website-Site-Files.svg',
    Availability = 'assets/images/Teachers & Staff - Availability.svg',
    WebsiteScriptCode = 'assets/images/Website-Script-Code.svg',
    PageNotFound = 'assets/images/Page Not Found.svg',
    FamilyContacts = 'assets/images/Students - family contacts.svg'
}
