import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
    selector: 'lib-checkbox',
    templateUrl: './form-field-checkbox.component.html',
    styleUrls: ['./form-field-checkbox.component.scss']
})
export class FormFieldCheckboxComponent extends FormFieldBaseComponent<boolean, FormControl<boolean>> {
    @Input() isIndeterminate: boolean = false;

    private _disabled: boolean = false;
    @Input() 
    set disabled(value: boolean) {
        this._disabled = value;
        if (this._disabled) {
            this.viewControl.disable();
        } else {
            this.viewControl.enable();
        }
    }

    private _checked: boolean = false;
    @Input()
    set checked(value: boolean) {
        this._checked = value;
        this.viewControl.setValue(value);
    }


    @Input('isOptional') isOptionalOverride: boolean = false;

    constructor() {
        super();

        this.viewControl = new FormControl(this._checked, {nonNullable: true});

        this.errors.Override({
            required: _ => $localize`:@@CommonCheckboxRequired:This checkbox is required`
        });
    }

    ChangeEvent(event: MatCheckboxChange){
        this.formControl.setValue(event.checked);
        this.SetFormControlValue(event.checked);

        super.OnBlur();
    }
}
