import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WebsiteBlockTextAndMediaType } from "@library/data-models";
import { DesignBlockImageBaseViewComponent } from "@library/base";
import { WebsiteGalleryItemAction, WebsiteMode } from '@library/base';

@Component({
    selector: 'lib-photo-gallery-design-block-item',
    templateUrl: './photo-gallery-design-block-item.component.html',
    styleUrls: ['./photo-gallery-design-block-item.component.scss'],
})
export class PhotoGalleryDesignBlockItemComponent extends DesignBlockImageBaseViewComponent {
    @Input() kind: WebsiteBlockTextAndMediaType = WebsiteBlockTextAndMediaType.PhotoGalleryGrid;
    @Input() isMiddleImageOfView: boolean = false;
    @Input() disableRemoveImage: boolean = false;
    @Input() mode: WebsiteMode = WebsiteMode.Preview;
    @Input() disableImageDetails: boolean = false;
    @Input() disableGridImageDetails: boolean = false;
    
    @Output() removePhotoGalleryItemClicked: EventEmitter<void> = new EventEmitter();

    private _showActionMenu: boolean = false;

    MouseEntered(): void {
        this._showActionMenu = true;
    }

    MouseLeave(): void {
        this._showActionMenu = false;
    }

    override ImageActionClicked(action: WebsiteGalleryItemAction): void {
      if (action == WebsiteGalleryItemAction.Delete) {
          this.removePhotoGalleryItemClicked.emit();
      } else {
          super.ImageActionClicked(action);
      }
    }

    get showActionMenu(): boolean {
        return this.mode == WebsiteMode.Preview && this._showActionMenu && (this.kind === WebsiteBlockTextAndMediaType.PhotoGalleryCarousel ||
        this.kind === WebsiteBlockTextAndMediaType.PhotoGalleryCarouselOverlay);
    }

    get WebsiteGalleryItemAction(): typeof WebsiteGalleryItemAction {
      return WebsiteGalleryItemAction;
    }

    get showGridImageActionMenu(): boolean {
        return this.mode == WebsiteMode.Preview && this.showGeneralImageActionMenu && this.kind === WebsiteBlockTextAndMediaType.PhotoGalleryGrid;
    }
}
