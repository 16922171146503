import { AfterViewInit, Component, ElementRef, HostListener, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LocalizationInfo } from '@library/data-models';
import { LocalizationService, SBDate } from '@library/localization';
import { MbscDatepicker, MbscDatepickerOpenEvent, MbscDatepickerOptions } from '@mobiscroll/angular';
import { FormFieldInputBaseComponent } from '../form-field-input-base.component';


@Component({
    template: '',
})
export class FormFieldDateBaseComponent<T> extends FormFieldInputBaseComponent<T> implements OnInit, OnDestroy {
    @ViewChild('input', {static: false}) input?: ElementRef;
    
    protected _datePicker!: any; //mobiscroll didnt export the type
    
    protected _popupOpen: boolean = false;
    protected _focused: boolean = false;

    protected _LocalizationService!: LocalizationService;

    private _localeInfo!: LocalizationInfo;

    private _defaultMinDateJS: Date = SBDate.FromISO('2013-01-01').ToJSDate(); // all users can go back to start of MMS creation
    protected _minDateJS: Date = this._defaultMinDateJS;
    @Input()
    public set minDate(value: SBDate | null) {
        this._minDateJS = value ? value.ToJSDate() : this._defaultMinDateJS;
    }

    private _defaultMaxDateJS: Date = SBDate.Today().AddYears(2).ToJSDate(); //initial value to be overrideen later
    private _maxDateJS: Date = this._defaultMaxDateJS;
    @Input()
    public set maxDate(value: SBDate | null) {
        this._maxDateJS = value ? value.ToJSDate() : this._defaultMaxDateJS;
    }

    private _lastInputValue?: string;

    constructor() {
        super();
        
        this.prefixIcon = this.Icon.Common.Calendar;
        this._LocalizationService = inject(LocalizationService);
        this._localeInfo = this._LocalizationService.LocalizationInfo;

        this._defaultMaxDateJS = SBDate.FromISO(this._LocalizationService.MaxCalendarDateISOString).AddDays(-1).ToJSDate(); //we need to turn exclusive date into inclusive max date
        this._maxDateJS = this._defaultMaxDateJS;

        window.addEventListener('scroll', this.scrollEvent, true);
    }

    ngAfterViewInit() {
        if (this.input) {
            this.input.nativeElement.disabled = this.viewControl.disabled;
            this._lastInputValue = this.input.nativeElement.value;
        }
    }

    override ngOnDestroy() {
        window.removeEventListener('scroll', this.scrollEvent, true);
    }

    override setDisabledState(state: boolean): void {
        super.setDisabledState(state);
        if (this.input) {
            this.input.nativeElement.disabled = state;
        }
    }

    override OnBlur(): void {
        this._focused = false;
        this.SendBlur();         
    }

    OnFocus(): void {
        this._focused = true;
    }

    OnClick(event: MouseEvent): void {
        if (this.isDisabled) {
            event.preventDefault();
        }
    }

    PopupOpened(event: MbscDatepickerOpenEvent) {
        this._datePicker = event.inst!;
        requestAnimationFrame(() => this._popupOpen = true);
    }

    PopupClosed() {
        this._popupOpen = false;
        this.SendBlur();
    }

    ComponentInitialized<T extends { inst: MbscDatepicker }>(event: T): void {
        this._datePicker = event.inst;
    }

    OpenDatePicker(): void {
        this._datePicker.open();
    }
    
    private scrollEvent = (): void => {
        if (this._datePicker && this._popupOpen) {
            this._datePicker.position();
        }
    };

    protected SendBlur() {
        if (!this._focused && !this._popupOpen) {
            super.OnBlur();
        }
    }

    // Called from search modal to close when event.stopPropagation is used
    public CloseIfNotFocused() {
        if (!this._focused && this.popupOpen) {
            this._datePicker.close();
        }
    }

    public get dateFormat() {
        return this._LocalizationService.ShortDatePattern;
    }

    public get minDateJS(): Date {
        return this._minDateJS;
    }

    public get maxDateJS(): Date {
        return this._maxDateJS;
    }

    public get popupOpen(): boolean {
        return this._popupOpen;
    }

    get localeInfo(): LocalizationInfo {
        return this._localeInfo;
    }

    get firstDayOfWeek(): number {
        return this._LocalizationService.firstDayOfWeekIndex;
    }

    @HostListener('input', ['$event'])
    handleInputChange(event: any) {
        if (this.input?.nativeElement.value !== this._lastInputValue) {
            this.formControl.isDirty = true;
            this._lastInputValue = this.input?.nativeElement.value;
        }
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyUp(event: KeyboardEvent) {
        if(event.key.toLowerCase() === 'enter' && this.popupOpen){
            this._datePicker.close();
        }
    }
}
