<div [ngSwitch]="websiteMode" class="scss-website-background-color">
    <ng-container *ngSwitchCase="WebsiteMode.Live">
        <div class="d-flex position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.ReadOnly">
        <div class="d-flex position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.Preview">
        <lib-design-blocks-action-menu
            [showEdit]="true"
            [disableMoveUp]="disableMoveUp"
            [disableMoveDown]="disableMoveDown" 
            (moveDownClicked)="MoveDownClicked()"
            (moveUpClicked)="MoveUpClicked()"
            (deleteClicked)="DeleteClicked()"
            (editClicked)="EditClicked()">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </lib-design-blocks-action-menu>
    </ng-container>
</div>

<ng-template #blockContent>
    <div class="w-100 d-flex scss-spacing-html-block align-items-center">
        <ng-content></ng-content>
        <div *ngIf="websiteMode !== WebsiteMode.Live" class="design-building-block-overlay" (dblclick)="EditClicked()"></div>
    </div>
</ng-template>