import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';



import { BaseViewComponent, Errors, FormContainerKind, IconItem, TextKind } from '@library/base';

@Component({
    selector: 'lib-form-field-container',
    templateUrl: './form-field-container.component.html',
    styleUrls: ['./form-field-container.component.scss']
})
export class FormFieldInputContainerComponent extends BaseViewComponent {

    @ViewChild('containerBox', {static: false}) containerBox!: ElementRef<HTMLDivElement>;
    @ViewChild('origin', {static: false, read: MatAutocompleteOrigin}) origin!: MatAutocompleteOrigin;
    @ViewChild('menuButton', { read: ElementRef, static: true }) menuButton!: ElementRef;


    @Input() control!: FormControl<any>
    @Input() errors!: Errors;
    @Input() hideErrors: boolean = false;
    @Input() isLoading!: boolean;
    @Input() isReadOnly!: boolean;    
    @Input() isViewOnly!: boolean;
    @Input() isOptional!: boolean;
    @Input() isDisabled: boolean = false;
    @Input() prefixIcon!: IconItem;
    @Input() suffixIcon!: IconItem;
    @Input() suffixIconClickable: boolean = false;
    @Input() kind: FormContainerKind = FormContainerKind.Default;
    @Input() textKind: TextKind = TextKind.Default;
    @Input() showLabel: boolean = true;

    @Output() fieldClicked: EventEmitter<MouseEvent> = new EventEmitter();
    @Output() containerClicked: EventEmitter<MouseEvent> = new EventEmitter();
    @Output() suffixIconClicked: EventEmitter<void> = new EventEmitter();

    private _hideErrorsOnFocus: boolean = false;

    Focus() {
        this._hideErrorsOnFocus = true;
    }

    FocusOut() {
        this._hideErrorsOnFocus = false;
    }

    get hideErrorsonFocus(): boolean {
        return this._hideErrorsOnFocus;
    }

    get FormContainerKind(): typeof FormContainerKind {
        return FormContainerKind;
    } 

    get hasError(): boolean {
        return this.control.enabled && !this.control.isReadOnly && !this.control.isLoading && this.control.touched && this.control.errors !== null && !this.hideErrors && !this._hideErrorsOnFocus;
    }
}
