export enum TabSize {
    Small = "Small",
    Medium = "Medium",
    Large = "Large"
}

export enum TabBadgeKind {
    Count = "Count",
    Dot = "Dot"
}
