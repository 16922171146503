import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

export enum ViewMode {
    Mobile,
    Tablet,
    Desktop
}

@Injectable({
    providedIn: 'root'
})
export class DeviceTypeManager {

    private _viewMode: ViewMode = ViewMode.Desktop;
    private _breakPointChange: Subject<ViewMode> = new Subject();

    protected _desktopPortraitMinWidth!: number;
    protected _desktopLandscapeMinWidth!: number;
    protected _tabletPortraitMinWidth!: number;
    protected _tabletPortraitMaxWidth!: number;
    protected _tabletLandscapeMinWidth!: number;
    protected _tabletLandscapeMaxWidth!: number;
    protected _mobilePortraitMaxWidth!: number;
    protected _mobileLandscapeMaxWidth!: number;

    constructor (private breakpointObserver?: BreakpointObserver) {
        this.ComputeBreakpoints();

        if (breakpointObserver) {
            breakpointObserver.observe([
                `(min-width: ${this._desktopLandscapeMinWidth}px) and (orientation: landscape)`
            ])?.subscribe(result => {
                if(result.matches){
                    this.SetViewMode(ViewMode.Desktop);
                }            
            });

            breakpointObserver.observe([
                `(min-width: ${this._desktopPortraitMinWidth}px) and (orientation: portrait), (min-width: ${this._tabletPortraitMinWidth}px) and (max-width: ${this._tabletPortraitMaxWidth}px) and (orientation: portrait), (min-width: ${this._tabletLandscapeMinWidth}px) and (max-width:  ${this._tabletLandscapeMaxWidth}px) and (orientation: landscape)`
            ])?.subscribe(result => {
                if(result.matches){
                    this.SetViewMode(ViewMode.Tablet);
                }            
            });

            breakpointObserver.observe([
                `(max-width: ${this._mobilePortraitMaxWidth}px) and (orientation: portrait), (max-width: ${this._mobileLandscapeMaxWidth}px) and (orientation: landscape)`
            ])?.subscribe(result => {
                if(result.matches){
                    this.SetViewMode(ViewMode.Mobile);
                }            
            });
        }
    }

    protected SetViewMode(mode: ViewMode): void {
        if (mode !== this._viewMode) {
            this._viewMode = mode;
            this._breakPointChange.next(mode);
        }
    }

    private ComputeBreakpoints(): void {
        const computedStyle = getComputedStyle(document.documentElement);

        this._desktopPortraitMinWidth = parseInt(computedStyle.getPropertyValue('--desktop-portrait-min-width'), 10);
        this._desktopLandscapeMinWidth = parseInt(computedStyle.getPropertyValue('--desktop-landscape-min-width'), 10);
        this._tabletPortraitMinWidth = parseInt(computedStyle.getPropertyValue('--tablet-portrait-min-width'), 10);
        this._tabletPortraitMaxWidth = parseInt(computedStyle.getPropertyValue('--tablet-portrait-max-width'), 10);
        this._tabletLandscapeMinWidth = parseInt(computedStyle.getPropertyValue('--tablet-landscape-min-width'), 10);
        this._mobilePortraitMaxWidth = parseInt(computedStyle.getPropertyValue('--mobile-portrait-max-width'), 10);
        this._tabletLandscapeMaxWidth = parseInt(computedStyle.getPropertyValue('--tablet-landscape-max-width'), 10);
        this._mobileLandscapeMaxWidth = parseInt(computedStyle.getPropertyValue('--mobile-landscape-max-width'), 10);
    }

    get isMobile(): boolean {
        return this._viewMode === ViewMode.Mobile;
    }

    get isTablet(): boolean {
        return this._viewMode === ViewMode.Tablet
    }

    get isDesktop(): boolean {
        return this._viewMode === ViewMode.Desktop;
    }

    get isMobileOrTablet(): boolean {
        return this.isMobile || this.isTablet;
    }

    get isDesktopOrTablet(): boolean {
        return this.isDesktop || this.isTablet;
    }

    get isNotMobile() {
      return !this.isMobileOrTablet;
    }


    get isMobileModeLandscape(): Observable<BreakpointState> {
        return this.breakpointObserver?.observe(
            [Breakpoints.HandsetLandscape]
        ) ?? of();
    }

    get isLandscape(): Observable<BreakpointState> {
        return this.breakpointObserver?.observe(
            [Breakpoints.HandsetLandscape, Breakpoints.TabletLandscape, Breakpoints.WebLandscape]
        ) ?? of();
    }

    get breakPointChange(): Subject<ViewMode> {
        return this._breakPointChange;
    }

    public get isTouchScreenDevice(): boolean {
        if ( window.matchMedia("(any-pointer: coarse)").matches) {
            return true;
        }

        return false;
    }

    public get isMobileIOS(): boolean {
        return /iP(hone|od|ad)/.test(navigator.userAgent||navigator.vendor);
    }

    public get isApple(): boolean {
        return this.isMobileIOS || navigator.userAgent.indexOf("Macintosh") >= 0;
    }
}
