<lib-dialog-layout>
    <ng-container title>
        <div class="d-flex justify-content-between">
            <h3>{{ data.Title }}</h3>
        </div>
    </ng-container>
    <ng-container content>
        <lib-progress-bar [kind]="ProgressBarKind.Determinate" [value]="percentageComplete"></lib-progress-bar>
    </ng-container>
    <ng-container submissionBar>
        <lib-submission-bar [kind]="SubmissionBarKind.DialogBasic">
            <lib-button
                id="LibraryDialogSecondaryButton"
                [kind]="ButtonKind.DangerSecondary"
                (clicked)="PrimaryAction()">
                <ng-container label>
                    {{ data.CancelButtonText }}
                </ng-container>        
            </lib-button>
        </lib-submission-bar>
    </ng-container>
</lib-dialog-layout>
