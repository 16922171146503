import { Component, Input } from '@angular/core';
import { DesignBlockBaseViewComponent, DesignBlockImageBaseViewComponent, WebsiteMode } from '@library/base';
import { WebsiteBlockTextAndMediaActionDisplayItem, WebsiteBlockTextAndMediaType } from '@library/data-models';

@Component({
    selector: 'lib-call-to-action-design-block',
    templateUrl: './call-to-action-design-block.component.html',
    styleUrls: ['./call-to-action-design-block.component.scss']
})
export class CallToActionDesignBlockComponent extends DesignBlockBaseViewComponent     {

    @Input() hasHeader: boolean = true;
    @Input() hasBody: boolean = true;
    @Input() action!: WebsiteBlockTextAndMediaActionDisplayItem;
    @Input() kind: WebsiteBlockTextAndMediaType = WebsiteBlockTextAndMediaType.CallToActionDefault;

    ButtonClicked(): void {
        if (this.action && this.action.Link != null && this.websiteMode == WebsiteMode.Live) {
            window.open(this.action.Link, this.action.OpenInNewTab ? '_blank' : '_self')?.focus();
        }
    }

}
