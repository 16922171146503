import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { DragAndDropComponent } from './drag-and-drop.component';
import { ButtonModule } from '@library/button';
import { IconModule } from '@library/icon';
import { DragAndDropItemComponent } from './drag-and-drop-item.component';

@NgModule({
    declarations: [
        DragAndDropComponent,
        DragAndDropItemComponent
    ],
    imports: [
        DragDropModule,
        CommonModule,
        ButtonModule,
        IconModule
    ],
    exports: [
        DragAndDropComponent,
        DragAndDropItemComponent,
        DragDropModule
    ]
})
export class DragAndDropModule { }
