import { ValidationErrors } from "@angular/forms";

type ValidationError = {
    Error: ErrorKey;
    Arguments: any;
}

enum ErrorPriority {
    required,  // requiredTrue also returns this as an error
    max,
    maxExclusive,
    min,
    minExclusive,
    duplicate,
    password,
    match,
    url,
    regex,
    exactlength,
    exactDigit,
    minlength,
    maxlength,
    api,
    subform
};

type ErrorKey = keyof typeof ErrorPriority;
export type ErrorMessages = Partial<AllErrorMessages>;

class AllErrorMessages {
    required = (value: any) =>
        $localize`:@@CommonInputRequired:This field is required`;

    min = (value: {min: number, actual: number}) =>
        $localize`:@@CommonInputValueTooSmallInclusive:Please enter an amount greater than or equal to ${value.min}`;

    minExclusive = (value: {min: number, actual: number}) =>
        $localize`:@@CommonInputValueTooSmallExclusive:Please enter an amount greater than ${value.min}`;

    max = (value: {max: number, actual: number}) =>
        $localize`:@@CommonInputValueTooLargeInclusive:Please enter an amount less than or equal to ${value.max}`;

    maxExclusive = (value: {max: number, actual: number}) =>
        $localize`:@@CommonInputValueTooLargeExclusive:Please enter an amount less than ${value.max}`;

    duplicate = (value: string) =>
        $localize`:@@CommonInputDuplicate:Please enter a unique value`;

    password = (value: {min: number, actual: number, score: number}) => 
        $localize`:@@CommonInputInvalidPassword:The password must be at least ${value.min} characters long, contain at least one number, one letter, and one special character`;

    match = (value: string) =>
        $localize`:@@CommonInputMismatch:Passwords do not match`;

    url = (value: string) =>
        $localize`:@@CommonInputInvalidURL:Please enter a valid URL`;

    exactlength = (value: {requiredLength: number, actualLength: number}) =>
        $localize`:@@CommonInputStringWrongLength:Please enter a value exactly ${value.requiredLength} characters long`;

    minlength = (value: {requiredLength: number, actualLength: number}) =>
        $localize`:@@CommonInputStringTooShort:Please enter a value longer than ${value.requiredLength}`;

    maxlength = (value: {requiredLength: number, actualLength: number}) =>
        $localize`:@@CommonInputStringTooLong:Please enter a value shorter than ${value.requiredLength}`;

    exactDigit = (value: {requiredLength: number, actualLength: number}) =>
        $localize`:@@CommonInputNumberWrongLength:Please enter a ${value.requiredLength} digit number `;

    api = (value: any) => {
        throw new Error('API Error must be set by the developer using custom error messages');
        return '';
    };

    regex = (value: string) =>{
        throw new Error('Regex Error must be set by the developer using custom error messages');
        return '';
    }

    subform = (value: any) => "";
}

export class Errors extends AllErrorMessages {
    Override(errors: ErrorMessages) {
        Object.assign(this, errors);
    }

    GetMessage(errors: ValidationErrors): string | null {
        if (!errors) {
            return null;
        }

        const error = FirstError(errors);

        return this[error.Error](error.Arguments);
    }
}

function FirstError(errors: ValidationErrors): ValidationError {
    // Get priorities of errors
    let priorities = Object.keys(errors).map(key => ErrorPriority[key as ErrorKey]);

    // Find index of highest priority error
    let index = Math.min(...priorities);

    // Use enum reverse mapping to get the error name
    let error = ErrorPriority[index] as ErrorKey;

    return { Error: error, Arguments: errors[error] };
}
