import { Component, Input, OnInit } from '@angular/core';
import { SBDate } from '@library/localization';
import { FormFieldDateBaseComponent } from '../form-field-date-base.component';

@Component({
    selector: 'lib-input-month-year',
    templateUrl: './form-field-month-year.component.html',
    styleUrls: ['./form-field-month-year.component.scss']
})
export class FormFieldMonthYearComponent extends FormFieldDateBaseComponent<SBDate | null> implements OnInit {

    @Input() override placeholderText: string = 'MM/YY';

    override ngOnInit() {
        super.ngOnInit();
    }

    ChangeEvent(change: any): void {
        if (change.value === null) {
            this.SetFormControlValue(null);
        } else {
            const newDate = SBDate.FromJSDate(change.value);
            if (newDate.totalMilliseconds != this.formControl.value?.totalMilliseconds) {
                this.SetFormControlValue(newDate);
            }
        }
    }

    Clear(): void {
        this.viewControl.setValue('');
        this.SetFormControlValue(null);
    }

    override writeValue(dateValue: SBDate | null): void {
        if (dateValue){
            this.viewControl.setValue(dateValue.ToISO());
        } else {
            this.viewControl.setValue('');
        }
    }
}
