<div class="d-flex flex-column align-items-center">
    <div class="scss-custom-thank-you-card p-3 p-md-4 col-lg-6 col-md-9 col-11">
        <div>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
            </svg>
        </div>

        <div class="d-flex justify-content-center pb-lg-5 pb-4">
            <div [innerHTML]="(thankYouMessage ?? '') | trustHtml" libRunScripts></div>
        </div>
    </div>
</div>
