import { Component, Input, OnInit } from '@angular/core';
import { BaseViewComponent, IconBulletAlignment, IconBulletTextStyle, IconSize, IconItem } from '@library/base';

@Component({
    selector: 'lib-icon-bullet',
    templateUrl: './icon-bullet.component.html',
    styleUrls: ['./icon-bullet.component.scss'],
})

export class IconBulletComponent extends BaseViewComponent implements OnInit {
    @Input() icon: IconItem | null = null;
    @Input() size: IconSize = IconSize.ExtraSmall;
    @Input() iconColorClass!: string;
    @Input() alignment: IconBulletAlignment = IconBulletAlignment.Center;
    @Input() textStyle: IconBulletTextStyle = IconBulletTextStyle.Normal;  
}
