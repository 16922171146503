import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizationCurrencyNoSymbolPipe } from './pipes/localization-currency-no-symbol.pipe';
import { LocalizationTimePipe } from './pipes/localization-time.pipe';
import { LocalizationPercentPipe } from './pipes/localization-percent.pipe';
import { LocalizationDecimalPipe } from './pipes/localization-decimal.pipe';
import { LocalizationFilesizePipe } from './pipes/localization-filesize.pipe';
import { LocalizationDatePipe } from './pipes/localization-date.pipe';
import { LocalizationCurrencyPipe } from './pipes/localization-currency.pipe';
import { LocalizationNumberToWordsPipe } from './pipes/localization-number-to-words.pipe';
import {LocalizationCalendarModePipe} from "./pipes/localization-calendar-mode.pipe";
import { LocalizationPercentNoSymbolPipe } from './pipes/localization-percent-no-symbol.pipe';
import { LocalizationMonthlyToWordsPipe } from './pipes/localization-monthly-to-words.pipe';
import { LocalizationRelativeTimePipe } from './pipes/localization-relative-time.pipe';

@NgModule({
    declarations: [
        LocalizationTimePipe,
        LocalizationPercentPipe,
        LocalizationDecimalPipe,
        LocalizationFilesizePipe,
        LocalizationDatePipe,
        LocalizationCurrencyPipe,
        LocalizationCurrencyNoSymbolPipe,
        LocalizationNumberToWordsPipe,
        LocalizationCalendarModePipe,
        LocalizationPercentNoSymbolPipe,
        LocalizationMonthlyToWordsPipe,
        LocalizationRelativeTimePipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        LocalizationTimePipe,
        LocalizationPercentPipe,
        LocalizationDecimalPipe,
        LocalizationFilesizePipe,
        LocalizationDatePipe,
        LocalizationCurrencyPipe,
        LocalizationCurrencyNoSymbolPipe,
        LocalizationNumberToWordsPipe,
        LocalizationCalendarModePipe,
        LocalizationPercentNoSymbolPipe,
        LocalizationMonthlyToWordsPipe,
        LocalizationRelativeTimePipe
    ],
    providers: [
        LocalizationTimePipe,
        LocalizationDatePipe,
    ]
})
export class LocalizationModule { }
