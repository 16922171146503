import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { ItemWithID } from '@library/base';
import { FormFieldSelectItemComponent, IItemGroup } from './form-field-select-item.component';

@Component({
    selector: 'lib-input-select-item-group',
    template: '',
})
export class FormFieldSelectItemGroupComponent<T extends ItemWithID> implements IItemGroup, AfterContentInit {
    @Input() name!: string;
    @ContentChildren(FormFieldSelectItemComponent) items!: QueryList<FormFieldSelectItemComponent<T>>;

    ngAfterContentInit(): void {
        this.items.forEach(item => item.group = this);
    }
}
