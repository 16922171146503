import { Type, inject } from "@angular/core";
import { DeviceTypeManager } from "./managers/device-type.manager";
import { StorageManager } from "./managers/storage.manager";


export class Base {
    private _DeviceTypeManager: DeviceTypeManager;
    private _StorageManager: StorageManager;

    constructor() {
        this._StorageManager = inject(StorageManager);
        this._DeviceTypeManager = inject(DeviceTypeManager);
    }

    protected get DeviceTypeManager(): DeviceTypeManager {
        return this._DeviceTypeManager;
    }

    protected get StorageManager(): StorageManager {
        return this._StorageManager;
    }

    public get isMobileOrTablet(): boolean {
        return this.DeviceTypeManager.isMobileOrTablet;
    }

    public get isMobile(): boolean {
        return this.DeviceTypeManager.isMobile;
    }

    public get isTablet(): boolean {
        return this.DeviceTypeManager.isTablet;
    }

    public get isDesktop(): boolean {
        return this.DeviceTypeManager.isDesktop;
    }
}