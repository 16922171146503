<div
    [ngClass]="{'scss-block-color-variable-font': type === WebsiteBlockTextAndMediaType.HeroBackgroundImage}"
    class="scss-website-background-color" [ngSwitch]="websiteMode">
    <ng-container *ngSwitchCase="WebsiteMode.Live">
        <div class="d-flex position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.ReadOnly">
        <div class="d-flex position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.Preview">
        <lib-design-blocks-action-menu
                [disableMoveUp]="disableMoveUp"
                [disableMoveDown]="disableMoveDown"
                (moveDownClicked)="MoveDownClicked()"
                (moveUpClicked)="MoveUpClicked()"
                (deleteClicked)="DeleteClicked()">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </lib-design-blocks-action-menu>
    </ng-container>
</div>

<ng-template #blockContent>
    <div class="w-100 hero-block">
        <div class="w-100 d-grid"  [ngClass]="{
        'custom-square-side-image': type === WebsiteBlockTextAndMediaType.HeroSquareSideImage || type === WebsiteBlockTextAndMediaType.MediaLeftHeroSquareImage,
        'custom-clipped-side-image': type === WebsiteBlockTextAndMediaType.HeroClippedSideImage || type ===  WebsiteBlockTextAndMediaType.MediaLeftHeroClippedImage,
        'custom-hero-no-image': type === WebsiteBlockTextAndMediaType.HeroNoImage,
        'custom-hero-background-image': type === WebsiteBlockTextAndMediaType.HeroBackgroundImage,
        'custom-hero-image-left': type ===  WebsiteBlockTextAndMediaType.MediaLeftHeroClippedImage || type === WebsiteBlockTextAndMediaType.MediaLeftHeroSquareImage,
        'custom-clipped-side-image-left': type === WebsiteBlockTextAndMediaType.MediaLeftHeroClippedImage}">
            <div class="hero-block-text d-flex align-items-center justify-content-center flex-column"
                [ngClass]="{'scss-media-left-hero-block': type ===  WebsiteBlockTextAndMediaType.MediaLeftHeroClippedImage || type === WebsiteBlockTextAndMediaType.MediaLeftHeroSquareImage}">
                <h1 [ngClass]="{'text-center': type === WebsiteBlockTextAndMediaType.HeroBackgroundImage || type === WebsiteBlockTextAndMediaType.HeroNoImage}"
                    class="w-100" *ngIf="hasHeader">
                    <ng-content select="[header]"></ng-content>
                </h1>
                <p [ngClass]="{'text-center': type === WebsiteBlockTextAndMediaType.HeroBackgroundImage}" class="w-100"
                   *ngIf="hasBody">
                    <ng-content select="[body]"></ng-content>
                </p>
            </div>
            <div 
                *ngIf="type !== WebsiteBlockTextAndMediaType.HeroNoImage"
                class="hero-block-image" 
                libScrollToView
                [currMode]="websiteMode"
                (mouseenter)="MouseEnterGeneralImage()"
                (mouseleave)="MouseLeaveGeneralImage()">

                <lib-image-action-menu-general 
                    [showActionMenu]="showGeneralImageActionMenu"
                    [disableImageDetails]="disableImageDetails"
                    (imageActionClicked)="ImageActionClicked($event)">
                </lib-image-action-menu-general>
                
                <ng-content select="[image]"></ng-content>
            </div>
        </div>

    </div>
</ng-template>

