import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { ButtonModule } from '@library/button';
import { IconModule } from '@library/icon';
import { UtilityModule } from '@library/utility';
import { VideoPlayerModule } from '@library/video-player';
import { AttachmentComponent } from './attachment.component';

@NgModule({
    declarations: [
        AttachmentComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        MatDialogModule,
        ButtonModule,
        MatDividerModule,
        MatExpansionModule,
        UtilityModule,
        IconModule,
        VideoPlayerModule
    ],
    exports: [
        AttachmentComponent
    ]
})
export class AttachmentModule { }
