import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { BaseViewComponent, ErrorMessages, FormBase, Optional, Required, string } from '@library/base';
import { FormAffinity, ParentSignupInputItem, VerifyEmailInputItem, WebsiteBlockSignupFormDisplayItem } from '@library/data-models';
import { ParentSignupFrontendInputItem, StudentSignupFrontendInputItem } from '../signup-form.definitions';
import { SignupFormCustomFieldsComponent } from '../custom-fields/signup-form-custom-fields.component';
import { firstValueFrom } from 'rxjs';
import { ApiBaseRoutes } from '@library/api';
import { FormControl, Validators } from '@angular/forms';


class AddContactForm extends FormBase {
    PrimaryContact = Optional(string);
    FirstName = Required(string);
    LastName = Required(string);
    Email = Required(string);
    HomePhone = Required(string);
    MobilePhone =  Required(string);
    Address = Required(string);
    Referrer = Optional(string);
}

enum ContactType {
    NewContact = 'NewContact'
}

@Component({
    selector: 'lib-signup-form-design-block-step2',
    templateUrl: './signup-form-design-block-step2.component.html',
    styleUrls: ['./signup-form-design-block-step2.component.scss']
})
export class SignupFormDesignBlockStep2Component extends BaseViewComponent implements OnInit, AfterViewInit {

    @ViewChild(SignupFormCustomFieldsComponent, {static: false}) customFields!: SignupFormCustomFieldsComponent;

    @Input() adultStudents!: StudentSignupFrontendInputItem[];
    @Input() data!: WebsiteBlockSignupFormDisplayItem;
    @Input() supportsStoredPaymentMethods!: boolean;
    @Input() parent!: ParentSignupFrontendInputItem;
    @Input() set enableSave(value: boolean | null){
        if(value !== null){
            this._disableSave = !value;
        }
    };

    @Output() nextClicked: EventEmitter<ParentSignupFrontendInputItem> = new EventEmitter();
    @Output() backClicked: EventEmitter<ParentSignupFrontendInputItem> = new EventEmitter();

    readonly addContactForm = AddContactForm.Create(false);

    readonly firstNameError: ErrorMessages = {
        required: _=> $localize`:@@SUFAddStudentFirstNameMissingError:Please enter a first name`
    }

    readonly lastNameError: ErrorMessages = {
        required: _=> $localize`:@@SUFAddStudentLastNameMissingError:Please enter a last name`
    }

    readonly emailError: ErrorMessages = {
        required: _=> $localize`:@@SUFAddStudentEmailMissingError:Please enter an email address`,
        api: _=> $localize`:@@SUFAddStudentVerifyEmailAPIError:Please enter a valid email address`
    }

    readonly phoneError: ErrorMessages = {
        required: _=> $localize`:@@SUFAddStudentPhoneMissingError:Please enter a valid home phone or mobile phone number`,
    }

    readonly addressError: ErrorMessages = {
        required: _=> $localize `:@@SUFAddressMissingError: Please enter an address`
    }

    private _parent!: ParentSignupFrontendInputItem;
    private _disableSave: boolean = false;

    override ngOnInit(): void {
        super.ngOnInit();

        if(this.adultStudents.length > 0) {
            this.SetStudentAsPrimaryContact(this.adultStudents[0].ID);
        } else {
            this.addContactForm.controls.PrimaryContact.setValue(ContactType.NewContact);
        }

        if(!this.data.CollectAddress){
            this.addContactForm.controls.Address.disable();
        }
    }

    ngAfterViewInit(): void {
        //ON Back of Step 3 - prefill the Parent Info
        if(this.parent){
            this.addContactForm.patchValue({
                PrimaryContact: this.parent.ID,
                FirstName: this.parent.FirstName!,
                LastName: this.parent.LastName!,
                Email: this.parent.EmailAddress!,
                HomePhone: this.parent.TelephoneNumberHome!,
                MobilePhone: this.parent.TelephoneNumberMobile!,
                Address: this.parent.Address!,
                Referrer: this.parent.Referrer!
            });
            this.customFields.PatchCustomFieldValues(this.parent.CustomFieldValues);

            if (this.parent.ID === ContactType.NewContact) {
                this.addContactForm.controls.FirstName.enable();
                this.addContactForm.controls.LastName.enable();
                this.addContactForm.controls.Email.enable();
            }

        }
    }

    PrimaryContactChanged(contactID: string): void {
        if(contactID !== ContactType.NewContact){
            this.SetStudentAsPrimaryContact(contactID);
        } else {
            this.addContactForm.patchValue({
                FirstName: '',
                LastName: '',
                Email: ''
            });
            this.addContactForm.controls.FirstName.enable();
            this.addContactForm.controls.LastName.enable();
            this.addContactForm.controls.Email.enable();
        }
    }

    BackClicked(): void {
        this.SaveParent();
        this.backClicked.emit(this._parent);
    }

    async NextClicked(): Promise<void> {
        if(await this.VerifyAndAddParent()){
            this.nextClicked.emit(this._parent);
        }
    }

    private SetStudentAsPrimaryContact(contactID: string): void {
        const primaryContact = this.adultStudents.find(student => student.ID === contactID)!;
        this.addContactForm.patchValue({
            PrimaryContact: primaryContact.ID,
            FirstName: primaryContact.FirstName!,
            LastName: primaryContact.LastName!,
            Email: primaryContact.EmailAddress!
        });

        this.addContactForm.controls.FirstName.disable();
        this.addContactForm.controls.LastName.disable();
        this.addContactForm.controls.Email.disable();
    }

    private async VerifyAndAddParent(): Promise<boolean> {

        this._disableSave = true;

        let isEmailValid: boolean = true;
        if(!this.addContactForm.controls.Email.disabled && this.addContactForm.controls.Email.value){

            isEmailValid = await firstValueFrom(ApiBaseRoutes.Email.VerifyEmail.Call({
                Body: new VerifyEmailInputItem({
                    EmailAddress: this.addContactForm.controls.Email.value
                })
            }));
        }

        if(this.addContactForm.CheckValidity(false) && isEmailValid){
            this.SaveParent();
            return true;
        } else if (!isEmailValid) {
            this.addContactForm.controls.Email.SetApiError();
        }

        this._disableSave = false;

        return false;
    }

    private SaveParent(): void {
        const form = this.addContactForm.controls;
        const inputItem = new ParentSignupFrontendInputItem({
            FirstName: form.FirstName.value,
            LastName: form.LastName.value,
            EmailAddress: form.Email.value,
            TelephoneNumberHome: form.HomePhone.value,
            TelephoneNumberMobile: form.MobilePhone.value,
            Address: form.Address.value,
            Referrer: form.Referrer.value,
            ID: form.PrimaryContact.value
        });
        inputItem.CustomFieldValues = this.customFields.GetCustomFieldValues();
        this._parent = inputItem;
    }

    HomeChanged(value: string): void {
        if(value && !this.addContactForm.controls.MobilePhone.value){
            this.addContactForm.controls.MobilePhone.setValidators(null);
            this.addContactForm.controls.MobilePhone.isRequired = false;
        } else if (!value) {
            if(!this.addContactForm.controls.MobilePhone.value){
                this.addContactForm.controls.HomePhone.setValidators(Validators.required);
                this.addContactForm.controls.HomePhone.isRequired = true;
            } else {
                this.addContactForm.controls.HomePhone.setValidators(null);
                this.addContactForm.controls.HomePhone.isRequired = false;
            }
            this.addContactForm.controls.HomePhone.updateValueAndValidity();
            this.addContactForm.controls.MobilePhone.setValidators(Validators.required);
            this.addContactForm.controls.MobilePhone.isRequired = true;
        }
        this.addContactForm.controls.MobilePhone.updateValueAndValidity();
    }

    MobileChanged(value: string): void {
        if(value && !this.addContactForm.controls.HomePhone.value){
            this.addContactForm.controls.HomePhone.setValidators(null);
            this.addContactForm.controls.HomePhone.isRequired = false;
        } else if(!value) {
            if(!this.addContactForm.controls.HomePhone.value) {
                this.addContactForm.controls.MobilePhone.setValidators(Validators.required);
                this.addContactForm.controls.MobilePhone.isRequired = true;
            } else {
                this.addContactForm.controls.MobilePhone.setValidators(null);
                this.addContactForm.controls.MobilePhone.isRequired = false;
            }
            this.addContactForm.controls.MobilePhone.updateValueAndValidity();
            this.addContactForm.controls.HomePhone.setValidators(Validators.required);
            this.addContactForm.controls.HomePhone.isRequired = true;
        }
        this.addContactForm.controls.HomePhone.updateValueAndValidity();
    }

    get FormAffinity(): typeof FormAffinity {
        return FormAffinity;
    }

    get ContactType(): typeof ContactType {
        return ContactType;
    }

    get disableSave(): boolean {
        return this._disableSave;
    }

}
