import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription, take } from 'rxjs';
import { DialogEvents, DialogSize } from '../definitions/dialog.definitions';
import { BaseViewComponent } from './base-view.component';
import { FormBase } from '../definitions/forms/form.definitions';

@Component({
    template: ''
})
export class DialogBaseViewComponent<T = null> extends BaseViewComponent implements OnInit, OnDestroy {
    private _afteOpenSubscription!: Subscription;
    private _afteCloseSubscription!: Subscription;
    private _beforeCloseSubscription!: Subscription;
    private _clickedOutsideSubscription!: Subscription;
    protected _primaryActionClicked: boolean = false;
    protected _secondaryActionClicked: boolean = false;
    protected dialogRef!: MatDialogRef<DialogBaseViewComponent<T>>;
    protected _data: T;
    events: Subject<DialogEvents> = new Subject<DialogEvents>();

    constructor() {
        super();

        this.dialogRef = inject(MatDialogRef);
        this._data = inject(MAT_DIALOG_DATA, {optional: true});

        this._afteOpenSubscription = this.dialogRef.afterOpened().subscribe( _ => {
            this.events.next(DialogEvents.AfterOpened);
        });

        this._afteCloseSubscription = this.dialogRef.afterClosed().subscribe( _ => {
            this.events.next(DialogEvents.AfterClosing);
            if(this._primaryActionClicked){
                this.events.next(DialogEvents.AfterClosingFromPrimaryAction);
            }            
            this._afteCloseSubscription.unsubscribe(); //We unsubrscribe here instead of onDestroy, otherwise this subscription has been cleared before we get here!
        });

        this._beforeCloseSubscription = this.dialogRef.beforeClosed().subscribe( _ => {
            FormBase.ignoreCreatedForms = false;
            this.events.next(DialogEvents.BeforeClosing);
            if(this._primaryActionClicked){
                this.events.next(DialogEvents.BeforeClosingFromPrimaryAction);
            }  
        });

        this._clickedOutsideSubscription = this.dialogRef.backdropClick().subscribe(_ => {
            this.events.next(DialogEvents.ClickedOutside);
        });
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this._afteOpenSubscription.unsubscribe();
        // this._afteCloseSubscription.unsubscribe(); //Note: See Above
        this._beforeCloseSubscription.unsubscribe();
        this._clickedOutsideSubscription.unsubscribe();
    }

    DismissAction() {
        this.events.next(DialogEvents.Dismissed);
        this.dialogRef.close();
    }

    PrimaryAction() {
        this._primaryActionClicked = true;
        this.events.next(DialogEvents.PrimaryAction);
    }

    SecondaryAction() {
        this._secondaryActionClicked = true;
        this.events.next(DialogEvents.SecondaryAction);
    }

    EnablePrimaryAction(): void {
        this._primaryActionClicked = false;
    }

    get secondaryActionClicked(): boolean {
        return this._secondaryActionClicked;
    }

    get primaryActionClicked(): boolean {
        return this._primaryActionClicked;
    }

    get data(): T {
        return this._data;
    }
    get DialogSize(): typeof DialogSize{
        return DialogSize;
    }
}
