<div  class="d-flex justify-content-center position-relative design-block-preview"
(mouseenter)="MouseEntered()"
(mouseleave)="MouseLeave()">
<div *ngIf="showActionMenu" class="design-block-action-menu">
    <div class="d-flex gap-2 px-2">
        <lib-button *ngIf="showMultiSelectImage"
            [kind]="ButtonKind.TertiaryLink"
            [prefixIcon]="Icon.Common.CollectionCircleAdd"
            [size]="ButtonSize.Small"
            [iconOnly]="true"
            [disabled]="disableMultiSelectImage"
            (clicked)="OpenMultiSelectMediaGallery()">
        </lib-button>
        <lib-button *ngIf="showAdd"
            [kind]="ButtonKind.TertiaryLink"
            [prefixIcon]="Icon.Common.Add"
            [size]="ButtonSize.Small"
            [disabled]="disableAddItem"
            [iconOnly]="true"
            (clicked)="AddItemClicked()">
        </lib-button>
        <lib-button *ngIf="showRemove"
            [kind]="ButtonKind.TertiaryLink"
            [prefixIcon]="Icon.Common.Remove"
            [disabled]="disableRemoveItem"
            [size]="ButtonSize.Small"
            [iconOnly]="true"
            (clicked)="RemoveItemClicked()">
        </lib-button>
        <lib-button
            [kind]="ButtonKind.Tertiary"
            [prefixIcon]="Icon.Common.ArrowUpward"
            [size]="ButtonSize.Small"
            [disabled]="disableMoveUp"
            [iconOnly]="true"
            (clicked)="MoveUpClicked()">
        </lib-button>
        <lib-button
            [kind]="ButtonKind.Tertiary"
            [prefixIcon]="Icon.Common.ArrowDownward"
            [size]="ButtonSize.Small"
            [disabled]="disableMoveDown"
            [iconOnly]="true"
            (clicked)="MoveDownClicked()">
        </lib-button>
        <lib-button *ngIf="showEdit"
            [kind]="ButtonKind.Tertiary"
            [prefixIcon]="Icon.Common.Edit"
            [size]="ButtonSize.Small"
            [iconOnly]="true"
            (clicked)="EditClicked()">
        </lib-button>
        <lib-button
            [kind]="ButtonKind.DangerSecondary"
            [prefixIcon]="Icon.Common.DeleteOutline"
            [size]="ButtonSize.Small"
            [iconOnly]="true"
            (clicked)="DeleteClicked()">
        </lib-button>
    </div>
</div>
    <ng-content></ng-content>
</div>
