<div class="v3" >
    <div [ngClass]="{'d-flex flex-row justify-content-between align-items-end': menuButton?.innerHTML,'form-field-menu-button-view-only': isViewOnly}" 
        (click)="containerClicked.emit($event)">
        <lib-label
            [isOptional]="false"
            [isReadOnly]="isReadOnly">
            <ng-content select="[label]"></ng-content>
            <ng-content hintMessage select="[hintMessage]"></ng-content>
            <ng-content customAction select="[customAction]"></ng-content>
        </lib-label>
        <div class="d-flex justify-content-end align-items-center" style="max-height: 20px;">
            <div #menuButton class="text-nowrap d-flex justify-content-end align-items-center" style="max-height: 20px;">
                <ng-content select="[menu]"></ng-content>
            </div>
        </div>
    </div>
    
    <form [formGroup]="mfsForm">
        <div class="d-flex justify-content-between w-100">
            <div *ngFor="let control of mfsForm.controls | keyvalue; let last = last" class="scss-mfa-input-width" (click)="Click(control.value)" [ngClass]="{'me-1':!last}">
                <lib-input-number
                    [formControl]="control.value"
                    [isOptional]="false"
                    [maxCharacterLength]="1"
                    [hideErrors]="true"
                    (blur)="Blur()">
                </lib-input-number>
            </div>
        </div>
    </form>

    <lib-error *ngIf="hasError"
        [control]="formControl"
        [errors]="errors">
    </lib-error>
</div>