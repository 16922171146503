import { Injectable } from "@angular/core";
import { StyleManager } from "./style.manager";
import { WebsiteDisplayItem, WebsiteSocialLinksLocation } from "@library/data-models";

@Injectable({
    providedIn: 'root',
})
export class WebsiteManager {

    constructor(private _StyleManager: StyleManager){}

    SetWebsiteColors(backgroundColor: string, accentColor: string,hColor: string, fColor: string): void {
        if(hColor && fColor){
            this.SetHeaderFooterColors(hColor, fColor);
        }
        this.SetBlockAccentColor(accentColor);
        this.SetWebsiteColor(backgroundColor);
    }

    SetHeaderFooterColors(hColor: string, fColor: string): void {
        this.SetHeaderBackgroundColor(hColor);
        this.SetFooterBackgroundColor(fColor);

    }

    SetBlockAccentColor(accent: string): void {
        this.SetBlockAccentBackgroundColor(accent);
        this._StyleManager.SetCSSPropertyValue('--block-text-color', this.GetTextColor(accent));
    }

    SetWebsiteColor(background: string): void {
        this.SetWebsiteBackgroundColor(background);
        this.SetLoadingAnimationColor(background);
        this._StyleManager.SetCSSPropertyValue('--website-text-color', this.GetTextColor(background));
    }

    SetLoadingAnimationColor(color: string): void {
        if (this.IsLightBackground(color)) {
            this._StyleManager.SetCSSPropertyValue('--loading-skeleton-color-1', '#e0e0e0');
            this._StyleManager.SetCSSPropertyValue('--loading-skeleton-color-2', '#f0f0f0');
        } else {
            this._StyleManager.SetCSSPropertyValue('--loading-skeleton-color-1', '#50535a');
            this._StyleManager.SetCSSPropertyValue('--loading-skeleton-color-2', '#656871');
        }
    }

    GetTextColor(color: string): string {
        return this.IsLightBackground(color) ? '#222222' : '#fff';
    }

    IsLightBackground(color: string): boolean {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substring(0, 0 + 2), 16);
        const c_g = parseInt(hex.substring(2, 2 + 2), 16);
        const c_b = parseInt(hex.substring(4, 4 + 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness > 175;
    }

    SetWebsiteBackgroundColor(color: string): void {
        this._StyleManager.SetCSSPropertyValue('--website-background-color', color);
    }

    SetBlockAccentBackgroundColor(color: string): void {
        this._StyleManager.SetCSSPropertyValue('--block-accent-background-color', color);
    }

    SetBlockTextFont(headingFont: string, bodyFont: string): void {
        this._StyleManager.SetCSSPropertyValue('--block-text-heading-font', headingFont);
        this._StyleManager.SetCSSPropertyValue('--block-text-body-font', bodyFont);
    }

    SetHeaderBackgroundColor(color: string): void {
        this._StyleManager.SetCSSPropertyValue('--website-header-background-color', color);
        this._StyleManager.SetCSSPropertyValue('--website-header-text-color', this.GetTextColor(color));
    }

    SetFooterBackgroundColor(color: string): void {
        this._StyleManager.SetCSSPropertyValue('--website-footer-background-color', color);
        this._StyleManager.SetCSSPropertyValue('--website-footer-text-color', this.GetTextColor(color));
    }

    SetWidgetAccentContrastColor(color: string) {
        this._StyleManager.SetCSSPropertyValue('--widget-accent-contrast-color', this.GetTextColor(color));
    }

    ShowSocialLinksInHeader(linksLocation: WebsiteSocialLinksLocation): boolean {
        return linksLocation == WebsiteSocialLinksLocation.All || linksLocation == WebsiteSocialLinksLocation.Header;
    }

    ShowSocialLinksInFooter(linksLocation: WebsiteSocialLinksLocation): boolean {
        return linksLocation == WebsiteSocialLinksLocation.All || linksLocation == WebsiteSocialLinksLocation.Footer;
    }

}
