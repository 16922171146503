import { Pipe, PipeTransform } from "@angular/core";
import { Icon, IconItem } from "@library/base";
import { PaymentSourceDisplayItem, PaymentSourceType } from "@library/data-models";

@Pipe({name: 'paymentIssuerIcon'})
export class PaymentIssuerIconPipe implements PipeTransform {

    transform(paymentSource: PaymentSourceDisplayItem): { Icon: IconItem } {
        const issuer = paymentSource.Issuer!.toLowerCase();
        if (paymentSource.Type === PaymentSourceType.BankAccount) {
            return { Icon: Icon.Payment.Savings };
        } else if (paymentSource.Type === PaymentSourceType.CreditCard) {
            switch (issuer) {
                case 'mastercard':
                    return { Icon: Icon.Payment.MasterCardLight };
                case 'visa':
                    return { Icon: Icon.Payment.VisaLight };
                case 'american express':
                    return { Icon: Icon.Payment.Amex };
                case 'discover':
                    return { Icon: Icon.Payment.Discover };
                case 'diners club':
                    return { Icon: Icon.Payment.DinersClub };
                case 'maestro':
                    return { Icon: Icon.Payment.Maestro };
                case 'jcb':
                    return { Icon: Icon.Payment.JCB };
                default:
                    return { Icon: Icon.Payment.CreditCard };
            }
        } else if (paymentSource.Type === PaymentSourceType.StripeLink) {           
            return { Icon: Icon.Payment.CCStripe };
        } else if (paymentSource.Type === PaymentSourceType.PayPal) {
            return { Icon: Icon.Payment.PayPal };
        } else if (paymentSource.Type === PaymentSourceType.Venmo) {
            return { Icon: Icon.Payment.Venmo };
        }

        throw Error("Unknown payment source type.");
    }
}
