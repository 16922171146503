import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { BaseViewComponent } from '@library/base';


@Component({
    selector: 'lib-expansion-card',
    templateUrl: './expansion-card.component.html',
    styleUrls: ['./expansion-card.component.scss']
})
export class ExpansionCardComponent extends BaseViewComponent implements OnInit, AfterViewInit {

    @ViewChild('panel', {static: true}) expansionPanel!: MatExpansionPanel;

    @Input() startExpanded!: boolean;
    @Input() optional!: boolean;
    

    @Output() panelStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

    ngAfterViewInit(): void {
        if(this.startExpanded) {
            this.expansionPanel.open();
        }
    }
    
    TogglePanel(panel: MatExpansionPanel): void {
        this.panelStatus.emit(panel.expanded);
    }

    get isCardExpanded(): boolean {
        return this.expansionPanel.expanded;
    }

}
