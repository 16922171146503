<div
    [ngClass]="{'scss-block-background-color-variable': kind === WebsiteBlockTextAndMediaType.CallToActionAccentBackground || kind === WebsiteBlockTextAndMediaType.CallToActionAccentBackgroundDescription, 
                'scss-website-background-color': kind === WebsiteBlockTextAndMediaType.CallToActionDefault || kind === WebsiteBlockTextAndMediaType.CallToActionDescription,
                'scss-block-color-variable-font' : kind === WebsiteBlockTextAndMediaType.CallToActionAccentBackground || kind === WebsiteBlockTextAndMediaType.CallToActionAccentBackgroundDescription}"
    [ngSwitch]="websiteMode">
    <ng-container *ngSwitchCase="WebsiteMode.Live">
        <div class="d-flex position-relative justify-content-center design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.ReadOnly">
        <div class="d-flex position-relative justify-content-center design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.Preview">
        <lib-design-blocks-action-menu
                [disableMoveUp]="disableMoveUp"
                [disableMoveDown]="disableMoveDown"
                [showEdit]="true"
                (moveDownClicked)="MoveDownClicked()"
                (moveUpClicked)="MoveUpClicked()"
                (deleteClicked)="DeleteClicked()"
                (editClicked)="EditClicked()">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </lib-design-blocks-action-menu>
    </ng-container>
</div>

<ng-template #blockContent>
    <div class="d-flex justify-content-center call-to-action-block">
        <div class="d-flex justify-content-center scss-call-to-action-text-button-spacing flex-column">
            <div class="d-flex flex-column w-100 h-100 justify-content-center scss-call-to-action-header-body-spacing">
                <h2 class="d-flex justify-content-center"><ng-content select="[header]"></ng-content></h2>
                <p *ngIf="hasBody" class="d-flex text-center typography-text-media-body-font text-break">
                    <ng-content select="[body]"></ng-content>
                </p>
            </div>
            <div class="d-flex justify-content-center">
                <button type="button" [ngClass]="
                    {'scss-call-to-action-button': kind === WebsiteBlockTextAndMediaType.CallToActionDefault || kind === WebsiteBlockTextAndMediaType.CallToActionDescription,
                    'scss-call-to-action-button-accent': kind === WebsiteBlockTextAndMediaType.CallToActionAccentBackground || kind === WebsiteBlockTextAndMediaType.CallToActionAccentBackgroundDescription,
                    'pe-none': websiteMode != WebsiteMode.Live}"
                    (click)="ButtonClicked()">
                    <p class="scss-call-to-action-button-text">{{action?.Text}}</p>
                </button>
            </div>
        </div>
    </div>
</ng-template>