import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BaseViewComponent, WebsiteMode } from '@library/base';
import { WebsiteDisplayItem, WebsitePageDisplayItem } from '@library/data-models';
import { WebsiteManager } from '@library/managers';

@Component({
    selector: 'lib-website-navigation-sidebar',
    templateUrl: './website-navigation-sidebar.component.html',
    styleUrls: ['./website-navigation-sidebar.component.scss']
})
export class WebsiteNavigationSidebarComponent extends BaseViewComponent {

    private _chevronRightClicked: boolean = false;
    private _isSideNavOpened: boolean = false;
    private _pageWithSubMenuItems: WebsitePageDisplayItem | null = null;
    
    private _websiteData!: WebsiteDisplayItem;
    @Input()
    set websiteData(value: WebsiteDisplayItem) {
        this._websiteData = value;
    }

    @Input() ID: string = '';
    @Input() mode: WebsiteMode = WebsiteMode.Preview;

    @ViewChild('subMenuItems') subMenuItems!: TemplateRef<any>;
    @ViewChild('mainMenuItemsNav') mainMenuItemsNav!: MatSidenav;

    @Output() navigationPageClicked: EventEmitter<string> = new EventEmitter();

    constructor(private _WebsiteManager: WebsiteManager) {
        super();
    }

    NavigationPageClicked(pageID: string | null): void {
        if(pageID !== this.ID){
            this.navigationPageClicked.emit(pageID!);
        }
        this.mainMenuItemsNav.close();
        this._isSideNavOpened = false;
    }

    ToggleMainMenuSideNav(): void {
        this.mainMenuItemsNav.toggle();
        this._chevronRightClicked = false;
        this._isSideNavOpened = !this._isSideNavOpened;
    }

    CloseMainMenuSideNav(): void {
        if (this.mainMenuItemsNav && this.mainMenuItemsNav.opened) {
            this.mainMenuItemsNav.close();
            this._chevronRightClicked = false;
            this._isSideNavOpened = false;
        }
    }

    ToggleSubMenu(page: WebsitePageDisplayItem | null): void {
        this._chevronRightClicked = !this._chevronRightClicked;
        this._pageWithSubMenuItems = page;  
    }

    get chevronRightClicked(): boolean {
        return this._chevronRightClicked;
    }
    
    get websiteData(): WebsiteDisplayItem {
        return this._websiteData;
    }

    get pageWithSubMenuItems(): WebsitePageDisplayItem| null {
        return this._pageWithSubMenuItems;
    }

    get isSideNavOpened(): boolean {
        return this._isSideNavOpened;
    }

    get socialLinksInHeader(): boolean {
        return this._WebsiteManager.ShowSocialLinksInHeader(this._websiteData.SocialLinksLocation);
    }

    get WebsiteMode(): typeof WebsiteMode {
        return WebsiteMode;
    }
}
