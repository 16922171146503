export enum ListMode {
    ExpandableMultiSelect,
    ExpandableSingleSelect,
    Expandable,
    MultiSelect,
    SingleSelect,
    None
}

export enum ListAction {
    SelectAll,
    UnselectAll,
    ExpandAll,
    UnexpandAll,
    SelectPage
}