import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseViewComponent, StatusKind, IconItem } from '@library/base';

@Component({
    selector: 'lib-status-label',
    templateUrl: './status-label.component.html',
    styleUrls: ['./status-label.component.scss']
})
export class StatusLabelComponent extends BaseViewComponent implements OnInit {

    @Input() icon!: IconItem;
    @Input() kind: StatusKind = StatusKind.Neutral;
    @Input() clickable: boolean = false;
    @Input() qaTag: string = "";
    @Input() labelColor: string = "";
    @Input() showSpinner: boolean = false;
    @Input() toolTipText: string = "";

    @Output() clicked = new EventEmitter<void>()

    Clicked() {
        if (this.clickable) {
            this.clicked.emit();
        }
    }
}
