import { inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '../localization.service';
import { LocalizationNumberBasePipe } from './localization-number-base.pipe';

@Pipe({
    name: 'formatDecimal'
})
export class LocalizationDecimalPipe extends LocalizationNumberBasePipe implements PipeTransform {
    constructor() {
        super();
        this._NumberFormatInfo = inject(LocalizationService).NumberFormatInfo;
    }

    override transform(value: number, minNumOfDecimals = 0, maxNumOfDecimals = 16): string {
        return super.transform(value, minNumOfDecimals, maxNumOfDecimals);
    }

    protected IsValid(value: number, minNumOfDecimals: number, maxNumOfDecimals: number): boolean {
        return value != null &&
            this._NumberFormatInfo.DecimalSeparator != null &&
            this._NumberFormatInfo.GroupSeparator != null &&
            this._NumberFormatInfo.GroupSizes != null &&
            this._NumberFormatInfo.NegativePattern != null &&
            this._NumberFormatInfo.PositivePattern != null &&
            minNumOfDecimals != null &&
            maxNumOfDecimals != null;
    }
}
