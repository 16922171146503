import { IncludesStream } from "@library/data-models";


export enum AvatarSize {
    Small = 'avatar-small',
    Medium = 'avatar-medium',
    Large = 'avatar-large',
    Edit = 'avatar-edit'
}

export enum AvatarProfileCrop {
    Automatic,
    Center
}

export class AvatarProfileUploadData {
    Files!: File[];
    UseFaceDetection!: boolean;
}

export class AvatarData {
    Data?: UploadData[];
    Deleted: boolean = false;

    constructor(initializer?: Partial<AvatarData>) {
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

interface UploadData {
    ID?: string;
    InputItem?: IncludesStream;
    File: File;
}

export enum ProfileCardMode {
    Avatar,
    Logo,
    None
}

