
import { CalendarBirthdayEventDisplayItem, CalendarEventDisplayItem, CalendarRepertoirePerformanceDisplayItem } from "@library/data-models";
import { MbscCalendarColor, MbscCalendarEvent } from "@mobiscroll/angular";
import { IconItem } from "./icon.definitions";

export enum CalendarView {
    Month,
    Week,
    Day,
    Agenda,
    Timeline
}

export enum CalendarEventKind {
    Birthday,
    Calendar,
    RepertoirePerformance
} 

export enum CalendarEventAttendanceStatus {
    AllUnrecorded,
    AtLeastOnePresent,
    AllPresent,
    AtLeastOneAbsent,
    AllAbsent
}

export enum CalendarEventColorTheme {
    Light = "Light",
    HighContrast = "HighContrast"
}

export enum CalendarEventLabelMode {
    Detailed = "Detailed",
    Simple = "Simple"
}

export enum CalendarGroupEventLabelMode {
    Number = "Number",
    Names = "Names"
}

export interface SchoolBoxCalendarEvent extends MbscCalendarEvent {    
    kind: CalendarEventKind;
    data: CalendarBirthdayEventDisplayItem | CalendarRepertoirePerformanceDisplayItem | CalendarEventDisplayItem;
    birthdayData?: CalendarBirthdayEventDisplayItem;
    calendarData?: CalendarEventDisplayItem;
    repertoirePerformanceData?: CalendarRepertoirePerformanceDisplayItem;
}

export class CalendarRepertoirePerformance {
    RepertoireTitle!: string;
    StudentNames!: string[];
}

export type SchoolBoxCalendarEventData<T> =   T extends CalendarEventKind.Birthday ? CalendarBirthdayEventDisplayItem
                                            : T extends CalendarEventKind.RepertoirePerformance ? CalendarRepertoirePerformanceDisplayItem
                                            : T extends CalendarEventKind.Calendar ? CalendarEventDisplayItem
                                            : never;

export class CalendarAttendanceStatusIcon {
    Icon!: IconItem;
    Color!: string;
}

export class CalendarEventsCount {
    Birthday: Array<CalendarBirthdayEventDisplayItem> = [];
    Repertoire: Array<CalendarRepertoirePerformanceDisplayItem> = [];
    Calendar: Array<CalendarEventDisplayItem> = [];

}

export class CalendarEventDot {
    Color!: string;
}

export enum CalendarEventColorKind {
    Default,
    Inverse,
    Border
}

export interface CalendarDragAndDropItem {
    StartDate: string;
    EndDate: string;
    Resource: string;
}

export class CalendarDrawableColors {
    Line: MbscCalendarColor[] = [];
    TodayShading: MbscCalendarColor | null = null;
    Unavailabilities: MbscCalendarColor[] = [];
    BlockedLocations: MbscCalendarColor[] = [];
}