export class SnackbarData<T> {
    Duration: SnackBarDuration = SnackBarDuration.Basic;
    Data!: T;
    IsClosable: boolean = false;
    ActionButtonText: string | null = null;
    Layout: SnackbarLayout = SnackbarLayout.A;

    constructor(initializer: Partial<SnackbarData<T>>){
        Object.assign(this, initializer);
    }

}

export enum SnackbarEvents {
    AfterOpened,
    AfterClosing,
    OnAction,
    Dismissed
}

export enum SnackbarLayout {
    A,
    B
}

export enum SnackBarDuration {
    Basic = 4000,
    BasicCloseable = 6000,
    Action = 8000,
    ActionCloseable = 10000,
    Important = 0 // no auto-dismiss, same effect as undefined duration
}
