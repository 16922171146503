
import { CalendarSearchOptions } from "@library/data-models";
import { AnnouncementKind } from "./definitions/announcement.definitions";
import { CalendarView } from "./definitions/calendar.definitions";
import { TemporaryReportDownloads } from "./definitions/report.definitions";
import { StorageKey } from "./managers/storage.manager";

export const StorageBaseKeys = {
    TemporaryReports: StorageKey.Local<TemporaryReportDownloads>({ expireAfterMinutes: 2 }),
    CurrentAnnouncements: StorageKey.Local<AnnouncementKind[]>(),

    Calendar: {
        View: StorageKey.Local<CalendarView>({expireAfterRefresh: true}),
        SearchOptions: StorageKey.Session<CalendarSearchOptions | null>()
    }
}