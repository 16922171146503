import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ProgressBarKind } from '@library/base';

@Component({
    selector: 'lib-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

    private _value!: number;

    @Input() kind: ProgressBarKind = ProgressBarKind.Indeterminate;

    @Input()
    set value(value: number) {
        this._value = value;
        
        // cant use style manager but this is the same as using it
        document.documentElement.style.setProperty('--scss-progress-bar-width', this._value + "%");
    }

    ngOnInit(): void {
    }

    get ProgressBarKind(): typeof ProgressBarKind {
        return ProgressBarKind;
    }

    get value(): number {
        return this._value;
    }

}
