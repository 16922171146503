import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseViewComponent, EmptyStateGraphic } from '@library/base';

@Component({
    selector: 'lib-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent extends BaseViewComponent implements OnInit {

    @Input() searchActive: boolean = false;
    @Input() useBackground: boolean = true;
    @Input() height: number = 225;
    @Input() padding: number = 0; 
    @Input() roundBorders: boolean = false; 

    private _emptyStateGraphicURL: string = EmptyStateGraphic.HomeHeader;
    @Input()
    public set emptyStateGraphic(value: EmptyStateGraphic) {
        this._emptyStateGraphicURL =  value ;
    }

    @Output() clearClicked: EventEmitter<void> = new EventEmitter();


    public get emptyStateGraphicURL(): string {
        return this._emptyStateGraphicURL;
    }

    ClearSearchClicked(): void {
        this.clearClicked.emit();
    }
}
