<lib-dialog-layout>
    <ng-container title>
        {{ data.Title }}
    </ng-container>
    <ng-container content>
        <p>{{ data.Text }}</p>
    </ng-container>
    <ng-container submissionBar>
        <lib-submission-bar [kind]="SubmissionBarKind.DialogBasic">
            <lib-button
                id="LibraryDialogPrimaryButton"
                [loading]="primaryActionClicked"
                (clicked)="PrimaryAction()">
                <ng-container label>
                    {{ data.PrimaryButtonText }}
                </ng-container>
            </lib-button>
            <lib-button
                id="LibraryDialogSecondaryButton"
                [kind]="ButtonKind.Secondary"
                [disabled]="primaryActionClicked"
                (clicked)="DismissAction()">
                <ng-container label>
                    {{ data.SecondaryButtonText }}
                </ng-container>
            </lib-button>
        </lib-submission-bar>    
    </ng-container>
</lib-dialog-layout>
