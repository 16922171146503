import { AfterContentInit, AfterViewInit, Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormContainerKind, ItemWithID } from '@library/base';
import { FormFieldSelectBaseComponent } from '../form-field-select-base.component';
import { FormFieldSelectItemComponent } from '../form-field-select-item.component';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'lib-input-select',
    templateUrl: './form-field-select.component.html',
    styleUrls: ['./form-field-select.component.scss']
})
export class FormFieldSelectComponent<T extends ItemWithID | string> extends FormFieldSelectBaseComponent<T, T | null> implements AfterContentInit {

    @ViewChild(MatSelect, {static: false}) select!: MatSelect;
    @ViewChild('selectTrigger', {static: false}) selectTrigger!: ElementRef;
    
    @ContentChild('triggerTemplate', {static: true}) triggerTemplate!: TemplateRef<any>;

    @Input() kindOverride: FormContainerKind = FormContainerKind.Default;

    private _selected!: FormFieldSelectItemComponent<T>;
    private _selectedItemHTML: string = '';

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.itemMap) {
                this.SetSelected(this._viewValue)
            }
        });
    }

    override SortItems() {
        super.SortItems();
        this._viewValue = this.formControl.value ? this.identifierOf(this.formControl.value) : ''
        this.viewControl.setValue(this._viewValue);

        this.SetSelected(this._viewValue);
    }

    override writeValue(value: T | null) {
        if (this.identifierOf) {
            this._viewValue = value !== null ? this.identifierOf(value) : '';
            this.viewControl.setValue(this._viewValue);
            this.SetSelected(this._viewValue);
        }
    }

    ChangeEvent(event: MatSelectChange) {
        this.SetSelected(event.value);
        const value = this._selected?.value;
        this.SetFormControlValue(value ?? null);
    }

    private SetSelected(value: string): void {
        this._selected = this.itemMap.get(value)!;

        setTimeout(() => {
            if (this.selectTrigger?.nativeElement?.textContent) {
                this._selectedItemHTML = '';
            } else {
                this._selectedItemHTML = (this.select?.selected as MatOption)?._getHostElement().innerHTML;
            }
        });
    }

    get selected(): FormFieldSelectItemComponent<T> {
        return this._selected;
    }

    get selectedItemHTML(): string {
        return this._selectedItemHTML;
    }
}
