import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";

export const routeAnimations =
    trigger('routeAnimation', [
        transition('* => Parent', slideTo('left')),
        transition('* => Child', slideTo('right'))
    ]);

function slideTo(direction: string) {
    const optional = { optional: true };
    return [
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                [direction]: 0,
                opacity: 1,
                width: '100%'
            })
        ], optional),
        query(':enter', [
            style({[direction]: '-70%', opacity: 0})
        ]),
        group([
            query(':leave', [
                animate('800ms ease', style({[direction]: '70%', opacity: 0}))
            ]),
            query(':enter', [
                animate('800ms ease', style({[direction]: '0%', opacity: 1}))
            ])
        ]),

        query(':leave', animateChild()),
        query(':enter', animateChild())
    ];
}