import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list.component';
import { MatListModule } from '@angular/material/list';
import { ListItemRowComponent } from './item-row/list-item-row.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormFieldModule } from '@library/form-field';
import { IconModule } from '@library/icon';
import { ListGroupComponent } from './group/list-group.component';
import { ButtonModule } from '@library/button';
import { BaseModule } from '@library/base';



@NgModule({
    declarations: [
        ListComponent,
        ListItemRowComponent,
        ListGroupComponent
    ],
    imports: [
        CommonModule,
        MatListModule,
        MatExpansionModule,
        FormFieldModule,
        IconModule,
        ButtonModule,
        BaseModule
    ],
    exports: [
        ListComponent,
        ListItemRowComponent,
        ListGroupComponent
    ]
})
export class ListModule { }
