import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentContext, PaymentSourceMode } from '@library/base';

@Component({
    selector: 'lib-payment-page-confirmation',
    templateUrl: './payment-page-confirmation.component.html',
    styleUrls: ['./payment-page-confirmation.component.scss']
})
export class PaymentPageConfirmationComponent {
    @Input() mode!: PaymentSourceMode;
    @Input() context!: PaymentContext;
    @Input() showDoneButton!: boolean;
    @Output() doneClicked: EventEmitter<void> = new EventEmitter()



    DoneClicked(): void {
        this.doneClicked.emit();
    }

    get PaymentSourceMode(): typeof PaymentSourceMode {
        return PaymentSourceMode;
    }

    get PaymentContext(): typeof PaymentContext {
        return PaymentContext;
    }
}
