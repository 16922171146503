import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'lib-step',
    templateUrl: './multi-step-view-step.component.html',
    styleUrls: ['./multi-step-view-step.component.scss']
})
export class MultiStepViewStepComponent implements OnInit {

    @ViewChild('labelTemplate', {static: true, read: TemplateRef}) label!: TemplateRef<any>;
    @ViewChild('contentTemplate', {static: true, read: TemplateRef}) content!: TemplateRef<any>;


    @Input() isOptional: boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

}
