import {Component, EventEmitter, Input, Output } from '@angular/core';
import { WebsiteBlockTextAndMediaImageDisplayItem, WebsiteBlockTextAndMediaType } from '@library/data-models';
import { DesignBlockBaseViewComponent } from '@library/base';

@Component({
  selector: 'lib-photo-gallery-design-block',
  templateUrl: './photo-gallery-design-block.component.html',
  styleUrls: ['./photo-gallery-design-block.component.scss']
})

export class PhotoGalleryDesignBlockComponent extends DesignBlockBaseViewComponent {

    @Input() type: WebsiteBlockTextAndMediaType = WebsiteBlockTextAndMediaType.Default;
    @Input() images: WebsiteBlockTextAndMediaImageDisplayItem[] = [];
    private _middleImageIndex: number = 1;
    @Input() set middleImageIndex(value: number) {
        this._middleImageIndex = value;
    }

    private _swipeTouchStartCoord!: [number, number];
    private _swipeTouchStartTime!: number;

    Swipe(e: TouchEvent, isTouchStartEvent: boolean): void {

        if(this.type !== WebsiteBlockTextAndMediaType.PhotoGalleryGrid){

            const touchCoord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
            const time : number = new Date().getTime();

            if(isTouchStartEvent){
                this._swipeTouchStartCoord = touchCoord;
                this._swipeTouchStartTime = time;
            } else {
                const swipeDirection = [touchCoord[0] - this._swipeTouchStartCoord[0], touchCoord[1] - this._swipeTouchStartCoord[1]];
                const swipeDuration = time - this._swipeTouchStartTime;

                if (swipeDuration < 1000
                    && Math.abs(swipeDirection[0]) > 30 // delta X (horizontal distance) threshold
                    && Math.abs(swipeDirection[0]) > Math.abs(swipeDirection[1] * 3) // delta X > delta Y (more horizontal vs vertical enough) threshold -
                    ) {

                    const swipeRight = swipeDirection[0] < 0;

                    if(swipeRight){
                        this.Next();
                    } else {
                        this.Previous();
                    }
                }
            }
        }
    }

    override AddItemClicked(): void {
        super.AddItemClicked();
        this._middleImageIndex = ((this._middleImageIndex+1) % this.images.length);
    }

    override RemoveItemClicked(index: number = this._middleImageIndex): void {
        super.RemoveItemClicked();
        if (this.images.length > 3 && index >= 0) {
            this.images.splice(index, 1);
        }
        this._middleImageIndex = (this._middleImageIndex - 1 + this.images.length) % this.images.length;
    }

    Next(): void {
        this._middleImageIndex = ((this._middleImageIndex+1) % this.images.length);
    }

    Previous(): void {
        this._middleImageIndex = (((this._middleImageIndex-1)+this.images.length) % this.images.length);
    }


    get middleImageIndex(): number {
        return this._middleImageIndex;
    }

}
