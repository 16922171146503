import { inject, Injectable, Type } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Base, SnackbarBaseViewComponent, SnackbarData } from '@library/base';

import { SnackbarComponent } from './snackbar.component';
import { ActionClosableSnackbarComponent } from './action-closable-snackbar/action-closable-snackbar.component';

type Extract<T> = T extends SnackbarBaseViewComponent<infer V> ? SnackbarData<V> : never;

@Injectable({
    providedIn: 'root'
})
export class SnackbarService extends Base {

    private _Snackbar!: MatSnackBar;
    private _snackbarRef!: MatSnackBarRef<any>;

    constructor() { 
        super();
        this._Snackbar = inject(MatSnackBar);
    }

    CreateBasicSnackBar(data: SnackbarData<string>): SnackbarComponent {
        return this.ShowCustomSnackBar(SnackbarComponent, data, ['snackbar-container-spacing']);
    }

    ShowCustomSnackBar<T>(component: Type<T>, data: Extract<T>, panelClass: string [] = []): T {
        this._snackbarRef = this._Snackbar.openFromComponent(component, {
            panelClass : [...panelClass ],
            duration: data.Duration,
            horizontalPosition: 'center',
            verticalPosition:'bottom',
            data: data
        });

        return this._snackbarRef.instance;
    }

    CreateActionSnackBar(data: SnackbarData<string>): ActionClosableSnackbarComponent {
        return this.ShowCustomSnackBar(ActionClosableSnackbarComponent, data, ['snackbar-container-spacing']);
    }

    DismissSnackbar(): void {
        if (this._snackbarRef == null) {
            return;
        }

        this._snackbarRef.dismiss();
    }
}
