<ng-container *ngFor="let field of sortedFields">
    <ng-container [ngSwitch]="field.Field?.FieldType">
        <ng-container *ngSwitchCase="CustomFieldType.CheckedList">
            <div class="single-column-row-website-form" *ngLet="(CustomFieldDisplayItem.AsCheckedList(field.Field!)) as checkedListField">
                <lib-checkbox-group
                    [formControl]="field | method: GetFormControl"
                    [isViewOnly]="isViewOnly">
                    <ng-container label>
                        {{field.Name}}
                    </ng-container>
                    <lib-checkbox-group-item *ngFor="let option of checkedListField.Options"
                    [value]="option">
                        {{option}}
                    </lib-checkbox-group-item>
                </lib-checkbox-group>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="CustomFieldType.DropDownList">
            <div class="single-column-row-website-form" *ngLet="(CustomFieldDisplayItem.AsDropDownList(field.Field!)) as dropDownListField">
                <lib-input-select
                    [formControl]="field | method: GetFormControl"
                    [isOptional]="!dropDownListField.Required"
                    [sortBy]="null"
                    [placeholderText]="dropDownListField.Hint ? dropDownListField.Hint : ''"
                    [isViewOnly]="isViewOnly">
                    <ng-container label>
                        {{field.Name}}
                    </ng-container>
                    <lib-input-select-item *ngFor="let option of dropDownListField.Options"
                        [value]="option">
                        {{option}}
                    </lib-input-select-item>
                </lib-input-select>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="CustomFieldType.Email">
            <div class="single-column-row-website-form" *ngLet="(CustomFieldDisplayItem.AsEmail(field.Field!)) as emailField">
                <lib-input-text
                    [formControl]="field | method: GetFormControl"
                    [placeholderText]="emailField.Hint ? emailField.Hint : ''"
                    [isOptional]="!emailField.Required"
                    [isViewOnly]="isViewOnly"
                    [errorMessages]="emailError">
                    <ng-container label>
                        {{field.Name}}
                    </ng-container>
                </lib-input-text>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="CustomFieldType.MultiLine">
            <div class="single-column-row-website-form" *ngLet="(CustomFieldDisplayItem.AsMultiLine(field.Field!)) as multiLineField">
                <lib-input-text-area
                    [formControl]="field | method: GetFormControl"
                    [placeholderText]="multiLineField.Hint ? multiLineField.Hint : ''"
                    [isOptional]="!multiLineField.Required"
                    [isViewOnly]="isViewOnly">
                    <ng-container label>
                        {{field.Name}}
                    </ng-container>
                </lib-input-text-area>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="CustomFieldType.Policy">
            <div class="single-column-row-website-form" *ngLet="(CustomFieldDisplayItem.AsPolicy(field.Field!)) as policyField">
                <lib-checkbox
                    [formControl]="field | method: GetFormControl"
                    [isViewOnly]="isViewOnly">
                    <ng-container label>
                        {{field.Name}}
                    </ng-container>
                    {{policyField.Statement}}
                    <div *ngIf="policyField.Url">
                        <a [href]="policyField.Url | method: PreparePolicyURL" target="_blank">Click to View</a>
                    </div>
                </lib-checkbox>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="CustomFieldType.RadioList">
            <div class="single-column-row-website-form" *ngLet="(CustomFieldDisplayItem.AsRadioList(field.Field!)) as radioListField">
                <lib-radio-group
                    [formControl]="field | method: GetFormControl"
                    [isViewOnly]="isViewOnly">
                    <ng-container label>
                        {{field.Name}}
                    </ng-container>
                    <lib-radio-group-item *ngFor="let option of radioListField.Options"
                        [value]="option">
                        {{option}}
                    </lib-radio-group-item>
                </lib-radio-group>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="CustomFieldType.Text">
            <div class="single-column-row-website-form" *ngLet="(CustomFieldDisplayItem.AsText(field.Field!)) as textField">
                <lib-input-text
                    [formControl]="field | method: GetFormControl"
                    [placeholderText]="textField.Hint ? textField.Hint : ''"
                    [isOptional]="!textField.Required"
                    [isViewOnly]="isViewOnly">
                    <ng-container label>
                        {{field.Name}}
                    </ng-container>
                </lib-input-text>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
