import { AbstractControl, FormGroup } from "@angular/forms";
import { CustomFieldValueInputItem, ParentSignupInputItem, StudentSignupInputItem } from "@library/data-models";

export class StudentSignupFrontendInputItem extends StudentSignupInputItem {
    ID: string = '0';
}

export class ParentSignupFrontendInputItem extends ParentSignupInputItem {
    CustomFieldValues: CustomFieldValueInputItem[] = [];
    ID: string = '';

    constructor(initializer?: Partial<ParentSignupFrontendInputItem>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}



