import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgLetDirective } from './directives/ng-let.directive';
import { BaseViewComponent } from './views/base-view.component';
import { DialogBaseViewComponent } from './views/dialog-base-view.component';

import { TypedTemplateDirective } from './directives/typed-template.directive';
import { CastPipe } from './pipes/cast.pipe';
import { MethodPipe } from './pipes/method.pipe';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { CalendarBaseViewComponent } from './views/calendar-base-view.component';
import { SnackbarBaseViewComponent } from './views/snackbar-base-view.component';

@NgModule({
    declarations: [
        BaseViewComponent,
        DialogBaseViewComponent,
        NgLetDirective,
        MethodPipe,
        CalendarBaseViewComponent,
        SnackbarBaseViewComponent,
        CastPipe,
        TypedTemplateDirective,
        OrderByPipe
    ],
    imports: [
        CommonModule,
        LayoutModule,
    ],
    exports: [
        BaseViewComponent,
        DialogBaseViewComponent,
        NgLetDirective,
        MethodPipe,
        CalendarBaseViewComponent,
        SnackbarBaseViewComponent,
        CastPipe,
        TypedTemplateDirective,
        OrderByPipe
    ]
})
export class BaseModule { }
