<svg height="0" width="0">
  <defs>
      <clipPath id="svgClippedMediaLeftImage" clipPathUnits="objectBoundingBox">
          <path d="M0.058,0.486 C-0.145,0.839,0.243,1,0.488,1 C0.729,1,1,0.898,1,0.581 C1,-0.235,0.406,-0.119,0.058,0.486"></path>
      </clipPath>
  </defs>
</svg>

<svg height="0" width="0">
  <defs>
      <clipPath id="svgClippedMediaRightImage" clipPathUnits="objectBoundingBox">
          <path d="M0.944,0.487 C1,0.839,0.759,1,0.514,1 C0.273,1,0.001,0.898,0.001,0.581 C0.001,-0.234,0.596,-0.118,0.944,0.487"></path>
      </clipPath>
  </defs>
</svg>

<svg height="0" width="0">
  <defs>
      <clipPath id="svgClippedMediaLeftBackground" clipPathUnits="objectBoundingBox">
          <path d="M0.329,0.306 C0.287,0.337,0.237,0.336,0.191,0.345 C0.149,0.353,0.071,0.396,0.039,0.445 C-0.016,0.529,-0.017,0.721,0.062,0.786 C0.152,0.862,0.269,0.804,0.369,0.789 C0.523,0.765,0.552,0.956,0.701,0.997 C0.758,1,0.84,0.99,0.87,0.899 C0.908,0.783,0.864,0.645,0.92,0.537 C0.941,0.496,1,0.328,0.944,0.251 C0.897,0.224,0.849,0.205,0.801,0.184 C0.755,0.164,0.711,0.14,0.67,0.1 C0.615,0.047,0.556,-0.031,0.491,0.016 C0.407,0.076,0.405,0.25,0.329,0.306"></path>
      </clipPath>
  </defs>
</svg>

<svg height="0" width="0">
  <defs>
      <clipPath id="svgClippedMediaRightBackground" clipPathUnits="objectBoundingBox">
          <path d="M0.671,0.305 C0.713,0.336,0.763,0.335,0.809,0.344 C0.851,0.352,0.929,0.394,0.961,0.443 C1,0.528,1,0.72,0.938,0.785 C0.848,0.86,0.731,0.803,0.631,0.787 C0.477,0.764,0.448,0.955,0.299,0.996 C0.242,1,0.16,0.988,0.13,0.897 C0.092,0.782,0.136,0.644,0.08,0.535 C0.059,0.495,-0.074,0.326,0.056,0.25 C0.103,0.222,0.151,0.204,0.199,0.183 C0.245,0.162,0.289,0.139,0.33,0.099 C0.385,0.046,0.444,-0.032,0.509,0.014 C0.593,0.075,0.595,0.248,0.671,0.305"></path>
      </clipPath>
  </defs>
</svg>

<!-- Responsive clipath from abosolute to relative https://yoksel.github.io/relative-clip-path/ -->
<svg height="0" width="0">
  <defs>
      <clipPath id="svgPathBig" clipPathUnits="objectBoundingBox">
          <path d="M1,0.999 V0 H0.401 H0.379 C0.379,0,0.327,0.021,0.094,0.302 C0.027,0.383,-0.002,0.45,0,0.553 C0.003,0.7,0.078,0.791,0.213,0.886 C0.368,0.996,0.512,0.995,0.715,0.999 C0.827,1,1,0.999,1,0.999"></path>            </clipPath>
  </defs>
</svg>

<svg height="0" width="0">
  <defs>
      <clipPath id="svgPathBigLeft" clipPathUnits="objectBoundingBox">
          <path d="M0,0.999 V0 H0.599 H0.621 C0.621,0,0.673,0.021,0.906,0.302 C0.973,0.383,1,0.45,1,0.553 C0.997,0.7,0.922,0.791,0.787,0.886 C0.632,0.996,0.488,0.995,0.285,0.999 C0.173,1,0,0.999,0,0.999"></path>     </clipPath>
  </defs>
</svg>
