import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnouncementComponent } from './announcement.component';
import { IconModule } from '@library/icon';
import { ButtonModule } from '@library/button';
import { MatExpansionModule } from '@angular/material/expansion';



@NgModule({
    declarations: [
        AnnouncementComponent
    ],
    imports: [
        CommonModule,
        IconModule,
        ButtonModule,
        MatExpansionModule
    ],
    exports: [
        AnnouncementComponent
    ]
})
export class AnnouncementModule { }
