import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'lib-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
    @Input() isLoading: boolean = false;
    @Input() qaTag: string = "";
    @Input() disablePadding: boolean = false;

    @ViewChild('card', {static: true}) card!: ElementRef;

    constructor() { }

    ngOnInit(): void {
    }

}
