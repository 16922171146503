export interface Authentication {
    ReturnUrl: string,
    ProfileID: string,
    SchoolID: string,
    Name: string,
    AuthSequence: string,
    Impersonating: boolean,
    ProfileMode: string,
    FirstName: string,
    LastName: string,
    EmailAddress: string,
    ElevioUserHash: string,
    iat: number,
    exp: number,
    IntercomCompanyID: string,
    IntercomUserID: string,
}

export const TEACHER_PORTAL_AUTH_TOKEN_NAME = "TeacherPortalAuth";
export const STUDENT_PORTAL_AUTH_TOKEN_NAME = "StudentPortalAuth";