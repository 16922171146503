<div  [hidden]="loadingStripe" id="payment-element" class="single-column-row">
    <!-- Stripe Elements will create form elements here -->
</div>
<form [hidden]="loadingStripe" [formGroup]="addStripePaymentMethodForm">
    <lib-checkbox *ngIf="context != PaymentContext.SignupWidget && mode === PaymentSourceMode.Payment"
        [formControl]="addStripePaymentMethodForm.controls.SavePaymentSource"
        (change)="SavePaymentMethodForFuture($event)"
        [qaTag]="'save-payment-method-for-future-use-checkbox'">
        <ng-container
            i18n="@@CommonAddPaymentSourceSavePaymentSourceLabel">
            Save this payment method for future use
        </ng-container>
    </lib-checkbox>

    <div class="pb-2" [ngClass]="{'pt-3': mode === PaymentSourceMode.Payment}" *ngIf="context != PaymentContext.SignupWidget && (mode === PaymentSourceMode.Add || (mode === PaymentSourceMode.Payment && addStripePaymentMethodForm.controls.SavePaymentSource.value))">
        <lib-checkbox
            [formControl]="addStripePaymentMethodForm.controls.AutoPay"
            [qaTag]="'automatically-pay-emailed-invoices-checkbox'">
            <ng-container label
                i18n="@@CommonAddPaymentSourceAutoPayLabel">
                Auto Pay
            </ng-container>
            <ng-container i18n="@@CommonAddPaymentSourceAutoPayCheckboxDescription">
                Automatically pay emailed invoices
            </ng-container>
        </lib-checkbox>
    </div>

    <ng-container *ngIf="(context === PaymentContext.StudentPortal && mode === PaymentSourceMode.Add) || (context === PaymentContext.SignupWidget && mode === PaymentSourceMode.Payment)">
        <div *ngIf="paymentSurchargeCreditCard && paymentSurchargeCreditCard.Type !== SurchargeType.None && paymentSourceType === PaymentSourceType.CreditCard" class="py-2" [attr.data-sb-qa]="'surcharge-text-creditcard'">
            <lib-label i18n="@@CommonNoteLabel">Note</lib-label>
            <p *ngIf="paymentSurchargeCreditCard.Type === SurchargeType.Fixed" i18n="@@CommonStudentsPaymentSourcesCreditCardFixedSurchargeText">
                {{ schoolName }} applies a {{ paymentSurchargeCreditCard.Amount! | formatCurrency  }} convenience fee to all credit card payments.
            </p>
            <p *ngIf="paymentSurchargeCreditCard.Type === SurchargeType.Percentage" i18n="@@CommonStudentsPaymentSourcesCreditCardPercentageSurchargeText">
                {{ schoolName }} applies a {{ paymentSurchargeCreditCard.Amount! | formatPercent:2:2  }} convenience fee to all credit card payments.
            </p>
        </div>

        <div *ngIf="paymentSurchargeCreditCard && paymentSurchargeCreditCard.Type !== SurchargeType.None && paymentSourceType === PaymentSourceType.StripeLink" class="py-2" [attr.data-sb-qa]="'surcharge-text-stripelink'">
            <lib-label i18n="@@CommonNoteLabel">Note</lib-label>
            <p *ngIf="paymentSurchargeCreditCard.Type === SurchargeType.Fixed" i18n="@@CommonStudentsPaymentSourcesStripeLinkFixedSurchargeText">
                {{ schoolName }} applies a {{ paymentSurchargeCreditCard.Amount! | formatCurrency  }} convenience fee to all Stripe Link payments.
            </p>
            <p *ngIf="paymentSurchargeCreditCard.Type === SurchargeType.Percentage" i18n="@@CommonStudentsPaymentSourcesStripeLinkPercentageSurchargeText">
                {{ schoolName }} applies a {{ paymentSurchargeCreditCard.Amount! | formatPercent:2:2  }} convenience fee to all Stripe Link payments.
            </p>
        </div>

        <div *ngIf="paymentSurchargeACH && paymentSurchargeACH.Type !== SurchargeType.None && paymentSourceType === PaymentSourceType.BankAccount" class="py-2" [attr.data-sb-qa]="'surcharge-text-bank'">
            <lib-label i18n="@@CommonNoteLabel">Note</lib-label>
            <p *ngIf="paymentSurchargeACH.Type === SurchargeType.Fixed" i18n="@@CommonStudentsPaymentSourcesBankFixedSurchargeText">
                {{ schoolName }} applies a {{ paymentSurchargeACH.Amount! | formatCurrency  }} convenience fee to all bank payments.
            </p>
            <p *ngIf="paymentSurchargeACH.Type === SurchargeType.Percentage" i18n="@@CommonStudentsPaymentSourcesBankPercentageSurchargeText">
                {{ schoolName }} applies a {{ paymentSurchargeACH.Amount! | formatPercent:2:2  }} convenience fee to all bank payments.
            </p>
        </div>
    </ng-container>
</form>

<div [hidden]="!loadingStripe">
    <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="spinner-border base-100 m-5"></div>
    </div>
</div>