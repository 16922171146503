
    <div class="scss-website-page-header d-flex gap-2 p-3 align-items-center">
        <lib-icon
            [icon]="Icon.Common.Menu"
            [size]="IconSize.ExtraSmall"
            (click)="ToggleMainMenuSideNav()">
        </lib-icon>
        <ng-content select="[websiteTitleLogoSelect]"></ng-content>
    </div>
    <mat-sidenav-container [hasBackdrop]="false" [ngClass]="{
        'sidenav-height-minus-header-with-logo': websiteData.HeaderLogoMediaLibraryItemID,
        'sidenav-height-minus-header-without-logo': !websiteData.HeaderLogoMediaLibraryItemID}">

        <mat-sidenav #mainMenuItemsNav class="scss-website-sidenav h-100"
            mode="over">
            <mat-nav-list role="list">
                <mat-list-item role="listitem">
                    <div class="d-flex p-3 w-100 justify-content-end">
                        <lib-icon
                            [icon]="Icon.Common.Close"
                            [size]="IconSize.ExtraSmall"
                            (click)="CloseMainMenuSideNav()">
                        </lib-icon>
                    </div>
                </mat-list-item>



                <mat-list-item [ngClass]="{'d-none': chevronRightClicked}" role="listitem" *ngFor="let page of websiteData.WebsitePages">
                    <ng-container *ngIf="page.InMenu && page.ChildPages.length == 0">
                        <div (click)="NavigationPageClicked(page.ID)" class="w-100 p-3">
                            <h6 class="cursor-pointer">
                                <a class="scss-custom-no-link-styling" [href]="page.Slug" onclick="return false;">{{page.Title}}</a>
                            </h6>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="page.InMenu && page.ChildPages.length > 0">
                        <div (click)="NavigationPageClicked(page.ID)" class="w-100 p-3 d-flex justify-content-between align-items-center">
                            <h6 class="cursor-pointer">
                                <a class="scss-custom-no-link-styling" [href]="page.Slug" onclick="return false;">{{page.Title}}</a>
                            </h6>

                            <lib-icon
                                [icon]="Icon.Common.ChevronRight"
                                [size]="IconSize.ExtraSmall"
                                (click)="ToggleSubMenu(page); $event.stopPropagation();">
                            </lib-icon>
                        </div>
                    </ng-container>
                </mat-list-item>





                <mat-list-item role="listitem" *ngIf="pageWithSubMenuItems" [ngClass]="{'d-none': !chevronRightClicked}">
                    <div (click)="NavigationPageClicked(pageWithSubMenuItems.ID)" class="w-100 p-3 d-flex justify-content-between align-items-center">
                        <h6  class="cursor-pointer">
                            <a class="scss-custom-no-link-styling" [href]="pageWithSubMenuItems.Slug" onclick="return false;">{{pageWithSubMenuItems.Title}}</a>
                        </h6>

                        <lib-icon
                            [icon]="Icon.Common.ChevronLeft"
                            [size]="IconSize.ExtraSmall"
                            (click)="ToggleSubMenu(null)">
                        </lib-icon>
                    </div>
                </mat-list-item>

                <mat-list-item [ngClass]="{'d-none': !chevronRightClicked}" role="listitem" *ngFor="let childPage of pageWithSubMenuItems?.ChildPages">
                    <div class="w-100 p-3">
                        <h6 (click)="NavigationPageClicked(childPage.ID)"  class="cursor-pointer">
                            <a class="scss-custom-no-link-styling" [href]="childPage.Slug" onclick="return false;">{{childPage.Title}}</a>
                        </h6>

                    </div>
                </mat-list-item>
            </mat-nav-list>

            <div *ngIf="socialLinksInHeader" class="d-flex p-3 scss-website-page-header">
                <ng-content select="[socialMediaSelect]"></ng-content>
            </div>
        </mat-sidenav>

        <mat-sidenav-content class="d-flex flex-column scss-scroll-y">
            <ng-content select="[mainContentSelect]"></ng-content>
            <ng-content select="[footerMenuSelect]"></ng-content>
        </mat-sidenav-content>

    </mat-sidenav-container>

