import { Pipe, PipeTransform } from "@angular/core";
import { DeviceTypeManager, WebsiteBlockTypeData } from "@library/base";
import { WebsiteBlockContactFormType, WebsiteBlockLoginFormType, WebsiteBlockTextAndMediaType, WebsiteBlockType } from "@library/data-models";

@Pipe({name: 'scaleImageUrl'})
export class ScaleImageUrlPipe implements PipeTransform {

    constructor(private _DeviceTypeManager: DeviceTypeManager) {
    }

    transform(url: string, type: WebsiteBlockTypeData ): { src: string, srcset: string } {

        const imageSrcAttributes: { src: string, srcset: string } = {src: '', srcset: ''};

        if(type.BlockType === WebsiteBlockType.TextAndMedia){
            switch (type.BlockSubtype) {
                case WebsiteBlockTextAndMediaType.MediaLeft:
                case WebsiteBlockTextAndMediaType.MediaRight:
                case WebsiteBlockTextAndMediaType.MediaLeftTwoColumn:
                case WebsiteBlockTextAndMediaType.MediaRightTwoColumn:
                case WebsiteBlockTextAndMediaType.MediaLeftShadow:
                case WebsiteBlockTextAndMediaType.MediaRightShadow:
                case WebsiteBlockTextAndMediaType.MediaLeftClipped:
                case WebsiteBlockTextAndMediaType.MediaRightClipped:
                    imageSrcAttributes.src = `${url}?Width=250&Height=250`;
                    imageSrcAttributes.srcset = `${url}?Width=500&Height=500 2x, ${url}?Width=750&Height=750 3x`;
                    break;
                case WebsiteBlockTextAndMediaType.Testimonial:
                case WebsiteBlockTextAndMediaType.AccentBackgroundTestimonial:
                case WebsiteBlockTextAndMediaType.MediaRightTestimonial:
                case WebsiteBlockTextAndMediaType.MediaRightAccentBackgroundTestimonial:
                    imageSrcAttributes.src = `${url}?Width=170&Height=170`;
                    imageSrcAttributes.srcset = `${url}?Width=340&Height=340 2x, ${url}?Width=510&Height=510 3x`;
                    break;
                case WebsiteBlockTextAndMediaType.HeroBackgroundImage:
                    if(this._DeviceTypeManager.isMobile){
                        imageSrcAttributes.src = `${url}?Width=600&Height=375`;
                        imageSrcAttributes.srcset = `${url}?Width=1200&Height=750 2x, ${url}?Width=1800&Height=1125 3x`;
                    } else if(this._DeviceTypeManager.isTablet){
                        imageSrcAttributes.src = `${url}?Width=1280&Height=560`;
                        imageSrcAttributes.srcset = `${url}?Width=2560&Height=1120 2x, ${url}?Width=3840&Height=1680 3x`;
                    } else {
                        imageSrcAttributes.src = `${url}?Width=1920&Height=840`;
                        imageSrcAttributes.srcset = `${url}?Width=3840&Height=1680 2x, ${url}?Width=5760&Height=2520 3x`;
                    }
                    break;
                case WebsiteBlockTextAndMediaType.HeroSquareSideImage:
                case WebsiteBlockTextAndMediaType.HeroClippedSideImage:
                case WebsiteBlockTextAndMediaType.MediaLeftHeroSquareImage:
                case WebsiteBlockTextAndMediaType.MediaLeftHeroClippedImage:
                    if(this._DeviceTypeManager.isMobile){
                        imageSrcAttributes.src = `${url}?Width=600&Height=450`;
                        imageSrcAttributes.srcset = `${url}?Width=1200&Height=900 2x, ${url}?Width=1800&Height=1350 3x`;
                    } else if(this._DeviceTypeManager.isTablet){
                        imageSrcAttributes.src = `${url}?Width=1200&Height=900`;
                        imageSrcAttributes.srcset = `${url}?Width=2400&Height=1800 2x, ${url}?Width=3600&Height=2700 3x`;
                    } else {
                        imageSrcAttributes.src = `${url}?Width=960&Height=720`;
                        imageSrcAttributes.srcset = `${url}?Width=1920&Height=1440 2x, ${url}?Width=2880&Height=2160 3x`;
                    }
                    break;
                case WebsiteBlockTextAndMediaType.PhotoGalleryCarousel:
                case WebsiteBlockTextAndMediaType.PhotoGalleryCarouselOverlay:
                    if(this._DeviceTypeManager.isMobile){
                        imageSrcAttributes.src = `${url}?Width=540&Height=540`;
                        imageSrcAttributes.srcset = `${url}?Width=1080&Height=1080 2x, ${url}?Width=1620&Height=1620 3x`;
                    } else if(this._DeviceTypeManager.isTablet){
                        imageSrcAttributes.src = `${url}?Width=360&Height=360`;
                        imageSrcAttributes.srcset = `${url}?Width=720&Height=720 2x, ${url}?Width=1080&Height=1080 3x`;
                    } else {
                        imageSrcAttributes.src = `${url}?Width=450&Height=450`;
                        imageSrcAttributes.srcset = `${url}?Width=900&Height=900 2x, ${url}?Width=1350&Height=1350 3x`;
                    }
                    break;
                case WebsiteBlockTextAndMediaType.PhotoGalleryGrid:
                    if(this._DeviceTypeManager.isMobile){
                        imageSrcAttributes.src = `${url}?Width=280&Height=440`;
                        imageSrcAttributes.srcset = `${url}?Width=560&Height=880 2x, ${url}?Width=840&Height=1320 3x`;
                    } else {
                        imageSrcAttributes.src = `${url}?Width=380&Height=590`;
                        imageSrcAttributes.srcset = `${url}?Width=760&Height=1180 2x, ${url}?Width=1140&Height=1770 3x`;
                    }
                    break;
                case WebsiteBlockTextAndMediaType.GallerySquareColumn:
                case WebsiteBlockTextAndMediaType.GalleryCircleColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareAccentColumn:
                case WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn:
                case WebsiteBlockTextAndMediaType.GallerySquareCardColumn:
                case WebsiteBlockTextAndMediaType.GalleryCircleCardColumn:
                    imageSrcAttributes.src = `${url}?Width=120&Height=120`;
                    imageSrcAttributes.srcset = `${url}?Width=240&Height=240 2x, ${url}?Width=360&Height=360 3x`;
                    break;
            }
        } else if (type.BlockType === WebsiteBlockType.ContactForm) {
            switch(type.BlockSubtype){
                case WebsiteBlockContactFormType.MediaLeft:
                case WebsiteBlockContactFormType.MediaRight:
                    if(this._DeviceTypeManager.isMobile){
                        imageSrcAttributes.src = `${url}?Width=540&Height=540`;
                        imageSrcAttributes.srcset = `${url}?Width=1080&Height=1080 2x, ${url}?Width=1620&Height=1620 3x`;
                    } else if(this._DeviceTypeManager.isTablet) {
                        imageSrcAttributes.src = `${url}?Width=930&Height=930`;
                        imageSrcAttributes.srcset = `${url}?Width=1860&Height=1860 2x, ${url}?Width=2790&Height=2790 3x`;
                    } else {
                        imageSrcAttributes.src = `${url}?Width=490&Height=490`;
                        imageSrcAttributes.srcset = `${url}?Width=980&Height=980 2x, ${url}?Width=1470&Height=1470 3x`;
                    }
                    break;
            }
        } else if (type.BlockType === WebsiteBlockType.LoginForm) {
            switch (type.BlockSubtype) {
                case WebsiteBlockLoginFormType.MediaLeft:
                case WebsiteBlockLoginFormType.MediaRight:
                    if(this._DeviceTypeManager.isMobile){
                        imageSrcAttributes.src = `${url}?Width=380&Height=380`;
                        imageSrcAttributes.srcset = `${url}?Width=760&Height=760 2x, ${url}?Width=1140&Height=1140 3x`;
                    } else {
                        imageSrcAttributes.src = `${url}?Width=490&Height=490`;
                        imageSrcAttributes.srcset = `${url}?Width=980&Height=980 2x, ${url}?Width=1470&Height=1470 3x`;
                    }
                    break;
            }
        }
        return imageSrcAttributes;
    }

}
