import { animate, transition, trigger } from '@angular/animations';
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { BaseViewComponent, ListMode } from '@library/base';
import { FormFieldCheckboxComponent } from '@library/form-field';
import { ListItemRowComponent } from './item-row/list-item-row.component';

@Component({
    selector: 'lib-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})

export class ListComponent<T> extends BaseViewComponent {

    @Input() listMode: ListMode = ListMode.ExpandableMultiSelect;
    @Input() defaultExpanded: boolean = false;
    @Input() listID!: string; // optional, used to save open/close state

    @ContentChildren(ListItemRowComponent) listItems!: QueryList<ListItemRowComponent<T>>;
    @ViewChild('list', {static: false, read: TemplateRef}) list!: TemplateRef<any>;
    @ViewChild('selectionList', {static: false}) selectionList!: MatSelectionList;
    @ViewChild('checkBox', {static: false}) checkBox!: FormFieldCheckboxComponent;

    @Output() selectionChange: EventEmitter<T[]> = new EventEmitter();

    private _panelOpened: boolean = false;
    private _isExpandable: boolean = true;
    private _isSelectable: boolean = true;
    private _hasFooter: boolean = false;
    private _footerExpanded: boolean = false;
    private _isIndeterminate: boolean = false;
    private _items: ListItemRowComponent<T>[] = [];

    override ngOnInit(): void {
        super.ngOnInit();
        this._isExpandable = this.listMode == ListMode.Expandable || this.listMode == ListMode.ExpandableMultiSelect || this.listMode == ListMode.ExpandableSingleSelect;
        this._isSelectable = this.listMode == ListMode.ExpandableMultiSelect || this.listMode == ListMode.MultiSelect
                            || this.listMode == ListMode.ExpandableSingleSelect || this.listMode == ListMode.SingleSelect;
        if (this.defaultExpanded) {
            this.SetPanelOpen(true);
        }
    }

    ngAfterContentInit(): void {
        this._items = Array.from(this.listItems);
        
        this.listItems.changes.subscribe(() => {
            this._items = Array.from(this.listItems);
        });
    }

    ngAfterViewInit(): void {
        if (this._isExpandable && this._isSelectable) {
            const selectedOptions = this.GetSelectedOptions();
            this.DetermineSelectAllChecked(selectedOptions);
        }
        this._hasFooter = this.listItems.filter(option => option.inFooter).length > 0;
    }

    SetPanelOpen(opened: boolean): void {
        this._panelOpened = opened;
    }

    SetFooterOpen(): void {
        this._footerExpanded = !this._footerExpanded;
    }

    SelectionChange(): void {
        const selectedOptions = this.GetSelectedOptions();
        this.DetermineSelectAllChecked(selectedOptions);
        this.selectionChange.emit(selectedOptions.map(option => option.value));
    }

    ToggleSelectAll(value: boolean): void {
        if (value) {
            this.selectionList.selectAll();
            this.checkBox.checked = true;
        } else {
            this.selectionList.deselectAll();
            this.checkBox.checked = false;
        }
        this.SelectionChange();
    }

    private DetermineSelectAllChecked(options: MatListOption[]): void {
        if (this._isExpandable && this._isSelectable && this.checkBox) {
            const isAllSelected = options.length == this.selectionList.options.length;
            this.checkBox.checked = isAllSelected;
            this._isIndeterminate = !isAllSelected && options.length > 0;
        }
    }

    private GetSelectedOptions(): MatListOption[] {
        return Array.from(this.selectionList?.options).filter(option => option.selected && !option.disabled);
    }

    get footerExpanded(): boolean {
        return this._footerExpanded;
    }

    get hasFooter(): boolean {
        return this._hasFooter;
    }

    get panelOpened(): boolean {
        return this._panelOpened;
    }

    get isExpandable(): boolean {
        return this._isExpandable;
    }

    get isSelectable(): boolean {
        return this._isSelectable;
    }

    get ListMode(): typeof ListMode {
        return ListMode;
    }

    get items(): ListItemRowComponent<T>[] {
        return this._items;
    }

    get isIndeterminate(): boolean {
        return this._isIndeterminate;
    }
}
