import { Component, Input } from '@angular/core';
import { BaseViewComponent, IconSize, IconItem } from '@library/base';

@Component({
    selector: 'lib-icon',
    templateUrl: './icon-svg.component.html',
    styleUrls: ['./icon-svg.component.scss']
})
export class IconSvgComponent extends BaseViewComponent {
    @Input() icon!: IconItem | null;
    @Input() size: IconSize = IconSize.Medium;
    @Input() toolTipText: string = '';
    @Input() visible: boolean = true;
    @Input() qaTag: string = '';
    
}
