import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyStateComponent } from './empty-state.component';
import { UtilityModule } from '@library/utility';
import { ButtonModule } from '@library/button';



@NgModule({
    declarations: [
        EmptyStateComponent
    ],
    imports: [
        CommonModule,
        UtilityModule,
        ButtonModule
    ],
    exports: [
        EmptyStateComponent
    ]
})
export class EmptyStateModule { }
