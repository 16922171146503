import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmissionBarComponent } from './submission-bar.component';
import { ButtonModule } from '@library/button';



@NgModule({
    declarations: [
        SubmissionBarComponent
    ],
    imports: [
        CommonModule,
        ButtonModule
    ],
    exports: [
        SubmissionBarComponent
    ]
})
export class SubmissionBarModule { }
