import { Component, Input } from '@angular/core';
import { DesignBlockImageBaseViewComponent, WebsiteGalleryItemAction } from '@library/base';

@Component({
  selector: 'lib-image-action-menu-general',
  templateUrl: './image-action-menu-general.component.html',
  styleUrls: ['./image-action-menu-general.component.scss']
})
export class ImageActionMenuGeneralComponent extends DesignBlockImageBaseViewComponent {
  @Input() showActionMenu: boolean = false;
  @Input() disableImageDetails: boolean = false;

  get WebsiteGalleryItemAction(): typeof WebsiteGalleryItemAction {
    return WebsiteGalleryItemAction;
  }
}
