
<div [ngClass]="{ 'd-flex align-items-center justify-content-between': layout === SnackbarLayout.A }">
    <div class="d-flex align-items-center snackbar-message-container">
        <div>
            <ng-content select="[icon]"></ng-content>
        </div>
        <p class="typography-body-medium base-0">
            <ng-content select="[message]"></ng-content>
        </p>
    </div>

    <div>
        <ng-content select="[actions]"></ng-content>
    </div>
</div>


