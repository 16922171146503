import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconModule } from '@library/icon';
import { ButtonComponent } from './button.component';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { MenuItemComponent } from './menu-button/menu-item/menu-item.component';
import { UtilityModule } from '@library/utility';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { SplitButtonComponent } from './split-button/split-button.component';


@NgModule({
    declarations: [
        ButtonComponent,
        MenuButtonComponent,
        MenuItemComponent,
        IconButtonComponent,
        SplitButtonComponent
    ],
    imports: [
        CommonModule,
        IconModule,
        MatMenuModule,
        MatTooltipModule,
        UtilityModule,
        MatDividerModule,
        MatBadgeModule,
        RouterModule
    ],
    exports:[
        ButtonComponent,
        MenuButtonComponent,
        MenuItemComponent,
        IconButtonComponent,
        SplitButtonComponent
    ]
})
export class ButtonModule { }
