import { Pipe, PipeTransform } from "@angular/core";
import { PostManager } from "../post.manager";
import { NewsPostIconEnum } from "@library/data-models";

@Pipe({name: 'postIcon'})
export class PostIconPipe implements PipeTransform {

	constructor(private _PostManager: PostManager) {
	}

    transform(icon: NewsPostIconEnum) {
        const svgIcon = this._PostManager.GetIconName(icon);
        return {Icon: svgIcon};
    }
}
