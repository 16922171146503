import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseViewComponent, Environment, ErrorMessages } from '@library/base';
import { CustomFieldCheckedListValueInputItem, CustomFieldDisplayItem, CustomFieldDropDownListValueInputItem, CustomFieldEmailValueInputItem, CustomFieldMultiLineValueInputItem, CustomFieldPolicyValueInputItem, CustomFieldRadioListValueInputItem, CustomFieldTextValueInputItem, CustomFieldType, CustomFieldValueInputItem, WebsiteCustomFieldContactFormDisplayItem } from '@library/data-models';
import { FormFieldTextComponent } from '@library/form-field';

@Component({
    selector: 'lib-contact-form-custom-fields',
    templateUrl: './contact-form-custom-fields.component.html',
    styleUrls: ['./contact-form-custom-fields.component.scss']
})
export class ContactFormCustomFieldsComponent extends BaseViewComponent implements OnInit {
    @Input()
    set fields(value: WebsiteCustomFieldContactFormDisplayItem[]) {
        if (value !== undefined) {
            this._sortedFields = value.sort((a, b) => a.OrderIndex - b.OrderIndex);
        }
    }
    @Input() form!: FormGroup;
    @Input() isViewOnly: boolean = false;

    private _sortedFields: WebsiteCustomFieldContactFormDisplayItem[] = [];
    private _emailControls: FormControl[]= [];

    readonly emailError: ErrorMessages = {
        required: _=> $localize`:@@ContactFormBlockEmailMissingError:Please enter an email address`,
        api: _=> $localize`:@@ContactFormBlockEmailAPIError:Please enter a valid email address`
    }

    constructor(private _Environment: Environment) {
        super();
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.form.isViewOnly = this.isViewOnly;
    }

    GetFormControl(field: WebsiteCustomFieldContactFormDisplayItem) {
        let control!: FormControl;
        switch(field.Field?.FieldType){
            case CustomFieldType.RadioList:
                control = this.form.AddControl(field.ID!, CustomFieldDisplayItem.AsRadioList(field.Field).Options[0]);
                break;
            case CustomFieldType.DropDownList:
            case CustomFieldType.Text:
            case CustomFieldType.MultiLine:
                control = this.form.AddControl(field.ID!, '');
                break;
            case CustomFieldType.Email:
                control = this.form.AddControl(field.ID!, '');
                this._emailControls.push(control);
                break;
            case CustomFieldType.CheckedList:
                control = this.form.AddControl(field.ID!, []);
                break;
            case CustomFieldType.Policy:
                control = this.form.AddControl(field.ID!, false);
                break;
        }

        if((CustomFieldDisplayItem.IsCheckedList(field.Field!)
            || CustomFieldDisplayItem.IsDropDownList(field.Field!)
            || CustomFieldDisplayItem.IsMultiLine(field.Field!)
            || CustomFieldDisplayItem.IsPolicy(field.Field!)
            || CustomFieldDisplayItem.IsText(field.Field!)
            || CustomFieldDisplayItem.IsEmail(field.Field!)
            )
            && field.Field.Required){
                control.addValidators(CustomFieldDisplayItem.IsPolicy(field.Field!) ? Validators.requiredTrue : Validators.required);
        }
        return control;
    }

    GetEmailControls(): FormControl[] {
        return this._emailControls;
    }

    GetCustomFieldValues(): CustomFieldValueInputItem[]{
        const fieldValues: CustomFieldValueInputItem[] = [];
        this._sortedFields.forEach(field => {
            let customFieldValueInputItem: CustomFieldValueInputItem;

            switch (field.Field!.FieldType) {
                case CustomFieldType.CheckedList:
                    customFieldValueInputItem = new CustomFieldCheckedListValueInputItem({ Options: this.form.get(field.ID!)?.value });
                    break;
                case CustomFieldType.DropDownList:
                    customFieldValueInputItem = new CustomFieldDropDownListValueInputItem({ Option: this.form.get(field.ID!)?.value });
                    break;
                case CustomFieldType.RadioList:
                    customFieldValueInputItem = new CustomFieldRadioListValueInputItem({ Option: this.form.get(field.ID!)?.value });
                    break;
                case CustomFieldType.Email:
                    customFieldValueInputItem = new CustomFieldEmailValueInputItem({ Email: this.form.get(field.ID!)?.value });
                    break;
                case CustomFieldType.MultiLine:
                    customFieldValueInputItem = new CustomFieldMultiLineValueInputItem({ Text: this.form.get(field.ID!)?.value });
                    break;
                case CustomFieldType.Text:
                    customFieldValueInputItem = new CustomFieldTextValueInputItem({ Text: this.form.get(field.ID!)?.value });
                    break;
                case CustomFieldType.Policy:
                    customFieldValueInputItem = new CustomFieldPolicyValueInputItem({ Accepted: this.form.get(field.ID!)?.value });
                    break;
                default:
                    throw Error;
            }

            customFieldValueInputItem.FieldID = field.ID;
            fieldValues.push(customFieldValueInputItem);
        });

        return fieldValues;
    }

    PatchCustomFieldValues(fields: CustomFieldValueInputItem[]): void {
        fields.forEach(field => {

            const fieldID = this._sortedFields.find(f => f.ID === field.FieldID!)?.ID;

            if (fieldID) {
                switch(field.FieldType){
                    case CustomFieldType.CheckedList:
                        this.form.get(fieldID)?.patchValue((field as CustomFieldCheckedListValueInputItem).Options);
                        break;
                    case CustomFieldType.DropDownList:
                    case CustomFieldType.RadioList:
                        this.form.get(fieldID)?.patchValue((field as CustomFieldDropDownListValueInputItem | CustomFieldRadioListValueInputItem).Option);
                        break;
                    case CustomFieldType.Email:
                        this.form.get(fieldID)?.patchValue((field as CustomFieldEmailValueInputItem).Email);
                        break;
                    case CustomFieldType.MultiLine:
                    case CustomFieldType.Text:
                        this.form.get(fieldID)?.patchValue((field as CustomFieldMultiLineValueInputItem | CustomFieldTextValueInputItem).Text);
                        break;
                    case CustomFieldType.Policy:
                        this.form.get(fieldID)?.patchValue((field as CustomFieldPolicyValueInputItem).Accepted);
                        break;
                }
            }
        })
    }

    PreparePolicyURL(originalURL: string): string {
        if(originalURL.startsWith("WebsiteMedia/wmi")){
            return `${this._Environment.Application.WebsiteBase}${originalURL}`;
        } else {
            return originalURL;
        }
    }

    get sortedFields(): WebsiteCustomFieldContactFormDisplayItem[] {
        return this._sortedFields;
    }

    get CustomFieldDisplayItem(): typeof CustomFieldDisplayItem {
        return CustomFieldDisplayItem;
    }

    get CustomFieldType(): typeof CustomFieldType {
        return CustomFieldType;
    }
}
