import { Component, EventEmitter, HostListener, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SnackbarData, SnackbarEvents, SnackbarLayout, SnackBarDuration, UploadFileType } from '@library/base';
import { BaseViewComponent, ErrorMessages, Errors, Validators } from '@library/base';
import { SnackbarService } from '@library/snackbar';

@Component({
    selector: 'lib-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent extends BaseViewComponent implements OnDestroy {
    @Input() attachmentDialogPreviewerTitle: string = $localize`:@@UploadComponentAttachmentsToUpload:Attachments to Upload`;
    @Input() showAttachmentDialogPreviewerTitle: boolean = true;
    @Input() showFullFileName: boolean = false;
    @Input() showDragAndDrop: boolean = true;
    @Input() showFormFieldLabel: boolean = true;
    @Input() disableUploadButton: boolean = false;
    @Input() isOptional: boolean = true;


    private _acceptedFormatList: string[] = [];
    private _acceptedFormatText: string = '';
    @Input()
    set acceptedFormat(value: UploadFileType) {
        if(value !== UploadFileType.All) {
            this._acceptedFormatList = value.split(",");
            this._acceptedFormatText = value;
        }
    }

    @Input() allowMultiFileUpload: boolean = true;
    @Input() SnackbarAction: () => void = () => {};
    @Output() RetrieveFiles = new EventEmitter<Array<File>>();
    @Output() RemoveFiles = new EventEmitter<Array<File>>();

    private _clearFiles: boolean = false;
    @Input()
    set clearFiles(value: boolean) {
        if(value){
            this._files = [];
        }
    }

    //this is a function which _WAS_ needed in TP, lets see if we still need it.
    // why cant we just call Upload(), instead of input.click???
    //is this ipad popup related to have the correct origin??

    // @ViewChild('inputFormElement', {static: false}) inputElement!: ElementRef; //appears to be necessary in TP, left to see if we need it here

    // public Click() {
    //     if(this.inputElement) {
    //         this.inputElement.nativeElement.click();
    //     }
    // }

    private _files: Array<File> = new Array<File>();
    private _filesSources: Array<string> = new Array<string>();

    private _formControl: FormControl<File[]> | null = null;
    protected errors: Errors = new Errors();

    //File size in bytes
    @Input() maxAllowedFilesize: number | null = null;

    // Optional: For single-file upload to show lib-errors i.e., max file size or required used with errorMessages. 
    //           For multi-file validation, can use with input hideInlineErrors and data.Control.errors?.max as in lib-upload-dialog to show custom banner
    @Input()
    public set control(value: FormControl<File[]> | null) { 
        if(value){
            this._formControl = value;
            this.SetValidators();
        }

    }
    @Input()
    public set errorMessages(value: ErrorMessages) {
        this.errors.Override(value);
    }

    @Input() hideInlineErrors: boolean = false;

    constructor(
        private _Renderer2: Renderer2, 
        private _SnackBar: SnackbarService
    ) {
        super();

        // Applicable if form control is provided, setter in constructor before @Input errorMessages executes
        this.SetDefaultErrorMessages();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.RemovePreviousInputIfApplicable();
    }

    UploadFile() {
        this.RemovePreviousInputIfApplicable();
        let input: HTMLInputElement = this._Renderer2.createElement('input');
        input.id = "newUploadInputID";
        input.setAttribute("type",'file');

        if(this._acceptedFormatList.length > 0) {
            input.setAttribute("accept", this._acceptedFormatText);
        }

        input.multiple = this.allowMultiFileUpload;

        //Attempt to remove the rare issue where the input still shows instead of being removed
        if(!this.isMobileOrTablet) {
            input.style.display = 'none';
        }

        document.body.appendChild(input);
        input.click();

        input.onchange = (foo)=> {
            try {
                this.HandleFiles(input.files)
            } catch(err){
            //  do nothing
            }

            this.RemovePreviousInputIfApplicable();
        }
    }
    private RemovePreviousInputIfApplicable() {
        let element = document.getElementById("newUploadInputID");
        if(element) {
            document.body.removeChild(element);
        }
    }

    public HandleFiles(files: FileList | null) {
        if(!files) {return}; //null guard.

        /* If only one file upload allowed empty array and save only the first file */
        if (!this.allowMultiFileUpload) {
            this._files = [];
            this._filesSources = [];

            this._files.push(files[0]);
            this.previewFile(files[0]);

        } else {
            for (const file of Array.from(files)) {
                const isFileAlreadyAdded = this.DoesFileExistInArrayOfFiles(file);

                if (isFileAlreadyAdded) {
                    return;
                }

                this.previewFile(file);
                this._files.push(file);
            }
        }
        
        let filesAccepted: File[] = this._files;

        // if this._acceptedFormatList is an empty array, then all types of files are accepted
        if(this._acceptedFormatList.length > 0) {
            filesAccepted = this._files.filter(file => this._acceptedFormatList.includes(file.type))
            
            if(filesAccepted.length !== this._files.length) {
                const acceptTypesDisplayArr: string[] = this._acceptedFormatList.map((type: string) => type.substring(type.indexOf("/") + 1));
                
                this._SnackBar.CreateActionSnackBar({
                    Data: $localize`:@@CommonUnacceptedFileTypeSnackbarNotification:Please upload a file with these extensions: ${acceptTypesDisplayArr.join(", ")}.`,
                    ActionButtonText: $localize`:@@CommonLearnMore:Learn more`,
                    IsClosable: true,
                    Duration: SnackBarDuration.Important,
                    Layout: SnackbarLayout.B,
                }).events.subscribe((event: SnackbarEvents) => {
                    switch (event) {
                        case SnackbarEvents.OnAction:
                            this.SnackbarAction()
                        break;
                    case SnackbarEvents.Dismissed:
                        this._SnackBar.DismissSnackbar();
                    }
                })
            } else {
                this._SnackBar.DismissSnackbar();
            }

            this._files = filesAccepted;
        }
        
        this.RetrieveFiles.emit(filesAccepted);

        if(this._formControl) {
            this._formControl.setValue(filesAccepted);
            this._formControl.isDirty = true;
        }
    }

    private DoesFileExistInArrayOfFiles(fileObjectToLookFor: File): boolean {
        for (const file of this._files) {
            if (file.name === fileObjectToLookFor.name) {
                return true;
            }
        }

        return false;
    }

    private previewFile(file: File) {
        const reader = new FileReader();

        if (this.IsFileImage(file)) {
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.filesSources.push(reader.result? reader.result.toString():"");
            };
        } else {
            this.filesSources.push("");
        }
    }

    IsFileImage(file: File): boolean {
        return file && file['type'].split('/')[0] === 'image' && file['type'].split('/')[1] !== 'x-icon' && file['type'].split('/')[1] !== 'svg+xml';
    }

    IsFileAudio(file: File): boolean {
        return file && file['type'].split('/')[0] === 'audio';
    }

    IsFileVideo(file: File): boolean {
        return file && file['type'].split('/')[0] === 'video';
    }

    IsFileArchive(file: File): boolean {
        return file && file['type'].indexOf('compressed') > 0;
    }

    IsFileOther(file: File): boolean {
        return file && !this.IsFileImage(file) && !this.IsFileVideo(file) && !this.IsFileAudio(file) && !this.IsFileArchive(file) ;
    }

    RemoveFileAtIndex(index: number) {
        this.files.splice(index, 1);
        this.filesSources.splice(index, 1);
        this.RemoveFiles.emit(this._files);

        if(this._formControl) {
            this._formControl.setValue(this._files);
        }
    }

    private SetDefaultErrorMessages(): void {
        this.errors.Override({
            required: _ => $localize`:@@CommonRequiredFileErrorMessage:Please choose a file`,
            max: (value: {max: number}) => $localize`:@@CommonFileTooLargeErrorMessage:Sorry, the file you're trying to upload is too large.`
        });
    }

    private SetValidators(): void {
        if(this.maxAllowedFilesize && this._formControl){
            this._formControl.addValidators(Validators.maxFileSize(this.maxAllowedFilesize));
            this._formControl.updateValueAndValidity();
        }
    }
    public get files(): Array<File> {
        return this._files;
    }
    public get filesSources(): Array<string> {
        return this._filesSources;
    }
    public get hasFiles(): boolean {
        return this._files.length > 0;
    }

    get acceptedFormatText(): string {
        return this._acceptedFormatText;
    }

    // Host listeners copied from TP
    @HostListener('dragenter', ['$event'])
        onDragEnter(event: any) {
        this.preventDefaults(event);
    }

    @HostListener('dragleave', ['$event'])
        onDragLeave(event: any) {
        this.preventDefaults(event);
    }

    @HostListener('dragover', ['$event'])
        onDragOver(event: any) {
        this.preventDefaults(event);
    }

    @HostListener('drop', ['$event'])
        onDrop(event: any) {
        this.preventDefaults(event);
        this.HandleDrop(event);
    }

    private preventDefaults (e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    private HandleDrop(e: any) {
        const dt = e.dataTransfer;
        const files = dt.files;

        this.HandleFiles(files);
    }

    public get control(): FormControl<File[]> | null {
        return this._formControl;
    }
}
