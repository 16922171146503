import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'lib-checkbox-group-item [value]',
    templateUrl: 'form-field-checkbox-group-item.html'
})
export class FormFieldCheckboxGroupItemComponent<T> {
    @Input() value!: T;
    @Input() hintMessage: string = "";
    @Input() disabled: boolean = false;

    @ViewChild('content', {static: true, read: TemplateRef}) label!: TemplateRef<any>;
}
