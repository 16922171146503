import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilityModule } from '@library/utility';
import { IframeComponent } from './iframe.component';

@NgModule({
    declarations: [
        IframeComponent
    ],
    imports: [
        CommonModule,
        UtilityModule
    ],
    exports:[
        IframeComponent
    ]
})
export class IframeModule { }
