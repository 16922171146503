import { BankAccountHolderType, CreditCardMetaData, NameCodePair, PaymentSourceType } from "@library/data-models";
import { ItemWithID } from "./base.definitions";
import { ConfirmPaymentData, StripeElements, StripeError } from "@stripe/stripe-js";

export const AccountTypeName: Record<BankAccountHolderType, string> = {
    Personal: $localize`:@@SPAddPaymentSourceBankAccountTypePersonal:Personal`,
    Business: $localize`:@@SPAddPaymentSourceBankAccountTypeBusiness:Business`
}


export class CreditCardType extends CreditCardMetaData implements ItemWithID {
    ID!: string;

    constructor(initializer: CreditCardType) {
        super();
        Object.assign(this, initializer);
    }
}

export class BankAccountType implements ItemWithID {
    ID!: string;
    Type!: BankAccountHolderType;
    Name!: string;

    constructor(initializer: BankAccountType) {
        Object.assign(this, initializer);
    }
}

export const BankAccountTypes: BankAccountType[] = [
    new BankAccountType({
        ID: 'business',
        Type: BankAccountHolderType.Business,
        Name: $localize`:@@SPAddPaymentSourceBankAccountTypeBusiness:Business`
    }),
    new BankAccountType({
        ID: 'personal',
        Type: BankAccountHolderType.Personal,
        Name: $localize`:@@SPAddPaymentSourceBankAccountTypePersonal:Personal`
    })
];

export class NameCodeWithID extends NameCodePair implements ItemWithID {
    ID!: string;

    constructor(initializer: NameCodeWithID) {
        super();
        Object.assign(this, initializer);
    }
}

export class PaymentPageSchoolInfo {
    ID!: string;
    Name!: string;
    Address!: string;
    Active: boolean = true;
    InvoiceLogoURL!: string;

    constructor(initializer: Partial<PaymentPageSchoolInfo>) {
        Object.assign(this, initializer);
    }
}

export enum PaymentSourceMode {
    Add = "Add",
    Payment = "Payment",
    AnonymousPayment = "AnonymousPayment",
    ReadOnly = "ReadOnly"
}

export enum PaymentMethodType {
    Existing = "Existing",
    New = "New",
    PayPal = "PayPal"
}

export enum PaymentReturnStatus {
    Cancelled = "Cancelled",
    ExpressCheckoutCompleted = "EC-Completed",
    PayPalStandardCompleted = "PP-Completed",
    PayPalCommercePlatformCompleted = "CP-Completed"
}

export type StripeOptions =  {
    elements: StripeElements;
    confirmParams?: Partial<ConfirmPaymentData> | undefined;
    redirect: "if_required";
};

export class StripeErrorDialogInfo {
    error!: StripeError;

    constructor(initializer: StripeErrorDialogInfo) {
        Object.assign(this, initializer);
    }
}


export enum PaymentContext {
    StudentPortal = "StudentPortal",
    PaymentPortal = "PaymentPortal",
    TeacherPortal = "TeacherPortal",
    SignupWidget = "SignupWidget"
}

export class StripeTransactionDisplayItem {
    SubTotal: number = 0;
    Surcharge: number | null = null;
    LateFee: number | null = null;
    ProcessorTransactionID: string | null = null;
    ProcessorPaymentTime: string = "1970-01-01T00:00:00";

    constructor(initializer?: Partial<StripeTransactionDisplayItem>) {
        if (initializer) {
            Object.assign(this, initializer);
        }
    }

    static readonly ClassName: string = "StripeTransactionDisplayItem";
}
