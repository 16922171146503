import { AfterViewInit, Component, ContentChildren, OnInit, QueryList } from '@angular/core';
import { ExpansionCardComponent } from '../expansion-card.component';

@Component({
    selector: 'lib-expansion-card-group',
    templateUrl: './expansion-card-group.component.html',
    styleUrls: ['./expansion-card-group.component.scss']
})
export class ExpansionCardGroupComponent implements AfterViewInit {
    @ContentChildren(ExpansionCardComponent) expansionCardComponentList!: QueryList<ExpansionCardComponent>;

    ngAfterViewInit(): void {
        const expansionCards = this.expansionCardComponentList.toArray();
        expansionCards.forEach((card, index) => {
            card.panelStatus.subscribe(expanded => {
                if(expanded){
                    expansionCards.forEach((c, j) => {
                        if(c.expansionPanel.expanded && index !== j) {
                            c.expansionPanel.close();
                        } 
                    })
                }
            })
        });

    }

}
