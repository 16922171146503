import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseViewComponent, Errors } from '@library/base';

@Component({
    selector: 'lib-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent extends BaseViewComponent {
    @Input() control!: FormControl<any>
    @Input() errors!: Errors;
}
