import { Component, Input, OnInit } from '@angular/core';
import { DeviceTypeManager, DialogSize, Orientation } from '@library/base';

@Component({
    selector: 'lib-dialog-layout',
    templateUrl: './dialog-layout.component.html',
    styleUrls: ['./dialog-layout.component.scss'],
})
export class DialogLayoutComponent implements OnInit {

    @Input() showTitle = true;
    @Input() showActions = true;
    @Input() size: DialogSize = DialogSize.Medium;
    
    constructor(private _DeviceTypeManager: DeviceTypeManager) {}
    
    ngOnInit(): void {
        const dialogPanel = document.getElementsByClassName('dialog-panel-class')[0];
        dialogPanel.classList.add(this.size);
    }    
    
    get orientation(): Orientation {
        return this._DeviceTypeManager.isMobile ? Orientation.Vertical : Orientation.Horizontal;
    }
    
    get isMobile(): boolean {
        return this._DeviceTypeManager.isMobile;
    }
}
