import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseViewComponent, PaymentContext, PaymentPageSchoolInfo, PaymentSourceMode } from '@library/base';
import { PaymentSourceInputItem, RegistrationPaymentType, WebsiteBlockSignupFormDisplayItem, WebsiteRenderContextDisplayItem } from '@library/data-models';
import { ParentSignupFrontendInputItem } from '../signup-form.definitions';

@Component({
  selector: 'lib-signup-form-design-block-step3',
  templateUrl: './signup-form-design-block-step3.component.html',
  styleUrls: ['./signup-form-design-block-step3.component.scss']
})
export class SignupFormDesignBlockStep3Component extends BaseViewComponent implements OnInit {

    @Input() renderContext!: WebsiteRenderContextDisplayItem;
    @Input() data!: WebsiteBlockSignupFormDisplayItem;
    @Input() parent!: ParentSignupFrontendInputItem;
    @Input() numStudents!: number;
    @Input() stripeNextStepClientSecret: string | null = null;
    @Input() paymentProcessorErrorMessage: string | null = null;
    @Input() set enableSave(value: boolean | null) {
        if(value !== null) {
            this._signupFormClicked = !value;
        }
    }

    @Output() submitClicked: EventEmitter<PaymentSourceInputItem | null> = new EventEmitter();
    @Output() backClicked: EventEmitter<void> = new EventEmitter();
    @Output() stripeNextStepComplete: EventEmitter<string> = new EventEmitter();

    private _schoolInfo!: PaymentPageSchoolInfo;

    private _signupFormClicked: boolean = false;

    override ngOnInit(): void {
        super.ngOnInit();

        this._schoolInfo = new PaymentPageSchoolInfo({
            ID: this.data.SchoolID!,
            Active: this.renderContext.SchoolActive,
            Name: this.renderContext.SchoolName!
        });
    }

    BackClicked(): void {
        this.backClicked.emit();
    }

    SubmitClicked(): void {
        this._signupFormClicked = true;
    }

    SignupFormPaymentSourceReady(result: PaymentSourceInputItem | null): void {
        this._signupFormClicked = (result !== null);
        this.submitClicked.emit(result);
    }

    StripeNextStepComplete(intentID: string): void {
        this.stripeNextStepComplete.emit(intentID);
    }

    get schoolInfo(): PaymentPageSchoolInfo {
        return this._schoolInfo;
    }

    get PaymentSourceMode(): typeof PaymentSourceMode {
        return PaymentSourceMode;
    }

    get PaymentContext(): typeof PaymentContext {
        return PaymentContext;
    }

    get RegistrationPaymentType(): typeof RegistrationPaymentType {
        return RegistrationPaymentType;
    }

    get signupFormClicked(): boolean {
        return this._signupFormClicked;
    }
}
