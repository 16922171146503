import { AfterContentInit, Component, ContentChildren, Input, OnInit, QueryList, ViewChild } from '@angular/core';
import { FieldOf, ItemWithID } from '@library/base';
import { filter, startWith } from 'rxjs';
import { FormFieldInputBaseComponent } from '../form-field-input-base.component';
import { FormFieldSelectItemGroupComponent } from './form-field-select-item-group.component';
import { FormFieldSelectItemComponent, IItemGroup } from './form-field-select-item.component';

type IdentifierOf<T> = T extends string ? never
                     : { [K in FieldOf<T>]: T[K] extends (string | null) ? K : never }[FieldOf<T>];

function HasStringKey<K extends string>(key: K, x: object): x is { [key in K]: string } {
    return key in x && typeof (x as any)[key] == 'string';
};

@Component({
    template: '',
})
export class FormFieldSelectBaseComponent<T extends ItemWithID | string, U> extends FormFieldInputBaseComponent<U> implements OnInit, AfterContentInit {

    @ContentChildren(FormFieldSelectItemComponent, {descendants: true}) itemQueryList!: QueryList<FormFieldSelectItemComponent<T>>;
    @ContentChildren(FormFieldSelectItemGroupComponent) groupsQueryList!: QueryList<IItemGroup>;

    private _sortedItemListByGroup!: Map<IItemGroup | null, FormFieldSelectItemComponent<T>[]>;
    private _itemMap!: Map<string, FormFieldSelectItemComponent<T>>;

    private _identifierOf!: (x: T) => string;
    private _nameOf?: (x: T) => string;
    private _sorterOf?: (x: T) => string;

    private _initialized: boolean = false;

    private _nameBy?: IdentifierOf<T>;
    @Input()
    public set nameBy(value: IdentifierOf<T>) {
        this._nameBy = value;
    }

    private _sortBy?: IdentifierOf<T> | null;
    @Input()
    public set sortBy(value: IdentifierOf<T> | null) {
        this._sortBy = value;
    }

    private _identifyBy?: IdentifierOf<T> | null;
    @Input()
    public set identifyBy(value: IdentifierOf<T> | null) {
        this._identifyBy = value;
    }

    ngAfterContentInit(): void {
        this.itemQueryList.changes
            .pipe(
                startWith(this.itemQueryList),
                filter((queryList: QueryList<FormFieldSelectItemComponent<T>>) => queryList?.length > 0)
            ).subscribe(_ => {
                this.SetIdentifierNameAndSorter();
                this.SortItems();

                this._initialized = true;
            });
    }

    protected SortItems(): void {
        this._sortedItemListByGroup = new Map();
        this._itemMap = new Map();

        this._sortedItemListByGroup.set(null, []);

        this.groupsQueryList.forEach(group => {
            this._sortedItemListByGroup.set(group, []);
        });

        this.itemQueryList.forEach(item => {
            this._sortedItemListByGroup.get(item.group)!.push(item);
            this._itemMap.set(this._identifierOf(item.value), item);
        });

        // Clean up empty keys
        this.sortedItemListByGroup.forEach((value, key) => {
            if (value.length === 0) {
                this._sortedItemListByGroup.delete(key);
            }
        });

        if (this._sorterOf) {
            this._sortedItemListByGroup.forEach(list =>
                list.sort((a, b) => this.SortListWithNullValues(a,b))
            );
        }
    }

    SortListWithNullValues(a: FormFieldSelectItemComponent<T>, b: FormFieldSelectItemComponent<T>): number {
        if(!this._identifierOf(a.value)) {
            return -1;
        } else if(!this._identifierOf(b.value)) {
            return 1;
        } else {
            return this._sorterOf!(a.value) < this._sorterOf!(b.value) ? -1 : 1;
        }
    }

    private SetIdentifierNameAndSorter(): void {
        if (!this.itemQueryList.length) {
            throw Error(`${this.constructor.name} must contain at least one item.`);
        }

        const lastItem = this.itemQueryList.last.value;

        if (typeof lastItem === 'string') {
            this._identifierOf = (item: T) => item as string;
            this._nameOf = this._identifierOf;

            if (this._sortBy !== null) {
                this._sorterOf = (item: T) => (item as string).toString().toLocaleLowerCase();
            }
        } else {
            if (this._identifyBy) {
                this._identifierOf = (item: T) => (item as typeof lastItem)[this._identifyBy!]! as string;
            } else if (HasStringKey('ID', lastItem)) {
                this._identifierOf = (item: T) => (item as ItemWithID).ID!;
            } else {
                throw Error(`Cannot determine how to identify item ${lastItem}`)
            }

            if (this._nameBy) {
                this._nameOf = (item: T) => (item as typeof lastItem)[this._nameBy!] as string;
            } else if (HasStringKey('Name', lastItem)) {
                this._nameOf = (item: T) => (item as typeof lastItem)['Name'];
            } else if (HasStringKey('FullName', lastItem)) {
                this._nameOf = (item: T) => (item as typeof lastItem)['FullName'];
            } else if (HasStringKey('DisplayName', lastItem)) {
                this._nameOf = (item: T) => (item as typeof lastItem)['DisplayName'];
            } else {
                throw Error(`Cannot determine how to name item ${lastItem}`)
            }

            if (this._sortBy) {
                this._sorterOf = (item: T) => ((item as typeof lastItem)[this._sortBy!]! as string).toLocaleLowerCase();
            } else if (this._sortBy === undefined && this._nameOf) {
                this._sorterOf = (item: T) => this._nameOf!(item)?.toLocaleLowerCase();
            }
        }
    }

    public get sortedItemListByGroup(): Map<IItemGroup | null, FormFieldSelectItemComponent<T>[]> {
        return this._sortedItemListByGroup;
    }

    public get itemMap(): Map<string, FormFieldSelectItemComponent<T>> {
        return this._itemMap;
    }

    public get initialized(): boolean {
        return this._initialized;
    }

    public get identifierOf(): (x: T) => string {
        return this._identifierOf;
    }

    public get nameOf(): undefined | ((x: T) => string) {
        return this._nameOf;
    }

    public get sorterOf(): undefined | ((x: T) => string) {
        return this._sorterOf;
    }

    public unsorted() {
        return 0;
    }
}
