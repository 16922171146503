<div
    [ngClass]="{'scss-block-background-color-variable': type === WebsiteBlockEmbedType.AccentBackgroundDefault, 
                'scss-website-background-color-only': type === WebsiteBlockEmbedType.Default}"
    [ngSwitch]="websiteMode">
    <ng-container *ngSwitchCase="WebsiteMode.Live">
        <div class="d-flex position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.ReadOnly">
        <div class="d-flex position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.Preview">
        <lib-design-blocks-action-menu
                [disableMoveUp]="disableMoveUp"
                [disableMoveDown]="disableMoveDown"
                [showEdit]="true"
                (moveDownClicked)="MoveDownClicked()"
                (moveUpClicked)="MoveUpClicked()"
                (deleteClicked)="DeleteClicked()"
                (editClicked)="EditClicked()">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </lib-design-blocks-action-menu>
    </ng-container>
</div>

<ng-template #blockContent>
    <div class="d-flex fullWidth justify-content-center ">
        <ng-container *ngIf="isInSidebar">
            <img src="assets/images/Website_MapBlock_Sidebar.png" class="scss-embed-block-sidebar "/>
        </ng-container>
        <ng-container *ngIf="!isInSidebar">
            <ng-container *ngIf="(websiteMode === WebsiteMode.Preview || (websiteMode === WebsiteMode.ReadOnly && !inSidebar)) && !isLocationSet">
                <lib-empty-state
                    class="scss-spacing-embed-block w-100"
                    [padding]="24"
                    [height]="228"
                    [roundBorders]="true"
                    [emptyStateGraphic]="EmptyStateGraphic.Locations">
                    <ng-container emptyStateText i18n="@@TPMapBlockNoLocationSetUp">You haven't set up your location yet</ng-container>
                    <ng-container emptyStateDescription i18n="@@TPMapBlockEmptyStateDescription">Set your business location with the map block to connect with your community</ng-container>
                    <ng-container emptyStateButton>
                        <lib-button
                            (clicked)="EditClicked()">
                            <ng-container label i18n="@@TPMapsSetYourOwnLocation">Set Your Location</ng-container>
                        </lib-button>
                    </ng-container>
                </lib-empty-state>
            </ng-container>
            <ng-container *ngIf="isLocationSet">
                    <iframe
                        [ngClass]="{'scss-spacing-embed-block': !isFullWidth}"
                        height="464"
                        style="border:0"
                        loading="lazy"
                        allowfullscreen
                        referrerpolicy="no-referrer-when-downgrade"
                        [style.width]="'100%'"
                        [src]="url! | trustUrl">
                    </iframe>
            </ng-container>
        </ng-container>
    </div>
</ng-template>