import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import { BaseViewComponent } from '@library/base';

@Component({
    selector: 'lib-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss'],
})
export class LabelComponent extends BaseViewComponent implements OnInit {
    @Input() isOptional: boolean = false;
    @Input() isReadOnly: boolean = false;
    @Input() isViewOnly: boolean = false;
}
