<lib-card *ngIf="hasCardContainer">
    <ng-container *ngTemplateOutlet="stepsTextTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="stepperTemplate"></ng-container>
</lib-card>

<ng-container *ngIf="!hasCardContainer">
    <ng-container *ngIf="showSteps">
        <ng-container *ngTemplateOutlet="stepsTextTemplate"></ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="stepperTemplate"></ng-container>
</ng-container>

<ng-template #stepperTemplate>
    <mat-stepper [linear]="true" labelPosition="bottom" #stepper>
        <mat-step *ngFor="let step of steps">
            <ng-template matStepLabel>
                <ng-container *ngTemplateOutlet="step.label"></ng-container>
            </ng-template>
            <ng-template matStepContent>
                <ng-container *ngTemplateOutlet="step.content"></ng-container>
            </ng-template>

        </mat-step>
        <ng-template matStepperIcon="edit" let-index="index"> {{ index + 1 }} </ng-template>
        <ng-template matStepperIcon="done">
            <lib-icon
                [icon]="Icon.Common.Check"
                [size]="IconSize.Small">
            </lib-icon>
        </ng-template>
    </mat-stepper>
</ng-template>

<ng-template #stepsTextTemplate>
    <p class="typography-caption base-100 pb-2 scss-step-counter" i18n="@@CommonStep">
        Step {{ currentStep + 1 }}<ng-container *ngIf="showTotalCount">/{{ steps.length }} </ng-container>
        <ng-container *ngIf="(steps.get(currentStep))?.isOptional">(Optional)</ng-container>
    </p>
</ng-template>



