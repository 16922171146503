import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-widget-thank-you',
  templateUrl: './widget-thank-you.component.html',
  styleUrls: ['./widget-thank-you.component.scss']
})
export class WidgetThankYouComponent {

    @Input() thankYouMessage!: string | null;

}