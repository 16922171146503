import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiBaseRoutes } from '@library/api';
import { BaseViewComponent, CreditCardType, ErrorMessages, FormBase, NameCodeWithID, Optional, PaymentContext, PaymentSourceMode, Required, Validators, boolean, string, SimpleDialogData, DialogEvents } from '@library/base';
import { CreditCardBrand, ErrorCode, NameCodePair, PaymentProcessorDisplayItem, PaymentProcessorType, PaymentSourceInputItem, PaymentSourceIntent, PaymentSourceType, PaymentSurchargeDisplayItem, PayPalProPaymentSourceInputItem, ProcessPaymentSourceResultItem, SurchargeType } from '@library/data-models';
import { DialogService } from '@library/dialog';
import { SBDate } from '@library/localization';

class PayPalAddPaymentSourceForm extends FormBase {
    HolderName = Required(string);
    CardType = Required(CreditCardType);
    AccountNumber = Required(string);
    ExpiryDate = Required(SBDate);
    CVV = Required(string);
    Country = Required(NameCodeWithID);
    State = Required(NameCodeWithID);
    City = Required(string);
    ZipCode = Required(string);
    Address1 = Required(string);
    Address2 = Optional(string);
    AutoPay = Optional(boolean);
    SavePaymentSource = Optional(boolean);
}

@Component({
    selector: 'lib-payment-sources-add-paypal-pro',
    templateUrl: './payment-sources-add-paypal-pro.component.html',
    styleUrls: ['./payment-sources-add-paypal-pro.component.scss']
})
export class PaymentSourcesAddPaypalProComponent extends BaseViewComponent implements OnInit {

    @Input() mode: PaymentSourceMode = PaymentSourceMode.Add;
    @Input() context!: PaymentContext;
    @Input() paymentSurchargeCreditCard!: PaymentSurchargeDisplayItem;
    @Input() schoolName!: string;
    @Input() totalAmountToPay: number | null = null;
    @Input() onlineConvenienceFee: number | null = null;
    @Input() schoolID: string | null = null;
    @Input() parentID!: string;
    @Input() familyID!: string;
    @Input() paymentProcessor!: PaymentProcessorDisplayItem;

    @Input() set paymentProcessorErrorMessage(error: string | null) {
        if (error !== null) {
            this.ShowErrorDialog(error);
        }
    }

    private _forceAutoPay!: boolean;
    @Input()
    set forceAutoPay(value: boolean) {
        this._forceAutoPay = value;
        if(value) {
            const autoPayForPayPal = this.addPayPalPaymentMethodForm.controls.AutoPay;
            autoPayForPayPal.setValue(true);
            autoPayForPayPal.disable();
        }
    }

    @Output() paymentSourceProcessed: EventEmitter<ProcessPaymentSourceResultItem | null> = new EventEmitter();
    @Output() signupFormPaymentSourceReady: EventEmitter<PaymentSourceInputItem | null> = new EventEmitter();

    readonly addPayPalPaymentMethodForm = PayPalAddPaymentSourceForm.Create(false);

    readonly cardTypeError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceCreditCardTypeMissingError:Please select your card type`
    };

    readonly cardHolderError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceCreditCardHolderMissingError:Please enter the name of the card holder`
    };

    readonly cardNumberError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceCreditCardNumberMissingError:Please enter your card number`,
        exactlength: (value: {requiredLength: number, actualLength: number}) =>
            $localize`:@@CommonAddPaymentSourceCardNumberWrongLengthError:${this.addPayPalPaymentMethodForm.controls.CardType.value?.Name} number must be ${value.requiredLength} digits long`
    };

    readonly cardExpiryDateError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceCreditCardExpiryDateMissingError:Please enter the card's expiry date`
    };

    readonly cardCVVError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceCreditCardCVVMissingError:Please enter the CVV (Card Verification Value)`,
        exactlength: (value: {requiredLength: number, actualLength: number}) =>
            $localize`:@@CommonAddPaymentSourceCVVWrongLengthError:${this.addPayPalPaymentMethodForm.controls.CardType.value?.Name} CVV must be ${value.requiredLength} digits long`
    };

    readonly countryError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceCountryMissingError:Please select your country`
    };

    readonly stateError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceStateProvinceMissingError:Please select your state or province`
    };

    readonly cityError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceCityMissingError:Please enter your city`
    };

    readonly zipCodeError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceZipCodeMissingError:Please enter your zip/postal code`
    };

    readonly addressError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceAddressMissingError:Please enter your address`
    };

    readonly bankNameError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceBankNameMissingError:Please enter the name of your bank`
    }

    readonly accountHolderError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceBankAccountHolderMissingError:Please enter the name of the account holder`
    };

    readonly accountTypeError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceBankAccountTypeMissingError:Please select your account type`
    };

    readonly accountNumberError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceBankAccountNumberMissingError:Please enter your bank account number`,
        minlength: (value: {requiredLength: number, actualLength: number}) =>
            $localize`:@@CommonAddPaymentSourceBankAccountNumberTooShortError:Account number must be at least ${value.requiredLength} digits long`,
        maxlength: (value: {requiredLength: number, actualLength: number}) =>
            $localize`:@@CommonAddPaymentSourceBankAccountNumberTooLongError:Account number must be at most ${value.requiredLength} digits long`
    };

    readonly routingNumberError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceBankRoutingNumberMissingError:Please enter the routing number for your bank account`,
        exactlength: (value: {requiredLength: number, actualLength: number}) =>
            $localize`:@@CommonAddPaymentSourceBankRoutingNumberWrongLengthError:Routing number must be ${value.requiredLength} digits long`
    };

    readonly consentError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceBankConsentRequiredError:You must provide consent to debit your bank account`
    }

    readonly autoPayError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceAutoPayRequiredError:Your #Studio# requires that Auto Pay be enabled`
    }

    readonly minDate = SBDate.StartOfMonth();
    readonly maxDate: SBDate = this.minDate.Clone().AddYears(15);

    private _creditCardTypes: CreditCardType[] = [];
    private _countries: NameCodeWithID[] = [];
    private _states: NameCodeWithID[] = [];
    private _cvvPlaceholder: string = '';
    private _cvvTooltip: string = $localize`:@@CommonAddPaymentSourceCardGenericCVVTooltip:Your CVV (Card Verification Value) is displayed on the back of your card as the last 3 digits`;
    private _paymentProcessorError: string | null = null;
    private _defaultState: NameCodeWithID = {
        ID: 'default',
        Name: $localize`:@@CommonAddPaymentSourceCountryStatesDefault:Outside US/Canada`,
        Code: null,
        Default: true
    };


    constructor( private _DialogService: DialogService,) { 
        super();
    }

    override ngOnInit(): void {
        super.ngOnInit();

        if (this.paymentProcessor.Type != PaymentProcessorType.PayPalPro) {
            throw Error('Wrong payment processor type for payment-sources-add-paypal-pro.component.ts')
        }

        this._creditCardTypes = this.paymentProcessor.CreditCardsMetaData.map(card => new CreditCardType({...card, ID: card.Brand}));

        this.GetCountries();
        this.addPayPalPaymentMethodForm.controls.SavePaymentSource.setValue(this.mode !== PaymentSourceMode.AnonymousPayment);
    }

    GetStates(country: NameCodePair) {
        this.addPayPalPaymentMethodForm.controls.State.isLoading = true;

        ApiBaseRoutes.Countries.GetStates.Call({Parameter: country.Code!}).subscribe(states => {
            if (states && states.length > 0) {
                this._states = states.map(state => new NameCodeWithID({...state, ID: state.Code!}));

                const defaultState = this._states.find(state => state.Default);
                if (defaultState) {
                    this.addPayPalPaymentMethodForm.controls.State.setValue(defaultState);
                } else {
                    this.addPayPalPaymentMethodForm.controls.State.setValue(null);
                }
            } else {
                this._states = [this._defaultState];
                this.addPayPalPaymentMethodForm.controls.State.setValue(this._defaultState);
            }

            this.addPayPalPaymentMethodForm.controls.State.isLoading = false;
        });
    }

    CardTypeChanged(card: CreditCardType) {
        this.addPayPalPaymentMethodForm.controls.AccountNumber.setValidators([Validators.required, Validators.exactLength(card.NumberLength, '[0-9]')]);
        this.addPayPalPaymentMethodForm.controls.AccountNumber.updateValueAndValidity();

        this.addPayPalPaymentMethodForm.controls.CVV.setValidators([Validators.required, Validators.exactLength(card.CvcLength)]);
        this.addPayPalPaymentMethodForm.controls.CVV.updateValueAndValidity();

        if (card.Brand === CreditCardBrand.Amex) {
            this._cvvTooltip = $localize`:@@CommonAddPaymentSourceCardAmexCVVTooltip:Your CVV (Card Verification Value) is the ${card.CvcLength} digits above the last few digits on the front of the card`;
        } else {
            this._cvvTooltip = $localize`:@@CommonAddPaymentSourceCardKnownLengthCVVTooltip:Your CVV (Card Verification Value) is displayed on the back of your card as the last ${card.CvcLength} digits`;
        }

        this._cvvPlaceholder = '1234567890'.slice(0, card.CvcLength);
    }

    SavePaymentMethodForFuture(savePaymentMethod: boolean): void {
        if(!savePaymentMethod && !this._forceAutoPay){
            this.addPayPalPaymentMethodForm.controls.AutoPay.setValue(false);
        }
    }

    AddPayPalProPaymentMethod(): void {
        if (this.addPayPalPaymentMethodForm.CheckValidity(true)) {
            const formValues = this.addPayPalPaymentMethodForm.value;
            const totalAmountToPay = this.mode === PaymentSourceMode.Add ? null : this.totalAmountToPay!;
            const intent = this.mode === PaymentSourceMode.Add ? PaymentSourceIntent.Vault
                         : this.addPayPalPaymentMethodForm.controls.SavePaymentSource.value ? PaymentSourceIntent.PayAndVault
                         : PaymentSourceIntent.Pay;
            const surcharge = this.mode === PaymentSourceMode.Add ? null : this.onlineConvenienceFee ?? 0;
            const autopay = intent === PaymentSourceIntent.Pay ? null : this.addPayPalPaymentMethodForm.controls.AutoPay.value;

            this.AddCard(new PayPalProPaymentSourceInputItem({
                AddressLine1: formValues.Address1,
                AddressLine2: formValues.Address2 ? formValues.Address2 : null,
                Brand: formValues.CardType!.Brand,
                City: formValues.City,
                CountryCode: formValues.Country!.Code,
                CvcCode: formValues.CVV,
                ExpiryDate: formValues.ExpiryDate!.ToISO(),
                PaymentProcessorID: this.paymentProcessor.ID,
                CardholderName: formValues.HolderName,
                CardNumber: formValues.AccountNumber!.replace(/\D/g, ''),
                ParentID: this.parentID,
                PostalCode: formValues.ZipCode,
                ProvStateCode: formValues.State!.Code,
                Intent: intent,
                Total: totalAmountToPay,
                Surcharge: surcharge,
                AutoPay: autopay,
                FamilyID: this.familyID
            }));
        } else if (this.context == PaymentContext.SignupWidget) {
            this.signupFormPaymentSourceReady.emit(null);
        } else {
            this.paymentSourceProcessed.emit(null);
        }
    }

    private AddCard(inputItem: PayPalProPaymentSourceInputItem) {
        if (this.context == PaymentContext.SignupWidget) {              
            this.signupFormPaymentSourceReady.emit(inputItem);                  
        } else {
            ApiBaseRoutes.PaymentSources.Add.Call({
                Parameter: this.mode === PaymentSourceMode.AnonymousPayment ? this.schoolID! : '',
                Body: inputItem
            }).subscribe({
                next: result => {
                    this.paymentSourceProcessed.emit(result);
                },
                error: (error: typeof ApiBaseRoutes.PaymentSources.Add.Error) => {
                    if (error.Response.NewErrorCode === ErrorCode.OnlinePaymentError) {
                        this.ShowErrorDialog(error.Response.ErrorMessage!);
                    } else {
                        throw error;
                    }
                }
            });
        }
    }

    private ShowErrorDialog(errorMessage: string) {
        this.addPayPalPaymentMethodForm.enable();

        this._DialogService.CreateInfoDialog(new SimpleDialogData({
            Title: $localize`:@@CommonAddPaymentSourcePaymentProcessorError:Payment Processor Error`,
            Text: errorMessage,
        }), false).events.subscribe(event => {
            if (event === DialogEvents.PrimaryAction) {
                if (this.context == PaymentContext.SignupWidget) {
                    this.signupFormPaymentSourceReady.emit(null);
                } else {
                    this.paymentSourceProcessed.emit(null);
                }
            }
        }); 
    }

    private GetCountries() {
        this.addPayPalPaymentMethodForm.controls.Country.isLoading = true;

        ApiBaseRoutes.Countries.GetAll.Call().subscribe(countries => {
            this._countries = countries.map(country => new NameCodeWithID({...country, ID: country.Code!}));

            let defaultCountry: NameCodeWithID | undefined;

            defaultCountry = this._countries.find(country => country.Default);

            if (!defaultCountry) {
                defaultCountry = this._countries[0];
            }

            this.addPayPalPaymentMethodForm.controls.Country.setValue(defaultCountry);
            this.addPayPalPaymentMethodForm.controls.Country.isLoading = false;

            this.GetStates(defaultCountry);
        });
    }
    get creditCardTypes(): CreditCardType[] {
        return this._creditCardTypes;
    }

    get countries(): NameCodeWithID[] {
        return this._countries;
    }

    get states(): NameCodeWithID[] {
        return this._states;
    }

    get cvvPlaceholder(): string {
        return this._cvvPlaceholder;
    }

    get cvvTooltip(): string {
        return this._cvvTooltip;
    }

    get paymentProcessorError(): string | null {
        return this._paymentProcessorError;
    }

    get PaymentSourceType(): typeof PaymentSourceType {
        return PaymentSourceType;
    }

    get SurchargeType(): typeof SurchargeType {
        return SurchargeType;
    }

    get PaymentSourceMode(): typeof PaymentSourceMode {
        return PaymentSourceMode;
    }

    get PaymentContext(): typeof PaymentContext {
        return PaymentContext;
    }

    get forceAutoPay(): boolean {
        return this._forceAutoPay;
    }
}
