import { Component, Input, OnInit } from '@angular/core';
import { DesignBlockBaseViewComponent } from '@library/base';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WebsiteBlockEmbedType } from '@library/data-models';

@Component({
  selector: 'lib-map-design-block',
  templateUrl: './map-design-block.component.html',
  styleUrls: ['./map-design-block.component.scss']
})
export class MapDesignBlockComponent extends DesignBlockBaseViewComponent {

  @Input() type: WebsiteBlockEmbedType = WebsiteBlockEmbedType.Default;
  @Input() isFullWidth: boolean = false; 
  @Input()
  set source(src : string | null) {
    if (src) {
      this._url = src;
    }
  }
  @Input() isInSidebar: boolean = false; 
  constructor( private _sanitizer: DomSanitizer) {
    super();
  }
  private _url : string | null = null; 

  get WebsiteBlockEmbedType(): typeof WebsiteBlockEmbedType {
    return WebsiteBlockEmbedType
  }

  get isLocationSet(): boolean {
    return this._url !== null; 
  }
  
  get url():  string | null {
    return this._url; 
  }

}
