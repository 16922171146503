import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiBaseModule } from '@library/api';
import { BaseModule } from '@library/base';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    BaseModule,
    ApiBaseModule,
  ],
  exports: [
  ]
})
export class ElevioModule { }
