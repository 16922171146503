<lib-form-field-container
    [control]="formControl"
    [isLoading]="isLoading"
    [isReadOnly]="isReadOnly"
    [isViewOnly]="isViewOnly"
    [isOptional]="isOptional"
    [isDisabled]="isDisabled"
    [prefixIcon]="prefixIcon"
    [suffixIcon]="suffixIcon"
    [errors]="errors"
    [kind]="kindOverride"
    [hideErrors]="hideErrors">

    <ng-content label select="[label]"></ng-content>
    <ng-content hintMessage select="[hintMessage]"></ng-content>
    <ng-content prefixText select="[prefixText]"></ng-content>
    <ng-content suffixText select="[suffixText]"></ng-content>
    <ng-content readOnly select="[readOnly]"></ng-content>
    <ng-content menu select="[menu]"></ng-content>




        <input
        #input
        [formControl]="viewControl"
        [attr.autocorrect]="autocorrect ? 'on' : 'off'"
        [autocomplete]="autocorrect ? 'on' : 'off'"
        [spellcheck]="autocorrect"
        [placeholder]="placeholderText"
        [maxLength]="maxCharacterLength"
        [attr.data-sb-qa]="qaTag"
        (blur)="OnBlur()"
        (change)="$event.stopPropagation()"/>



</lib-form-field-container>


