import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, QueryList, Renderer2, TemplateRef, ViewChildren } from '@angular/core';
import { FormFieldInputBaseComponent } from '../form-field-input-base.component';
import { FormContainerKind } from '@library/base';

@Component({
    selector: 'lib-input-text',
    templateUrl: './form-field-text.component.html',
    styleUrls: ['./form-field-text.component.scss']
})
export class FormFieldTextComponent extends FormFieldInputBaseComponent<string> implements OnInit, AfterViewInit {

    @ViewChildren('input') input!: QueryList<ElementRef<HTMLInputElement>>;

    @Input() kindOverride: FormContainerKind = FormContainerKind.Default;

    private _inputFilter: RegExp | undefined = undefined;
    @Input()
    public set inputFilter(value: string) {
        this._inputFilter = new RegExp(`(${value})|.`, 'g');
    }

    constructor(private _Renderer: Renderer2) {
        super();

        this.viewControl.valueChanges.subscribe(value => {
            this.SetFormControlValue(value?.trim());
        })
    }

    ngAfterViewInit() {
        const placeholder = this.input.first.nativeElement.getAttribute('placeholder');
        if(placeholder !== null){
            const placeholderLength = placeholder.length + 200; // 200 for the min-width of menu
            this._Renderer.setStyle(this.input.first.nativeElement,'min-width', placeholderLength+'px');

        }
    }

    @HostListener('input', ['$event'])
    ApplyFilter() {
        const rawValue = this.viewControl.value;

        if (this._inputFilter && rawValue) {
            this.viewControl.setValue(rawValue.replace(this._inputFilter, '$1'));
        }
    }
}

