import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseViewComponent, Environment } from '@library/base';
import { CustomFieldCheckedListValueInputItem, CustomFieldDisplayItem, CustomFieldDropDownListValueInputItem, CustomFieldEmailValueInputItem, CustomFieldMultiLineValueInputItem, CustomFieldPolicyValueInputItem, CustomFieldRadioListValueInputItem, CustomFieldTextValueInputItem, CustomFieldType, CustomFieldValueInputItem, FormAffinity, WebsiteCustomFieldSignupFormDisplayItem } from '@library/data-models';

@Component({
    selector: 'lib-signup-form-custom-fields',
    templateUrl: './signup-form-custom-fields.component.html',
    styleUrls: ['./signup-form-custom-fields.component.scss']
})
export class SignupFormCustomFieldsComponent extends BaseViewComponent implements OnInit{
    @Input() kind!: FormAffinity;
    @Input()
    set fields(value: WebsiteCustomFieldSignupFormDisplayItem[]) {
        if (value !== undefined) {
            this._fields = value;

            this._filteredFields = this.fields.filter(field => field.Affinity === this.kind).sort((a, b) => a.OrderIndex - b.OrderIndex);
        
            let uniqueID = 0;
            this._filteredFields.forEach(field => field.GUID = (uniqueID++).toString());
        }
    }
    @Input() form!: FormGroup;
    @Input() isViewOnly: boolean = false;

    private _fields: WebsiteCustomFieldSignupFormDisplayItem[] = [];
    private _filteredFields: WebsiteCustomFieldSignupFormDisplayItem[] = [];

    override ngOnInit(): void {
        super.ngOnInit();
        this.form.isViewOnly = this.isViewOnly;
    }

    constructor(private _Environment: Environment) {
        super();
    }

    GetFormControl(field: WebsiteCustomFieldSignupFormDisplayItem) {
        let control!: FormControl;
        switch(field.Field?.FieldType){
            case CustomFieldType.RadioList:
                control = this.form.AddControl(field.GUID!, CustomFieldDisplayItem.AsRadioList(field.Field).Options[0]);
                break;
            case CustomFieldType.DropDownList:
            case CustomFieldType.Text:
            case CustomFieldType.MultiLine:
            case CustomFieldType.Email:
                control = this.form.AddControl(field.GUID!, '');
                break;
            case CustomFieldType.CheckedList:
                control = this.form.AddControl(field.GUID!, []);
                break;
            case CustomFieldType.Policy:
                control = this.form.AddControl(field.GUID!, false);
                break;
        }

        if((CustomFieldDisplayItem.IsCheckedList(field.Field!)
            || CustomFieldDisplayItem.IsDropDownList(field.Field!)
            || CustomFieldDisplayItem.IsMultiLine(field.Field!)
            || CustomFieldDisplayItem.IsPolicy(field.Field!)
            || CustomFieldDisplayItem.IsText(field.Field!)
            || CustomFieldDisplayItem.IsEmail(field.Field!)
            )
            && field.Field.Required){
                control.addValidators(CustomFieldDisplayItem.IsPolicy(field.Field!) ? Validators.requiredTrue : Validators.required);
        }
        return control;
    }

    GetCustomFieldValues(): CustomFieldValueInputItem[]{
        const fieldValues: CustomFieldValueInputItem[] = [];
        this._filteredFields.forEach(field => {
            let customFieldValueInputItem: CustomFieldValueInputItem;

            switch (field.Field!.FieldType) {
                case CustomFieldType.CheckedList:
                    customFieldValueInputItem = new CustomFieldCheckedListValueInputItem({ Options: this.form.get(field.GUID!)?.value });
                    break;
                case CustomFieldType.DropDownList:
                    customFieldValueInputItem = new CustomFieldDropDownListValueInputItem({ Option: this.form.get(field.GUID!)?.value });
                    break;
                case CustomFieldType.RadioList:
                    customFieldValueInputItem = new CustomFieldRadioListValueInputItem({ Option: this.form.get(field.GUID!)?.value });
                    break;
                case CustomFieldType.Email:
                    customFieldValueInputItem = new CustomFieldEmailValueInputItem({ Email: this.form.get(field.GUID!)?.value });
                    break;
                case CustomFieldType.MultiLine:
                    customFieldValueInputItem = new CustomFieldMultiLineValueInputItem({ Text: this.form.get(field.GUID!)?.value });
                    break;
                case CustomFieldType.Text:
                    customFieldValueInputItem = new CustomFieldTextValueInputItem({ Text: this.form.get(field.GUID!)?.value });
                    break;
                case CustomFieldType.Policy:
                    customFieldValueInputItem = new CustomFieldPolicyValueInputItem({ Accepted: this.form.get(field.GUID!)?.value });
                    break;
                default:
                    throw Error;
            }

            customFieldValueInputItem.FieldID = field.ID;
            fieldValues.push(customFieldValueInputItem);
        });

        return fieldValues;
    }

    PatchCustomFieldValues(fields: CustomFieldValueInputItem[]): void {
        fields.forEach(field => {

            const fieldGUID = this._filteredFields.find(f => f.ID === field.FieldID!)?.GUID;

            if (fieldGUID) {
                switch(field.FieldType){
                    case CustomFieldType.CheckedList:
                        this.form.get(fieldGUID)?.patchValue((field as CustomFieldCheckedListValueInputItem).Options);
                        break;
                    case CustomFieldType.DropDownList:
                    case CustomFieldType.RadioList:
                        this.form.get(fieldGUID)?.patchValue((field as CustomFieldDropDownListValueInputItem | CustomFieldRadioListValueInputItem).Option);
                        break;
                    case CustomFieldType.Email:
                        this.form.get(fieldGUID)?.patchValue((field as CustomFieldEmailValueInputItem).Email);
                        break;
                    case CustomFieldType.MultiLine:
                    case CustomFieldType.Text:
                        this.form.get(fieldGUID)?.patchValue((field as CustomFieldMultiLineValueInputItem | CustomFieldTextValueInputItem).Text);
                        break;
                    case CustomFieldType.Policy:
                        this.form.get(fieldGUID)?.patchValue((field as CustomFieldPolicyValueInputItem).Accepted);
                        break;
                }
            }
        })
    }

    PreparePolicyURL(originalURL: string): string {
        if(originalURL.startsWith("WebsiteMedia/wmi")){
            return `${this._Environment.Application.WebsiteBase}${originalURL}`;
        } else {
            return originalURL;
        }
    }

    get filteredFields(): WebsiteCustomFieldSignupFormDisplayItem[] {
        return this._filteredFields;
    }

    get CustomFieldDisplayItem(): typeof CustomFieldDisplayItem {
        return CustomFieldDisplayItem;
    }

    get CustomFieldType(): typeof CustomFieldType {
        return CustomFieldType;
    }
    
    get fields() {
        return this._fields;
    }
}
