/**
 *  important: make sure no spaces around the comma, the string will be processed with split method to get every single type's name
 *  We are using MIME types instead of common extension, for details, see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types}
 */
export enum UploadFileType {
    All = "all",
    AllImages = "image/png,image/jfif,image/pjpeg,image/jpeg,image/pjp,image/jpg,image/bmp,image/dib,image/gif,image/webp",
    AvatarImages = "image/png,image/jpeg,image/jpg,image/bmp",
    MediaGalleryImages = "image/png,image/jpeg,image/jpg,image/bmp,image/gif,image/webp",
    PDF = "application/pdf",
}

const FILE_SIZE: {[key: string]: number} = {
    SIZE_25MB: 26214400,
    SIZE_50MB: 52428800,
    SIZE_2GB: 2147483648,
}

export const FileSize = {
    Maximum: {
        Images: {
            Size25MB: FILE_SIZE.SIZE_25MB,
            Size50MB: FILE_SIZE.SIZE_50MB,
        },
        Attachment: {
            Size25MB: FILE_SIZE.SIZE_25MB,
            Size50MB: FILE_SIZE.SIZE_50MB,
            Size2GB: FILE_SIZE.SIZE_2GB,
        },
        PDF: {
            Size25MB: FILE_SIZE.SIZE_25MB,
        }
    }
};