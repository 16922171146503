<lib-card>
    <h5 i18n="@@CommonPaymentPagePaymentCompletedLabel">Payment Completed</h5>
    <p class="pt-3" i18n="@@CommonPaymentPagePaymentCompletedDescription">Your payment was completed successfully. It may take a few minutes before this payment is reflected on your account.</p>
    <div class="d-flex justify-content-center w-100 py-3">
        <img *ngIf="context !== PaymentContext.PaymentPortal" src="assets/images/Payment Successful.svg">
        <img *ngIf="context === PaymentContext.PaymentPortal" src="assets/images/Invoice-Paid.svg">
    </div>
    <hr>
    <ng-content></ng-content>
    <div *ngIf="mode !== PaymentSourceMode.AnonymousPayment && showDoneButton" class="d-flex justify-content-end">
        <hr>
        <lib-button
            (clicked)="DoneClicked()"
            [qaTag]="'done-btn'">
            <ng-container label i18n="@@CommonDone">Done</ng-container>
        </lib-button>
    </div>
</lib-card>
