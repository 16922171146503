import { LocalizationInfo } from "@library/data-models";

export type TimeFormat = 'short' | 'long' | 'iso';
export type DateFormat = 'short' | 'long' | 'monthday' | 'yearmonth' | 'dayofweek' | 'iso';

export class SBDateTime {

    // Static

    private static _isoRegex: RegExp = new RegExp('^(\\d{4})-(\\d{2})-(\\d{2})(?:T(\\d{2}):(\\d{2}):(\\d{2}).*)?$');

    // Date parsing/rendering
    protected static _shortDatePattern: string = '';
    protected static _longDatePattern: string = '';
    protected static _monthDayPattern: string = '';
    protected static _yearMonthPattern: string = '';
    protected static _dayOfWeekPattern: string = 'dddd';
    protected static _yearIndex: number = 0;
    protected static _monthIndex: number = 1;
    protected static _dayIndex: number = 2;
    protected static _dateSeparatorRegExp: RegExp = new RegExp('\\D+', 'g');

    protected static _dateRenderRegExp: RegExp = new RegExp("'[^']*'|y+|M+|d+", 'g');
    protected static _dateRenderMapping: { [x: string]: (d: SBDateTime) => string };

    // Time parsing/rendering
    protected static _is12Hour: boolean = false;
    protected static _amDesignator: string = 'am';
    protected static _pmDesignator: string = 'pm';
    protected static _shortTimePattern: string = '';
    protected static _longTimePattern: string = '';
    protected static _hourIndex: number = 1;
    protected static _minuteIndex: number = 2;
    protected static _meridianIndex: number = 3;
    protected static _timeRegExp: RegExp = /./;

    protected static _timeRenderRegExp = new RegExp("'[^']*'|H+|h+|m+|s+|t+", 'g');
    protected static _timeRenderMapping: { [x: string]: (d: SBDateTime) => string } = null as any;

    static SetLocalizationInfo(localizationInfo: LocalizationInfo): void {
        // Build regexes based on localization info
        SBDateTime.InitializeDateParser(localizationInfo);
        SBDateTime.InitializeTimeParser(localizationInfo);
        SBDateTime.InitializeTimeRenderer(localizationInfo);
        SBDateTime.InitializeDateRenderer(localizationInfo);
    }

    private static InitializeDateParser(localizationInfo: LocalizationInfo): void {
        SBDateTime._shortDatePattern = localizationInfo.ShortDatePattern!;
        SBDateTime._longDatePattern = localizationInfo.LongDatePattern!;
        SBDateTime._monthDayPattern = localizationInfo.MonthDayPattern!;
        SBDateTime._yearMonthPattern = localizationInfo.YearMonthPattern!;

        const order = SBDateTime._shortDatePattern.replace(/y+/, '0').replace(/M+/, '1').replace(/d+/, '2').replace(/\D/g, '');

        SBDateTime._yearIndex = order.indexOf('0');
        SBDateTime._monthIndex = order.indexOf('1');
        SBDateTime._dayIndex = order.indexOf('2');
    }

    private static InitializeTimeParser(localizationInfo: LocalizationInfo): void {
        SBDateTime._is12Hour = localizationInfo.Is12Hour;
        SBDateTime._amDesignator = localizationInfo.AMDesignator!;
        SBDateTime._pmDesignator = localizationInfo.PMDesignator!;
        SBDateTime._shortTimePattern = localizationInfo.ShortTimePattern!;
        SBDateTime._longTimePattern = localizationInfo.LongTimePattern!;

        const meridianRegExp = `(${SBDateTime._amDesignator}|${SBDateTime._pmDesignator})?`;

        const order = localizationInfo.ShortTimePattern!.replace(/h+/i, '0').replace(/m+/, '1').replace(/t+/, '2').replace(/\D/g, '');
        SBDateTime._hourIndex = order.indexOf('0');
        SBDateTime._minuteIndex = order.indexOf('1');
        SBDateTime._meridianIndex = order.indexOf('2');

        let regExpBuilder: string[] = [];
        regExpBuilder[SBDateTime._hourIndex] = "(\\d{1,4})";
        regExpBuilder[SBDateTime._minuteIndex] = "(\\d{0,2})";
        if (SBDateTime._is12Hour) {
            regExpBuilder[SBDateTime._meridianIndex] = meridianRegExp;
        }

        const separators = `[^0-9${SBDateTime._amDesignator[0]}${SBDateTime._pmDesignator[0]}]*`;
        SBDateTime._timeRegExp = new RegExp(`^${regExpBuilder.join(separators)}\$`, 'i');
    }

    private static InitializeTimeRenderer(localizationInfo: LocalizationInfo): void {
        SBDateTime._timeRenderMapping = {
            H:  (dateTime: SBDateTime) => dateTime.hour.toString(),
            HH: (dateTime: SBDateTime) => dateTime.hour.toString().padStart(2, '0'),
            h:  (dateTime: SBDateTime) => ((dateTime.hour + 11) % 12 + 1).toString(),
            hh: (dateTime: SBDateTime) => ((dateTime.hour + 11) % 12 + 1).toString().padStart(2, '0'),
            m:  (dateTime: SBDateTime) => dateTime.minute.toString(),
            mm: (dateTime: SBDateTime) => dateTime.minute.toString().padStart(2, '0'),
            s:  (dateTime: SBDateTime) => dateTime.second.toString(),
            ss: (dateTime: SBDateTime) => dateTime.second.toString().padStart(2, '0'),
            t:  (dateTime: SBDateTime) => dateTime.hour < 12 ? SBDateTime._amDesignator[0] : SBDateTime._pmDesignator[0],
            tt: (dateTime: SBDateTime) => dateTime.hour < 12 ? SBDateTime._amDesignator : SBDateTime._pmDesignator
        };
    }

    private static InitializeDateRenderer(localizationInfo: LocalizationInfo): void {
        SBDateTime._dateRenderMapping = {
            y:     (dateTime: SBDateTime) => dateTime.year.toString(),
            yy:    (dateTime: SBDateTime) => dateTime.year.toString().padStart(2, '0'),
            yyy:   (dateTime: SBDateTime) => dateTime.year.toString().padStart(3, '0'),
            yyyy:  (dateTime: SBDateTime) => dateTime.year.toString().padStart(4, '0'),
            yyyyy: (dateTime: SBDateTime) => dateTime.year.toString().padStart(5, '0'),
            M:     (dateTime: SBDateTime) => dateTime.month.toString(),
            MM:    (dateTime: SBDateTime) => dateTime.month.toString().padStart(2, '0'),
            MMM:   (dateTime: SBDateTime) => localizationInfo.AbbreviatedMonthGenitiveNames[dateTime.month - 1],
            MMMM:  (dateTime: SBDateTime) => localizationInfo.MonthGenitiveNames[dateTime.month - 1],
            d:     (dateTime: SBDateTime) => dateTime.dayOfMonth.toString(),
            dd:    (dateTime: SBDateTime) => dateTime.dayOfMonth.toString().padStart(2, '0'),
            ddd:   (dateTime: SBDateTime) => localizationInfo.AbbreviatedDayNames[dateTime.dayOfWeek],
            dddd:  (dateTime: SBDateTime) => localizationInfo.DayNames[dateTime.dayOfWeek]
        }
    }

    static Now(): SBDateTime {
        return new SBDateTime(new Date());
    }

    static FromISO(dateString: string): SBDateTime {
        return new SBDateTime(SBDateTime.ISOtoJSDate(dateString));
    }

    static FromJSDate(jsDate: Date): SBDateTime {
        const date = new Date(jsDate);
        if (isNaN(date.valueOf())) {
            throw new Error('Invalid JS date');
        }

        return new SBDateTime(date);
    }

    static FromMilliseconds(milliseconds: number): SBDateTime {
        const date = new Date(milliseconds);
        if (isNaN(date.valueOf())) {
            throw new Error('Invalid date milliseconds');
        }
        
        return new SBDateTime(date);
    }

    static FromSBDateAndSBTime(date: SBDateTime, time: SBDateTime) {
        return new SBDateTime(new Date(date.year, date.month-1, date.dayOfMonth, time.hour, time.minute, time.second));
    }

    protected static ISOtoJSDate(dateString: string) {
        let matches: RegExpMatchArray | null;
        try {
            matches = dateString.match(SBDateTime._isoRegex);
            if (!matches) {
                throw new Error('Invalid ISO date');
            }
        } catch {
            throw new Error('Invalid ISO date');
        }
        
        let date: Date;
        const year = Number(matches[1]);
        const month = Number(matches[2]) - 1;
        const dayOfMonth = Number(matches[3]);
        if (matches[4] == undefined) {
            date = new Date(year, month, dayOfMonth);

            if (date.getFullYear() != year || date.getMonth() != month) {
                throw new Error('Invalid ISO date');
            }
        } else {
            const hour = Number(matches[4]);
            const minute = Number(matches[5]);
            const second = Number(matches[6]);
            date = new Date(year, month, dayOfMonth, hour, minute, second);

            if (date.getFullYear() != year || date.getMonth() != month || hour > 23 || minute > 59 || second > 59) {
                throw new Error('Invalid ISO date');
            }
        }

        return date;
    }


    // Instance

    protected _date!: Date;

    constructor(date: Date) {
        this._date = date;
    }

    protected get dateWithoutTimezone(): Date {
        return new Date(this._date.getTime() - this._date.getTimezoneOffset() * 60000);
    }

    ToISO(): string {
        return this.dateWithoutTimezone.toISOString().slice(0, -5);
    }

    ToJSDate(): Date {
        return new Date(this._date);
    }

    Clone(): SBDateTime {
        return new SBDateTime(new Date(this._date));
    }

    GetDateOnly(): SBDate {
        return this instanceof SBDate ? this : new SBDate(new Date(this._date));
    }

    GetTimeOnly(): SBTime {
        return this instanceof SBTime ? this : new SBTime(new Date(this._date));
    }

    set year(value: number) {
        if (value < 1900 || value > 2100) {
            throw new Error('Setting invalid year value');
        }
        this._date.setFullYear(value);
    }
    get year(): number {
        return this._date.getFullYear();
    }

    set month(value: number) {
        if (value < 1 || value > 12) {
            throw new Error('Setting invalid month value');
        }
        this._date.setMonth(value - 1);
    }
    get month(): number {
        return this._date.getMonth() + 1;
    }

    set dayOfMonth(value: number) {
        let testDate = new Date(this._date.getFullYear(), this._date.getMonth(), value);
        if (testDate.getDate() != value) {
            throw new Error('Setting invalid day-of-month value');
        }
        this._date.setDate(value);
    }
    get dayOfMonth(): number {
        return this._date.getDate();
    }

    get dayOfWeek(): number {
        return this._date.getDay();
    }

    set hour(value: number) {
        if (value < 0 || value >= 24) {
            throw new Error('Setting invalid hour value');
        }
        this._date.setHours(value);
    }
    get hour(): number {
        return this._date.getHours();
    }

    set minute(value: number) {
        if (value < 0 || value >= 60) {
            throw new Error('Setting invalid minute value');
        }
        this._date.setMinutes(value);
    }
    get minute(): number {
        return this._date.getMinutes();
    }

    set second(value: number) {
        if (value < 0 || value >= 60) {
            throw new Error('Setting invalid second value');
        }
        this._date.setSeconds(value);
    }
    get second(): number {
        return this._date.getSeconds();
    }

    get numberOfDaysInMonth(): number {
        return new Date(this._date.getFullYear(), this._date.getMonth()+1, 0).getDate();
    }

    get totalMilliseconds(): number {
        return this._date.getTime();
    }

    // These should be static getters, but for some reason the compiler throws up an error if they are
    get hourPlacement(): number {
        return SBDateTime._hourIndex;
    }

    get minutePlacement(): number {
        return SBDateTime._minuteIndex;
    }

    get meridianPlacement(): number {
        return SBDateTime._meridianIndex;
    }

    AddMinutes(minutes: number): this {
        this._date.setMinutes(this._date.getMinutes() + minutes);
        return this;
    }

    AddHours(hours: number): this {
        this._date.setHours(this._date.getHours() + hours);
        return this;
    }

    AddDays(days: number): this {
        this._date.setDate(this._date.getDate() + days);
        return this;
    }
    AddMonths(months: number): this {
        this._date.setMonth(this._date.getMonth() + months);
        return this;
    }

    AddYears(years: number): this {
        this._date.setFullYear(this._date.getFullYear() + years);
        return this;
    }
}

export class SBDate extends SBDateTime {

    static FromComponents(year: number, month: number, day: number = 1): SBDate {
        const monthIndex = month - 1;
        const date = new Date(year, monthIndex, day);
        if (!date || year < 1850 || date.getDate() != day || date.getMonth() != monthIndex) {
            throw new Error('Invalid date');
        }

        return new SBDate(date);
    }

    // Parsing

    static Parse(dateString: string, format: DateFormat = 'short'): SBDate {
        switch (format) {
            case 'short': return SBDate.ParseShort(dateString);
            case 'iso': return SBDate.FromISO(dateString);
            default: throw new Error(`Parsing of format ${format} is unimplemented`);
        }
    }

    private static ParseShort(dateString: string): SBDate { 
        const parsed = dateString.trim().replace(SBDate._dateSeparatorRegExp, ' ').split(' ');

        if (parsed.length != 3) {
            throw new Error('Invalid date');
        }
        
        const parsedYear = parsed[SBDate._yearIndex];
        if (parsedYear.length < 2) {
            throw new Error('Invalid date');
        }

        let year = Number(parsedYear);
        if (year <= 50) {
            year += 2000;
        } else if (year < 100) {
            year += 1900;
        }

        const month = Number(parsed[SBDate._monthIndex]);
        if (month < 0 || month > 12){
            throw new Error('Invalid date');
        }
        const day = Number(parsed[SBDate._dayIndex]);
        const dateCheck = SBDate.FromComponents(year, month, 1);
        if (day < 0 || day > dateCheck.numberOfDaysInMonth){
            throw new Error('Invalid date');
        }

        return SBDate.FromComponents(year, month, day);
    }

    constructor(dateTime: Date) {
        const date = new Date(dateTime);
        date.setHours(0, 0, 0, 0);
        super(date);
    } 

    // Rendering

    Render(format: DateFormat = 'short'): string {
        switch (format) {
            case 'short': return this.RenderForFormatString(SBDate._shortDatePattern);
            case 'long': return this.RenderForFormatString(SBDate._longDatePattern);
            case 'monthday': return this.RenderForFormatString(SBDate._monthDayPattern);
            case 'yearmonth': return this.RenderForFormatString(SBDate._yearMonthPattern);
            case 'dayofweek': return this.RenderForFormatString(SBDate._dayOfWeekPattern);
            case 'iso': return this.ToISO();
            default: throw new Error(`Parsing of format ${format} is unimplemented`);
        }
    }

    private RenderForFormatString(formatString: string): string {
        return formatString.replace(SBDate._dateRenderRegExp, match => {
            if (match[0] == "'") {
                return match.slice(1, -1);
            } else {
                return SBDate._dateRenderMapping[match](this);
            }
        });
    }

    override ToISO(): string {
        return this.dateWithoutTimezone.toISOString().slice(0, 10);
    }

    override Clone(): SBDate {
        return new SBDate(this._date);
    }

    // Convenience methods

    static Today(): SBDate {
        return new SBDate(new Date());
    }

    static StartOfMonth(): SBDate {
        const date = new Date();
        date.setDate(1);
        return new SBDate(date);
    }

    static EndOfMonth(): SBDate {
        const date = new Date();
        date.setMonth(date.getMonth() + 1);
        date.setDate(0);
        return new SBDate(date);
    }

    static override FromISO(dateString: string): SBDate {
        return new SBDate(SBDateTime.ISOtoJSDate(dateString));
    }

    static override FromJSDate(jsDate: Date): SBDate {
        const date = new Date(jsDate);
        if (isNaN(date.valueOf())) {
            throw new Error('Invalid JS date');
        }

        return new SBDate(date);
    }

    static override FromMilliseconds(milliseconds: number): SBDate {
        const date = new Date(milliseconds);
        if (isNaN(date.valueOf())) {
            throw new Error('Invalid date milliseconds');
        }
        
        return new SBDate(date);
    }

    static FromSBDateTime(dateTime: SBDateTime): SBDate {
        return dateTime.GetDateOnly();
    }

    static MaxSBDate(dates: Array<SBDate>): SBDate {
      return dates.reduce(function (a, b) {
        return a.totalMilliseconds > b.totalMilliseconds ? a : b;
      });
    }

    static MinSBDate(dates: Array<SBDate>): SBDate {
      return dates.reduce(function (a, b) {
        return a.totalMilliseconds < b.totalMilliseconds ? a : b;
      }); 
    }
}

export class SBTime extends SBDateTime {

    // Used to initialize year/month/day component of Date object
    private static _today = new Date();
    private static _thisYear = SBTime._today.getFullYear();
    private static _thisMonth = SBTime._today.getMonth();
    private static _thisDay = SBTime._today.getDate();
    private static _isoPrefix = SBTime._today.toISOString().slice(0, 11);

    static override Now(): SBTime {
        return new SBTime(new Date());
    }

    override Clone(): SBTime {
        return new SBTime(new Date(this._date));
    }

    static override FromISO(dateString: string): SBTime {
        if (dateString.length <= 12) {
            dateString = SBTime._isoPrefix + dateString;
        }

        const date = SBDateTime.ISOtoJSDate(dateString);

        date.setFullYear(SBTime._thisYear, SBTime._thisMonth, SBTime._thisDay);
        return new SBTime(date);
    }

    static override FromJSDate(jsDate: Date): SBTime {
        const date = new Date(jsDate);
        if (isNaN(date.valueOf())) {
            throw new Error('Invalid JS date');
        }

        date.setFullYear(SBTime._thisYear, SBTime._thisMonth, SBTime._thisDay);
        return new SBTime(date);
    }

    static FromSBDateTime(dateTime: SBDateTime): SBTime {
        return dateTime.GetTimeOnly();
    }

    static FromMinutes(minutes: number): SBTime {
        if (minutes < 0) {
            throw new Error('Invalid minute offset');
        }

        if (minutes == 1440) { //temporary investigation
            // const date = new Date(SBTime._thisYear, SBTime._thisMonth, SBTime._thisDay, 0, 0);
            // return new SBTime(date).AddDays(1);

            return this.FromMinutes(1439);
        }

        const hour = Math.floor(minutes / 60);
        const minute = minutes % 60;
        const date = new Date(SBTime._thisYear, SBTime._thisMonth, SBTime._thisDay, hour, minute);

        return new SBTime(date);
    }

    static FromComponents(hour: number, minute: number, second: number = 0): SBTime {
        const date = new Date(SBTime._thisYear, SBTime._thisMonth, SBTime._thisDay, hour, minute, second);
        if (!date || date.getHours() != hour || date.getMinutes() != minute) {
            throw new Error('Invalid time');
        }
        
        return new SBTime(date);
    }

    // Parsing

    static Parse(timeString: string, format: TimeFormat = 'short'): SBTime {
        switch (format) {
            case 'short': return SBTime.ParseShort(timeString);
            case 'iso': return SBTime.FromISO(timeString);
            default: throw new Error(`Parsing of format '${format}' is unimplemented`);
        }
    }

    private static ParseShort(timeString: string): SBTime {
        const matches = timeString.trim().match(SBDateTime._timeRegExp);
        if (!matches || matches.length < 3) {
            throw new Error(`Could not parse time string ${timeString}`);
        }

        const parsed = matches.slice(1);

        let parsedHour = parsed[SBTime._hourIndex];
        let parsedMinute = parsed[SBTime._minuteIndex];
        if (parsedHour.length > 2) {
            // No separator between hours and minutes, so split them up manually
            if (parsedMinute.length > 0) {
                throw new Error('Invalid time');
            }
            parsedMinute = parsedHour.slice(-2);
            parsedHour = parsedHour.slice(0, -2);
        }

        let hour = Number(parsedHour);
        if (SBTime._is12Hour) {
            if (isNaN(hour) || hour < 1 || hour > 12) {
                throw new Error('Invalid time');
            }
    
            const meridian = parsed[SBTime._meridianIndex];
            const isPM = meridian ? meridian.toLowerCase() == SBTime._pmDesignator.toLowerCase() : hour <= 7;
            
            hour %= 12;
            if (isPM) {
                hour += 12;
            }
        }

        return SBTime.FromComponents(hour, Number(parsedMinute), 0);
    }

    // Rendering

    Render(format: TimeFormat = 'short'): string {
        switch (format) {
            case 'short': return this.RenderForFormatString(SBTime._shortTimePattern);
            case 'long': return this.RenderForFormatString(SBTime._longTimePattern);
            case 'iso': return this.ToISO();
            default: throw new Error(`Parsing of format ${format} is unimplemented`);
        }
    }

    private RenderForFormatString(formatString: string): string {
        return formatString.replace(SBTime._timeRenderRegExp, match => {
            if (match[0] == "'") {
                return match.slice(1, -1);
            } else {
                return SBTime._timeRenderMapping[match](this);
            }
        });
    }

    override ToISO(): string {
        return this.dateWithoutTimezone.toISOString().slice(11, -1);
    }
}