

<div [hidden]="loading" id="paypal-button-container" class="single-column-row" style="position: relative">
    <div *ngIf="!totalAmountToPay" style="height:100%; width:100%; position:absolute; z-index:1000000000; opacity:60%; background-color:white;"></div>
    <div *ngIf="showNewWindowOverlay" (click)="PayInNewTab()" style="height:100%; width:100%; position:absolute; z-index:100000000; opacity:0%; cursor: pointer;"></div>

    <!-- PayPal Commerce Platform will create buttons here -->
</div>

<!-- Hidden input to match style between PayPal fields and our own -->
<div [hidden]="true" class="v3"><input #payPalInputStyleElement class="paypal-fontmatch"></div>

<form [hidden]="loading" [formGroup]="form">
    <div class="card_container">
        <div class="single-column-row">
            <lib-form-field-container
                [kind]="FormContainerKind.PayPal"
                [isDisabled]="form.disabled"
                [control]="form.controls.CardNumber"
                [errors]="cardNumberErrors">

                <ng-container label
                    i18n="@@CommonAddPaymentSourceCreditCardNumberLabel">
                    Card Number
                </ng-container>

                <div id="card-number" class="paypal-card-field"></div>
            </lib-form-field-container>
        </div>
        <div class="two-column-row">
            <lib-form-field-container
                [kind]="FormContainerKind.PayPal"
                [isDisabled]="form.disabled"
                [control]="form.controls.ExpiryDate"
                [errors]="expiryDateErrors">

                <ng-container label
                    i18n="@@CommonAddPaymentSourceCreditCardExpiryDateLabel">
                    Expiry Date
                </ng-container>

                <div id="expiry-date" class="paypal-card-field"></div>
            </lib-form-field-container>

            <lib-form-field-container
                [kind]="FormContainerKind.PayPal"
                [isDisabled]="form.disabled"
                [control]="form.controls.CVV"
                [errors]="cvvErrors">
                <ng-container label
                    i18n="@@CommonAddPaymentSourceCreditCardCVVLabel">
                    CVV
                </ng-container>
                <ng-container menu>
                    <lib-icon
                        [icon]="Icon.Common.Help"
                        [size]="IconSize.ThreeExtraSmall"
                        [matTooltip]="cvvTooltip">
                    </lib-icon>
                </ng-container>

                <div id="cvv" class="paypal-card-field"></div>
            </lib-form-field-container>
        </div>

        <div class="two-column-row">
            <lib-form-field-container
                [kind]="FormContainerKind.PayPal"
                [isDisabled]="form.disabled"
                [control]="form.controls.CardHolderName"
                [errors]="cardHolderNameErrors">
                <ng-container label
                    i18n="@@CommonAddPaymentSourceCreditCardHolderNameLabel">
                    Card Holder Name
                </ng-container>

                <div id="cardholder-name" class="paypal-card-field"></div>
            </lib-form-field-container>

            <lib-input-text
                [formControl]="form.controls.PostalCode"
                [kindOverride]="FormContainerKind.PayPalStyleMatch"
                [errorMessages]="zipCodeError"
                qaTag="input-paypalcp-zippostalcode">
                <ng-container label
                    i18n="@@CommonAddPaymentSourceCreditCardZipCodeLabel">
                    Zip/Postal Code
                </ng-container>
            </lib-input-text>
        </div>

        <div class="two-column-row">
            <lib-input-select
                [formControl]="form.controls.Country"
                [kindOverride]="FormContainerKind.PayPalStyleMatch"
                qaTag="input-paypalcp-country"
                (change)="GetStates($event!)">
                <ng-container label
                    i18n="@@CommonAddPaymentSourceCountryLabel">
                    Country
                </ng-container>

                <lib-input-select-item *ngFor="let country of countries"
                    [value]="country">
                    {{ country.Name }}
                </lib-input-select-item>
            </lib-input-select>

            <lib-input-select
                [formControl]="form.controls.State"
                [kindOverride]="FormContainerKind.PayPalStyleMatch"
                placeholderText="Select..."
                i18n-placeholderText="@@CommonSelectPlaceholder"
                [errorMessages]="stateError"
                qaTag="input-paypalcp-stateprovince">
                <ng-container label
                    i18n="@@CommonAddPaymentSourceCreditCardStateProvinceLabel">
                    State/Province
                </ng-container>

                <lib-input-select-item *ngFor="let state of states"
                    [value]="state">
                    {{ state.Name }}
                </lib-input-select-item>
            </lib-input-select>
        </div>
    </div>

    <lib-checkbox *ngIf="context != PaymentContext.SignupWidget && mode === PaymentSourceMode.Payment"
        [formControl]="form.controls.SavePaymentSource"
        qaTag="save-payment-method-for-future-use-checkbox">
        <ng-container
            i18n="@@CommonAddPaymentSourceSavePaymentSourceLabel">
            Save this payment method for future use
        </ng-container>
    </lib-checkbox>

    <div class="pb-2" [ngClass]="{'pt-3': mode === PaymentSourceMode.Payment}" *ngIf="context != PaymentContext.SignupWidget && (mode === PaymentSourceMode.Add || (mode === PaymentSourceMode.Payment && form.controls.SavePaymentSource.value))">
        <lib-checkbox
            [formControl]="form.controls.AutoPay"
            qaTag="automatically-pay-emailed-invoices-checkbox">
            <div label
                i18n="@@CommonAddPaymentSourceAutoPayLabel">
                Auto Pay
            </div>
            <ng-container i18n="@@CommonAddPaymentSourceAutoPayCheckboxDescription">
                Automatically pay emailed invoices
            </ng-container>
        </lib-checkbox>
    </div>

    <ng-container *ngIf="(context === PaymentContext.StudentPortal && mode === PaymentSourceMode.Add) || (context === PaymentContext.SignupWidget && mode === PaymentSourceMode.Payment)">
        <div *ngIf="paymentSurchargeCreditCard && paymentSurchargeCreditCard.Type !== SurchargeType.None && paymentSourceType === PaymentSourceType.CreditCard" class="py-2" [attr.data-sb-qa]="'surcharge-text-creditcard'">
            <lib-label i18n="@@CommonNoteLabel">Note</lib-label>
            <p *ngIf="paymentSurchargeCreditCard.Type === SurchargeType.Fixed" i18n="@@CommonStudentsPaymentSourcesPaymentFixedSurchargeText">
                {{ schoolName }} applies a {{ paymentSurchargeCreditCard.Amount! | formatCurrency  }} convenience fee to all payments.
            </p>
            <p *ngIf="paymentSurchargeCreditCard.Type === SurchargeType.Percentage" i18n="@@CommonStudentsPaymentSourcesPaymentPercentageSurchargeText">
                {{ schoolName }} applies a {{ paymentSurchargeCreditCard.Amount! | formatPercent:2:2  }} convenience fee to all payments.
            </p>
        </div>
    </ng-container>
</form>

<div [hidden]="!loading">
    <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="spinner-border base-100 m-5"></div>
    </div>
</div>