import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseViewComponent, DialogEvents, SimpleDialogData } from '@library/base';
import { DialogService } from '@library/dialog';

@Component({
    selector: 'lib-design-blocks-action-menu',
    templateUrl: './design-blocks-action-menu.component.html',
    styleUrls: ['./design-blocks-action-menu.component.scss']
})
export class DesignBlocksActionMenuComponent extends BaseViewComponent implements OnInit {


    @Input() disableMoveUp: boolean = false;
    @Input() disableMoveDown: boolean = false;
    @Input() disableRemoveItem: boolean = false;
    @Input() disableAddItem: boolean = false;
    @Input() disableMultiSelectImage: boolean = false;
    @Input() showEdit: boolean = false;
    @Input() showAdd: boolean = false;
    @Input() showRemove: boolean = false;
    @Input() showMultiSelectImage: boolean = false;

    @Output() moveUpClicked: EventEmitter<void> = new EventEmitter();
    @Output() moveDownClicked: EventEmitter<void> = new EventEmitter();
    @Output() deleteClicked: EventEmitter<void> = new EventEmitter();
    @Output() editClicked: EventEmitter<void> = new EventEmitter();
    @Output() addItemClicked: EventEmitter<void> = new EventEmitter();
    @Output() removeItemClicked: EventEmitter<void> = new EventEmitter();
    @Output() openMultiSelectMediaGallery: EventEmitter<void> = new EventEmitter();

    private _showActionMenu: boolean = false;

    constructor(private _DialogService: DialogService) {
        super();
        this._showActionMenu = !this.isDesktop;
    }

    MouseEntered(): void {
        if(!this.isDesktop){
            return;
        }
        this._showActionMenu = true;
    }

    MouseLeave() {
        if(!this.isDesktop){
            return
        }
        this._showActionMenu = false;
    }

    MoveUpClicked(): void {
        this.moveUpClicked.emit();
    }

    MoveDownClicked(): void {
        this.moveDownClicked.emit();
    }

    AddItemClicked(): void {
        this.addItemClicked.emit();
    }

    RemoveItemClicked(): void {
        this.removeItemClicked.emit();
    }

    DeleteClicked(): void {
        this._DialogService.CreateDeleteDialog (
            new SimpleDialogData({
                Title: $localize`:@@CommonDesignBlockActionMenuDeleteBlockTitle:Delete Block`,
                Text: $localize`:@@CommonDesignBlockActionMenuDeleteBlockText:Are you sure you want to delete this block?`,
            })).events.subscribe((e: DialogEvents) => {
            switch (e) {
                case DialogEvents.PrimaryAction:
                    this._DialogService.DismissDialog();
                    this.deleteClicked.emit();
                    break;
            }
        });
    }

    EditClicked(): void {
        this.editClicked.emit();
    }

    OpenMultiSelectMediaGallery(): void {
        this.openMultiSelectMediaGallery.emit();
    }

    get showActionMenu(): boolean {
        return this._showActionMenu;
    }

}
