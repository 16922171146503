<lib-form-field-container 
    [control]="formControl"
    [isLoading]="isLoading"
    [isReadOnly]="isReadOnly"
    [isViewOnly]="isViewOnly"
    [isOptional]="isOptional"
    [isDisabled]="isDisabled"
    [prefixIcon]="prefixIcon"
    [suffixIcon]="suffixIcon"
    [suffixIconClickable]="true"
    [errors]="errors"
    (suffixIconClicked)="ToggleView()">


    <ng-content label select="[label]"></ng-content> 
    <ng-content readOnly select="[readOnly]"></ng-content>

    <input matInput   
        [formControl]="viewControl" 
        [attr.autocorrect]="autocorrect ? 'on' : 'off'"
        [autocomplete]="autocorrect ? 'on' : 'off'" 
        [spellcheck]="autocorrect"
        [type]="inputType"
        [placeholder]="placeholderText"
        [maxLength]="maxCharacterLength"
        [attr.data-sb-qa]="qaTag"
        (blur)="OnBlur()"
        (change)="$event.stopPropagation()" />
</lib-form-field-container>


