import { Component } from '@angular/core';
import { DialogBaseViewComponent, SimpleDialogData } from '@library/base';

@Component({
    selector: 'lib-dialog-info',
    templateUrl: './dialog-info.component.html',
    styleUrls: ['./dialog-info.component.scss']
})
export class DialogInfoComponent extends DialogBaseViewComponent<SimpleDialogData> {
    override PrimaryAction() {
      super.PrimaryAction();
      this.DismissAction();
    }
}
