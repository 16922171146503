import { Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Orientation } from '@library/base';
import { FormFieldBaseComponent } from '../form-field-base.component';
import { FormFieldRadioGroupItemComponent } from './form-field-radio-group-item.component';

@Component({
    selector: 'lib-radio-group',
    templateUrl: './form-field-radio-group.component.html',
    styleUrls: ['./form-field-radio-group.component.scss']
})
export class FormFieldRadioGroupComponent<T extends keyof any> extends FormFieldBaseComponent<T, FormControl<T>> implements OnInit {

    @Input() orientation: Orientation = Orientation.Vertical;
    @Input() useParentFullWidth: boolean = false;
    
    @ContentChildren(FormFieldRadioGroupItemComponent) itemQueryList!: QueryList<FormFieldRadioGroupItemComponent<T>>;

    constructor() {
        super();

        this.viewControl = new FormControl<T>(null as any, {nonNullable: true});
    }

    override ngOnInit() {
        super.ngOnInit();

        this.viewControl.setValue(this.formControl.value);
    }

    ChangeEvent() {
        this.SetFormControlValue(this.viewControl.value);

        super.OnBlur();
    }

    get currentItemComponent(): FormFieldRadioGroupItemComponent<T> {
        return this.itemQueryList.find(item => item.value === this.formControl.value)!;
    }
}
