import { MatDateFormats } from '@angular/material/core';

export const SB_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: null,
    },
    display: {
        dateInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
        monthYearLabel: {year: 'numeric', month: 'short'},
        dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
        monthYearA11yLabel: {year: 'numeric', month: 'long'},
    }
};
