import { PipeTransform } from '@angular/core';
import { NumberFormatInfo } from '@library/data-models';

export abstract class LocalizationNumberBasePipe implements PipeTransform {
    protected _NumberFormatInfo!: NumberFormatInfo;

    transform(value: number, minNumOfDecimals = 0, maxNumOfDecimals = 16): string {
        if (this.IsValid(value, minNumOfDecimals, maxNumOfDecimals)) {
            let decimalCount = this.DetermineDecimalCountOfNumber(value);
            decimalCount = this.BoundDecimalCount(decimalCount, minNumOfDecimals, maxNumOfDecimals);
            value = this.RoundNumberToCorrectBoundNumberDecimals(value, decimalCount);
            const isNegative = this.IsValueNegative(value);
            value = this.GetValueMagnitude(value);

            // get string representation of input number, as rest is string manipulation
            let valueStr = value.toString();

            valueStr = this.AddCorrectDecimalZeroPadding(valueStr, decimalCount);
            let modstr = this.GroupNumbersIntoCorrectGroupSizes(decimalCount, valueStr);
            modstr = this.RemoveLeadingGroupSeperatorArtifact(modstr);

            return this.GetLocalizedNumber(modstr, isNegative);
        } else {
            return '';
        }
    }

    //abstract functions
    protected abstract IsValid(value: number, minNumOfDecimals: number, maxNumOfDecimals: number): boolean;

    //shared functions
    private DetermineDecimalCountOfNumber(value: number): number {
        let decimalCount = value.toString().indexOf('.');
        if (decimalCount === -1) {
        } else {
            decimalCount = value.toString().length - decimalCount - 1;
        }
        return decimalCount;
    }

    private BoundDecimalCount(decimalCount: number, minNumOfDecimals: number, maxNumOfDecimals: number): number {
        if (decimalCount > maxNumOfDecimals) {
            decimalCount = maxNumOfDecimals;
        }
        if (decimalCount < minNumOfDecimals) {
            decimalCount = minNumOfDecimals;
        }
        return decimalCount;
    }

    private RoundNumberToCorrectBoundNumberDecimals(value: number, decimalCount: number): number {
        return Math.round(value * Math.pow(10, decimalCount)) / Math.pow(10, decimalCount);
    }

    private IsValueNegative(value: number): boolean {
        if ( value < 0 ) {
            return true;
        } else {
            return false;
        }
    }

    private GetValueMagnitude(value: number): number {
        return Math.abs(value);
    }

    private AddCorrectDecimalZeroPadding(valueStr: string, decimalCount: number): string {
        if ( decimalCount > 0) {
            // have correct number of decimal points.
            const decimalIndex = valueStr.indexOf('.');

            if (decimalIndex === -1) {
                valueStr += '.';
                for (let i = 0; i < decimalCount; ++i) {
                    valueStr += '0';
                }
            } else {
                const numOfZeros = valueStr.length - 1 - decimalIndex;
                if (numOfZeros < decimalCount) {
                    for (let i = 0; i < (decimalCount - numOfZeros); ++i) {
                        valueStr += '0';
                    }
                }
            }
        }
        return valueStr;
    }

    protected GroupNumbersIntoCorrectGroupSizes(decimalCount: number, valueStr: string): string {
        let modstr = '';
        let currentGroup = 0, currentGroupCount = 0;
        let needDecimal = (decimalCount > 0) ? true : false;

        for (let i = valueStr.length - 1; i >= 0; --i) {
            if (needDecimal) {
                if (valueStr.substring(i, i+1) === '.') {
                    needDecimal = false;
                    modstr = this._NumberFormatInfo.DecimalSeparator + modstr;
                } else {
                    modstr = valueStr.substring(i, i+1) + modstr;
                }
            } else {
                modstr = valueStr.substring(i, i+1) + modstr;
                if (currentGroupCount >= this._NumberFormatInfo.GroupSizes[currentGroup] - 1) {
                    modstr = this._NumberFormatInfo.GroupSeparator + modstr;

                    currentGroupCount = 0;
                    currentGroup++;
                    if (currentGroup >= this._NumberFormatInfo.GroupSizes.length) {
                        currentGroup = this._NumberFormatInfo.GroupSizes.length - 1;
                    }
                } else {
                    currentGroupCount++;
                }
            }
        }
        return modstr;
    }

    private RemoveLeadingGroupSeperatorArtifact(modstr: string): string {
        if (modstr[0] === this._NumberFormatInfo.GroupSeparator) {
            modstr = modstr.substring(1);
        }
        return modstr;
    }

    private GetLocalizedNumber(modstr: string, isNegative: boolean): string {
        let str = this.GetNumberFormatString(isNegative);
        str = str.replace('n', modstr);
        return str;
    }

    private GetNumberFormatString(isNegative: boolean): string {
        if (isNegative) {
            return this._NumberFormatInfo.NegativePattern!;
        } else {
            return this._NumberFormatInfo.PositivePattern!;
        }
    }
}
