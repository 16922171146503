import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { WebsiteMode } from '@library/base';

@Directive({
  selector: '[libScrollToView]'
})
export class ScrollToViewDirective {
  @Input() currMode: WebsiteMode = WebsiteMode.ReadOnly;

  constructor(private el: ElementRef) { }

  @HostListener('click') onClickAutoScroll() {
    if(this.currMode === WebsiteMode.Preview) {      
      this.el.nativeElement.scrollIntoView({behavior: 'smooth'});
    }
  }
}
