<!-- Projections:
    title - Used for title of banner. Bold and placed on its own line.
    text - Used for main text of banner. Appears in both unexpanded and expanded banner.
    body - Used for extra text in banner that appears only when expanded. If not provided or empty, banner is unexpandable.
    footer - Used for content to appear at the bottom of the banner, whether expanded or not.
 -->

<div #panelDiv *ngIf="showBanner" class="position-relative">
    <mat-expansion-panel #matExpansionPanel="matExpansionPanel"
        class="border border-1 rounded-3 v3 scss-banner-body-container"
        [ngClass]="{
            'alert-danger border-danger': kind === StatusKind.Danger,
            'alert-info border-info': kind === StatusKind.Default,
            'alert-dark border-dark': kind === StatusKind.Neutral,
            'alert-success border-success': kind === StatusKind.Success,
            'alert-warning border-warning': kind === StatusKind.Warning,
            'background-brand-0' : kind === StatusKind.Brand,
            'alert' : kind !== StatusKind.Brand,
        }"
        [disabled]="!isExpandable"
        [expanded]="!isExpandable || startExpanded"
        [hideToggle]="true"
        (opened)="ExpansionPressed(true)"
        (closed)="ExpansionPressed(false)">
        <mat-expansion-panel-header class="scss-banner-header" expandedHeight="auto">
            <div class="d-flex flex-column w-100">
                <div class="d-flex justify-content-between w-100 scss-banner-title-text-container" [ngClass]="{'cursor-default': !isDismissable}">
                    <div class="d-flex justify-content-start align-items-center w-100">
                        <div class="w-100">
                            <div class="d-flex align-items-start gap-1">
                                <lib-icon [ngClass]="{'brand-500': kind === StatusKind.Brand}" [icon]="kind | method: IconForBannerKind" [size]="IconSize.ExtraSmall"></lib-icon>
                                <div class="d-flex flex-column w-100">
                                    <div class="scss-banner-title-container"><ng-content select="[title]"></ng-content></div>
                                    <div class="scss-banner-text-container"><ng-content select="[text]"></ng-content></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pe-auto">
                        <lib-icon [hidden]="!isDismissable"
                            [icon]="Icon.Common.Close"
                            [size]="IconSize.TwoExtraSmall"
                            (click)="DismissBanner()">
                        </lib-icon>
                    </div>
                </div>
            </div>
        </mat-expansion-panel-header>

        <div [hidden]="!isExpandable" class="scss-expansion-panel-card-container">
            <div class="d-flex justify-content-start align-items-center scss-banner-expandable-body-container">
                <!-- Icon with height 0 here for equal spacing to above -->
                <lib-icon class="me-2 banner-hidden-icon" [ngClass]="{'brand-500': kind === StatusKind.Brand}" 
                    [icon]="kind | method: IconForBannerKind" [size]="IconSize.ExtraSmall"></lib-icon>
                <div #content class="scss-banner-panel-body" (cdkObserveContent)="CheckIfExpandableOrHasFooter()">
                    <ng-content select="[body]"></ng-content>
                </div>
            </div>
        </div>
    </mat-expansion-panel>

    <div #footer class="scss-banner-footer border rounded-3" [hidden]="!isExpandable && !hasFooter"
        [ngClass]="{
            'alert-danger border-danger': kind === StatusKind.Danger,
            'alert-info border-info': kind === StatusKind.Default,
            'alert-dark border-dark': kind === StatusKind.Neutral,
            'alert-success border-success': kind === StatusKind.Success,
            'alert-warning border-warning': kind === StatusKind.Warning,
            'background-brand-0' : kind === StatusKind.Brand,
        }">
        <div class="d-flex">
            <lib-icon *ngIf="hasFooter" class="me-2 banner-hidden-icon" [icon]="kind | method: IconForBannerKind" [size]="IconSize.TwoExtraSmall"></lib-icon>
            <div [hidden]="!hasFooter" #footerContent class="pt-1" (cdkObserveContent)="CheckIfExpandableOrHasFooter()">
                <ng-content select="[footer]"></ng-content>
            </div>
        </div>
        <div class="align-self-end">
            <div *ngIf="isExpandable" class="scss-expand-button">
                <lib-button
                    [kind]="ButtonKind.Link"
                    [suffixIcon]="isExpanded ? Icon.Common.ExpandLess : Icon.Common.ExpandMore"
                    (clicked)="TogglePanel()">
                    <ng-container label>
                        <div [hidden]="!isExpanded" i18n="@@CommonShowLessLabel">Show Less</div>
                        <div [hidden]="isExpanded" i18n="@@CommonShowMoreLabel">Show More</div>
                    </ng-container>
                </lib-button>
            </div>
        </div>
    </div>
</div>
