export enum StatusKind {
    Danger = 'background-danger',
    Default = 'background-default',
    Neutral = 'background-neutral',
    Success = 'background-success',
    Warning = 'background-warning',
    Waiting = 'background-tangerine',
    Trial = 'background-teal',
    Lead = 'background-purple',
    Brand = 'background-brand',
    Light = 'background-light status-label-border',
    Custom = '',
}