<lib-dialog-layout>
    <ng-container title>
        {{data.Title}}
    </ng-container>
    <ng-container content>
        <lib-upload
            (RetrieveFiles)="EmitFiles($event)"
            (RemoveFiles)="EmitFiles($event)"
            [acceptedFormat]="data.AcceptedFormat"
            [allowMultiFileUpload]="data.AllowMultiFileUpload"
            [maxAllowedFilesize]="data.MaxAllowedFilesize"
            [control]="data.Control"
            [hideInlineErrors]="data.HideInlineErrors">
        </lib-upload>

        <div *ngIf="data.Control && data.HideInlineErrors && data.Control.errors?.max" class="mt-3">
            <lib-banner
                customCssClass="banner-expansion-panel-header-height"
                [isDismissable]="false"
                [kind]="StatusKind.Danger">
                <ng-container text>
                    <ng-container *ngIf="data.Control.value.length === 1">
                    <p class="typography-caption-large pb-2" i18n="@@UploadDialogSingleFileTooLarge">
                        Sorry, the file you're trying to upload is too large. Please upload a file no larger than 2GB.
                    </p>
                    </ng-container>
                    <ng-container *ngIf="data.Control.value.length > 1">
                    <p class="typography-caption-large pb-2" i18n="@@UploadDialogMultiFileTooLarge">
                        Sorry, some of the files you're trying to upload are too large. Please upload a file no larger than 2GB.
                    </p>
                    <ng-container *ngFor="let fileName of (true | method: NamesAboveMaxSize)">
                        <li class="typography-input ps-4">
                        {{fileName}}
                        </li>
                    </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container body>
                    <ng-container *ngFor="let fileName of (false | method: NamesAboveMaxSize)">
                    <li class="typography-input ps-4">
                        {{fileName}}
                    </li>
                    </ng-container>
                </ng-container>
            </lib-banner>
        </div>
    </ng-container>
    <ng-container submissionBar>
        <lib-submission-bar [kind]="SubmissionBarKind.DialogBasic">
            <lib-button
                id="LibraryDialogSecondaryButton"
                [loading]="primaryActionClicked"
                [disabled]="!hasFiles || (data.Control !== null && data.Control.invalid)"
                (clicked)="PrimaryAction()">
                <ng-container label>
                    {{data.PrimaryButtonText}}
                </ng-container>
            </lib-button>
            <lib-button
                id="LibraryDialogPrimaryButton"
                [kind]="ButtonKind.Secondary"
                [disabled]="primaryActionClicked"
                (clicked)="DismissAction()">
                <ng-container label>
                    {{data.SecondaryButtonText}}
                </ng-container>
            </lib-button>
        </lib-submission-bar>
    </ng-container>
</lib-dialog-layout>
