import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '@library/base';
import { LayoutModule } from '@library/layout';
import { DialogModule } from '@library/dialog';
import { FormFieldModule } from '@library/form-field';
import { ButtonModule } from '@library/button';
import { MultiFactorAuthSetupDialogComponent } from './setup-mfa-dialog/multi-factor-auth-setup-mfa-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MultiFactorAuthInputComponent } from './mfa-input/multi-factor-auth-mfa-input.component';
import { DrawerModule } from '@library/drawer';
import { MultiFactorAuthSetupDrawerComponent } from './setup-mfa-drawer/multi-factor-auth-setup-mfa-drawer.component';
import { SubmissionBarModule } from '@library/submission-bar';

@NgModule({
  declarations: [
    MultiFactorAuthSetupDialogComponent,
    MultiFactorAuthInputComponent,
    MultiFactorAuthSetupDrawerComponent,
  ],
  imports: [
    CommonModule,
    BaseModule,
    LayoutModule,
    DialogModule,
    DrawerModule,
    FormFieldModule,
    ReactiveFormsModule,
    ButtonModule,
    SubmissionBarModule
  ],
  exports: [
    MultiFactorAuthSetupDialogComponent,
    MultiFactorAuthSetupDrawerComponent,
    MultiFactorAuthInputComponent
  ]
})
export class MultiFactorAuthModule { }
