import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of, Subject, take } from "rxjs";
import { Subscription } from "rxjs/internal/Subscription";
import { AnnouncementKind } from "../definitions/announcement.definitions";
import { AvatarSize } from "../definitions/avatar.definitions";
import { ButtonKind, ButtonSize, MenuButtonMode, MenuDivider } from "../definitions/button.definitions";
import { CalendarEventColorKind } from "../definitions/calendar.definitions";
import { ChartKind } from "../definitions/chart.definitions";
import { EmptyStateGraphic } from "../definitions/empty-state.definitions";
import { FormBase, FormContainerSize, Orientation, TextKind } from "../definitions/forms/form.definitions";
import { FroalaGalleryMode, FroalaSize } from "../definitions/froala.definitions";
import { Icon, IconBulletAlignment, IconBulletTextStyle, IconSize } from "../definitions/icon.definitions";
import { IframeCssClass } from "../definitions/iframe.definitions";
import { ProgressBarKind } from "../definitions/progess.definitions";
import { StatusKind } from "../definitions/status-label.definitions";
import { TabSize } from "../definitions/tab.definitions";
import { UploadFileType } from "../definitions/upload.definitions";
import { SubmissionBarKind } from "../definitions/submission-bar.definitions";
import { Base } from "../base";


@Component({
    template: '',
})
export class BaseViewComponent extends Base implements OnInit, OnDestroy {
    protected _subscriptions: Subscription[] = [];

    protected readonly _ChangeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
    protected readonly Router: Router = inject(Router);

    private static readonly _transitionAnimationSubject: Subject<void> = new Subject();
    private static _inTransitionAnimation: boolean = false;

    constructor() {
        super();
        window.onbeforeunload = () => {
            return FormBase.currentForm?.isDirty ? '' : null;
        }
    }
    
    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(element => {
            element?.unsubscribe();
        });
    }

    DetectChanges(): void {
        this._ChangeDetector.detectChanges();
    }

    static BeginTransitionAnimation() {
        BaseViewComponent._inTransitionAnimation = true;
    }

    static EndTransitionAnimation() {
        if (BaseViewComponent._inTransitionAnimation) {
            BaseViewComponent._inTransitionAnimation = false;
            BaseViewComponent._transitionAnimationSubject.next();
        }
    }

    static get transitionAnimationEnd(): Observable<void> {
        return BaseViewComponent._inTransitionAnimation ? BaseViewComponent._transitionAnimationSubject.pipe(take(1)) : of(1) as any;
    } 

    RandomEnumValue<T>(anEnum: T): T[keyof T] {
        const enumValues = (Object.values(anEnum as any) as unknown) as T[keyof T][];
        const randomIndex = Math.floor(Math.random() * enumValues.length);
        return enumValues[randomIndex];
    }

    get ButtonSize(): typeof ButtonSize {
        return ButtonSize;
    }

    get ButtonKind(): typeof ButtonKind {
        return ButtonKind;
    }

    get IconSize(): typeof IconSize {
        return IconSize;
    }

    get Icon(): typeof Icon {
        return Icon;
    }

    get StatusKind(): typeof StatusKind {
        return StatusKind;
    }

    get Orientation(): typeof Orientation {
        return Orientation;
    }

    get ProgressBarKind(): typeof ProgressBarKind {
        return ProgressBarKind;
    }

    get IframeCssClass(): typeof IframeCssClass {
        return IframeCssClass;
    }

    get AvatarSize(): typeof AvatarSize {
        return AvatarSize;
    }

    get EmptyStateGraphic(): typeof EmptyStateGraphic {
        return EmptyStateGraphic;
    }

    get AnnouncementKind(): typeof AnnouncementKind {
        return AnnouncementKind;
    }

    get ChartKind(): typeof ChartKind {
        return ChartKind;
    }

    get CalendarEventColorKind(): typeof CalendarEventColorKind {
        return CalendarEventColorKind;
    }

    get IconBulletAlignment(): typeof IconBulletAlignment{
        return IconBulletAlignment;
    }

    get IconBulletTextStyle(): typeof IconBulletTextStyle{
        return IconBulletTextStyle;
    }

    get MenuButtonMode(): typeof MenuButtonMode {
        return MenuButtonMode;
    }

    get MenuDivider(): typeof MenuDivider {
        return MenuDivider
    }

    get notSpecifiedString(): string {
        return $localize`:@@CommonNotSpecifiedLabel:Not specified`;
    }

    get TextKind(): typeof TextKind {
        return TextKind;
    }

    get FroalaSize(): typeof FroalaSize {
        return FroalaSize;
    }

    get FroalaGalleryMode(): typeof FroalaGalleryMode {
        return FroalaGalleryMode;
    }

    get UploadFileType(): typeof UploadFileType {
        return UploadFileType;
    }

    get FormContainerSize(): typeof FormContainerSize {
        return FormContainerSize;
    }

    get TabSize(): typeof TabSize {
        return TabSize;
    }

    get SubmissionBarKind(): typeof SubmissionBarKind {
        return SubmissionBarKind;
    }
    
}