import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Subject, Subscription } from 'rxjs';
import { SnackbarData, SnackbarEvents } from '../definitions/snackbar.definitions';
import { BaseViewComponent } from './base-view.component';

@Component({
    template: ''
})
export class SnackbarBaseViewComponent<T> extends BaseViewComponent implements OnInit, OnDestroy {
    private _afteOpenSubscription!: Subscription;
    private _afteCloseSubscription!: Subscription;
    private _snackbarRef!: MatSnackBarRef<SnackbarBaseViewComponent<T>>;
    private _data!: SnackbarData<T>;
    events: Subject<SnackbarEvents> = new Subject<SnackbarEvents>();


    constructor() {
        super();
        this._snackbarRef = inject(MatSnackBarRef);
        this._data = inject(MAT_SNACK_BAR_DATA); 

        this._afteOpenSubscription = this._snackbarRef.afterOpened().subscribe( _ => {
            this.events.next(SnackbarEvents.AfterOpened);
        });
        
        this._afteCloseSubscription = this._snackbarRef.afterDismissed().subscribe( _ => {
            this.events.next(SnackbarEvents.AfterClosing); 
            this._afteCloseSubscription.unsubscribe(); //We unsubrscribe here instead of onDestroy, otherwise this subscription has been cleared before we get here!
        });
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this._afteOpenSubscription.unsubscribe();
    }

    OnAction() {
        this.events.next(SnackbarEvents.OnAction);
    }

    Dismiss() {
        this.events.next(SnackbarEvents.Dismissed);
        this._snackbarRef.dismiss();
    }

    get data(): SnackbarData<T> {
        return this._data;
    }
}
