import { Component, EventEmitter, Input, Output } from '@angular/core';

import { WebsiteBlockTextAndMediaType } from '@library/data-models';
import { TextBlockElementKind, WebsiteMode } from '../definitions/design-blocks.definitions';
import { BaseViewComponent } from './base-view.component';

@Component({
    template: '',
})
export abstract class DesignBlockBaseViewComponent extends BaseViewComponent    {

    @Input() websiteMode: WebsiteMode = WebsiteMode.Preview;
    @Input() disableMoveUp: boolean = false;
    @Input() disableMoveDown: boolean = false;
    @Input() disableRemoveItem: boolean = false;
    @Input() disableAddItem: boolean = false;
    @Input() disableMultiSelectImage: boolean = false;
    @Input() inSidebar: boolean = false;

    @Output() moveUpClicked: EventEmitter<void> = new EventEmitter();
    @Output() moveDownClicked: EventEmitter<void> = new EventEmitter();
    @Output() deleteClicked: EventEmitter<void> = new EventEmitter();
    @Output() editClicked: EventEmitter<void> = new EventEmitter();
    @Output() addItemClicked: EventEmitter<void> = new EventEmitter();
    @Output() removeItemClicked: EventEmitter<void> = new EventEmitter();
    @Output() openMultiSelectMediaGallery: EventEmitter<void> = new EventEmitter();

    MoveUpClicked(): void {
        this.moveUpClicked.emit();
    }

    MoveDownClicked(): void {
        this.moveDownClicked.emit();
    }

    DeleteClicked(): void {
        this.deleteClicked.emit();
    }
    
    EditClicked(): void {
        this.editClicked.emit();
    }

    AddItemClicked(): void {
        this.addItemClicked.emit();
    }

    RemoveItemClicked(): void {
        this.removeItemClicked.emit();
    }

    OpenMultiSelectMediaGallery(): void {
        this.openMultiSelectMediaGallery.emit();
    }

    get WebsiteBlockTextAndMediaType(): typeof WebsiteBlockTextAndMediaType {
        return WebsiteBlockTextAndMediaType;
    } 

    get TextBlockElementKind(): typeof TextBlockElementKind {
        return TextBlockElementKind;
    }

    get WebsiteMode(): typeof WebsiteMode {
        return WebsiteMode;
    }
}
