<lib-dialog-layout>
    <ng-container title>
        <div *ngIf="!hasUploadErrors">
            {{data.Title}}
        </div>
        <div *ngIf="hasUploadErrors" i18n="@@UploadComponentUploadUnsuccessfulLabel">
            Upload Unsuccessful
        </div>
    </ng-container>
    <ng-container content>
        <ng-container *ngIf="hasEmptyFiles">
            <p i18n="@@UploadComponentUploadUnsuccessfulText">Error: Empty File Array, nothing to upload!</p>
        </ng-container>
        <ng-container *ngIf="!hasEmptyFiles">
            <div *ngIf="isFinalizing && !hasUploadErrors">            
                <lib-progress-bar></lib-progress-bar>
                <p class="mt-2" i18n="@@UploadComponentFinalizingLabel">Finalizing...</p>
            </div>
            <div *ngIf="!isFinalizing && !hasUploadErrors">            
                <lib-progress-bar [kind]="ProgressBarKind.Determinate" [value]="progressBarValue"></lib-progress-bar>
                <p class="mt-2" i18n="@@UploadComponentUploadingProgressText">Uploading Attachments... {{progressBarValue | formatDecimal:0:0}}% Complete</p>
            </div>
            <div *ngIf="hasUploadErrors">
                <p class="mb-2" i18n="@@UploadComponentTroubleUploadingText">We had some trouble uploading your file(s). You can try to upload them again, or click "Ignore & Continue" to save without these files.</p>
                <lib-banner
                    [isDismissable]="false"
                    [kind]="StatusKind.Danger">
                    <ng-container text>
                        <p class="typography-caption-large mb-1" i18n="@@UploadComponentUploadingFailedText">
                            The following file(s) couldn't be uploaded:
                        </p>
                        <ng-container *ngFor="let file of (true | method: GetFailedFiles)">
                            <li class="typography-input ps-4">
                                {{ file }}
                            </li>
                        </ng-container>
                    </ng-container>
        
                    <ng-container body>
                        <ng-container *ngFor="let file of (false | method: GetFailedFiles)">
                            <li class="typography-input ps-4">
                                {{ file }}
                            </li>
                      </ng-container>
                    </ng-container>
                </lib-banner>
            </div>
        </ng-container>
    </ng-container>
    <ng-container submissionBar>
        <lib-submission-bar [kind]="SubmissionBarKind.DialogBasic">
            <lib-button *ngIf="hasUploadErrors"
                id="LibraryDialogSecondaryButton"
                (clicked)="TryAgainAction()">
                <ng-container label i18n="@@UploadComponentTryAgainLabel">
                    Try Again
                </ng-container>
            </lib-button>
            <lib-button
                id="LibraryDialogSecondaryButton"
                [kind]="ButtonKind.Secondary"
                (clicked)="CancelUploadAction()">
                <ng-container label i18n="@@CommonCancelButton">
                    Cancel
                </ng-container>
            </lib-button>
            <lib-button *ngIf="hasUploadErrors"
                id="LibraryDialogSecondaryButton"
                [kind]="ButtonKind.Secondary"
                (clicked)="IgnoreContinueAction()">
                <ng-container label i18n="@@UploadComponentIgnoreAndContinueLabel">
                    Ignore & Continue
                </ng-container>
            </lib-button>
        </lib-submission-bar>
    </ng-container>
</lib-dialog-layout>