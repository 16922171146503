<div class="scss-list">
    <mat-expansion-panel *ngIf="isExpandable"
        class="scss-list-expandable-container v3"
        hideToggle
        [expanded]="panelOpened" 
        (opened)="SetPanelOpen(true)"
        (closed)="SetPanelOpen(false)">
        
        <mat-expansion-panel-header>
            <mat-panel-title class="align-items-start align-items-lg-center">
                <lib-checkbox #checkBox *ngIf="listMode == ListMode.ExpandableMultiSelect" class="scss-list-checkbox-padding" [isIndeterminate]="isIndeterminate"
                    (change)="ToggleSelectAll($event)"></lib-checkbox>
                <div class="d-flex flex-column flex-lg-row" [ngClass]="{'ps-3': listMode != ListMode.ExpandableMultiSelect}">
                    <div class="d-flex align-items-center text-nowrap scss-list-title">
                        <ng-content select="[header]"></ng-content>
                    </div>
                    <div class="d-flex align-items-center scss-list-header-height">
                        <ng-content select="[labels]"></ng-content>
                    </div>
                    <div
                        *ngIf="isMobile"
                        class="d-flex align-items-center pb-3 scss-list-header-height">
                        <ng-container *ngTemplateOutlet="rightMenu"></ng-container>
                    </div>
                </div>
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
            <div class="d-flex position-absolute end-0 align-items-sm-center align-items-start scss-list-icon-container">
                <div *ngIf="!isMobile"><ng-container *ngTemplateOutlet="rightMenu"></ng-container></div>
                <div class="scss-expandable-icons">
                    <lib-icon *ngIf="panelOpened" [icon]="Icon.Common.ExpandLess"></lib-icon>
                    <lib-icon *ngIf="!panelOpened" [icon]="Icon.Common.ExpandMore"></lib-icon>
                </div>

            </div>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
    </mat-expansion-panel>

    <div *ngIf="!isExpandable" class="scss-list-container v3">
        <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
    </div>
</div>

<ng-template #listTemplate let-item>
    <mat-selection-list #selectionList *ngIf="isSelectable" [disableRipple]="true" (selectionChange)="SelectionChange()"
        [multiple]="listMode == ListMode.MultiSelect || listMode == ListMode.ExpandableMultiSelect">
        <ng-container *ngFor="let item of items | orderBy: 'inFooter'">
            <mat-list-option *ngIf="!item.inFooter || footerExpanded" togglePosition="before" [value]="item.value" [disabled]="item.disabled" [selected]="item.selected">
                <ng-container *ngTemplateOutlet="rowContent; context:{$implicit:item}"></ng-container>
            </mat-list-option>
        </ng-container>
        <ng-container *ngTemplateOutlet="footerItem"></ng-container>
    </mat-selection-list>

    <mat-list *ngIf="!isSelectable">
        <ng-container *ngFor="let item of items | orderBy: 'inFooter'">
            <mat-list-item *ngIf="!item.inFooter || footerExpanded" [disabled]="item.disabled">
                <ng-container *ngTemplateOutlet="rowContent; context:{$implicit:item}"></ng-container>
            </mat-list-item>
        </ng-container>
        <ng-container *ngTemplateOutlet="footerItem"></ng-container>
    </mat-list>
</ng-template>

<ng-template #rowContent let-item>
    <ng-container *ngIf="item.isExpandable">
        <div class="d-flex justify-content-between">
            <div class="d-flex w-100 flex-wrap px-1" [ngClass]="{'scss-list-item-closed': !item.expanded, 'scss-list-item-open': item.expanded,
                    'align-items-center': !item.isExpandable, 'align-items-start my-1': item.isExpandable}">
                <ng-container *ngTemplateOutlet="item.content"></ng-container>
            </div>
            <lib-icon-button class="m-1" [icon]="item.expanded ? Icon.Common.ExpandLess : Icon.Common.ExpandMore" [kind]="ButtonKind.Tertiary"
                (clicked)="item.ExpandMore()">
            </lib-icon-button>
        </div>
    </ng-container>
    <ng-container *ngIf="!item.isExpandable">
        <ng-container *ngTemplateOutlet="item.content"></ng-container>
    </ng-container>
</ng-template>

<ng-template #footerItem>
    <mat-list-item *ngIf="hasFooter" class="scss-list-footer-height">
        <div class="d-flex w-100 justify-content-center">
            <lib-button [kind]="ButtonKind.Tertiary" [prefixIcon]="footerExpanded ? Icon.Common.ExpandLess : Icon.Common.ExpandMore" (clicked)="SetFooterOpen()">
                <ng-container label>
                    <ng-content select="[footerText]"></ng-content>
                </ng-container>
            </lib-button>
        </div>
    </mat-list-item>
</ng-template>

<ng-template #rightMenu>
    <ng-content select="[rightMenu]"></ng-content>
</ng-template>