<lib-dialog-layout>
    <ng-container title>
        <div class="d-flex justify-content-between">
            <h3>{{ data.Title }}</h3>
        </div>
    </ng-container>
    <ng-container content>
        <p  style="white-space: pre-line;">{{ data.Text }}</p>
    </ng-container>
    <ng-container submissionBar>
        <lib-submission-bar [kind]="SubmissionBarKind.DialogBasic">
            <lib-button
                id="LibraryDialogSecondaryButton"
                [kind]="ButtonKind.Secondary"
                (clicked)="PrimaryAction()">
                <ng-container label>
                    {{ data.PrimaryButtonText }}
                </ng-container>
            </lib-button>
        </lib-submission-bar>
    </ng-container>
</lib-dialog-layout>
