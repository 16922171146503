<lib-dialog-layout>
    <ng-container title>
        {{ data.Title }}
    </ng-container>
    <ng-container content>
        <p class="pb-3" *ngIf="data.Text" [innerHtml]="data.Text | trustHtml"></p>
        <form [formGroup]="form">
            <lib-radio-group [useParentFullWidth]="true" [formControl]="form.controls.Option"
                [orientation]="Orientation.Vertical">
                <lib-radio-group-item [value]="RecurringDeleteOptions.PrimaryOption">
                    {{ data.PrimaryOptionText }}
                </lib-radio-group-item>
                <lib-radio-group-item [value]="RecurringDeleteOptions.SecondaryOption">
                    {{ data.SecondaryOptionText }}
                </lib-radio-group-item>
            </lib-radio-group>
        </form>
    </ng-container>
    <ng-container submissionBar>
        <lib-submission-bar [kind]="SubmissionBarKind.DialogBasic">
            <lib-button 
                id="LibraryDialogPrimaryButton" 
                class="text-nowrap" 
                [kind]="ButtonKind.Danger"
                [loading]="primaryActionClicked || secondaryActionClicked" 
                (clicked)="PrimaryButtonClicked()">
                <ng-container label>
                    {{ data.PrimaryButtonText }}
                </ng-container>
            </lib-button>
            <lib-button 
                id="LibraryDialogSecondaryButton" 
                [kind]="ButtonKind.Secondary" 
                [disabled]="primaryActionClicked || secondaryActionClicked"
                (clicked)="DismissAction()" >
                <ng-container label>
                    {{ data.SecondaryButtonText }}
                </ng-container>
            </lib-button>
        </lib-submission-bar>    
    </ng-container>
</lib-dialog-layout>
