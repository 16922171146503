<lib-snackbar-layout [layout]="data.Layout">
    <ng-container message>
        {{data.Data}}
    </ng-container>
    <ng-container actions>
        <div class="d-flex align-items-center me-1" [ngClass]="{ 'my-1': data.Layout === SnackbarLayout.A, 'justify-content-end mb-1': data.Layout === SnackbarLayout.B }">
            <ng-container *ngIf="data.ActionButtonText">
                <!-- remove lib-button px-2 and py-1 if we create new button kind for action snackbar -->
                <lib-button class="px-2 py-1"
                    [size]="ButtonSize.Small"
                    [kind]="ButtonKind.Secondary"
                    (clicked)="OnAction()">
                    <ng-container label>{{data.ActionButtonText}}</ng-container>
                </lib-button>
            </ng-container>
            <lib-icon *ngIf="data.IsClosable"
                class="cursor-pointer snackbar-close-color p-2"
                [icon]="Icon.Common.Close"
                [size]="IconSize.Medium"
                (click)="Dismiss()">
            </lib-icon>
        </div>
    </ng-container>

</lib-snackbar-layout>
