<lib-payment-page
    [mode]="data.RegistrationFeeType === RegistrationPaymentType.None? PaymentSourceMode.Add: PaymentSourceMode.Payment"
    [paymentProcessor]="renderContext.SignupFormRenderContext!.PaymentProcessor!"
    [currencyCode]="renderContext.SignupFormRenderContext!.CurrencyCode!"
    [forceAutoPay]="renderContext.SignupFormRenderContext!.ForceAutoPay"
    [paymentSurchargeACH]="renderContext.SignupFormRenderContext!.PaymentSurchargeACH!"
    [paymentSurchargeCreditCard]="renderContext.SignupFormRenderContext!.PaymentSurchargeCreditCard!"
    [context]="PaymentContext.SignupWidget"
    [schoolInfo]="schoolInfo"
    [amountToPay]="data.RegistrationFeeType === RegistrationPaymentType.None ? 0 : (numStudents * data.RegistrationFee!)"
    [signupFormSubmitClicked]="signupFormClicked"
    [stripeNextStepClientSecret]="stripeNextStepClientSecret"
    [paymentProcessorErrorMessage]="paymentProcessorErrorMessage"
    (signupFormPaymentSourceReady)="SignupFormPaymentSourceReady($event)"
    (stripeNextStepComplete)="StripeNextStepComplete($event)">
</lib-payment-page>

<ng-content></ng-content>

<div class="pt-2" [ngClass]="{
    'd-flex flex-row w-100 justify-content-end gap-2': !isMobile
    }">

    <div [ngClass]="{
        'mb-2': isMobile
        }">
        <lib-button
            class="scss-signupform-outline-secondary-btn-accent-color"
            [kind]="ButtonKind.Secondary"
            [stretch]="isMobile"
            (clicked)="BackClicked()"
            [disabled]="signupFormClicked">
            <ng-container label i18n="@@CommonBackButton">Back</ng-container>
        </lib-button>
    </div>

    <div>
        <lib-button
            class="scss-widget-primary-btn-accent-color"
            [kind]="ButtonKind.Primary"
            [stretch]="isMobile"
            (clicked)="SubmitClicked()"
            [loading]="signupFormClicked"
            [disabled]="signupFormClicked">
            <ng-container label>
                <ng-container i18n="@@CommonSubmitButton">Submit</ng-container>
            </ng-container>
        </lib-button>
    </div>
</div>
