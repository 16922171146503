import { Injectable } from "@angular/core";
import { CalendarEventColorTheme } from "@library/base";
import { EventColorEnum } from "@library/data-models";

@Injectable({
    providedIn: 'root'
})

export class StyleManager {
    private _eventColorClassMapping = {
        [EventColorEnum.Beach]: { Color: 'beach', Name: $localize`:@@CommonColorBeach:Beach` },
        [EventColorEnum.Black]: { Color: 'black', Name: $localize`:@@CommonColorBlack:Black` },
        [EventColorEnum.Blue]: { Color: 'blue', Name: $localize`:@@CommonColorBlue:Blue` },
        [EventColorEnum.Brown]: { Color: 'brown', Name: $localize`:@@CommonColorBrown:Brown` },
        [EventColorEnum.DarkOrange]: { Color: 'darkorange', Name: $localize`:@@CommonColorDarkOrange:Dark Orange` },
        [EventColorEnum.Gray]: { Color: 'gray', Name: $localize`:@@CommonColorGray:Gray` },
        [EventColorEnum.Green]: { Color: 'green', Name: $localize`:@@CommonColorGreen:Green` },
        [EventColorEnum.GrassGreen]: { Color: 'grassgreen', Name: $localize`:@@CommonColorGrassGreen:Grass Green` },
        [EventColorEnum.Lavender]: { Color: 'lavender', Name: $localize`:@@CommonColorLavender:Lavender` },
        [EventColorEnum.LightGrey]: { Color: 'lightgrey', Name: $localize`:@@CommonColorLightGrey:Light Grey` },
        [EventColorEnum.LightPink]: { Color: 'lightpink', Name: $localize`:@@CommonColorLightPink:Light Pink` },
        [EventColorEnum.Lime]: { Color: 'lime', Name: $localize`:@@CommonColorLime:Lime` },
        [EventColorEnum.Magenta]: { Color: 'magenta', Name: $localize`:@@CommonColorMagenta:Magenta` },
        [EventColorEnum.Mint]: { Color: 'mint', Name: $localize`:@@CommonColorMint:Mint` },
        [EventColorEnum.Olive]: { Color: 'olive', Name: $localize`:@@CommonColorOlive:Olive` },
        [EventColorEnum.Orange]: { Color: 'orange', Name: $localize`:@@CommonColorOrange:Orange` },
        [EventColorEnum.Pink]: { Color: 'pink', Name: $localize`:@@CommonColorPink:Pink` },
        [EventColorEnum.Purple]: { Color: 'purple', Name: $localize`:@@CommonColorPurple:Purple` },
        [EventColorEnum.Red]: { Color: 'red', Name: $localize`:@@CommonColorRed:Red` },
        [EventColorEnum.Royal]: { Color: 'royal', Name: $localize`:@@CommonColorRoyal:Royal` },
        [EventColorEnum.Ruby]: { Color: 'ruby', Name: $localize`:@@CommonColorRuby:Ruby` },
        [EventColorEnum.Salmon]: { Color: 'salmon', Name: $localize`:@@CommonColorSalmon:Salmon` },
        [EventColorEnum.SeaBlue]: { Color: 'seablue', Name: $localize`:@@CommonColorSeaBlue:Sea Blue` },
        [EventColorEnum.Tan]: { Color: 'tan', Name: $localize`:@@CommonColorTan:Tan` },
        [EventColorEnum.Tangerine]: { Color: 'tangerine', Name: $localize`:@@CommonColorTangerine:Tangerine` },
        [EventColorEnum.Teal]: { Color: 'teal', Name: $localize`:@@CommonColorTeal:Teal` },
        [EventColorEnum.Yellow]: { Color: 'yellow', Name: $localize`:@@CommonColorYellow:Yellow` },
        [EventColorEnum.White]: { Color: 'white', Name: $localize`:@@CommonColorWhite:White` }
    };

    GetColorName(color: EventColorEnum): string {
        return this._eventColorClassMapping[color].Name;
    }

    GetColorCssClass(color: EventColorEnum): string {
        return 'color-' + this._eventColorClassMapping[color].Color;
    }

    GetDarkColorCssClass(color: EventColorEnum): string {
        return 'color-dark-' + this._eventColorClassMapping[color].Color;
    }
    GetBackgroundColorCssClass(color: EventColorEnum): string {
        return 'background-' + this._eventColorClassMapping[color].Color;
    }
    GetBackgroundInverseColorCssClass(color: EventColorEnum): string {
        return 'background-inverse-color-' + this._eventColorClassMapping[color].Color;
    }
    GetBorderColorCssClass(color: EventColorEnum): string {
        return 'border-color-' + this._eventColorClassMapping[color].Color;
    }
    GetCSSPropertyValue(property: string): string {
        return  getComputedStyle(document.body).getPropertyValue(property).trim();
    }
    SetCSSPropertyValue(property: string, value: string): void {
       document.documentElement.style.setProperty(property, value);
    }

    GetRealBackgroundColor(elem: HTMLElement | null): string {
        const transparent = 'rgba(0, 0, 0, 0)';
        if (!elem) {
            return transparent;
        }
        var backgroundColor = getComputedStyle(elem).backgroundColor;

        if (backgroundColor === transparent) {
            return this.GetRealBackgroundColor(elem.parentElement);
        } else {
            return backgroundColor;
        }
    }

    GetColorDisplayName(color: EventColorEnum): string {
        return this._eventColorClassMapping[color].Name;
    }

    GetBackgroundColorThemeClass(theme: CalendarEventColorTheme, colorName: string): string {
        return (`background-${theme === CalendarEventColorTheme.HighContrast ? "high-contrast-" : ""}${colorName}`).toLowerCase();
    }
}
