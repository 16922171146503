import { AbstractType } from "@angular/core";

export type FieldOf<T> = keyof T & string;

export type InstanceOf<T> = T extends AbstractType<infer U> ? U : never;
export type Class<T> = { [K in keyof T]: T[K] };
export type Enum<T> = T extends Array<any> ? never
                    : T extends { prototype: any } ? never
                    : Class<T>;

export const string = 'string';
export const number = 'number';
export const boolean = 'boolean';

export interface ItemWithID {
    ID: string | null;
}

export function IsItemWithID(x: any): x is ItemWithID {
    return 'ID' in x && typeof x.ID === 'string';
}

export interface NameWithID extends ItemWithID {
    FullName? : string | null;
    Name? : string | null;
}

export enum ProductName {
    AthletaDesk = 'AthletaDesk',
    MyMusicStaff = 'MyMusicStaff',
    TutorBird = 'TutorBird',
    Test443 = 'Test443',
    Local = 'Local'
}

export interface RouteState<T> {
    fromRoute: T
}



export function JSONstringifyInOrder<T>(obj: T, exclude: Array<keyof T> = []) {
    const allKeys = new Set();
    JSON.stringify(obj, (key, value) => (allKeys.add(key), value));
    for (const key of exclude) {
        allKeys.delete(key); 
    }

    return JSON.stringify(obj, Array.from(allKeys).sort() as any);
}

export function CompareObjects<T>(obj1: T, obj2: T, exclude: Array<keyof T> = []): boolean {
    return JSONstringifyInOrder(obj1, exclude) === JSONstringifyInOrder(obj2, exclude);
}

// the search options, if its a string, null and "" should equivalent.
export function CompareSearchOptions<T>(obj1: T, obj2: T, searchKey?: keyof T, exclude: Array<keyof T> = []): boolean {
    let searchTextKey: string = 'SearchText';
    if(searchKey !== undefined) {
        searchTextKey = searchKey.toString(); //eww?
    }

    if(obj1 && obj2 && (obj1 as any).hasOwnProperty(searchTextKey) && !(exclude as string[]).includes(searchTextKey)) { //REVIEW: not fully happy here
        if((obj1 as any)[searchTextKey] !== (obj2 as any)[searchTextKey]) {
            //deal with issue where for searchtext (or equivalent), null and empty string ""
            //should be treated as not different
            if (((obj1 as any)[searchTextKey]=== null || (obj1 as any)[searchTextKey] === "") &&
                ((obj2 as any)[searchTextKey]=== null || (obj2 as any)[searchTextKey] === "")) {
                (obj1 as any)[searchTextKey] = null;
                (obj2 as any)[searchTextKey] = null;
            } else {
                return false; //At this point the two objects cannot be the same anymore.
            }
        }
    }

    //no special cases, use normal compare objects method
    return CompareObjects<T>(obj1, obj2, exclude.concat([]));
}

export function GetDistinctSearchOptionsCount<T extends {}>(defaultSearchOptions: T, searchOptions: T, keysToExcludeFromSearchCount: Array<keyof T> = []): number {
    let differences = 0;

    const keys = Object.keys(defaultSearchOptions) as Array<keyof T>;

    keys.forEach(key => {
        if (keysToExcludeFromSearchCount.includes(key)) {
            return;
        }

        if (!CompareSearchOptions(defaultSearchOptions[key], searchOptions[key])) {
            differences++;
        }
    });

    return differences;
}