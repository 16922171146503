<div *ngIf="loading" class="h-100 w-100 d-flex align-items-center justify-content-center" [ngClass]="{'loader-background-color': context === PaymentContext.PaymentPortal}">
    <div class="text-center">
        <div class="spinner-border" [ngClass]="{'base-0': context === PaymentContext.PaymentPortal, 'base-100': context !== PaymentContext.PaymentPortal}">
        </div>
    </div>
</div>

<div *ngIf="!loading && !paymentProcessor" class="w-100">
   <ng-container *ngTemplateOutlet="summary"></ng-container>
</div>

<div *ngIf="!loading && paymentProcessor" [@slideInOut]="currentStep | method: OnNextAnimationFrame" (@slideInOut.done)="AnimationDone()"  >
    <div *ngIf="currentStep === 0" >
        <div class="d-flex gap-3" [ngClass]="{'flex-column': isMobileOrTablet}">
            <div class="d-flex flex-column" [ngClass]="{'w-75': invoiceStatus === InvoiceStatus.Open && !isMobileOrTablet && context !== PaymentContext.SignupWidget && schoolInfo.Active, 'w-100': invoiceStatus !== InvoiceStatus.Open || !schoolInfo.Active || isMobileOrTablet || context === PaymentContext.SignupWidget}">
                <ng-container *ngTemplateOutlet="summary"></ng-container>
                <ng-container *ngIf="schoolInfo.Active && invoiceStatus === InvoiceStatus.Open">
                    <h5 class="pb-3" *ngIf="context !== PaymentContext.SignupWidget">
                        <ng-container *ngIf="hasAtleastOnePaymentSource" i18n="@@CommonPaymentPageSelectPaymentMethod">Select a payment method </ng-container>
                        <ng-container *ngIf="!hasAtleastOnePaymentSource" i18n="@@CommonPaymentPagePaymentMethod">Payment Method</ng-container>
                    </h5>
                    <lib-expansion-card-group *ngIf="(paymentProcessor.Type === PaymentProcessorType.PayPalPro || hasAtleastOnePaymentSource) && context !== PaymentContext.SignupWidget">
                        <lib-expansion-card [startExpanded]="true" *ngIf="mode === PaymentSourceMode.Payment && hasAtleastOnePaymentSource"
                        (panelStatus)="ExistingPaymentMethodOpened($event)" [attr.data-sb-qa]="'use-existing-payment-method-card'">
                            <ng-container header>
                                <lib-icon-bullet [icon]="Icon.Payment.CreditCard" [size]="IconSize.ExtraSmall"> <p class="typography-caption-large typography-semibold" i18n="@@CommonPaymentPageUseExistingPaymentMethod">Use Existing Payment Method</p></lib-icon-bullet>
                            </ng-container>
                            <ng-container body>
                                <lib-payment-sources
                                    [mode]="mode"
                                    [paymentContext]="context"
                                    [paymentSources]="paymentSources"
                                    (paymentSourceSelected)="PaymentSourceSelected($event)">
                                </lib-payment-sources>
                                <ng-container *ngIf="isMobileOrTablet">
                                    <ng-container *ngTemplateOutlet="paymentSummary; context: {$implicit: false}"></ng-container>
                                </ng-container>
                                <div [ngClass]="{'d-flex justify-content-end': !isMobileOrTablet }" class="pt-3">
                                    <lib-button
                                        [disabled]="amountToPay === null || amountToPay <= 0 || payNowClicked || onlineConvenienceFeeNotReady"
                                        [toolTipText]="(amountToPay === null || amountToPay <= 0) ? 'Please enter a valid amount' : ''"
                                        [loading]="payNowClicked"
                                        [stretch]="(isMobileOrTablet)"
                                        (clicked)="PayUsingExistingPaymentMethod()"
                                        [qaTag]="'pay-now-btn'">
                                        <ng-container label i18n="@@CommonPaymentPagePayNow">Pay Now</ng-container>
                                    </lib-button>
                                </div>
                            </ng-container>
                        </lib-expansion-card>
                        <lib-expansion-card *ngIf="paymentProcessor.Type === PaymentProcessorType.PayPalStandard || paymentProcessor.Type === PaymentProcessorType.PayPalPro">
                            <ng-container header>
                                <lib-icon-bullet [icon]="Icon.Payment.PayPal"> <p class="typography-caption-large typography-semibold" i18n="@@CommonPaymentPagePayPalPaymentMethod">PayPal</p></lib-icon-bullet>
                            </ng-container>
                            <ng-container body>
                                <ng-template *ngTemplateOutlet="PayWithPayPal"></ng-template>
                            </ng-container>
                        </lib-expansion-card>
                        <lib-expansion-card *ngIf="paymentProcessor.Type === PaymentProcessorType.PayPalPro || hasAtleastOnePaymentSource"
                        (panelStatus)="NewPaymentMethodOpened($event)" [attr.data-sb-qa]="'new-payment-method-card'">
                            <ng-container header>
                                <lib-icon-bullet [icon]="Icon.Payment.CreditCard" [size]="IconSize.Small">
                                    <p *ngIf="paymentProcessor.Type !== PaymentProcessorType.PayPalPro" class="typography-caption-large typography-semibold" i18n="@@CommonPaymentPageNewPaymentMethod">New Payment Method</p>
                                    <p *ngIf="paymentProcessor.Type === PaymentProcessorType.PayPalPro" class="typography-caption-large typography-semibold" i18n="@@CommonPaymentPageNewCreditCard">New Credit Card</p>
                                </lib-icon-bullet>
                            </ng-container>
                            <ng-container body>
                                <ng-container *ngTemplateOutlet="addPaymentMethod"></ng-container>
                                <hr>
                                <ng-container *ngIf="isMobileOrTablet">
                                    <ng-container *ngTemplateOutlet="paymentSummary; context: {$implicit: false}"></ng-container>
                                </ng-container>
                                <div [ngClass]="{'d-flex justify-content-end': !isMobileOrTablet }" class="pt-3">
                                    <lib-button
                                        [disabled]="amountToPay === null || amountToPay <= 0 || payNowClicked || (paymentProcessor.Type === PaymentProcessorType.Stripe && !stripeReady) || onlineConvenienceFeeNotReady"
                                        [toolTipText]="(amountToPay === null || amountToPay <= 0) ? 'Please enter a valid amount' : ''"
                                        [loading]="payNowClicked"
                                        [stretch]="(isMobileOrTablet)"
                                        (clicked)="PayUsingNewPaymentMethod()"
                                        [qaTag]="'pay-now-btn'">
                                        <ng-container label i18n="@@CommonPaymentPagePayNow">Pay Now</ng-container>
                                    </lib-button>
                                </div>
                            </ng-container>
                        </lib-expansion-card>
                </lib-expansion-card-group>

                <lib-card *ngIf="!(paymentProcessor.Type === PaymentProcessorType.PayPalPro || hasAtleastOnePaymentSource) || context === PaymentContext.SignupWidget">
                    <h5 *ngIf="context === PaymentContext.SignupWidget" i18n="@@PaymentPageBillingInformationHeader" class="pb-3">Billing Information</h5>
                    <ng-container *ngIf="paymentProcessor.Type !== PaymentProcessorType.PayPalStandard">
                        <ng-container *ngTemplateOutlet="addPaymentMethod"></ng-container>
                        <ng-container *ngIf="isMobileOrTablet && context !== PaymentContext.SignupWidget">
                            <ng-container *ngTemplateOutlet="paymentSummary; context: {$implicit: false}"></ng-container>
                        </ng-container>
                        <hr>
                        <div *ngIf="context !== PaymentContext.SignupWidget" [ngClass]="{'d-flex justify-content-end': !isMobileOrTablet }" class="pt-3">
                            <lib-button
                                [disabled]="amountToPay === null || amountToPay <= 0 || payNowClicked || (paymentProcessor.Type === PaymentProcessorType.Stripe && !stripeReady) || onlineConvenienceFeeNotReady"
                                [toolTipText]="(amountToPay === null || amountToPay <= 0) ? 'Please enter a valid amount' : ''"
                                [loading]="payNowClicked"
                                [stretch]="(isMobileOrTablet)"
                                (clicked)="PayUsingNewPaymentMethod()"
                                [qaTag]="'pay-now-btn'">
                                <ng-container label i18n="@@CommonPaymentPagePayNow">Pay Now</ng-container>
                            </lib-button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="paymentProcessor.Type === PaymentProcessorType.PayPalStandard">
                        <ng-template *ngTemplateOutlet="PayWithPayPal"></ng-template>
                    </ng-container>
                </lib-card>
                </ng-container>

            </div>

            <div *ngIf="invoiceStatus === InvoiceStatus.Open && !isMobileOrTablet && context !== PaymentContext.SignupWidget && schoolInfo.Active" [ngClass]="{'w-25': !isMobileOrTablet, 'w-100': isMobileOrTablet, 'scss-summary-card-padding': context === PaymentContext.StudentPortal && !allowPartialPayment && !hasZeroBalance}" class="scss-sticky-summary-card">
                <lib-card >
                    <ng-container *ngTemplateOutlet="paymentSummary; context: {$implicit: true}"></ng-container>
                </lib-card>
            </div>
        </div>
    </div>

    <div *ngIf="currentStep === 1">
        <lib-payment-page-confirmation
            [mode]="mode"
            [context]="context"
            [showDoneButton]="context !== PaymentContext.PaymentPortal"
            (doneClicked)="DoneClicked()">
            <ng-content select="[confirmation]"></ng-content>
            <ng-container *ngIf="transactionDetailsForPayPalExpressCheckout || transactionDetailsForPayPalStandard || paymentCompleteDisplayItem || processPaymentSourceResultItem">
                <p class="typography-semibold pb-4 pt-2" i18n="@@CommonPaymentPageTransactionDetailsLabel">Transaction Details</p>
            </ng-container>
            <ng-container *ngIf="transactionDetailsForPayPalExpressCheckout">
                <div>
                    <p class="typography-semibold" i18n="@@CommonPaymentPageDateLabel">Date:</p>
                    <p class="pb-3" [attr.data-sb-qa]="'payment-date'">{{transactionDetailsForPayPalExpressCheckout.ProcessorPaymentTime | formatDate: 'long'}} {{transactionDetailsForPayPalExpressCheckout.ProcessorPaymentTime | formatTime: 'long'}}</p>
                </div>
                <div>
                    <p class="typography-semibold" i18n="@@CommonPaymentPagePaymentLabel">Payment:</p>
                    <p  class="pb-3" [attr.data-sb-qa]="'payment-amount'">{{transactionDetailsForPayPalExpressCheckout.PaidAmount | formatCurrency}}</p>
                </div>
                <div>
                    <p class="typography-semibold" i18n="@@CommonPaymentPageTransactionIDLabel">Transaction ID:</p>
                    <p  class="pb-3" [attr.data-sb-qa]="'transaction-id'">{{transactionDetailsForPayPalExpressCheckout.ProcessorTransactionID}}</p>
                </div>
            </ng-container>
            <ng-container *ngIf="transactionDetailsForPayPalStandard">
                <div>
                    <p class="typography-semibold" i18n="@@CommonPaymentPagePaymentLabel">Payment:</p>
                    <p  class="pb-3">{{transactionDetailsForPayPalStandard.PaidAmount | formatCurrency}}</p>
                </div>
            </ng-container>
            <ng-container *ngIf="paymentCompleteDisplayItem">
                <div>
                    <p class="typography-semibold" i18n="@@CommonPaymentPageDateLabel">Date:</p>
                    <p class="pb-3" [attr.data-sb-qa]="'payment-date'">{{paymentCompleteDisplayItem.PaymentProcessorTimestamp | formatDate: 'long'}} {{paymentCompleteDisplayItem.PaymentProcessorTimestamp | formatTime: 'long'}}</p>
                </div>
                <div>
                    <p class="typography-semibold" i18n="@@CommonPaymentPagePaymentLabel">Payment:</p>
                    <p  class="pb-3" [attr.data-sb-qa]="'payment-amount'">{{paymentCompleteDisplayItem.Amount | formatCurrency}}</p>
                </div>
                <div>
                    <p class="typography-semibold" i18n="@@CommonPaymentPageTransactionIDLabel">Transaction ID:</p>
                    <p  class="pb-3" [attr.data-sb-qa]="'transaction-id'">{{paymentCompleteDisplayItem.PaymentProcessorTransactionID}}</p>
                </div>
            </ng-container>
            <ng-container *ngIf="processPaymentSourceResultItem">
                <div>
                    <p class="typography-semibold" i18n="@@CommonPaymentPageDateLabel">Date:</p>
                    <p class="pb-3" [attr.data-sb-qa]="'payment-date'">{{processPaymentSourceResultItem.TransactionTime! | formatDate: 'long'}} {{processPaymentSourceResultItem.TransactionTime! | formatTime: 'long'}}</p>
                </div>
                <div>
                    <p class="typography-semibold" i18n="@@CommonPaymentPagePaymentLabel">Payment:</p>
                    <p  class="pb-3" [attr.data-sb-qa]="'payment-amount'">{{totalAmountToPay! | formatCurrency}}</p>
                </div>
                <div>
                    <p class="typography-semibold" i18n="@@CommonPaymentPageTransactionIDLabel">Transaction ID:</p>
                    <p  class="pb-3" [attr.data-sb-qa]="'transaction-id'">{{processPaymentSourceResultItem.ProcessorTransactionID}}</p>
                </div>
            </ng-container>
        </lib-payment-page-confirmation>
    </div>
</div>

<ng-template #paymentSummary let-showHeader>
    <h5 *ngIf="showHeader" class="text-nowrap" i18n="@@CommonPaymentPagePaymentSummary">Payment Summary</h5>
    <hr>
    <div class="d-flex justify-content-between" [attr.data-sb-qa]="'subtotal'">
        <p i18n="@@CommonSubtotalHeader">Subtotal</p>
        <p *ngIf="amountToPay !== null">{{amountToPay | formatCurrency }}</p>
        <p *ngIf="amountToPay === null">---</p>
    </div>
    <div *ngIf="latePaymentFee" class="d-flex justify-content-between" [attr.data-sb-qa]="'late-payment-fee'">
        <p i18n="@@CommonLatePaymentFeeHeader">Late Payment Fee</p>
        <p>{{latePaymentFee | formatCurrency}}</p>
    </div>
    <div *ngIf="schoolChargesOnlineConvenienceFee" class="d-flex justify-content-between" [attr.data-sb-qa]="'online-convenience-fee'">
        <p i18n="@@CommonOnlineConvenienceFeeHeader">Online Convenience Fee</p>
        <p *ngIf="onlineConvenienceFee !== null">{{onlineConvenienceFee | formatCurrency}}</p>
        <p *ngIf="onlineConvenienceFee === null">---</p>
    </div>
    <hr>
    <div class="d-flex justify-content-between" [attr.data-sb-qa]="'total-amount'">
        <p class="typography-semibold" i18n="@@CommonTotalHeader">Total</p>
        <p *ngIf="totalAmountToPay !== null" class="typography-semibold">{{totalAmountToPay | formatCurrency}}</p>
        <p *ngIf="totalAmountToPay === null && !onlineConvenienceFee && latePaymentFee === null" class="typography-semibold">---</p>
        <p *ngIf="totalAmountToPay === null && !onlineConvenienceFee && latePaymentFee !== null" class="typography-semibold">{{latePaymentFee | formatCurrency}}</p>
    </div>
</ng-template>

<ng-template #addPaymentMethod>
    <lib-add-payment-source
        [mode]="mode"
        [paymentProcessor]="paymentProcessor"
        [paymentSurchargeCreditCard]="paymentSurchargeCreditCard"
        [paymentSurchargeACH]="paymentSurchargeACH"
        [forceAutoPay]="forceAutoPay"
        [schoolName]="schoolInfo.Name"
        [currencyCode]="currencyCode"
        [totalAmountToPay]="totalAmountToPay"
        [invoiceDetailsGUID]="invoiceDetails?.ID ?? null"
        [context]="context"
        [onlineConvenienceFee]="onlineConvenienceFee"
        [addClicked]="payNowClickedForNewPaymentMethod"
        [schoolID]="schoolInfo.ID"
        [parentID]="parentID"
        [familyID]="familyID"
        [stripeNextStepClientSecret]="stripeNextStepClientSecret"
        [paymentProcessorErrorMessage]="paymentProcessorErrorMessage"
        (paymentSourceTypeChanged)="PaymentSourceTypeChanged($event)"
        (paymentSourceProcessed)="SubmissionResult($event)"
        (signupFormPaymentSourceReady)="SignupFormPaymentSourceReady($event)"
        (stripeReady)="SetStripeReady()"
        (stripeNextStepComplete)="StripeNextStepComplete($event)"
        (payPalCPButtonClicked)="PayUsingNewPaymentMethod($event)">
    </lib-add-payment-source>
</ng-template>

<ng-template #PayWithPayPal>
    <p class="base-100 typography-caption">You'll be redirected to PayPal to complete your payment.</p>
    <ng-container *ngIf="isMobileOrTablet">
        <ng-container *ngTemplateOutlet="paymentSummary; context: {$implicit: false}"></ng-container>
    </ng-container>
    <hr>
    <div [ngClass]="{'d-flex justify-content-end': !isMobileOrTablet }" class="pt-3">
        <lib-button
            [disabled]="amountToPay === null || amountToPay <= 0 || payNowClicked || onlineConvenienceFeeNotReady"
            [toolTipText]="(amountToPay === null || amountToPay <= 0) ? 'Please enter a valid amount' : ''"
            [loading]="payNowClicked"
            [stretch]="(isMobileOrTablet)"
            (clicked)="PayUsingPayPal()"
            [qaTag]="'pay-with-paypal-btn'">
            <ng-container label i18n="@@CommonPaymentPagePayWithPayPal">Pay with PayPal</ng-container>
        </lib-button>
    </div>
</ng-template>

<ng-template #summary>
    <ng-content select="[summary]"></ng-content>
</ng-template>


