<lib-form-field-container
    #anchor
    [control]="formControl"
    [isLoading]="isLoading"
    [isReadOnly]="isReadOnly"
    [isViewOnly]="isViewOnly"
    [isOptional]="isOptional"
    [isDisabled]="isDisabled"
    [prefixIcon]="prefixIcon"
    [suffixIcon]="suffixIcon"
    [errors]="errors"
    [hideErrors]="popupOpen">

    <ng-content label select="[label]"></ng-content>
    <ng-content hintMessage select="[hintMessage]"></ng-content>
    <ng-content prefixText select="[prefixText]"></ng-content>
    <ng-content suffixText select="[suffixText]"></ng-content>

    <ng-container readOnly>
        <div class="wrapper-content" #providedReadOnly>
            <ng-content select="[readOnly]" ></ng-content>
        </div>
        <div class="default-content">
            <ng-container *ngIf="formControl.value">
                {{ formControl.value.Render() }}
            </ng-container>
            <ng-container *ngIf="!formControl.value" i18n="@@CommonNotSpecifiedLabel">
                Not specified
            </ng-container>
        </div>
    </ng-container>

    <lib-button menu *ngIf="isOptional && !isReadOnly"
        [disabled]="isDisabled"
        [kind]="ButtonKind.Link"
        (clicked)="Clear()">
        <ng-container label i18n="@@FormFieldClearLabel">Clear</ng-container>
    </lib-button>

        <input #input [hidden]="isLoading || isReadOnly"
        mbsc-datepicker
        [formControl]="viewControl"
        [min]="minDateJS"
        [max]="maxDateJS"
        [anchor]="anchor?.containerBox?.nativeElement"
        [rtl]="localeInfo.RightToLeft"
        [attr.placeholder]="placeholderText"
        [touchUi]="false"
        [attr.data-sb-qa]="qaTag"
        (onChange)="ChangeEvent($event)"
        (onOpen)="PopupOpened($event)"
        (onClose)="PopupClosed()"
        (change)="$event.stopPropagation()"
        [dateFormat]="dateFormat | uppercase"
        themeVariant="light"
        theme="material"
        [focusOnClose]="false"
        [scrollLock]="false"
        [dayNamesShort]="localeInfo.AbbreviatedDayNames"
        [monthNames]="localeInfo.MonthNames"
        [monthNamesShort]="localeInfo.AbbreviatedMonthNames"
        [dayNamesMin]="localeInfo.AbbreviatedDayNames"
        [firstDay]="firstDayOfWeek"
        (blur)="OnBlur()"
        (click)="OnClick($event)"
        (focus)="OnFocus()"/>



</lib-form-field-container>
