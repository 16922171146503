import { AbstractType, Directive, Input, TemplateRef, Type } from '@angular/core';


@Directive({selector: 'ng-template[type]'})
export class TypedTemplateDirective<T> {

    // how you tell the directive what the type should be
    @Input('type') typeFrom!: AbstractType<T>;

    // the directive gets the template from Angular
    constructor(private contentTemplate: TemplateRef<T>) {
    }

    // this magic is how we tell Angular the context type for this directive, which then propagates down to the type of the template
    static ngTemplateContextGuard<T>(dir: TypedTemplateDirective<T>, ctx: unknown): ctx is {$implicit: T} {
        return true;
    }
}