import { Component, Input } from '@angular/core';
import { DesignBlockImageBaseViewComponent } from '@library/base';
import { WebsiteBlockTextAndMediaType } from '@library/data-models';

@Component({
    selector: 'lib-text-design-block',
    templateUrl: './text-design-block.component.html',
    styleUrls: ['./text-design-block.component.scss']
})
export class TextDesignBlockComponent extends DesignBlockImageBaseViewComponent     {

    @Input() hasHeader: boolean = true;
    @Input() hasBody: boolean = true;
    @Input() kind: WebsiteBlockTextAndMediaType = WebsiteBlockTextAndMediaType.Default;
    @Input() disableImageDetails: boolean = false;

    IsMediaRightBlock(blockKind: WebsiteBlockTextAndMediaType): boolean {
        return  blockKind === WebsiteBlockTextAndMediaType.MediaRight || 
        blockKind === WebsiteBlockTextAndMediaType.MediaRightTwoColumn || 
        blockKind === WebsiteBlockTextAndMediaType.MediaRightShadow || 
        blockKind === WebsiteBlockTextAndMediaType.MediaRightClipped || 
        blockKind === WebsiteBlockTextAndMediaType.MediaRightTestimonial || 
        blockKind ===  WebsiteBlockTextAndMediaType.MediaRightAccentBackgroundTestimonial;
    }
}
