<div class="v3">
    <div class="d-flex align-items-center">
        <p class="pe-1 v3 form-field-label">
            <ng-content></ng-content>
        </p>
        <lib-hint *ngIf="isOptional && !isReadOnly">
            <ng-container i18n="@@CommonOptional">Optional</ng-container>
        </lib-hint>
    </div>
</div>
<lib-hint>
    <ng-content select="[hintMessage]"></ng-content>
</lib-hint> 
<ng-content select="[customAction]"></ng-content>


