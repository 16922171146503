import { CustomFieldCheckedListDisplayItem } from "../api/CustomFieldCheckedListDisplayItem";
import { CustomFieldDateDisplayItem } from "../api/CustomFieldDateDisplayItem";
import { CustomFieldDisplayItem } from "../api/CustomFieldDisplayItem";
import { CustomFieldDropDownListDisplayItem } from "../api/CustomFieldDropDownListDisplayItem";
import { CustomFieldEmailDisplayItem } from "../api/CustomFieldEmailDisplayItem";
import { CustomFieldMultiLineDisplayItem } from "../api/CustomFieldMultiLineDisplayItem";
import { CustomFieldPolicyDisplayItem } from "../api/CustomFieldPolicyDisplayItem";
import { CustomFieldRadioListDisplayItem } from "../api/CustomFieldRadioListDisplayItem";
import { CustomFieldTextDisplayItem } from "../api/CustomFieldTextDisplayItem";
import { CustomFieldType } from "../api/Enums";

declare module '../api/CustomFieldDisplayItem' {
    namespace CustomFieldDisplayItem {
        let IsCheckedList: typeof isCheckedList;
        let IsDate: typeof isDate;
        let IsDropDownList: typeof isDropDownList;
        let IsEmail: typeof isEmail;
        let IsMultiLine: typeof isMultiLine;
        let IsPolicy: typeof isPolicy;
        let IsRadioList: typeof isRadioList;
        let IsText: typeof isText;

        let AsCheckedList: typeof asCheckedList;
        let AsDate: typeof asDate;
        let AsDropDownList: typeof asDropDownList;
        let AsEmail: typeof asEmail;
        let AsMultiLine: typeof asMultiLine;
        let AsPolicy: typeof asPolicy;
        let AsRadioList: typeof asRadioList;
        let AsText: typeof asText;
    }
}

CustomFieldDisplayItem.IsCheckedList = isCheckedList;
CustomFieldDisplayItem.IsDate = isDate;
CustomFieldDisplayItem.IsDropDownList = isDropDownList;
CustomFieldDisplayItem.IsEmail = isEmail;
CustomFieldDisplayItem.IsMultiLine = isMultiLine;
CustomFieldDisplayItem.IsPolicy = isPolicy;
CustomFieldDisplayItem.IsRadioList = isRadioList;
CustomFieldDisplayItem.IsText = isText;

CustomFieldDisplayItem.AsCheckedList = asCheckedList;
CustomFieldDisplayItem.AsDate = asDate;
CustomFieldDisplayItem.AsDropDownList = asDropDownList;
CustomFieldDisplayItem.AsEmail = asEmail;
CustomFieldDisplayItem.AsMultiLine = asMultiLine;
CustomFieldDisplayItem.AsPolicy = asPolicy;
CustomFieldDisplayItem.AsRadioList = asRadioList;
CustomFieldDisplayItem.AsText = asText;

function isCheckedList(item: CustomFieldDisplayItem): item is CustomFieldCheckedListDisplayItem {
    return item.FieldType === CustomFieldType.CheckedList;
}

function isDate(item: CustomFieldDisplayItem): item is CustomFieldDateDisplayItem {
    return item.FieldType === CustomFieldType.Date;
}

function isDropDownList(item: CustomFieldDisplayItem): item is CustomFieldDropDownListDisplayItem {
    return item.FieldType === CustomFieldType.DropDownList;
}

function isEmail(item: CustomFieldDisplayItem): item is CustomFieldEmailDisplayItem {
    return item.FieldType === CustomFieldType.Email;
}

function isMultiLine(item: CustomFieldDisplayItem): item is CustomFieldMultiLineDisplayItem {
    return item.FieldType === CustomFieldType.MultiLine;
}

function isPolicy(item: CustomFieldDisplayItem): item is CustomFieldPolicyDisplayItem {
    return item.FieldType === CustomFieldType.Policy;
}

function isRadioList(item: CustomFieldDisplayItem): item is CustomFieldRadioListDisplayItem {
    return item.FieldType === CustomFieldType.RadioList;
}

function isText(item: CustomFieldDisplayItem): item is CustomFieldTextDisplayItem {
    return item.FieldType === CustomFieldType.Text;
}

function asCheckedList(item: CustomFieldDisplayItem): CustomFieldCheckedListDisplayItem {
    if (isCheckedList(item)) { return item; }
    throw Error(`${CustomFieldDisplayItem.ClassName} is not a ${CustomFieldCheckedListDisplayItem.ClassName}.`);
}

function asDate(item: CustomFieldDisplayItem): CustomFieldDateDisplayItem {
    if (isDate(item)) { return item; }
    throw Error(`${CustomFieldDisplayItem.ClassName} is not a ${CustomFieldDateDisplayItem.ClassName}.`);
}

function asDropDownList(item: CustomFieldDisplayItem): CustomFieldDropDownListDisplayItem {
    if (isDropDownList(item)) { return item; }
    throw Error(`${CustomFieldDisplayItem.ClassName} is not a ${CustomFieldDropDownListDisplayItem.ClassName}.`);
}

function asEmail(item: CustomFieldDisplayItem): CustomFieldEmailDisplayItem {
    if (isEmail(item)) { return item; }
    throw Error(`${CustomFieldDisplayItem.ClassName} is not a ${CustomFieldEmailDisplayItem.ClassName}.`);
}

function asMultiLine(item: CustomFieldDisplayItem): CustomFieldMultiLineDisplayItem {
    if (isMultiLine(item)) { return item; }
    throw Error(`${CustomFieldDisplayItem.ClassName} is not a ${CustomFieldMultiLineDisplayItem.ClassName}.`);
}

function asPolicy(item: CustomFieldDisplayItem): CustomFieldPolicyDisplayItem {
    if (isPolicy(item)) { return item; }
    throw Error(`${CustomFieldDisplayItem.ClassName} is not a ${CustomFieldPolicyDisplayItem.ClassName}.`);
}

function asRadioList(item: CustomFieldDisplayItem): CustomFieldRadioListDisplayItem {
    if (isRadioList(item)) { return item; }
    throw Error(`${CustomFieldDisplayItem.ClassName} is not a ${CustomFieldRadioListDisplayItem.ClassName}.`);
}

function asText(item: CustomFieldDisplayItem): CustomFieldTextDisplayItem {
    if (isText(item)) { return item; }
    throw Error(`${CustomFieldDisplayItem.ClassName} is not a ${CustomFieldTextDisplayItem.ClassName}.`);
}