import { Injectable } from "@angular/core";
import { Icon, IconItem } from "@library/base";
import { NewsPostIconEnum } from "@library/data-models";

@Injectable({
    providedIn: 'root',
})

export class PostManager {

    private _iconNameClassMapping:{ [key in NewsPostIconEnum]: IconItem } = {
        [NewsPostIconEnum.Airplane]: Icon.Common.AirPlane,        
        [NewsPostIconEnum.Bell]: Icon.Common.NotificationsOutline,
        [NewsPostIconEnum.BirthdayCake]: Icon.Common.CakeOutline,
        [NewsPostIconEnum.Book]: Icon.Common.BookClosedOutline,
        [NewsPostIconEnum.Bullhorn]: Icon.Common.BullhornOutline,
        [NewsPostIconEnum.Cake]: Icon.Common.PiePiece,
        [NewsPostIconEnum.Calendar]: Icon.Common.Calendar,
        [NewsPostIconEnum.Camera]: Icon.Common.PhotoCameraOutline,
        [NewsPostIconEnum.Checkmark]: Icon.Common.Check,
        [NewsPostIconEnum.Clock]: Icon.Common.Time,
        [NewsPostIconEnum.Flash]: Icon.Common.Bolt,
        [NewsPostIconEnum.Gift]: Icon.Common.GiftOutline,
        [NewsPostIconEnum.Globe]: Icon.Common.Language,
        [NewsPostIconEnum.GraduationCap]: Icon.Common.GraduationCapOutline,
        [NewsPostIconEnum.Heart]: Icon.Common.HeartOutline,
        [NewsPostIconEnum.Lightbulb]: Icon.Common.LightbulbOutline,
        [NewsPostIconEnum.Newspaper]: Icon.Common.Newspaper,
        [NewsPostIconEnum.Microphone]: Icon.Common.MicNone,
        [NewsPostIconEnum.Money]: Icon.Common.PaymentsOutline,
        [NewsPostIconEnum.Music]: Icon.Common.MusicNoteDoubleOutline,        
        [NewsPostIconEnum.Recycle]: Icon.Common.Recycling,
        [NewsPostIconEnum.Smile]: Icon.Common.SentimentSatisfiedOutline,
        [NewsPostIconEnum.SpeechBubble]: Icon.Common.ChatOutline,
        [NewsPostIconEnum.Star]: Icon.Common.StarOutline,
        [NewsPostIconEnum.ThumbsUp]: Icon.Common.ThumbUpOutline,
        [NewsPostIconEnum.Youtube]: Icon.Brand.Youtube
    }

    GetIconName(icon: NewsPostIconEnum): IconItem {
        return this._iconNameClassMapping[icon];
    }

    get iconNameClassMapping(): { [key in NewsPostIconEnum]: IconItem } {
        return this._iconNameClassMapping;
    }
}
