export enum ProgressBarKind {
    Determinate = 'determinate',
    Indeterminate = 'indeterminate',
    Buffer = 'buffer',
    Query = 'query',
    Default = 'default',
}

export enum ProgressSpinnerKind {
    Determinate = 'determinate',
    Indeterminate = 'indeterminate',
}
