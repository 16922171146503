import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ApiBaseModule } from '@library/api';
import { AttachmentModule } from '@library/attachment';
import { BannerModule } from '@library/banner';
import { ButtonModule } from '@library/button';
import { FormFieldModule } from '@library/form-field';
import { IconModule } from '@library/icon';
import { LayoutModule } from '@library/layout';
import { LocalizationModule } from '@library/localization';
import { ProgressBarModule } from '@library/progress-bar';
import { UtilityModule } from '@library/utility';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { UploadComponent } from './upload/upload.component';
import { UploadingDialogComponent } from './uploading-dialog/uploading-dialog.component';
import { BaseModule } from '@library/base';
import { SubmissionBarModule } from '@library/submission-bar';

@NgModule({
    declarations: [
        UploadComponent,
        UploadDialogComponent,
        UploadingDialogComponent,
    ],
    imports: [
        CommonModule,
        ButtonModule,
        LayoutModule,
        AttachmentModule,
        IconModule,
        ApiBaseModule,
        MatDividerModule,
        ProgressBarModule,
        LocalizationModule,
        BannerModule,
        FormFieldModule,
        UtilityModule,
        BaseModule,
        SubmissionBarModule
    ],
    exports: [
        UploadComponent,
        UploadDialogComponent,
        UploadingDialogComponent,
    ]
})
export class UploadModule { }
