import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ButtonBaseComponent } from './button-base.component';

@Component({
    selector: 'lib-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends ButtonBaseComponent implements OnInit {

    @ViewChild('label', {static: false}) buttonLabel: ElementRef<HTMLElement> | undefined;

    override ngOnInit(): void {
        super.ngOnInit();      
    }
}
