<lib-form-field-container
    [control]="formControl"
    [isLoading]="isReadOnly && isLoading"
    [isReadOnly]="isReadOnly"
    [isViewOnly]="isViewOnly"
    [kind]="FormContainerKind.Radio"
    [errors]="errors">

    <ng-content label select="[label]"></ng-content>
    <lib-hint>
        <ng-content select="[hintMessage]"></ng-content>
    </lib-hint>
    <ng-container readOnly>
        <div #providedReadOnly class="wrapper-content">
            <ng-content select="[readOnly]"></ng-content>
        </div>
        <div class="default-content" *ngIf="isReadOnly">
            <ng-template *ngTemplateOutlet="currentItemComponent.label"></ng-template>
        </div>
    </ng-container>

    <div class="v3">
        <mat-radio-group
        [ngClass]="{
            'gap-3': orientation === Orientation.Horizontal,
            'flex-column': orientation === Orientation.Vertical
        }"
        class="d-flex"
        [formControl]="viewControl"
        (change)="ChangeEvent()">
        <ng-container *ngIf="isLoading && orientation === Orientation.Vertical">
            <div *ngFor="let item of itemQueryList" class="form-field-loading-small"></div>
        </ng-container>
        <ng-container *ngIf="isLoading && orientation === Orientation.Horizontal">
            <div class="form-field-loading-small"></div>
        </ng-container>
        <ng-container *ngIf="!isLoading && !isReadOnly">
            <mat-radio-button *ngFor="let item of itemQueryList" [value]="item.value" [disabled]="item.disabled" [ngClass]="{'use-parent-full-width': useParentFullWidth}" [attr.data-sb-qa]="item.qaTag">
                <div class="typography-body-medium px-1"><ng-template *ngTemplateOutlet="item.label"></ng-template></div>
                <lib-hint>
                    {{item.hintMessage}}
                </lib-hint>
            </mat-radio-button>
        </ng-container>

    </mat-radio-group>
    </div>



</lib-form-field-container>
