import { AbstractControl, ValidationErrors, ValidatorFn, Validators as DefaultValidators } from "@angular/forms";
import { FormControlExtraFields } from "./form-extension.definitions";

// Much of this code is adapted (or taken) directly from Angular source

export class Validators extends DefaultValidators {
    static minExclusive(min: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(min)) {
                return null;  // don't validate empty values to allow optional controls
            }

            const value = parseFloat(control.value);
            // Controls with NaN values after parsing should be treated as not having a
            // minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min
            return !isNaN(value) && value <= min ? {'minExclusive': {'min': min, 'actual': control.value}} : null;
        };
    }

    static maxExclusive(max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
                return null;  // don't validate empty values to allow optional controls
            }

            const value = parseFloat(control.value);
            // Controls with NaN values after parsing should be treated as not having a
            // minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min
            return !isNaN(value) && value >= max ? {'maxExclusive': {'max': max, 'actual': control.value}} : null;
        };
    }

    static duplicate(blackList: string[], caseSensitive: boolean = true): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(blackList)) {
                return null;  // don't validate empty values to allow optional controls
            }

            let checkValue = (control.value as string).trim();
            if (!caseSensitive) {
                blackList = blackList.map(b => b.toLowerCase());
                checkValue = checkValue.toLowerCase();
            }

            return blackList.includes(checkValue) ? {'duplicate': control.value} : null;
        }
    }

    static match(text: string): ValidatorFn {
        return ((control: AbstractControl) : ValidationErrors | null =>
            (text?.trim() !== (control.value as string)?.trim()) ? {'match': control.value} : null);
    }

    static url(): ValidatorFn {
        const URLRegex = /^\s*(http|https):\/\//i;
        return (control: AbstractControl) : ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;  // don't validate empty values to allow optional controls
            }
            return URLRegex.test(control.value) ? null : {'url': control.value};
        }
    }

    static regex(regex: RegExp): ValidatorFn {
        const RegexExpression = regex; 
        return (control: AbstractControl) : ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;  // don't validate empty values to allow optional controls
            }

            return RegexExpression.test(control.value) ? null : {'regex': control.value};
        }
    }

    static password(minLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value)) {
                return null;  // don't validate empty values to allow optional controls
            }

            let password: string = control.value.trim();
            let passwordLength = password.length;

            let  complexityScore: number = 0;

            if(password.match(/[A-Z]/)) {
                complexityScore++;
            }

            if(password.match(/[a-z]/)) {
                complexityScore++;
            }

            if(password.match(/[0-9]/)) {
                complexityScore++;
            }

            if(password.match(/[^0-9a-zA-Z]/)) {
                complexityScore++;
            }

            const isValid = passwordLength >= minLength && complexityScore >= 3;

            return !isValid ? {'password': {'min': minLength, 'actual': passwordLength, 'score': complexityScore}} : null;
        };
    }

    static override minLength(minLength: number, filter?: string): ValidatorFn {
        let filterRegexp: RegExp | null = null;
        if (filter) {
            filterRegexp = new RegExp(`(${filter})|.`, 'g');
        }
        return (control: AbstractControl): ValidationErrors | null => {
            let value = control.value;
            if (value && filter) {
                value = value?.replace(filterRegexp, '$1');
            }

            return hasValidLength(value) && value.length < minLength
                 ? {minlength: {requiredLength: minLength, actualLength: value.length}}
                 : null;
        };
    }

    static override maxLength(maxLength: number, filter?: string): ValidatorFn {
        let filterRegexp: RegExp | null = null;
        if (filter) {
            filterRegexp = new RegExp(`(${filter})|.`, 'g');
        }
        return (control: AbstractControl): ValidationErrors | null => {
            let value = control.value;
            if (value && filter) {
                value = value?.replace(filterRegexp, '$1');
            }

            return hasValidLength(value) && value.length > maxLength
                 ? {maxlength: {requiredLength: maxLength, actualLength: value.length}}
                 : null;
        };
    }

    static exactLength(maxLength: number, filter?: string): ValidatorFn {
        let filterRegexp: RegExp | null = null;
        if (filter) {
            filterRegexp = new RegExp(`(${filter})|.`, 'g');
        }
        return (control: AbstractControl): ValidationErrors | null => {
            let value = control.value;
            if (value && filter) {
                value = value?.replace(filterRegexp, '$1');
            }

            return hasValidLength(value) && value.length !== maxLength
                 ? {exactlength: {requiredLength: maxLength, actualLength: value.length}}
                 : null;
        };
    }

    static exactDigit(maxLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let value = control.value;
            if (value) {
                value = value.toString();
            }
            return hasValidLength(value) && value.length !== maxLength
                ? {exactDigit: {requiredLength: maxLength, actualLength: value.length}}
                : null;
        };
    }

    // taken from max validator but uses control.value.size, i.e., file.size 
    static maxFileSize(max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (isEmptyInputValue(control.value) || isEmptyInputValue(max)) {
                return null;  // don't validate empty values to allow optional controls
            }

            return (control.value).some((file: File)=> (!isNaN(file.size) && file.size > max))
                ? {'max': {'max': max }}
                : null;
        };
    }

    static subform(control: AbstractControl): ValidationErrors | null {
        if (!(control as FormControlExtraFields).connectedForm) {
            throw Error('Subform validator can only be used on a control with a connected subform (i.e., a control that inherits from FormFieldSubformBaseComponent)');
        }
        return !(control as FormControlExtraFields).connectedForm!.invalid  ?  null : { subform: true };
    }
}

export function isEmptyInputValue(value: any): boolean {
    /**
   * Check if the object is a string or array before evaluating the length attribute.
   * This avoids falsely rejecting objects that contain a custom length attribute.
   * For example, the object {id: 1, length: 0, width: 0} should not be returned as empty.
   */
    return value == null ||
        ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
}

export function hasValidLength(value: any): boolean {
    // non-strict comparison is intentional, to check for both `null` and `undefined` values
    return value != null && typeof value.length === 'number';
}
