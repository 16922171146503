import { Injectable } from '@angular/core';
import { ApiRequestManager, ApiRoute } from '@library/api';
import { BaseService } from '@library/base';
import { WebsiteBlockDisplayItem, WebsiteBlockType, WebsiteDisplayItem, WebsiteRenderContextDisplayItem } from '@library/data-models';
import { LocalizationService } from '@library/localization';
import { StyleManager, WebsiteManager } from '@library/managers';

export interface CssColorVariables {
    cssVariable: string;
    alpha: number;
}

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService extends BaseService {
    private _renderContext?: WebsiteRenderContextDisplayItem;

    private _website?: WebsiteDisplayItem;
    private _websiteBlock?: WebsiteBlockDisplayItem;

    private _idByPath: Record<string, string> = {};
    private _pathAndTitleByID: Record<string, [string, string]> = {};

    constructor(
        private _LocalizationService: LocalizationService,
        private _WebsiteManager: WebsiteManager,
        private _StyleManager: StyleManager,
        _ApiRequestManager: ApiRequestManager,
    ) {
        super();
        ApiRoute.SetApiRequestManager(_ApiRequestManager);

        const websiteData = document.getElementById('sb-bootstrap-website')?.getAttribute('data-json');
        if (websiteData) {
            this._website = JSON.parse(websiteData);
            const websiteBlockData = document.getElementById('sb-bootstrap-websiteblock')?.getAttribute('data-json');
            if (websiteBlockData) {
                this._websiteBlock = JSON.parse(websiteBlockData);
                this.SetRenderContext(this._websiteBlock!.RenderContext!);
            } else {
                this.SetUpPagePaths();
                this.SetRenderContext(this._website!.RenderContext!);
            }
        } else {
            this.HandleError();
        }
    }

    private SetUpPagePaths() {
        this._website!.WebsitePages!.forEach(page => {
            this._idByPath![page.Slug!] = page.ID!
            this._pathAndTitleByID![page.ID!] = [page.Slug!, page.SEOTitle ? page.SEOTitle : `${this._website!.Title} - ${page.Title}`];
            page.ChildPages?.forEach(childPage => {
                this._idByPath![childPage.Slug!] = childPage.ID!
                this._pathAndTitleByID![childPage.ID!] = [childPage.Slug!, childPage.SEOTitle ? childPage.SEOTitle : `${this._website!.Title} - ${childPage.Title}`];
            })
        });
    }

    private SetRenderContext(renderContext: WebsiteRenderContextDisplayItem) {
        this._renderContext = renderContext;
        this._LocalizationService.Initialize(renderContext.LocalizationInformation!);

        const accentColor = this._website!.AccentColor ?? this._StyleManager.GetCSSPropertyValue('--primary-brand-500');
        const backgroundColor = this._website!.BackgroundColor ?? this._StyleManager.GetCSSPropertyValue('--base-0');

        // Below not setting accent text color / removing text color picker from UI bc user could select white text WB-296

        // const accentTextColor = this._StyleManager.GetCSSPropertyValue('--base-300');

        const colors: CssColorVariables[] = [
            { cssVariable: '--brand-0', alpha: 146 },
            { cssVariable: '--brand-50', alpha: 131 },
            { cssVariable: '--brand-100', alpha: 117 },
            { cssVariable: '--brand-200', alpha: 88 },
            { cssVariable: '--brand-300', alpha: 54 },
            { cssVariable: '--brand-400', alpha: 29 },
            { cssVariable: '--brand-500', alpha: 0 },
            { cssVariable: '--brand-600', alpha: -15 },
            { cssVariable: '--brand-700', alpha: -33 },
            { cssVariable: '--brand-800', alpha: -49 },
            { cssVariable: '--brand-900', alpha: -66 },
        ];

        // const textColors: CssColorVariables[] = [
        //     {cssVariable: '--base-0', alpha: 300},
        //     {cssVariable: '--base-50', alpha: 210},
        //     {cssVariable: '--base-100', alpha: 49},
        //     {cssVariable: '--base-200', alpha: 0},
        //     {cssVariable: '--base-300', alpha: -31},
        // ];

        colors.forEach(color => {
            this.SetColorVariables(color.cssVariable, accentColor, color.alpha);
        });

        // textColors.forEach(color => {
        //     this.SetColorVariables(color.cssVariable, accentTextColor!, color.alpha);
        // });

        switch(this._websiteBlock?.WebsiteBlockType) {
            case WebsiteBlockType.SignupForm:
                this.SetWidgetAccentContrastColor(accentColor);
                break;
            case WebsiteBlockType.LoginForm:
            case WebsiteBlockType.ContactForm:
                this.SetWidgetAccentContrastColor(accentColor);
                this._WebsiteManager.SetBlockAccentBackgroundColor(accentColor);
                break;
        }

        if (!this._websiteBlock) {
            this._WebsiteManager.SetWebsiteColors(backgroundColor!, accentColor!, this._website!.HeaderColor!, this._website!.FooterColor!);
        }
        this._WebsiteManager.SetBlockTextFont(this.website.HeadingFontFamily!, this.website.BodyFontFamily!);
    }

    private SetWidgetAccentContrastColor(accent: string): void {
        this._WebsiteManager.SetWidgetAccentContrastColor(accent);
    }

    private AddTransparency(color: string, alpha: number): string {
        return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + alpha)).toString(16)).substr(-2));
    }

    private SetColorVariables(cssVariable: string, color: string, alpha: number): void {
        document.documentElement.style.setProperty(cssVariable, this.AddTransparency(color, alpha));
    }

    private HandleError() {
        let retries = 40;

        setTimeout(function reloadParent() {
            if ((window as any).parentIFrame) {
                (window as any).parentIFrame.sendMessage('reload');
            } else if (retries-- > 0) {
                setTimeout(reloadParent, 200);
            }
        }, 200);

        // If we cannot reload the outer page (e.g., external widget) show an error
        setTimeout(() => {
            const loadElement = document.getElementsByTagName("app-root")[0];
            const failElement = document.getElementById("widget-load-failed")!;
            loadElement.classList.add('d-none');
            failElement.classList.remove('d-none');
            failElement.classList.add('d-flex');

            const errorData = document.getElementById('sb-error')?.getAttribute('data-json');
            if (errorData) {
                const errorElement = document.getElementById("error-info")!;
                errorElement.innerHTML = '<p class="mb-0" style="font-family: monospace;">' + JSON.parse(errorData) + '</p>';
            }
        }, 8000);
    }

    get websiteBlock(): WebsiteBlockDisplayItem {
        return this._websiteBlock!;
    }

    get website(): WebsiteDisplayItem {
        return this._website!;
    }

    public get idByPath(): Record<string, string> {
        return this._idByPath;
    }

    public get pathAndTitleByID(): Record<string, [string, string]> {
        return this._pathAndTitleByID;
    }

    get renderContext(): WebsiteRenderContextDisplayItem | undefined {
        return this._renderContext;
    }
}
