import { AfterViewInit, Component, ContentChild, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, forwardRef } from '@angular/core';
import { BaseViewComponent, MenuDivider, IconItem } from '@library/base';

@Component({
    selector: 'lib-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent extends BaseViewComponent implements OnInit, AfterViewInit {
    @Input() id: string | null = null;
    @Input() label: string| null =  null;
    @Input() hint: string | null = null;
    @Input() prefixIcon: IconItem | null = null;
    @Input() disabled: boolean = false;
    @Input() suffixIcon: IconItem | null = null;
    @Input() suffixIconVisible: boolean = true;
    @Input() prefixIconVisible: boolean = true;
    @Input() stopPropagation: boolean = false;
    @Input() menuDivider: MenuDivider = MenuDivider.None;
    @Input() hasGroupHeader: boolean = false;
    @Input() qaTag: string = "";
    @Input() urlLink?: string;

    private _hasSubOptions: boolean = false;
    private _toolTipText!: string;

    @Input()
    public set toolTipText(value: string) {
        this._toolTipText = this.disabled ? value: '';
    }

    @ViewChild('content', { static: true, read: TemplateRef }) menuItemContent!: TemplateRef<any>;
    @ContentChild('header',  {static: false} ) menuItemHeader!: TemplateRef<any>;
    @ContentChildren(forwardRef(() => MenuItemComponent)) subMenuItems!: QueryList<MenuItemComponent>;

    public get toolTipText(): string {
        return this._toolTipText;
    }
    
    @Output() clicked = new EventEmitter<any>()
    constructor() {
        super();
    }

    override ngOnInit() {
    }

    ngAfterViewInit() {
        this._hasSubOptions = this.subMenuItems.length > 0;
    }

    public MenuItemClicked(event: MouseEvent){
        if(!this.disabled){
            this.clicked.emit();
        }

        if(this.stopPropagation){
            event.stopPropagation();
        }
    }

    public GetMenuItemID(): string | null {
        return (this.id) ? `menuItemID-${this.id}` : null;
    }

    get hasSubOptions(): boolean {
        return this._hasSubOptions;
    }

}
