<form [formGroup]="addPayPalPaymentMethodForm">
    <div class="two-column-row">
        <lib-input-select
            [formControl]="addPayPalPaymentMethodForm.controls.CardType"
            [errorMessages]="cardTypeError"
            placeholderText="Select..."
            i18n-placeholderText="@@CommonSelectPlaceholder"
            [qaTag]="'input-paypalpro-cardtype'"
            (change)="CardTypeChanged($event!)">
            <ng-container label
                i18n="@@CommonAddPaymentSourceCreditCardTypeLabel">
                Card Type
            </ng-container>

            <lib-input-select-item *ngFor="let type of creditCardTypes"
                [value]="type">
                {{ type.Name }}
            </lib-input-select-item>
        </lib-input-select>

        <lib-input-text
            [formControl]="addPayPalPaymentMethodForm.controls.AccountNumber"
            inputFilter="[-., 0-9]"
            [errorMessages]="cardNumberError"
            [qaTag]="'input-paypalpro-cardnumber'">
            <ng-container label
                i18n="@@CommonAddPaymentSourceCreditCardNumberLabel">
                Card Number
            </ng-container>
        </lib-input-text>
    </div>

    <div class="single-column-row">
        <lib-input-text
            [formControl]="addPayPalPaymentMethodForm.controls.HolderName"
            [errorMessages]="cardHolderError"
            [qaTag]="'input-paypalpro-cardholdername'">
            <ng-container label
                i18n="@@CommonAddPaymentSourceCreditCardHolderNameLabel">
                Card Holder Name
            </ng-container>
        </lib-input-text>
    </div>

    <div class="two-column-row">
        <lib-input-month-year
            [formControl]="addPayPalPaymentMethodForm.controls.ExpiryDate"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [errorMessages]="cardExpiryDateError"
            [qaTag]="'input-paypalpro-expirydate'">
            <ng-container label
                i18n="@@CommonAddPaymentSourceCreditCardExpiryDateLabel">
                Expiry Date
            </ng-container>
        </lib-input-month-year>

        <lib-input-text
            [formControl]="addPayPalPaymentMethodForm.controls.CVV"
            inputFilter="[0-9]"
            [placeholderText]="cvvPlaceholder"
            [errorMessages]="cardCVVError"
            [qaTag]="'input-paypalpro-cvv'">
            <ng-container label
                i18n="@@CommonAddPaymentSourceCreditCardCVVLabel">
                CVV
            </ng-container>
            <ng-container menu>
                <lib-icon
                    [icon]="Icon.Common.Help"
                    [size]="IconSize.ThreeExtraSmall"
                    [matTooltip]="cvvTooltip">
                </lib-icon>
            </ng-container>
        </lib-input-text>
    </div>

    <div class="two-column-row">
        <lib-input-select
            [formControl]="addPayPalPaymentMethodForm.controls.Country"
            [qaTag]="'input-paypalpro-country'"
            (change)="GetStates($event!)">
            <ng-container label
                i18n="@@CommonAddPaymentSourceCountryLabel">
                Country
            </ng-container>

            <lib-input-select-item *ngFor="let country of countries"
                [value]="country">
                {{ country.Name }}
            </lib-input-select-item>
        </lib-input-select>

        <lib-input-select
            [formControl]="addPayPalPaymentMethodForm.controls.State"
            placeholderText="Select..."
            i18n-placeholderText="@@CommonSelectPlaceholder"
            [errorMessages]="stateError"
            [qaTag]="'input-paypalpro-stateprovince'">
            <ng-container label
                i18n="@@CommonAddPaymentSourceCreditCardStateProvinceLabel">
                State/Province
            </ng-container>

            <lib-input-select-item *ngFor="let state of states"
                [value]="state">
                {{ state.Name }}
            </lib-input-select-item>
        </lib-input-select>
    </div>

    <div class="two-column-row">
        <lib-input-text
            [formControl]="addPayPalPaymentMethodForm.controls.City"
            [errorMessages]="cityError"
            [qaTag]="'input-paypalpro-city'">
            <ng-container label
                i18n="@@CommonAddPaymentSourceCreditCardCityLabel">
                City
            </ng-container>
        </lib-input-text>

        <lib-input-text
            [formControl]="addPayPalPaymentMethodForm.controls.ZipCode"
            [errorMessages]="zipCodeError"
            [qaTag]="'input-paypalpro-zippostalcode'">
            <ng-container label
                i18n="@@CommonAddPaymentSourceCreditCardZipCodeLabel">
                Zip/Postal Code
            </ng-container>
        </lib-input-text>
    </div>

    <div class="single-column-row">
        <lib-input-text
            [formControl]="addPayPalPaymentMethodForm.controls.Address1"
            [errorMessages]="addressError"
            [qaTag]="'input-paypalpro-addressline1'">
            <ng-container label
                i18n="@@CommonAddPaymentSourceCreditCardAddressLine1Label">
                Address Line 1
            </ng-container>
        </lib-input-text>
    </div>

    <div class="single-column-row">
        <lib-input-text
            [formControl]="addPayPalPaymentMethodForm.controls.Address2"
            [qaTag]="'input-paypalpro-addressline2'">
            <ng-container label
                i18n="@@CommonAddPaymentSourceCreditCardAddressLine2Label">
                Address Line 2
            </ng-container>
        </lib-input-text>
    </div>

    <div class="single-column-row" *ngIf="context != PaymentContext.SignupWidget && mode === PaymentSourceMode.Payment">
        <lib-checkbox
            [formControl]="addPayPalPaymentMethodForm.controls.SavePaymentSource"
            (change)="SavePaymentMethodForFuture($event)"
            [qaTag]="'save-payment-method-for-future-use-checkbox'">
            <ng-container
                i18n="@@CommonAddPaymentSourceSavePaymentSourceLabel">
                Save this payment method for future use
            </ng-container>
        </lib-checkbox>
    </div>

    <div class="single-column-row" *ngIf="context != PaymentContext.SignupWidget && (mode === PaymentSourceMode.Add || (mode === PaymentSourceMode.Payment && addPayPalPaymentMethodForm.controls.SavePaymentSource.value))">
        <lib-checkbox
            [formControl]="addPayPalPaymentMethodForm.controls.AutoPay"
            [errorMessages]="autoPayError"
            [qaTag]="'checkbox-paypalpro-autopay'">
            <ng-container label
                i18n="@@CommonAddPaymentSourceAutoPayLabel">
                Auto Pay
            </ng-container>

            <ng-container i18n="@@CommonAddPaymentSourceAutoPayCheckboxDescription">
                Automatically pay emailed invoices
            </ng-container>
        </lib-checkbox>
    </div>
</form>


<div *ngIf="((context === PaymentContext.StudentPortal && mode === PaymentSourceMode.Add) || (context === PaymentContext.SignupWidget && mode === PaymentSourceMode.Payment)) && paymentSurchargeCreditCard && paymentSurchargeCreditCard.Type !== SurchargeType.None" class="pb-2">
    <lib-label i18n="@@CommonNoteLabel">Note</lib-label>
    <p *ngIf="paymentSurchargeCreditCard.Type === SurchargeType.Fixed" i18n="@@CommonStudentsPaymentSourcesCreditCardFixedSurchargeText">
        {{ schoolName }} applies a {{ paymentSurchargeCreditCard.Amount! | formatCurrency  }} convenience fee to all credit card payments.
    </p>
    <p *ngIf="paymentSurchargeCreditCard.Type === SurchargeType.Percentage" i18n="@@CommonStudentsPaymentSourcesCreditCardPercentageSurchargeText">
        {{ schoolName }} applies a {{ paymentSurchargeCreditCard.Amount! | formatPercent:2:2  }} convenience fee to all credit card payments.
    </p>
</div>


<lib-banner *ngIf="paymentProcessorError"
    [isDismissable]="false"
    [kind]="StatusKind.Danger">
    <ng-container text>
        <p
            i18n="@@CommonPaymentSourceCreditCardPaymentProcessorError">
            Credit card could not be processed. The payment processor returned the following message:
            <b>{{ paymentProcessorError }}</b>
        </p>
    </ng-container>
</lib-banner>