import { Sort, SortDirection as SortDirectionKind, SortHeaderArrowPosition } from "@angular/material/sort";
import { SearchOptions } from "@library/data-models";
import { FieldOf } from "./base.definitions";


export enum ColumnVisibility {
    Required,
    DefaultOn,
    DefaultOff,
    Hidden, // for future
}

export enum ColumnAlignment {
    Left,
    Right,
    Center
}

export enum TableKind {
    Parent, // will take you to another table,
    Item, // will let you edit/view item
    Basic // can only view table itself
}

export class SortDirection {
    static readonly Ascending: SortDirectionKind = 'asc';
    static readonly Descending: SortDirectionKind = 'desc';
}

export class SortArrowPosition {
    static readonly After: SortHeaderArrowPosition = 'after';
    static readonly Before: SortHeaderArrowPosition = 'before';
}

export type ColumnOf<T> = FieldOf<T> | SyntheticColumn;

class SyntheticColumn {  // Not exported - use static Column class methods
    Identifier!: string;
    SortBy?: string;
    RequestBy?: string;

    constructor(initializer: SyntheticColumn) {
        Object.assign(this, initializer);
    }
}

export class Column {
    // Static class
    private constructor() { }

    static Identifier<T>(column: ColumnOf<T>): string {
        if (this.IsSynthetic(column)) {
            return column.Identifier;
        }
        return column;
    }

    static Synthetic(initializer: SyntheticColumn) {
        return new SyntheticColumn(initializer);
    }

    static IsSynthetic<T extends ColumnOf<any>>(column: T): column is SyntheticColumn & T {
        return typeof column === 'object' && 'Identifier' in column;
    }
}

export class ColumnData<T> {
    Column!: ColumnOf<T>;
    Name!: string;

    constructor(initializer: ColumnData<T>){
        Object.assign(this, initializer);
    }
}

export class ColumnPickerData<T> extends ColumnData<T> {
    Active!: boolean;
    DefaultState!: ColumnVisibility;

    constructor(initializer: ColumnPickerData<T>){
        super(initializer);
    }
}

export class SortColumn<T> {
    Column!: ColumnOf<T>;
    Direction!: SortDirectionKind;

    constructor(initializer: SortColumn<T>){
        Object.assign(this, initializer);
    }

    static FromSort<T>(sort: Sort): SortColumn<T> {
        const column = sort.active as ColumnOf<T>;
        const direction = sort.direction == '' ? SortDirection.Ascending : sort.direction;

        return new SortColumn<T>({Column: column, Direction: direction})
    }
}

export class TableStorageData<T> {
    SearchOptions?: SearchOptions<T>;
    SortColumn?: SortColumn<T>;

    constructor(initializer: TableStorageData<T> | undefined){
        Object.assign(this, initializer);
    }
}

export class OrderBy<T> {
    SortColumns!: SortColumn<T>[];

    constructor(sortColumns?: SortColumn<T>[]) {
        if(!sortColumns){
            this.SortColumns = [];
        }else{
            this.SortColumns = sortColumns;
        }
    }
}

export class PaginatorData {
    PageNumber!: number;
    PageSize!: number;
    OffsetAdjustment?: number;

    constructor(initializer: PaginatorData) {
        Object.assign(this, initializer);
    }

    static get TotalCount(): PaginatorData {
        return new PaginatorData({PageNumber: 0, PageSize: 0});
    }
}

export class SelectableData<T> {
    PageItems?: T[];
    AllItems?: T[];
    TotalCount?: number;

    constructor(initializer: SelectableData<T>) {
        Object.assign(this, initializer);
    }
}

export enum TableFilter {
    Rows,
    Sort
}