import {Component, Input } from '@angular/core';
import { WebsiteBlockTextAndMediaType } from '@library/data-models';
import { DesignBlockBaseViewComponent } from '@library/base';

@Component({
  selector: 'lib-gallery-column-design-block',
  templateUrl: './gallery-column-design-block.component.html',
  styleUrls: ['./gallery-column-design-block.component.scss']
})
export class GalleryColumnDesignBlockComponent extends DesignBlockBaseViewComponent {

  @Input() hasHeader: boolean = true;
  @Input() type: WebsiteBlockTextAndMediaType = WebsiteBlockTextAndMediaType.Default;


}
