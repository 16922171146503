import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DialogBaseViewComponent } from '@library/base';
import { UploadDialogData } from '../upload.definitions';

@Component({
    selector: 'lib-upload-dialog',
    templateUrl: './upload-dialog.component.html',
    styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent extends DialogBaseViewComponent<UploadDialogData> implements OnInit {
    @Output() selectedFiles: EventEmitter<Array<File>> = new EventEmitter();
    private _files: File[] = [];

    override ngOnInit() {
        super.ngOnInit();
        this.selectedFiles.emit([]);
        this._files = [];
    }

    EmitFiles(files: Array<File>) {
        this.selectedFiles.emit(files);
        this._files = files;
    }

    NamesAboveMaxSize(showFirstThreeNames: boolean): string[] {
        let filesAboveMaxSize = this._files.filter((file: File) => (!isNaN(file.size) && (file.size > this.data.MaxAllowedFilesize!))).map((file: File)=> file.name);
        return showFirstThreeNames ? filesAboveMaxSize.slice(0,3) : filesAboveMaxSize.slice(3);
    }

    get hasFiles(): boolean {
        return this._files.length > 0;
    }

    set files(value: File[]) {
        this._files = value;
    }

    get files(): File[] {
        return this._files;
    }
}
