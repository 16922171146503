<ng-container [ngSwitch]="data.WebsiteBlockType">
    <ng-container *ngSwitchCase="WebsiteBlockType.SignupForm">
        <lib-signup-form-design-block *ngLet="(data | method: WebsiteBlockDisplayItem.AsSignupForm) as signupBlock"
            [data]="signupBlock"
            [websiteMode]="WebsiteMode.Live"
            [hostingType]="hostingType"
            [renderContext]="renderContext">
        </lib-signup-form-design-block>
    </ng-container>

    <ng-container *ngSwitchCase="WebsiteBlockType.ContactForm">
        <lib-contact-form-design-block *ngLet="(data | method: WebsiteBlockDisplayItem.AsContactForm) as contactBlock"
            [data]="contactBlock"
            [websiteMode]="WebsiteMode.Live"
            [renderContext]="renderContext">
        </lib-contact-form-design-block>
    </ng-container>
    
    <ng-container *ngSwitchCase="WebsiteBlockType.LoginForm">
        <lib-login-form-design-block *ngLet="(data | method: WebsiteBlockDisplayItem.AsLoginForm) as loginBlock"
            [data]="loginBlock"
            [websiteMode]="WebsiteMode.Live"
            [renderContext]="renderContext">
            <ng-container header *ngIf="loginBlock.Header">
                <span [innerHTML]="loginBlock.Header! | trustHtml"></span>
            </ng-container>
        </lib-login-form-design-block>
    </ng-container>
</ng-container>
