import { Component } from '@angular/core';
import { DialogBaseViewComponent, ProgressDialogData } from '@library/base';

@Component({
    selector: 'lib-dialog-progress',
    templateUrl: './dialog-progress.component.html'
})
export class DialogProgressComponent extends DialogBaseViewComponent<ProgressDialogData> {
    private _percentageComplete: number = 0;

    constructor() {
        super();
        this.data.ProgressObservable.subscribe(percentageComplete => {
            this._percentageComplete = percentageComplete;
        });
    }

    override PrimaryAction() {
        super.PrimaryAction();
        this.DismissAction();
    }

    get percentageComplete(): number {
        return this._percentageComplete;
    }
}
