import { Component } from '@angular/core';
import { DialogBaseViewComponent, SimpleDialogData } from '@library/base';

@Component({
    selector: 'lib-dialog-iframe',
    templateUrl: './dialog-iframe.component.html',
    styleUrls: ['./dialog-iframe.component.scss']
})
export class DialogIFrameComponent extends DialogBaseViewComponent<SimpleDialogData> {
    override PrimaryAction() {
        super.PrimaryAction();
        this.DismissAction();
    }
}
