<div class="d-flex flex-column align-items-center justify-content-center gap-3 w-100"
    [ngStyle]="{'padding': padding + 'px'}"
    [ngClass]="{'background-base-0 border': useBackground, 'border-top-0 rounded-bottom': searchActive, 'rounded-bottom': !searchActive, 'rounded-bottom rounded-top': roundBorders}">
    <img class="w-100" [height]="height" [src]="emptyStateGraphicURL | trustUrl"/>

    <div class="d-flex align-items-center flex-column gap-1">
        <div>
            <h4 class="wrapper-content base-300 typography-semibold text-center">
                <ng-content select="[emptyStateText]"></ng-content>
            </h4>
            <h4 class="default-content base-300 typography-semibold" i18n="@@CommonEmptyStateText" [ngClass]="{'text-center': isMobileOrTablet}">
                Nothing to show
            </h4>
        </div>

        <p class="base-100 text-center">
            <ng-content select="[emptyStateDescription]"></ng-content>
        </p>

    </div>

    <div class="d-block mt-2 empty-state-graphic-button">
        <ng-content *ngIf="!searchActive" select="[emptyStateButton]"></ng-content>
        <lib-button *ngIf="searchActive"
            [kind]="ButtonKind.Secondary"
            (clicked)="ClearSearchClicked()">
            <ng-container label i18n="@@CommonEmptyStateClearSearchLabel">
                Clear Search
            </ng-container>
        </lib-button>
    </div>

</div>
