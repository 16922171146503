import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { BaseViewComponent } from '@library/base';
import { ListComponent } from '../list.component';

@Component({
    selector: 'lib-list-item-row',
    templateUrl: './list-item-row.component.html',
    styleUrls: ['./list-item-row.component.scss']
})
export class ListItemRowComponent<T> extends BaseViewComponent {

    @Input() value!: T;
    @Input() disabled: boolean = false;
    @Input() selected: boolean = false;
    @Input() isExpandable: boolean = false;
    @Input() inFooter: boolean = false;

    private _expanded: boolean = false;

    @ViewChild('content', {static: true, read: TemplateRef}) content!: TemplateRef<any>;

    ExpandMore(): void {
        this._expanded = !this._expanded;
    }

    get expanded() {
        return this._expanded;
    }
}
