import { inject, Pipe, PipeTransform } from '@angular/core';
import { CurrencyFormatInfo } from '@library/data-models';
import { LocalizationService } from '../localization.service';
import { LocalizationNumberBasePipe } from './localization-number-base.pipe';

@Pipe({
    name: 'formatCurrency'
})
export class LocalizationCurrencyPipe extends LocalizationNumberBasePipe implements PipeTransform {
    private _CurrencyFormatInfo!: CurrencyFormatInfo;

    constructor() {
        super();
        const localizationService:LocalizationService = inject(LocalizationService);
        this._CurrencyFormatInfo = localizationService.CurrencyFormatInfo;

        //need the group sizes and seperators for currency to be used for the base class
        //functions
        this._NumberFormatInfo = localizationService.CurrencyFormatInfo;
    }

    override transform(value: number,
            minNumOfDecimals = this._CurrencyFormatInfo.DecimalDigits,
            maxNumOfDecimals = this._CurrencyFormatInfo.DecimalDigits,
            currencySymbol: string = this._CurrencyFormatInfo.CurrencySymbol!): string {
        let str = super.transform(value, minNumOfDecimals, maxNumOfDecimals);
        return this.ReplaceCurrencyPlaceHolderWithSymbole(str, currencySymbol);
    }

    protected IsValid(value: number, minNumOfDecimals: number, maxNumOfDecimals: number): boolean {
        return value != null &&
        this._CurrencyFormatInfo.SymbolPlaceholder != null &&
        this._CurrencyFormatInfo.PositivePattern != null &&
        this._CurrencyFormatInfo.NegativePattern != null &&
        this._CurrencyFormatInfo.GroupSizes != null &&
        this._CurrencyFormatInfo.GroupSeparator != null &&
        this._CurrencyFormatInfo.DecimalSeparator != null &&
            minNumOfDecimals != null &&
            maxNumOfDecimals != null &&
            this._CurrencyFormatInfo.CurrencySymbol != null;
    }

    private ReplaceCurrencyPlaceHolderWithSymbole(str: string, currencySymbol: string) {
        return str.replace(this._CurrencyFormatInfo.SymbolPlaceholder!, currencySymbol);
    }
}
