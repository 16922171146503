import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BannerModule } from '@library/banner';
import { BaseModule } from '@library/base';
import { ButtonModule } from '@library/button';
import { CardModule } from '@library/card';
import { FormFieldModule } from '@library/form-field';
import { IconModule } from '@library/icon';
import { LocalizationModule } from '@library/localization';
import { PaymentSourcesModule } from '@library/payment-sources';
import { UtilityModule } from '@library/utility';
import { PaymentPageConfirmationComponent } from './confirmation/payment-page-confirmation.component';
import { PaymentPageSummaryComponent } from './summary/payment-page-summary.component';
import { PaymentPageComponent } from './payment-page.component';
import { ExpansionCardModule } from '@library/expansion-card';



@NgModule({
    declarations: [
        PaymentPageComponent,
        PaymentPageSummaryComponent,
        PaymentPageConfirmationComponent,
    ],
    imports: [
        CommonModule,
        PaymentSourcesModule,
        ButtonModule,
        IconModule,
        CardModule,
        FormFieldModule,
        ReactiveFormsModule,
        LocalizationModule,
        BaseModule,
        BannerModule,
        UtilityModule,
        ExpansionCardModule
    ],
    exports: [
        PaymentPageComponent,
        PaymentPageSummaryComponent,
        PaymentPageConfirmationComponent
    ]
})
export class PaymentPageModule { }
