import { ProductName } from "./base.definitions";
import { ElevioArticleIDs } from "./elevio.definitions";

export class Environment {
    Version: string = 'local';
    Build: string = 'served';
    Branch?: string;

    Application!: {
        BaseUrls: {
            Api?: string,
            App?: string,
        },

        ProductName?: ProductName,
        ProductionMode?: boolean,

        Hostname?: string,
        WebsiteBase?: string,

        LoginURL?: string,
        LogoutURL?: string,
        ImpersonatingLogoutURL?: string,
    }

    Authentication?: {
        PrimaryToken?: string,
        SecondaryToken?: string
    }

    Help?: {
        VirtualHelperURL?: string,
        Elevio?: {
            Active?: boolean,
            ElevioID?: string,
            ArticleIDs?: ElevioArticleIDs,
            Hidden?: number[],
            Disabled?: number[],
        }
    }

    Payment? : {
        Stripe?: {
            PublicKey?: string,
            TeacherPaymentMethodConfigurationKey?: string,
            DefaultPaymentMethodConfigurationKey?: string,
        },
        PayPalCommercePlatform?: {
            ClientID?: string,
            BNCode?: string,
        }
    }

    Telemetry? : {
        GoogleAnalytics?: {
            ID?: string,
        },
        AWS_RUM?: {
            ApplicationId?: string,
        },
        HotJar?: {
            SiteID?: string,
        },
        Intercom?: {
            AppID?: string,
        },
        reCAPTCHA?: {
            SiteKey?: string
        },
    }

    constructor(initializer?: Partial<Environment>) {
        if (initializer) {
            Object.assign(this, initializer);
        }

        //Fill in dependand data
        this.Application.LoginURL = `${this.Application.BaseUrls.App}/Default.aspx`;
        this.Application.LogoutURL = `${this.Application.BaseUrls.App}/Default.aspx/Logout`;
        this.Application.ImpersonatingLogoutURL = `${this.Application.BaseUrls.App}/Default.aspx/ImpersonatingLogout`;
    }
}

//reCAPTCHA constant
export const reCAPTCHASiteKey: string = "6LeTTjEUAAAAAJG1Dpg41l_MmQzYgbtn93FwRy-8";

//Authentication overrides
export const TeacherPortalAuthenticationOverrides = {
    Authentication: {
        PrimaryToken: "TeacherPortalAuth",
        SecondaryToken: "StudentPortalAuth"
    }
}

export const StudentPortalAuthenticationOverrides = {
    Authentication: {
        PrimaryToken: "StudentPortalAuth",
        SecondaryToken: "TeacherPortalAuth"
    }
}

//Telemetry overrides
export const DisableTelemetryAndHelpOverrides = {
    Help: {},
    Telemetry: {}
}

//Brand Specific Settings
//visit https://bookstack.port443.io/books/development/page/brand-specific-configurations
export const MMSEnvironment = new Environment({
    //Brand Defaults
    Application: {
        ProductName: ProductName.MyMusicStaff,
        ProductionMode: true,
        WebsiteBase: '/',
        Hostname: 'mymusicstaff.com',
        BaseUrls: {
            Api: '//api.mymusicstaff.com/v1/',
            App: 'https://app.mymusicstaff.com',
        },
    },
    Help: {
        VirtualHelperURL: 'https://support.mymusicstaff.com/en/articles/1378/',
        Elevio: {
            Active: true,
            ElevioID: "589c86136bdbd",
            Hidden: [18],
            Disabled: [],
            ArticleIDs: {
                WebsiteBuilder: {
                    Upload: 1423,
                    Hostname: 202,
                    ScriptCode: 188
                },
                EmailSettings: {
                    SPF: 1017,
                    DKIM: 1019
                },
                Reminders: {
                    Email: 1032,
                    SMS: 1031
                },
                ProfilePictureUpload: {
                    ForTeacher: 1431,
                    ForStudent: 994
                },
                BusinessLogoUpload: 1434,
                AddPolicyAgreement: {
                    ToSignupForm: 1404,
                    ToContactForm: 1432
                }
            }
        }
    },
    Telemetry: {
        GoogleAnalytics: {
            ID: 'G-NCMGBNVFC0'
        },
        AWS_RUM: {
            ApplicationId: 'ada88b6f-3b4a-4073-82c8-fdbc2d0a4d07'
        },
        HotJar: {
            SiteID: "2132300"
        },
        Intercom: {
            AppID: "twuids1f"
        },
        reCAPTCHA: {
            SiteKey: reCAPTCHASiteKey
        }
    },
    Payment: {
        Stripe: {
            PublicKey: "pk_live_XsWuMGhdL2zin7Rf4tNLods1",
            TeacherPaymentMethodConfigurationKey: "pmc_0NlCsMpyz8uJElV9OO3Q2asG",
            DefaultPaymentMethodConfigurationKey: "pmc_0NhY5vpyz8uJElV9o5IumJbz"
        },
        PayPalCommercePlatform: {
            //Changing this? There is a corresponding value in the backend web/app config
            ClientID: "AUgoqHvokVDzmJNVzql3ZOLvvI8ndVIhHzzPwpD5C4443A765Qyky3pIZ-J4-FFDr3RJED8XQyuITc_Z",
            BNCode: "Port443CPFS_Ecom"
        }
    }
});

export const TBEnvironment = new Environment({
    //Brand Defaults
    Application: {
        ProductName: ProductName.TutorBird,
        ProductionMode: true,
        WebsiteBase: '/',
        Hostname: 'tutorbird.com',
        BaseUrls: {
            Api: '//api.tutorbird.com/v1/',
            App: 'https://app.tutorbird.com',
        },
    },
    Help: {
        VirtualHelperURL: 'https://support.tutorbird.com/en/articles/967/',
        Elevio: {
            Active: true,
            ElevioID: "58b48ce417772",
            Hidden: [7],
            Disabled: [],
            ArticleIDs: {
                WebsiteBuilder: {
                    Upload: 994,
                    Hostname: 228,
                    ScriptCode: 232
                },
                EmailSettings: {
                    SPF: 646,
                    DKIM: 648
                },
                Reminders: {
                    Email: 662,
                    SMS: 132
                },
                ProfilePictureUpload: {
                    ForTeacher: 1006,
                    ForStudent: 692
                },
                BusinessLogoUpload: 1008,
                AddPolicyAgreement: {
                    ToSignupForm: 979,
                    ToContactForm: 1007
                }
            }
        }
    },
    Telemetry: {
        GoogleAnalytics: {
            ID: 'G-DSKSP0XZQL'
        },
        AWS_RUM: {
            ApplicationId: '6da284c4-f178-464b-9529-d8003c2a15da'
        },
        HotJar: {
            SiteID: "2132310"
        },
        Intercom: {
            AppID: "octxwss9"
        },
        reCAPTCHA: {
            SiteKey: reCAPTCHASiteKey
        }
    },
    Payment: {
        Stripe: {
            PublicKey: "pk_live_OfTaX7cZ5cqbBG7rL2aai5QK",
            TeacherPaymentMethodConfigurationKey: "pmc_1NlCnQHqtNEo96caRtSrTxnQ",
            DefaultPaymentMethodConfigurationKey: "pmc_1NfZZeHqtNEo96caSfILOUpI"
        },
        PayPalCommercePlatform: {
            //Changing this? There is a corresponding value in the backend web/app config
            ClientID: "AeX8UR3Ht8ZfZ39La7VomxT8ulJFTU_riXX_Evp0srQb5wWpJLZcSfzyaHw-CMFl2AfJWwXilkGnJWwz",
            BNCode: "TutorBird_SP_PPCPFS"
        }
    }
});

export const ADEnvironment = new Environment({
    //Brand Defaults
    Application: {
        ProductName: ProductName.AthletaDesk,
        ProductionMode: true,
        WebsiteBase: '/',
        Hostname: 'athletadesk.com',
        BaseUrls: {
            Api: '//api.athletadesk.com/v1/',
            App: 'https://app.athletadesk.com',
        },
    },
    Help: {
        VirtualHelperURL: 'https://support.athletadesk.com/en/articles/921/',
        Elevio: {
            Active: true,
            ElevioID: "59406a63f0c3f",
            Hidden: [7],
            Disabled: [],
            ArticleIDs: {
                WebsiteBuilder: {
                    Upload: 967,
                    Hostname: 949,
                    ScriptCode: 944
                },
                EmailSettings: {
                    SPF: 866,
                    DKIM: 692
                },
                Reminders: {
                    Email: 706,
                    SMS: 705
                },
                ProfilePictureUpload: {
                    ForTeacher: 971,
                    ForStudent: 661
                },
                BusinessLogoUpload: 973,
                AddPolicyAgreement: {
                    ToSignupForm: 955,
                    ToContactForm: 972
                }
            }
        }
    },
    Telemetry: {
        GoogleAnalytics: {
            ID: 'AW-718956354'
        },
        AWS_RUM: {
            ApplicationId: 'ee33abd9-1130-4627-bfd6-105c5077aa5b'
        },
        HotJar: {
            SiteID: "2132315"
        },
        Intercom: {
            AppID: "azl84hgt"
        },
        reCAPTCHA: {
            SiteKey: reCAPTCHASiteKey
        }
    },
    Payment: {
        Stripe: {
            PublicKey: "pk_live_hGdAndO0PzDqcAbj2GrkLuUV",
            TeacherPaymentMethodConfigurationKey: "pmc_1NlCZ8ILnO3TZYO7z5tIehPl",
            DefaultPaymentMethodConfigurationKey: "pmc_1Nf7MZILnO3TZYO7wYqxGXD6"
        },
        PayPalCommercePlatform: {
            //Changing this? There is a corresponding value in the backend web/app config
            ClientID: "Ad_34k8J9EgzFSd3KlqFm0JYbyGHk748qDYp-zZjMDqefsJBbHPdmgZzZR02gnLseCsQUbFZ4lquuVxT",
            BNCode: "AthletaDesk_SP_PPCPFS"
        }
    }
});

export const T443Environment = new Environment({
    //Brand Defaults
    Application: {
        ProductName: ProductName.Test443,
        ProductionMode: true,
        WebsiteBase: '/',
        Hostname: 'test443.com',
        BaseUrls: {
            Api: '//api.test443.com/v1/',
            App: 'https://app.test443.com',
        },
    },
    Help: {
        VirtualHelperURL: 'https://support.mymusicstaff.com/en/articles/1378/',
        Elevio: {
            Active: true,
            ElevioID: "589c86136bdbd",
            Hidden: [18],
            Disabled: [],
            ArticleIDs: {
                WebsiteBuilder: {
                    Upload: 1423,
                    Hostname: 202,
                    ScriptCode: 188
                },
                EmailSettings: {
                    SPF: 1017,
                    DKIM: 1019
                },
                Reminders: {
                    Email: 1032,
                    SMS: 1031
                },
                ProfilePictureUpload: {
                    ForTeacher: 1431,
                    ForStudent: 994
                },
                BusinessLogoUpload: 1434,
                AddPolicyAgreement: {
                    ToSignupForm: 1404,
                    ToContactForm: 1432
                }
            }
        }
    },
    Telemetry: {
        GoogleAnalytics: {
            ID: 'G-4NGN4E2PV0'
        },
        AWS_RUM: {
            ApplicationId: 'd6bba15b-6fe8-430d-b70f-f4f7d29c7f6d'
        },
        HotJar: {
            SiteID: "2132499"
        },
        Intercom: {
            AppID: "yvf2p27u"
        },
        reCAPTCHA: {
            SiteKey: reCAPTCHASiteKey
        }
    },
    Payment: {
        Stripe: {
            PublicKey: "pk_test_laE0EY7pZb4VQVp1L96X9dyI",
            TeacherPaymentMethodConfigurationKey: "pmc_1NlCVHAdlU23LDcY5IIJZPeX",
            DefaultPaymentMethodConfigurationKey: "pmc_1NTR56AdlU23LDcY2jnKOWOe"
        },
        PayPalCommercePlatform: {
            //Changing this? There is a corresponding value in the backend web/app config
            ClientID: "AfSG2W79yvE2EfrW3aSvG-7m3_P4wftJK0MHaQl7hwh-9lRyRZf-oA6mlIBqadeH2CAbBUIygsJZ7782",
            BNCode: "Port443CPFS_Ecom"
        }
    }
});

//Brand overrides
export const MMSStagingURLOverrides = {
    BaseUrls: {
            Api: '//api.staging.mymusicstaff.com/v1/',
            App: 'https://app.staging.mymusicstaff.com',
        }
}

export const TBStagingURLOverrides = {
    BaseUrls: {
            Api: '//api.staging.tutorbird.com/v1/',
            App: 'https://app.staging.tutorbird.com',
        }
}

export const ADStagingURLOverrides = {
    BaseUrls: {
            Api: '//api.staging.athletadesk.com/v1/',
            App: 'https://app.staging.athletadesk.com',
        }
}

export const LocalHostURLOverrides = {
    BaseUrls: {
            Api: '//localhost/schoolbox2.3/api/v1/',
            App: 'http://localhost/SchoolBox2.3',
        }
}

export const LocalHostProductNameOverride = {
    ProductName: ProductName.Local
}

export const LocalPaymentOverrides = {
    Payment: {
        Stripe: {
            PublicKey: "pk_test_IRCKxrBAEXIimMyY39abtNaK",
            TeacherPaymentMethodConfigurationKey: "pmc_1Nky0FHDODZYZQ1alS2VerM8",
            DefaultPaymentMethodConfigurationKey: "pmc_1MZgO0HDODZYZQ1avj1JRO4f"
        },
        PayPalCommercePlatform: {
            ClientID: "Afz0eIiynam5orDLbCF5noqCjpJluNCLpfANrMmae1YwaL-86EA0ioW4ySiBdtVCBY1FLlOPZcOHma5b",
            BNCode: "Port443CPFS_Ecom"
        }
    }
}

// mockbuilder.provide({ provide: Environment, useClass: MockEnvironment })
// OR
// TestBed.configureTestingModule({
//     declarations: [ProfileSettingsLogOutAllDevicesDialogComponent],
//     providers: [
//         { provide: Environment, useClass: MockEnvironment },
//     ]
// })
// Elevio.Articles = new MockEnvironment().Help.Elevio.ArticleIDs;
//it.skip('TODO', () => {});
export class MockEnvironment extends Environment {
    constructor() {
        super({
            Authentication: {
                PrimaryToken: "primary",
                SecondaryToken: "secondary"
            },
            Application: {
                BaseUrls: {
                    Api: "api test url",
                    App: "app test url"
                },
                LoginURL: "test login url",
                Hostname: "example.com"
            },
            Help: {
                Elevio: {
                    Active: false,
                    ElevioID: "elevioid",
                    Hidden: [],
                    Disabled: [],
                    ArticleIDs: {
                        WebsiteBuilder: {
                            Upload: 1423,
                            Hostname: 202,
                            ScriptCode: 188
                        },
                        EmailSettings: {
                            SPF: 1017,
                            DKIM: 1019
                        },
                        Reminders: {
                            Email: 1032,
                            SMS: 1031
                        },
                        ProfilePictureUpload: {
                            ForTeacher: 1431,
                            ForStudent: 994
                        },
                        BusinessLogoUpload: 1434,
                        AddPolicyAgreement: {
                            ToSignupForm: 1404,
                            ToContactForm: 1432
                        }
                    }
                }
            }
        });
    }
}
