import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player.component';
import { IconModule } from '@library/icon';
import {MatSliderModule} from '@angular/material/slider';



@NgModule({
    declarations: [
        VideoPlayerComponent
    ],
    imports: [
        CommonModule,
        IconModule,
        MatSliderModule
    ],
    exports: [
        VideoPlayerComponent
    ]
})
export class VideoPlayerModule { }
