import { Directive, OnDestroy } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
    selector: 'mat-select',
})
export class SelectPositionDirective implements OnDestroy {

    public constructor(
        private readonly matSelect: MatSelect,
    ) {
        window.addEventListener('scroll', this.scrollEvent, true);
    }

    public ngOnDestroy(): void {
        window.removeEventListener('scroll', this.scrollEvent, true);
    }

    private scrollEvent = (event: Event): void => {
        if (this.matSelect == null) {
            return;
        }
        const isSelf = (event.target as HTMLElement)?.classList?.contains('mat-mdc-select-panel');
        if (this.matSelect.panelOpen && !isSelf) {
            this.matSelect.close();
        }
    };
}
