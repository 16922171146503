import { WebsiteBlockContactFormDisplayItem, WebsiteBlockEmbedContentType, WebsiteBlockEmbedDisplayItem, WebsiteBlockEmbedType, WebsiteBlockLoginFormDisplayItem, WebsiteBlockTextAndMediaDisplayItem } from "@library/data-models";
import { WebsiteBlockDisplayItem } from "@library/data-models";
import { WebsiteBlockContactFormType, WebsiteBlockLoginFormType, WebsiteBlockSignupFormType, WebsiteBlockTextAndMediaType, WebsiteBlockType } from "@library/data-models"
import { LoremIpsumText } from "./dummytext.definitions";

export const photoGalleryCarouselMaxItems: number = 10;

export const ContactWidgetBlockPlaceholderText: Record<WebsiteBlockContactFormType, {header: string, body: string}> = {
    [WebsiteBlockContactFormType.Default]: { header: $localize `:@@TPWebsiteContactWidgetBlock:Contact Us`, body: LoremIpsumText.TwoSentences },
    [WebsiteBlockContactFormType.AccentBackgroundDefault]: { header: $localize `:@@TPWebsiteContactWidgetBlock:Contact Us`, body: LoremIpsumText.TwoSentences },
    [WebsiteBlockContactFormType.MediaLeft]: { header: $localize `:@@TPWebsiteContactWidgetBlock:Contact Us`, body: LoremIpsumText.TwoSentences },
    [WebsiteBlockContactFormType.MediaRight]: { header: $localize `:@@TPWebsiteContactWidgetBlock:Contact Us`, body: LoremIpsumText.TwoSentences },
}

export enum LoginFormMode {
    Normal = "Normal",
    ForgotPassword = "ForgotPassword",
    AlreadyLoggedIn = "AlreadyLoggedIn",
    MultipleJWTs = "MultipleJWTs",
    ResetPasswordThankYou = "ResetPasswordThankYou",
    ManualRedirect = "ManualRedirect",
    MfaRequired = 'MfaRequired'
}

export const LoginWidgetBlockPlaceholderText: Record<WebsiteBlockLoginFormType, {header: string, body: string}> = {
    [WebsiteBlockLoginFormType.Default]: { header: $localize `:@@TPWebsitLoginWidgetBlock:Login`, body: LoremIpsumText.TwoSentences },
    [WebsiteBlockLoginFormType.AccentBackgroundDefault]: { header: $localize `:@@TPWebsiteLoginWidgetBlock:Login`, body: LoremIpsumText.TwoSentences },
    [WebsiteBlockLoginFormType.MediaLeft]: { header: $localize `:@@TPWebsiteLoginWidgetBlock:Login`, body: LoremIpsumText.TwoSentences },
    [WebsiteBlockLoginFormType.MediaRight]: { header: $localize `:@@TPWebsiteLoginWidgetBlock:Login`, body: LoremIpsumText.TwoSentences },
}

export const SignupWidgetBlockPlaceholderText: Record<WebsiteBlockSignupFormType, {header: string, body: string}> = {
    [WebsiteBlockSignupFormType.Default]: { header: $localize `:@@TPWebsitSignupWidgetBlock:Sign-Up`, body:''},
    [WebsiteBlockSignupFormType.AccentBackgroundDefault]: { header: $localize `:@@TPWebsitSignupWidgetBlock:Sign-Up`, body:''},
    [WebsiteBlockSignupFormType.MediaLeft]: { header: $localize `:@@TPWebsitSignupWidgetBlock:Sign-Up`, body:''},
    [WebsiteBlockSignupFormType.MediaRight]: { header: $localize `:@@TPWebsitSignupWidgetBlock:Sign-Up`, body:''},
}

export class WebsiteBuildingBlock {
    kind!: WebsiteBuildingBlockKind;
    type!: WebsiteBlockTextAndMediaType | WebsiteBlockWidgetType | WebsiteBlockEmbedContentType;
}

export class WebsiteBuildingBlockTextAndMedia extends WebsiteBuildingBlock {

    hasHeader!: boolean;
    hasBody: boolean = true;
    override kind: WebsiteBuildingBlockKind = WebsiteBuildingBlockKind.TextAndMedia;

    constructor(initializer?: Partial<WebsiteBuildingBlockTextAndMedia>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockHTML extends WebsiteBuildingBlock {
    override kind: WebsiteBuildingBlockKind = WebsiteBuildingBlockKind.HTML;
    constructor(initializer?: Partial<WebsiteBuildingBlockHTML>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}
export class WebsiteBuildingBlockBlog extends WebsiteBuildingBlock {
    override kind: WebsiteBuildingBlockKind = WebsiteBuildingBlockKind.Blog;
    constructor(initializer?: Partial<WebsiteBuildingBlockBlog>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockCallToAction extends WebsiteBuildingBlock {
    override kind: WebsiteBuildingBlockKind = WebsiteBuildingBlockKind.CallToAction;
    constructor(initializer?: Partial<WebsiteBuildingBlockCallToAction>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockWidget extends WebsiteBuildingBlock {
    override kind: WebsiteBuildingBlockKind = WebsiteBuildingBlockKind.Widget;

    constructor(initializer?: Partial<WebsiteBuildingBlockWidget>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockContactWidget extends WebsiteBuildingBlockWidget {
    override type: WebsiteBlockWidgetType = WebsiteBlockWidgetType.Contact;
    contactType: WebsiteBlockContactFormType = WebsiteBlockContactFormType.Default;

    hasHeader!: boolean;
    hasBody!: boolean;

    constructor(initializer?: Partial<WebsiteBuildingBlockContactWidget>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockLoginWidget extends WebsiteBuildingBlockWidget {
    override type: WebsiteBlockWidgetType = WebsiteBlockWidgetType.Login;
    loginType: WebsiteBlockLoginFormType = WebsiteBlockLoginFormType.Default;

    hasHeader!: boolean;
    hasBody!: boolean;

    constructor(initializer?: Partial<WebsiteBuildingBlockLoginWidget>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockSignupWidget extends WebsiteBuildingBlockWidget {
    override type: WebsiteBlockWidgetType = WebsiteBlockWidgetType.Signup;
    signupType: WebsiteBlockSignupFormType = WebsiteBlockSignupFormType.Default;

    constructor(initializer?: Partial<WebsiteBuildingBlockSignupWidget>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}


export class WebsiteBuildingBlockEmbed extends WebsiteBuildingBlock {
    override kind: WebsiteBuildingBlockKind = WebsiteBuildingBlockKind.Embed;
    embedType : WebsiteBlockEmbedType = WebsiteBlockEmbedType.Default; 
    source: string | null = null;
    isFullWidth : boolean = false; 
    constructor(initializer?: Partial<WebsiteBuildingBlockEmbed>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}

export class WebsiteBuildingBlockMap extends WebsiteBuildingBlockEmbed {
    override type : WebsiteBlockEmbedContentType = WebsiteBlockEmbedContentType.Map; 
    constructor(initializer?: Partial<WebsiteBuildingBlockEmbed>) {
        super();
        if (initializer) {
            Object.assign(this, initializer);
        }
    }
}
export enum WebsiteBuildingBlockKind {
    TextAndMedia,
    HTML,
    Hero,
    Gallery,
    Testimonial,
    Widget,
    Blog, 
    Embed,
    CallToAction
}

export enum WebsiteBlockWidgetType {
    Signup = "Signup",
    Contact = "Contact",
    Login = "Login"
}

export enum WebsiteGalleryItemAction {
    LeftSwap = "LeftSwap",
    RightSwap = "RightSwap",
    Delete = "Delete",
    MediaGallery = "MediaGallery",
    MediaDetails = "MediaDetails"
}

const TestimonialDummyTest1 = $localize`:@@CommonTestimonialDummyTestOne:<div class="typography-testimonial-quote-caption-large">"Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean."</div><br><div class="typography-testimonial-person-name-and-title"><div class="typography-testimonial-person-name">Person Name</div><div class="typography-testimonial-person-title">Co-founder at Company</div></div>`;
const TestimonialDummyTest2 = $localize`:@@CommonTestimonialDummyTestTwo:<div class="typography-testimonial-quote-caption-large">"Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar."</div><div class="typography-testimonial-person-name-and-title"><div class="typography-testimonial-person-name">Person Name</div><div class="typography-testimonial-person-title">Co-founder at Company</div></div>`;

export const TextMediaBlockPlaceholderText: Record<WebsiteBlockTextAndMediaType, {header: string, body: string}> = {
    [WebsiteBlockTextAndMediaType.Default]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.NineSentences },
    [WebsiteBlockTextAndMediaType.TwoColumn]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.NineSentences },
    [WebsiteBlockTextAndMediaType.AccentBackgroundDefault]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.FourSentences },
    [WebsiteBlockTextAndMediaType.MediaLeft]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.NineSentences },
    [WebsiteBlockTextAndMediaType.MediaRight]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.NineSentences },
    [WebsiteBlockTextAndMediaType.MediaLeftClipped]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.FiveSentences },
    [WebsiteBlockTextAndMediaType.MediaRightClipped]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.FiveSentences },
    [WebsiteBlockTextAndMediaType.MediaLeftTwoColumn]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.NineSentences },
    [WebsiteBlockTextAndMediaType.MediaRightTwoColumn]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.NineSentences },
    [WebsiteBlockTextAndMediaType.Testimonial]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: TestimonialDummyTest1 },
    [WebsiteBlockTextAndMediaType.AccentBackgroundTestimonial]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: TestimonialDummyTest2 },
    [WebsiteBlockTextAndMediaType.MediaRightTestimonial]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: TestimonialDummyTest1 },
    [WebsiteBlockTextAndMediaType.MediaRightAccentBackgroundTestimonial]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: TestimonialDummyTest2 },
    [WebsiteBlockTextAndMediaType.MediaLeftShadow]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.FiveSentences },
    [WebsiteBlockTextAndMediaType.MediaRightShadow]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.FiveSentences },
    [WebsiteBlockTextAndMediaType.HTML]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: $localize`:@@TPWebsiteHTMLBlockDescription:<p>This is an HTML code block. To edit this section, use the edit button.</p>` },
    [WebsiteBlockTextAndMediaType.HeroNoImage]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.OneSentence },
    [WebsiteBlockTextAndMediaType.HeroBackgroundImage]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.OneSentence },
    [WebsiteBlockTextAndMediaType.HeroClippedSideImage]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.OneSentence },
    [WebsiteBlockTextAndMediaType.HeroSquareSideImage]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.OneSentence },
    [WebsiteBlockTextAndMediaType.MediaLeftHeroSquareImage]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.OneSentence },
    [WebsiteBlockTextAndMediaType.MediaLeftHeroClippedImage]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.OneSentence },
    [WebsiteBlockTextAndMediaType.PhotoGalleryGrid]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.OneSentence },
    [WebsiteBlockTextAndMediaType.PhotoGalleryCarousel]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.OneSentence },
    [WebsiteBlockTextAndMediaType.PhotoGalleryCarouselOverlay]: { header: $localize`:@@TPWebsiteTextMediaBlock:Heading`, body: LoremIpsumText.OneSentence },
    [WebsiteBlockTextAndMediaType.GallerySquareColumn]: {
        header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
        body: LoremIpsumText.OneSentence
    },
    [WebsiteBlockTextAndMediaType.GalleryCircleColumn]: {
        header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
        body: LoremIpsumText.OneSentence
    },
    [WebsiteBlockTextAndMediaType.GallerySquareAccentColumn]: {
        header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
        body: LoremIpsumText.OneSentence
    },
    [WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn]: {
        header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
        body: LoremIpsumText.OneSentence
    },
    [WebsiteBlockTextAndMediaType.GallerySquareCardColumn]: {
        header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
        body: LoremIpsumText.OneSentence
    },
    [WebsiteBlockTextAndMediaType.GalleryCircleCardColumn]: {
        header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
        body: 'Lorem ipsum dolor sit amet consectetur.'
    },
    [WebsiteBlockTextAndMediaType.CallToActionDefault]: {
        header: $localize`:@@TPWebsiteCallToActionPlaceholderTitle:Build Something Amazing`,
        body: '',
    },
    [WebsiteBlockTextAndMediaType.CallToActionDescription]: {
        header: $localize`:@@TPWebsiteCallToActionPlaceholderTitle:Build Something Amazing`,
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    },
    [WebsiteBlockTextAndMediaType.CallToActionAccentBackground]: {
        header: $localize`:@@TPWebsiteCallToActionPlaceholderTitle:Build Something Amazing`,
        body: '',
    },
    [WebsiteBlockTextAndMediaType.CallToActionAccentBackgroundDescription]: {
        header: $localize`:@@TPWebsiteCallToActionPlaceholderTitle:Build Something Amazing`,
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
}

export const TextAndMediaImagePlaceholderText = {
    header: $localize`:@@TPWebsiteTextMediaBlock:Heading`,
    body: LoremIpsumText.OneSentence
};

export const defaultContactFormThankYouMessage = $localize`:@@TPContactWidgetSettingsThankYouDefault:<div>
    <h2 style="text-align: center; padding-bottom: .75rem";>Thank you for reaching out!</h2>
    <p style="text-align: center;">Your message has been successfully submitted. We’ll get back to you as soon as possible.</p>
</div>`;

export const defaultSignupFormThankYouMessage = $localize`:@@TPSignupWidgetSettingsThankYouDefault:<div>
    <h2 style="text-align: center; padding-bottom: .75rem";>Thank you for signing up!</h2>
    <p style="text-align: center;">We will contact you as soon as possible.</p>
</div>`;

export const WEBSITE_PREFIX_ID = 'new_';

export interface WebsiteBlockTypeData {
    BlockType: WebsiteBlockType;
    BlockSubtype: WebsiteBlockContactFormType | WebsiteBlockLoginFormType | WebsiteBlockTextAndMediaType;
}

export interface WebsiteBlockImageIndexItem {
    ImageIndex: number;
    Block: WebsiteBlockTextAndMediaDisplayItem | WebsiteBlockContactFormDisplayItem | WebsiteBlockLoginFormDisplayItem;
}

