import { HttpParams } from '@angular/common/http';
import { AbstractType, Type } from '@angular/core';
import { ApiError, ApiProgressSubject, ApiReplaySubject, ApiSubject, BodyRequired, CancellableObservable, Column, Enum, IApiRequest, InstanceOf, IRequestOptions, OneParameterRequired, OrderByOptional, PaginatorDataOptional, RequestedFieldsOptional, RequestKind, SortDirection, TwoParametersRequired, ThreeParametersRequired } from '@library/base';
import { DisplayItemCollection } from '@library/data-models';
import { map, ReplaySubject, Subject, take } from 'rxjs';
import { ApiRequestManager } from './api-request.manager';


export class ApiRoute<T extends IRequestOptions<Body, OrderBy, Fields>, URLType, Body = undefined, OrderBy = undefined, Fields = undefined, Result = boolean> {

    private static _apiRequestManager?: ApiRequestManager;

    static Define<URLType extends URL,
                  Kind extends RequestKind,
                  Body extends AbstractType<any> | undefined = undefined,
                  OrderBy extends AbstractType<any> | undefined = undefined,
                  Fields extends AbstractType<any> | undefined = undefined,
                  Paging = false,
                  Result extends ResultType = 'boolean'>(definition: {
            URL: URLType,
            Kind: Kind,
            BodyType?: BodyAllowed<Body, Kind>,
            OrderByType?: OrderBy,
            RequestedFieldsType?: Fields,
            PagingAllowed?: BooleanType<Paging>,
            ResultType?: Result,
            UseRawURL?: boolean
        }): ApiRoute<URLTypeMap<URLType> & BodyTypeMap<Body> & OrderByRequestTypeMap<OrderBy, Result> & FieldsRequestTypeMap<Fields, Result> & PagingTypeMap<Paging>,
                     URLType, InstanceOf<Body>, OrderByTypeMap<OrderBy, Result>, FieldsTypeMap<Fields, Result>, ResultTypeMap<Result>> {

        return new ApiRoute(definition.URL, definition.Kind, definition.UseRawURL);
    }

    static SetApiRequestManager(manager: ApiRequestManager) {
        this._apiRequestManager = manager;
    }

    Kind!: RequestKind;
    URL!: URLType;
    UseRawURL!: boolean;
    readonly Error!: ApiError<T>;

    private constructor(url: URLType, kind: RequestKind, useRawURL?: boolean) {
        this.URL = url;
        this.Kind = kind;
        this.UseRawURL = useRawURL ?? false;
    }

    Call(requestOrVoid: EmptyToVoid<T>, ignoreTransitionAnimations?: boolean): CancellableObservable<Result> {
        return this.CallWithSubject(requestOrVoid, undefined, undefined, ignoreTransitionAnimations);
    }

    CallWithSubject(requestOrVoid: EmptyToVoid<T>, subject?: ApiSubject<Result, T>, progressSubject?: ApiProgressSubject<T>, ignoreTransitionAnimations?: boolean): CancellableObservable<Result>  {
        let request = (requestOrVoid ?? {}) as T;
        if (!subject) {
            subject = new Subject();
        }

        if (ignoreTransitionAnimations === undefined) {
            // By default, count query results should not be held back until the animation is complete
            if (request.PaginatorData && request.PaginatorData.PageNumber === 0 && request.PaginatorData.PageSize === 0) {
                ignoreTransitionAnimations = true;
            } else {
                ignoreTransitionAnimations = false;
            }
        }

        const apiRequest: IApiRequest<Body> = {
            URL: this.MakeURL(request, this.URL),
            Kind: this.Kind,
            Body: request.Body,
            HttpParams: this.MakeHttpParams(request)
        };

        if (!ApiRoute._apiRequestManager) {
            throw Error('ApiRoute must have its ApiRequestManager set before a request can be made.');
        }

        const cancelCallback = ApiRoute._apiRequestManager.Request(apiRequest, subject, progressSubject, request, this.UseRawURL, ignoreTransitionAnimations);

        // Return a one-off observable. Point being: if the return value of Call is used, no need to unsubscribe.
        // (We strip out the tracking information from this one-off observable, since it is unnecessary.)
        let result = subject.pipe(
            map(tracked => tracked.Response),
            take(1)) as CancellableObservable<Result>;
        
        result.Cancel = cancelCallback;

        return result;
    }

    public MakeSubject(): ApiSubject<Result, T> {
        return new Subject();
    }

    public MakeReplaySubject(): ApiReplaySubject<Result, T> {
        return new ReplaySubject();
    }

    public MakeProgressSubject(): ApiProgressSubject<T> {
        return new Subject();
    }

    public ModifyURL<NewURLType extends URL>(urlModifier: (x: URLType) => NewURLType): ApiRoute<T, NewURLType, Body, OrderBy, Fields, Result> {
        return new ApiRoute(urlModifier(this.URL), this.Kind, this.UseRawURL);
    }

    private MakeURL(request: T, url: URLType): string {
        if (IsURLWithNoParameters(url)) {
            return url;
        } else if (IsURLWithOneParameter(url)) {
            return url(request.Parameter!);
        } else if (IsURLWithTwoParameters(url)) {
            return url(request.Parameter1!, request.Parameter2!);
        } else if (IsURLWithThreeParameters(url)) {
            return url(request.Parameter1!, request.Parameter2!, request.Parameter3!);
        } else {
            throw Error('More than 3 URL parameters not implemented');
        }
    }

    private MakeHttpParams(request: T): HttpParams {
        let httpParams = new HttpParams();

        const paginatorData = request.PaginatorData;
        if (paginatorData) {
            httpParams = httpParams.append('offset', paginatorData.PageNumber * paginatorData.PageSize + (paginatorData.OffsetAdjustment ?? 0));
            httpParams = httpParams.append('limit', paginatorData.PageSize);
        }

        if (request.RequestedFields && request.RequestedFields.length) {
            let optionalFieldsArray: string[] = [];

            request.RequestedFields.forEach(field => {
                let fieldString: string;
                if (Column.IsSynthetic(field.Field)) {
                    if (field.Field.RequestBy === undefined) {
                        console.error(`Cannot request synthetic column ${(field.Field.Identifier)} as it does not have a RequestBy provided.`);
                        return;
                    }
                    fieldString = field.Field.RequestBy;
                } else {
                    fieldString = field.Field;
                }

                if (field.Value !== undefined) {
                    fieldString += `=${field.Value}`;
                }

                optionalFieldsArray.push(fieldString);
            });

            httpParams = httpParams.append('fields', optionalFieldsArray.join(','));
        }

        if (request.OrderBy && request.OrderBy.SortColumns.length) {
            let orderByArray: string[] = [];

            request.OrderBy.SortColumns.forEach(sortColumn => {
                let orderByString: string;
                if (Column.IsSynthetic(sortColumn.Column)) {
                    if (!sortColumn.Column.SortBy) {
                        throw new Error(`No SortBy provided for synthetic column ${sortColumn.Column.Identifier}`)
                    }
                    orderByString = sortColumn.Column.SortBy;
                } else {
                    orderByString = sortColumn.Column;
                }

                if(sortColumn.Direction == SortDirection.Ascending) {
                    orderByArray.push(orderByString);
                } else if (sortColumn.Direction == SortDirection.Descending){
                    orderByArray.push('-' + orderByString);
                }
            });

            httpParams = httpParams.append('orderby', orderByArray.join(','));
        }

        return httpParams;
    }
}


// The following types are used only in the definitions above

// Used to allow empty Call() on routes with no required parameters
type EmptyToVoid<T> = {} extends T ? void | T : T;

export type URLWithNoParameters = string;
export type URLWithOneParameter = (param: string) => string;
export type URLWithTwoParameters = (param1: string, param2: string) => string;
export type URLWithThreeParameters = (param1: string, param2: string, param3: string) => string;
export type URL = URLWithNoParameters | URLWithOneParameter | URLWithTwoParameters | URLWithThreeParameters;

// Force T to be either true or false (but not boolean)
type BooleanType<T> = [T] extends [boolean] ? [boolean] extends [T] ? never : T : never;
type ResultType = 'string' | 'number' | 'boolean' | Enum<any> | AbstractType<any>
type BodyAllowed<T, Kind> = Kind extends RequestKind.Get ? never : T;

type URLTypeMap<T> = T extends URLWithOneParameter ? OneParameterRequired : T extends URLWithTwoParameters ? TwoParametersRequired
    : T extends URLWithThreeParameters ? ThreeParametersRequired : {};
type BodyTypeMap<T> = [T] extends [undefined] ? {} : T extends AbstractType<infer U> ? BodyRequired<U> : never;
type FieldsTypeMap<T, U> = [T] extends [undefined] ? U extends Type<DisplayItemCollection<infer V>> ? V
                                                   : U extends Type<infer V> ? V
                                                   : never
                         : T extends Type<infer U> ? U
                         : never;
type OrderByTypeMap<T, U> = [T] extends [undefined] ? U extends Type<DisplayItemCollection<infer V>> ? V : never
                          : T extends Type<infer U> ? U
                          : never;
type PagingTypeMap<T> = T extends true ? PaginatorDataOptional : {};
type ResultTypeMap<T> = T extends 'string' ? string 
                      : T extends 'number' ? number
                      : T extends 'boolean' ? boolean
                      : T extends Type<infer U> ? U
                      : T extends AbstractType<infer U> ? U
                      : T[keyof T];


type FieldsRequestTypeMap<T, U> = [T] extends [undefined] ? U extends Type<DisplayItemCollection<infer V>> ? RequestedFieldsOptional<V>
                                                          : U extends Type<infer V> ? RequestedFieldsOptional<V>
                                                          : {}
                                : T extends Type<infer U> ? RequestedFieldsOptional<U>
                                : never;
type OrderByRequestTypeMap<T, U> = [T] extends [undefined] ? U extends Type<DisplayItemCollection<infer V>> ? OrderByOptional<V> : {}
                                 : T extends Type<infer U> ? OrderByOptional<U>
                                 : never;

export function IsURLWithNoParameters(url: any): url is URLWithNoParameters {
    return typeof url == 'string';
}
export function IsURLWithOneParameter(url: any): url is URLWithOneParameter {
    return typeof url == 'function' && url.length == 1;
}
export function IsURLWithTwoParameters(url: any): url is URLWithTwoParameters {
    return typeof url == 'function' && url.length == 2;
}
export function IsURLWithThreeParameters(url: any): url is URLWithThreeParameters {
    return typeof url == 'function' && url.length == 3;
}