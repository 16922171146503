import { boolean, RequestKind, string } from "@library/base";
import { CalculateSurchargeInputItem, CalendarBirthdayEventDisplayItem, CalendarBirthdayEventSearchOptions, CalendarEventDisplayItem, CalendarEventInputItem, CalendarEventSearchOptions, CalendarRepertoirePerformanceDisplayItem, CalendarSyncURLInputItem, ChangePasswordInputItem, ChangePasswordResult, ContactFormInputItem, DisplayItemCollection, MakePaymentInputItem, MfaInputItem, MfaSetupDisplayItem, MfaSetupResult, NameCodePair, NewsDisplayItem, NewsInputItem, NewsSearchOptions, PasswordResetInputItem, PaymentCompleteDisplayItem, PaymentSourceDisplayItem, PaymentSourceInputItem, PaymentSourceSearchOptions, PaymentSourceUpdateItem, PayPalCommercePlatformCreateOrderInputItem, PayPalCommercePlatformCreateTokenInputItem, PayPalCommercePlatformCreateVaultSetupTokenInputItem, PayPalCommercePlatformOrderResultItem, PayPalCommercePlatformTokenResultItem, PayPalProExpressCheckoutCompletedTransactionDisplayItem, PayPalProExpressCheckoutCompleteInputItem, PayPalProExpressCheckoutGetUrlInputItem, PayPalStandardCompletedTransactionDisplayItem, PayPalStandardCompleteInputItem, PayPalStandardGetUrlInputItem, ProcessPaymentSourceResultItem, ReportDisplayItem, ReportInputItem, ReportSearchOptions, SignupInputItem, SignupResultItem, StockInvoiceLogoDisplayItem, SurchargeResultItem, TaxDefinitionDisplayItem, TaxDefinitionSearchOptions, TaxDisplayItem, TaxSearchOptions, TimeZoneInfoDisplayItem, UserAuthenticationInputItem, VerifyEmailInputItem, WebsiteMediaLibraryDisplayItem, WebsitePageDisplayItem } from "@library/data-models";
import { ApiRoute } from "./api-route";

export class ApiBaseRoutes {

    static readonly Taxes = {
        GetTaxDefinitions: ApiRoute.Define({
            URL:'search/salestax/definitions',
            BodyType: TaxDefinitionSearchOptions,
            Kind: RequestKind.Post,
            ResultType: DisplayItemCollection<TaxDefinitionDisplayItem>
        }), 
        GetTax: ApiRoute.Define({
            URL:'search/salestax', 
            BodyType: TaxSearchOptions, 
            Kind: RequestKind.Post, 
            ResultType: DisplayItemCollection<TaxDisplayItem>
        })
    }

    static readonly Authentication = {
        Refresh: ApiRoute.Define({
            URL: 'auth',
            Kind: RequestKind.Put,
            ResultType: string
        }),
        Login: ApiRoute.Define({
            URL: 'auth',
            Kind: RequestKind.Post,
            BodyType: UserAuthenticationInputItem,
            ResultType: Array<string>
        }),
        Logout: ApiRoute.Define({
            URL: 'auth',
            Kind: RequestKind.Delete,
            ResultType: boolean
        }),
        ResetPassword: ApiRoute.Define({
            URL: 'passwords',
            Kind: RequestKind.Put,
            BodyType: PasswordResetInputItem,
            ResultType: boolean
        }),
        LogoutAll: ApiRoute.Define({
            URL: (profileID: string) => `profile/${profileID}/auth`,
            Kind: RequestKind.Delete,
            ResultType: boolean,
        }),
        ChangePassword: ApiRoute.Define({
            URL: (profileID: string) => `profile/${profileID}/password`,
            BodyType: ChangePasswordInputItem,
            Kind: RequestKind.Put,
            ResultType: ChangePasswordResult,
        })
    };

    static readonly MFA = {
        GetMFASetup: ApiRoute.Define({
            URL: (profileID: string) => `profile/${profileID}/mfa`,
            Kind: RequestKind.Get,
            ResultType: MfaSetupDisplayItem
        }),
        EnableMFA: ApiRoute.Define({
            URL: (profileID: string) => `profile/${profileID}/mfa`,
            BodyType: MfaInputItem,
            Kind: RequestKind.Post,
            ResultType: MfaSetupResult
        }),
        Delete: ApiRoute.Define({
            URL: (profileID: string) => `profile/${profileID}/mfa`,
            Kind: RequestKind.Delete,
            ResultType: boolean,
        })
    }

    static readonly Application = {
        LogOut: ApiRoute.Define({
            URL: '' as string,   // URL must be set after environment is injected (in AuthenticationBaseService)
            Kind: RequestKind.Post,
            UseRawURL: true
        })
    };

    static readonly Calendar = {
        AddCalendarEvent: ApiRoute.Define({
            URL: `calendar/event`,
            Kind: RequestKind.Post,
            BodyType: CalendarEventInputItem,
            ResultType: Array<string>,
        }),
        CalendarSync: ApiRoute.Define({
            URL: 'calendar/sync',
            Kind: RequestKind.Post,
            BodyType: CalendarSyncURLInputItem,
            ResultType: string
        }),
        GetEventDetails: ApiRoute.Define({
            URL: (id: string) => `calendar/events/${id}`,
            Kind: RequestKind.Get,
            ResultType: CalendarEventDisplayItem
        }),
        SearchEvents: ApiRoute.Define({
            URL: 'search/calendar/events',
            Kind: RequestKind.Post,
            BodyType: CalendarEventSearchOptions,
            PagingAllowed: true,
            ResultType: DisplayItemCollection<CalendarEventDisplayItem>
        }),
        SearchRepertoireEvents: ApiRoute.Define({
            URL: 'search/calendar/repertoire',
            Kind: RequestKind.Post,
            BodyType: CalendarEventSearchOptions,
            ResultType: DisplayItemCollection<CalendarRepertoirePerformanceDisplayItem>
        }),
        SearchBirthdayEvents: ApiRoute.Define({
            URL: 'search/calendar/birthdays',
            Kind: RequestKind.Post,
            BodyType: CalendarBirthdayEventSearchOptions,
            ResultType: DisplayItemCollection<CalendarBirthdayEventDisplayItem>
        })
    }

    static readonly DesignBlocks = {
        Signup: ApiRoute.Define({
            URL: 'signup',
            Kind: RequestKind.Post,
            BodyType: SignupInputItem,
            ResultType: SignupResultItem 
        }),
        Contact: ApiRoute.Define({
            URL: 'contact',
            Kind: RequestKind.Post,
            BodyType: ContactFormInputItem,
            ResultType: boolean
        })
    }

    static readonly Email = {
        VerifyEmail: ApiRoute.Define({
            URL: 'verifyemail',
            Kind: RequestKind.Post,
            BodyType: VerifyEmailInputItem,
            ResultType: boolean
        }),
    }

    static readonly Reports = {
        Search: ApiRoute.Define({
            URL: 'search/reports',
            Kind: RequestKind.Post,
            PagingAllowed: true,
            BodyType: ReportSearchOptions,
            OrderByType: ReportDisplayItem,
            ResultType: DisplayItemCollection<ReportDisplayItem>
        }),
        Create: ApiRoute.Define({
            URL: 'reports',
            Kind: RequestKind.Post,
            BodyType: ReportInputItem,
            ResultType: string
        }),
        Delete: ApiRoute.Define({
            URL: (reportID: string) => `reports/${reportID}`,
            Kind: RequestKind.Delete,
        }),
    };

    static readonly School = {
        GetLogos: ApiRoute.Define({
            URL: 'stockinvoicelogos',
            Kind: RequestKind.Get,
            ResultType: Array<StockInvoiceLogoDisplayItem>
        }),
        GetSchoolName: ApiRoute.Define({
            URL: 'names/school',
            Kind: RequestKind.Get,
            ResultType: string
        }),
    };

    static readonly Websites = {
        GetMediaLibraryItem: ApiRoute.Define({
            URL: (id: string)=> `websitemedialibrary/${id}`,
            Kind: RequestKind.Get,
            ResultType: WebsiteMediaLibraryDisplayItem
        }),
        Pages: {
            GetAll: ApiRoute.Define({
                URL: 'websitepages',
                Kind: RequestKind.Get,
                ResultType: DisplayItemCollection<WebsitePageDisplayItem>
            })
        }
    };

    static readonly PaymentSources = {
        Get: ApiRoute.Define({
            URL: (id: string) => `paymentsources/${id}`,
            Kind: RequestKind.Get,
            ResultType: PaymentSourceDisplayItem
        }),
        Search: ApiRoute.Define({
            URL: 'search/paymentsources',
            Kind: RequestKind.Post,
            BodyType: PaymentSourceSearchOptions,
            PagingAllowed: true,
            ResultType: DisplayItemCollection<PaymentSourceDisplayItem>
        }),
        Add: ApiRoute.Define({
            URL: (schoolID: string) => schoolID ? `schools/${schoolID}/paymentsources` : `paymentsources`,
            Kind: RequestKind.Post,
            BodyType: PaymentSourceInputItem,
            ResultType: ProcessPaymentSourceResultItem
        }),
        Delete: ApiRoute.Define({
            URL: (id: string) => `paymentsources/${id}`,
            Kind: RequestKind.Delete
        }),
        Update: ApiRoute.Define({
            URL: (id: string) => `paymentsources/${id}`,
            Kind: RequestKind.Put,
            BodyType: PaymentSourceUpdateItem
        }),
        CalculateSurcharge: ApiRoute.Define({
            URL: (schoolID: string) => schoolID ? `schools/${schoolID}/paymentsources/calculatesurcharge` : 'paymentsources/calculatesurcharge',
            Kind: RequestKind.Post,
            BodyType: CalculateSurchargeInputItem,
            ResultType: Array<SurchargeResultItem>
        })
    };

    static readonly PaymentMethods = {
        Get: ApiRoute.Define({
            URL: 'school/paymentmethods/',
            Kind: RequestKind.Get,
            ResultType: Array<string>
        })
    }

    static readonly PaymentProcessors = {
        PayPalCommercePlatform: {
            GetTokens: ApiRoute.Define({
                URL: (schoolID: string, processorID: string) => schoolID ? `schools/${schoolID}/paymentprocessors/${processorID}/paypalcommerceplatformtokens` : `paymentprocessors/${processorID}/paypalcommerceplatformtokens`,
                Kind: RequestKind.Post,
                BodyType: PayPalCommercePlatformCreateTokenInputItem,
                ResultType: PayPalCommercePlatformTokenResultItem
            }),
            CreateOrder: ApiRoute.Define({
                URL: (schoolID: string, processorID: string) => schoolID ? `schools/${schoolID}/paymentprocessors/${processorID}/paypalcommerceplatformorder` : `paymentprocessors/${processorID}/paypalcommerceplatformorder`,
                Kind: RequestKind.Post,
                BodyType: PayPalCommercePlatformCreateOrderInputItem,
                ResultType: PayPalCommercePlatformOrderResultItem
            }),
            CreateVaultSetupToken: ApiRoute.Define({
                URL: (schoolID: string, processorID: string) => schoolID ? `schools/${schoolID}/paymentprocessors/${processorID}/paypalcommerceplatformvaultsetuptoken` : `paymentprocessors/${processorID}/paypalcommerceplatformvaultsetuptoken`,
                Kind: RequestKind.Post,
                BodyType: PayPalCommercePlatformCreateVaultSetupTokenInputItem,
                ResultType: string
            })
        }
    }

    static readonly Countries = {
        GetAll: ApiRoute.Define({
            URL: 'countries',
            Kind: RequestKind.Get,
            ResultType: Array<NameCodePair>
        }),
        GetStates: ApiRoute.Define({
            URL: (countryCode: string) => `countries/${countryCode}/provstates`,
            Kind: RequestKind.Get,
            ResultType: Array<NameCodePair>
        })
    }

    static readonly Payment = {
        MakePayment: ApiRoute.Define({
            URL: 'paymentsources/makepayment',
            Kind: RequestKind.Post,
            BodyType: MakePaymentInputItem,
            ResultType: PaymentCompleteDisplayItem
        }),
        PayPalExpressCheckoutGetURL: ApiRoute.Define({
            URL: (schoolID: string) => schoolID ? `schools/${schoolID}/paymentprocessors/paypalproexpresscheckouturl` : 'paymentprocessors/paypalproexpresscheckouturl',
            Kind: RequestKind.Post,
            BodyType: PayPalProExpressCheckoutGetUrlInputItem,
            ResultType: string
        }),
        PayPalExpressCheckoutComplete: ApiRoute.Define({
            URL: 'paymentprocessors/paypalproexpresscheckoutcomplete',
            Kind: RequestKind.Post,
            BodyType: PayPalProExpressCheckoutCompleteInputItem,
            ResultType: PayPalProExpressCheckoutCompletedTransactionDisplayItem
        }),
        PayPalStandardGetURL: ApiRoute.Define({
            URL: (schoolID: string) => schoolID ? `schools/${schoolID}/paymentprocessors/paypalstandardurl` : 'paymentprocessors/paypalstandardurl',
            Kind: RequestKind.Post,
            BodyType: PayPalStandardGetUrlInputItem,
            ResultType: string
        }),
        PayPalStandardComplete: ApiRoute.Define({
            URL: 'paymentprocessors/paypalstandardcomplete',
            Kind: RequestKind.Post,
            BodyType: PayPalStandardCompleteInputItem,
            ResultType: PayPalStandardCompletedTransactionDisplayItem
        })
    };

    static readonly Posts = {
        Search: ApiRoute.Define({
            URL: (schoolID: string) => schoolID ? `search/schools/${schoolID}/news` : `search/news`,
            Kind: RequestKind.Post,
            BodyType: NewsSearchOptions,
            OrderByType: NewsDisplayItem,
            ResultType: DisplayItemCollection<NewsDisplayItem>,
            PagingAllowed: true
        }),
        Update: ApiRoute.Define({
            URL: (postID: string) => `news/${postID}/`,
            Kind: RequestKind.Put,
            BodyType: NewsInputItem
        }),
        Delete: ApiRoute.Define({
            URL: (postID: string) => `news/${postID}/`,
            Kind: RequestKind.Delete
        })
    }

    static readonly TimeZones = {
        Get: ApiRoute.Define({
            URL: 'timezones',
            Kind: RequestKind.Get,
            ResultType: Array<TimeZoneInfoDisplayItem>
        })
    }
}
