<lib-form-field-container 
    [control]="formControl"
    [isLoading]="(orientation === Orientation.Horizontal || isReadOnly) && isLoading"
    [isReadOnly]="isReadOnly"
    [isViewOnly]="isViewOnly"
    [kind]="FormContainerKind.None"
    [errors]="errors">

    <ng-content label select="[label]"></ng-content> 
    <ng-container readOnly>
        <div #providedReadOnly class="wrapper-content">
            <ng-content select="[readOnly]"></ng-content>
        </div>
        <div class="default-content" *ngIf="isReadOnly">
            <ng-container *ngIf="!selectedItemComponents.length" i18n="@@FormFieldNoneSelectedLabel">
                None selected
            </ng-container>
            <div *ngIf="selectedItemComponents.length" class="d-flex flex-row">
                <ng-container *ngFor="let item of selectedItemComponents; let last = last">
                    <div *ngTemplateOutlet="item.label"></div><div>{{last ? '' : ',&nbsp;'}}</div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <div *ngIf="!isReadOnly" [ngClass]="{'d-flex flex-wrap' : orientation === Orientation.Horizontal}" style="width:fit-content">
        <lib-checkbox *ngFor="let item of itemQueryList; let i = index"
            [formControl]="viewControl.controls[i]"
            [isLoading]="isLoading"
            (change)="ChangeEvent()">
            <ng-container hintMessage>{{item.hintMessage}}</ng-container>
            <ng-container *ngTemplateOutlet="item.label"></ng-container>
        </lib-checkbox>
    </div>


</lib-form-field-container>