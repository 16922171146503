import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-website-svgs',
  templateUrl: './website-svgs.component.html',
  styleUrls: ['./website-svgs.component.scss']
})
export class WebsiteSvgsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
