import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BaseViewComponent } from '@library/base';

@Component({
    selector: 'lib-hint',
    templateUrl: './hint.component.html',
    styleUrls: ['./hint.component.scss']
})
export class HintComponent extends BaseViewComponent implements OnInit {

    @Input() isViewOnly: boolean = false;
    constructor() {

        super();
    }

    override ngOnInit() {
        super.ngOnInit();
    }
}
