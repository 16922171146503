
    <ng-container [ngSwitch]="paymentProcessor.Type">
        <ng-container *ngSwitchCase="PaymentProcessorType.PayPalPro">
            <lib-payment-sources-add-paypal-pro
                [forceAutoPay]="forceAutoPay"
                [schoolName]="schoolName"
                [schoolID]="schoolID"
                [parentID]="parentID"
                [familyID]="familyID"
                [mode]="mode"
                [context]="context"
                [paymentProcessor]="paymentProcessor"
                [paymentSurchargeCreditCard]="paymentSurchargeCreditCard"
                [totalAmountToPay]="totalAmountToPay"
                [onlineConvenienceFee]="onlineConvenienceFee"
                [paymentProcessorErrorMessage]="paymentProcessorErrorMessage"
                (paymentSourceProcessed)="PaymentSourceProcessed($event)"
                (signupFormPaymentSourceReady)="SignupFormPaymentSourceReady($event)">
            </lib-payment-sources-add-paypal-pro>
        </ng-container>
        <ng-container *ngSwitchCase="PaymentProcessorType.Stripe">
            <lib-payment-sources-add-stripe 
                [forceAutoPay]="forceAutoPay"
                [schoolName]="schoolName"
                [schoolID]="schoolID"
                [parentID]="parentID"
                [familyID]="familyID"
                [mode]="mode"
                [context]="context"
                [paymentProcessor]="paymentProcessor"
                [paymentSurchargeCreditCard]="paymentSurchargeCreditCard"
                [paymentSurchargeACH]="paymentSurchargeACH"
                [currencyCode]="currencyCode"
                [totalAmountToPay]="totalAmountToPay"
                [stripeNextStepClientSecret]="stripeNextStepClientSecret"
                [onlineConvenienceFee]="onlineConvenienceFee"
                [paymentProcessorErrorMessage]="paymentProcessorErrorMessage"
                (paymentSourceProcessed)="PaymentSourceProcessed($event)"
                (signupFormPaymentSourceReady)="SignupFormPaymentSourceReady($event)"
                (paymentSourceTypeChanged)="PaymentSourceTypeChanged($event)"
                (stripeReady)="StripeReady()"
                (stripeNextStepComplete)="StripeNextStepComplete($event)">
            </lib-payment-sources-add-stripe>
        </ng-container>

        <ng-container *ngSwitchCase="PaymentProcessorType.PayPalCommercePlatform">
            <lib-payment-sources-add-paypal-commerce-platform
                [forceAutoPay]="forceAutoPay"
                [schoolName]="schoolName"
                [schoolID]="schoolID"
                [parentID]="parentID"
                [familyID]="familyID"
                [mode]="mode"
                [context]="context"
                [paymentProcessor]="paymentProcessor"
                [paymentSurchargeCreditCard]="paymentSurchargeCreditCard"
                [paymentSurchargeACH]="paymentSurchargeACH"
                [currencyCode]="currencyCode"
                [totalAmountToPay]="totalAmountToPay"
                [invoiceDetailsGUID]="invoiceDetailsGUID"
                [onlineConvenienceFee]="onlineConvenienceFee"
                [paymentProcessorErrorMessage]="paymentProcessorErrorMessage"
                (paymentSourceProcessed)="PaymentSourceProcessed($event)"
                (signupFormPaymentSourceReady)="SignupFormPaymentSourceReady($event)"
                (payPalCPButtonClicked)="payPalCPButtonClicked.emit($event)">
            </lib-payment-sources-add-paypal-commerce-platform>
        </ng-container>

    </ng-container>




