import { Component, OnInit } from '@angular/core';
import { Required } from '@library/base';
import { DialogBaseViewComponent, FormBase, Optional, RecurringDeleteOptions, SimpleDialogData, boolean } from '@library/base';

class RecurringDeleteDialogForm extends FormBase {
    Option = Required(RecurringDeleteOptions);
}

@Component({
    selector: 'lib-dialog-recurring-delete',
    templateUrl: './dialog-recurring-delete.component.html',
    styleUrls: ['./dialog-recurring-delete.component.scss'],
})
export class DialogRecurringDeleteComponent extends DialogBaseViewComponent<SimpleDialogData> implements OnInit{
    readonly form = RecurringDeleteDialogForm.Create(false);

    override ngOnInit() {
        super.ngOnInit();
        this.form.patchValue({
            Option: RecurringDeleteOptions.PrimaryOption
        });
    }

    PrimaryButtonClicked(): void {
        if (this.form.CheckValidity(true)) {
            if (this.form.controls.Option.value === RecurringDeleteOptions.PrimaryOption) {
                this.PrimaryAction();
            } else if (this.form.controls.Option.value === RecurringDeleteOptions.SecondaryOption) {
                this.SecondaryAction();
            }
        }
    }

    get RecurringDeleteOptions(): typeof RecurringDeleteOptions {
        return RecurringDeleteOptions;
    }
}
