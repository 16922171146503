import { Component } from '@angular/core';
import { DialogBaseViewComponent, SimpleDialogData } from '@library/base';

@Component({
    selector: 'lib-dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent extends DialogBaseViewComponent<SimpleDialogData> {
}
