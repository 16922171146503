import { Component, Input, OnInit } from '@angular/core';
import { SBDate } from '@library/localization';
import { FormFieldDateBaseComponent } from '../form-field-date-base.component';
import { MbscDatepicker, MbscDatepickerOpenEvent } from '@mobiscroll/angular';

@Component({
    selector: 'lib-input-date',
    templateUrl: './form-field-date.component.html',
    styleUrls: ['./form-field-date.component.scss']
})
export class FormFieldDateComponent extends FormFieldDateBaseComponent<SBDate | null> implements OnInit {

    @Input() scrollLock: boolean = true;

    override ngOnInit() {
        super.ngOnInit();
    }

    ChangeEvent(change: any): void {
        if (change.value === null) {
            this.SetFormControlValue(null);
        } else {
            const newDate = SBDate.FromJSDate(change.value);
            if (newDate.totalMilliseconds != this.formControl.value?.totalMilliseconds) {
                this.SetFormControlValue(newDate);
            }
        }
    }

    Clear(): void {
        this.viewControl.setValue('');
        this.SetFormControlValue(null);
    }

    override writeValue(dateValue: SBDate | null): void {
        if (dateValue){
            this.viewControl.setValue(dateValue.ToISO());
        } else {
            this.viewControl.setValue('');
        }
    }

    override PopupOpened(event: any): void {
        super.PopupOpened(event);
        if(!this.scrollLock){
            this.input?.nativeElement.scrollIntoView({behavior: "smooth", block: "center"});
        }
    }
}
