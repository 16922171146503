import { Component, Input, OnInit } from '@angular/core';
import { BaseViewComponent, TextKind } from '@library/base';

@Component({
    selector: 'lib-read-only',
    templateUrl: './read-only.component.html',
    styleUrls: ['./read-only.component.scss']
})
export class ReadOnlyComponent extends BaseViewComponent implements OnInit {    

    @Input() defaultReadOnlyMessage!: string;  
    @Input() textKind: TextKind = TextKind.Default;  

    constructor() {
      super();
    }
}
