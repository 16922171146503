import { Pipe, PipeTransform } from "@angular/core";
import { SortDirection } from "../definitions/table.definitions";

type SortValue<T> = { Value: T, Primary: T[keyof T] | string, Secondary?: T[keyof T] | string };

@Pipe({name: 'orderBy'})
export class OrderByPipe implements PipeTransform {
    transform<T>(
        targetArray: Array<T>, 
        primaryPropertyName: keyof T, 
        primaryOrder: SortDirection = SortDirection.Ascending, 
        primaryCaseSensitive?: boolean,
        secondaryPropertyName?: keyof T, 
        secondaryOrder: SortDirection = SortDirection.Ascending,
        secondaryCaseSensitive?: boolean
    ): Array<T> {
        if (!targetArray?.length) {
            return [];
        }

        if (primaryCaseSensitive === undefined) {
            // Case insensitive by default if the property is a string
            primaryCaseSensitive = typeof targetArray[0][primaryPropertyName] !== 'string';
        }

        const sortArray: SortValue<T>[] = targetArray.map(value => ({
            Value: value,
            Primary: this.AdjustCase(value[primaryPropertyName], primaryCaseSensitive!)
        }));

        if (secondaryPropertyName) {
            if (secondaryCaseSensitive === undefined) {
                // Case insensitive by default if the property is a string
                secondaryCaseSensitive = typeof targetArray[0][secondaryPropertyName] !== 'string';
            }

            sortArray.forEach(item => item.Secondary = this.AdjustCase(item.Value[secondaryPropertyName], secondaryCaseSensitive!));
        }

        sortArray.sort(this.Comparator<T>(primaryOrder, secondaryOrder));

        return sortArray.map(item => item.Value);
    }

    private AdjustCase<T>(value: T, caseSensitive: boolean): T | string {
        return caseSensitive ? value : String(value).toLocaleLowerCase();
    }

    private Comparator<T>(primaryOrder: SortDirection, secondaryOrder: SortDirection) {
        const primarySign = primaryOrder === SortDirection.Ascending ? -1 : 1;
        const secondarySign = secondaryOrder === SortDirection.Ascending ? -1 : 1;

        return (a: SortValue<T>, b: SortValue<T>) =>
              a.Primary < b.Primary ? primarySign
            : a.Primary > b.Primary ? -primarySign
            : a.Secondary! < b.Secondary! ? secondarySign
            : a.Secondary! > b.Secondary! ? -secondarySign
            : 0;
    }
}