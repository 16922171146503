<lib-form-field-container 
    [control]="formControl"
    [isLoading]="isLoading"
    [isOptional]="isOptionalOverride"
    [isReadOnly]="isReadOnly"
    [isViewOnly]="isViewOnly"
    [kind]="FormContainerKind.Checkbox"
    [errors]="errors">

    <ng-content label select="[label]"></ng-content> 
    <ng-content hintMessage select="[hintMessage]"></ng-content>

    <ng-container readOnly>
        <div #providedReadOnly class="wrapper-content">
            <ng-content select="[readOnly]"></ng-content>
        </div>
        <div class="default-content">
            {{ formControl.value ? 'Selected' : 'Not selected' }}
        </div>
    </ng-container>
    <div class="v3">
        <mat-checkbox 
            [formControl]="viewControl"
            [indeterminate]="isIndeterminate"
            [attr.data-sb-qa]="qaTag"
            (click)="$event.stopPropagation()"
            (change)="ChangeEvent($event)">
            <p #pContent [ngClass]="{'px-1': pContent.hasChildNodes()}" class="typography-body-medium"> <ng-content></ng-content> </p>
            <lib-hint >
                <ng-content select="[checkBoxHintMessage]"></ng-content>
            </lib-hint>
        </mat-checkbox>
    </div>

</lib-form-field-container>