import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiBaseRoutes } from '@library/api';
import { LoremIpsumText } from '@library/base';
import { DesignBlockBaseViewComponent, PaginatorData, WebsiteMode } from '@library/base';
import { EventColorEnum, NewsDisplayItem, NewsPostIconEnum, NewsPublishLocations, NewsSearchOptions } from '@library/data-models';
import { SBDate } from '@library/localization';
import { StyleManager } from '@library/managers';

@Component({
    selector: 'lib-blog-posts-design-block',
    templateUrl: './blog-posts-design-block.component.html',
    styleUrls: ['./blog-posts-design-block.component.scss']
})
export class BlogPostsDesignBlockComponent extends DesignBlockBaseViewComponent implements OnInit, AfterViewInit {
    private _loading: boolean = true;
    private _blogSize: number = 0;
    private _stickyPosts: Array<NewsDisplayItem> = [];
    private _visiblePosts: Array<NewsDisplayItem> = [];
    private _allPostsLength: number | null = null;
    private _examplePosts: Array<NewsDisplayItem> = [];
    private _publishLocations: NewsPublishLocations = new NewsPublishLocations({Blog: true, StudentPortal: false, TeacherPortal: false});

    @Input() schoolID!: string;
    @Output() goToNewsPageClicked: EventEmitter<void> = new EventEmitter();

    constructor(private _StyleManager: StyleManager){
        super();
        for(let i = 0; i <= 3; i++) {
            this._examplePosts.push(new NewsDisplayItem({
                Title: `Lorem Ipsum ${i+1}`,
                Author: 'Author',
                PublishDate: SBDate.Today().ToISO(),
                Sticky: i < 2,
                Icon: this.RandomEnumValue(NewsPostIconEnum),
                Color: this.RandomEnumValue(EventColorEnum),                
                BodyHTML: LoremIpsumText.FourSentences
            }))
        }
    }

    ngAfterViewInit(): void {
        const searchOptions = new NewsSearchOptions({
            Sticky: true,
            EndDate: SBDate.Today().AddDays(1).ToISO(),
            PublishLocations: this._publishLocations
        });
        ApiBaseRoutes.Posts.Search.Call({Parameter: (this.websiteMode === WebsiteMode.Live) ? this.schoolID: '', Body: searchOptions}).subscribe(data => {
            this._stickyPosts = data.ItemSubset;
            this.GetNews();
        });
    
    }

    GetNews(): void {
        this._loading = true;
        const searchOptions = new NewsSearchOptions({
            Sticky: false,
            EndDate: SBDate.Today().AddDays(1).ToISO(),
            PublishLocations: this._publishLocations
        });
        this._blogSize += 3; 
        const paginatorData = new PaginatorData({
            PageSize: this._blogSize,
            PageNumber: 0
        })
        ApiBaseRoutes.Posts.Search.Call({Parameter: (this.websiteMode === WebsiteMode.Live) ? this.schoolID: '', Body: searchOptions, PaginatorData: paginatorData }).subscribe(data => {
            this._visiblePosts = [...this._stickyPosts, ...data.ItemSubset];
            this._allPostsLength = this._stickyPosts.length + data.TotalItemCount;
            this._loading = false;
        });
    }

    GetBackgroundColor(post: NewsDisplayItem): string {
        return this._StyleManager.GetBackgroundColorCssClass(post.Color);
    }

    GoToNewsPage(): void {
        this.goToNewsPageClicked.emit();
    }

    TrackByID(index: number, item: NewsDisplayItem) {
        return item.ID;
    }

    get visiblePosts(): Array<NewsDisplayItem> {
        return this.inSidebar ? this._examplePosts : this._visiblePosts;
    }

    get allPostsLength(): number | null {
        return this.inSidebar ? this._examplePosts.length : this._allPostsLength;
    }

}
