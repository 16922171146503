import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';



import { IconBulletComponent } from './icon-bullet/icon-bullet.component';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Icon } from '@library/base';
import { IconSvgComponent } from './icon-svg/icon-svg.component';

@NgModule({
    declarations: [
        IconSvgComponent,
        IconBulletComponent
    ],
    imports: [
        CommonModule,
        MatTooltipModule,
        MatIconModule
    ],
    exports: [
        IconSvgComponent,
        IconBulletComponent
    ],
})


export class IconModule {
    constructor(private _MatIconRegistry: MatIconRegistry, private _DomSanitizer: DomSanitizer) {
        this.AddToIconRegistry();
    }

    private AddToIconRegistry(): void {
        Object.entries(Icon).forEach(([group, icons]) => {
            Object.entries(icons).forEach(([icon, details]) => {
                this._MatIconRegistry.addSvgIconLiteral(details.Name, this._DomSanitizer.bypassSecurityTrustHtml(details.Path));
            });
        });
    }
}
