import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseViewComponent, PaymentContext, PaymentSourceMode } from '@library/base';
import { InvoiceDetailsDisplayItem, PaymentProcessorDisplayItem, PaymentProcessorType, PaymentSourceInputItem, PaymentSourceType, PaymentSurchargeDisplayItem, ProcessPaymentSourceResultItem } from '@library/data-models';
import { PaymentSourcesAddPayPalCommercePlatformComponent } from './paypal-commerce-platform/payment-sources-add-paypal-commerce-platform.component';
import { PaymentSourcesAddPaypalProComponent } from './paypal-pro/payment-sources-add-paypal-pro.component';
import { PaymentSourcesAddStripeComponent } from './stripe/payment-sources-add-stripe.component';

@Component({
    selector: 'lib-add-payment-source',
    templateUrl: './add-payment-source.component.html',
    styleUrls: ['./add-payment-source.component.scss']
})
export class AddPaymentSourceComponent extends BaseViewComponent {

    @ViewChild(PaymentSourcesAddPaypalProComponent, {static: false}) paypalProComponent!: PaymentSourcesAddPaypalProComponent;
    @ViewChild(PaymentSourcesAddStripeComponent, {static: false}) stripeComponent!: PaymentSourcesAddStripeComponent;
    @ViewChild(PaymentSourcesAddPayPalCommercePlatformComponent, {static: false}) paypalCommercePlatformComponent!: PaymentSourcesAddPayPalCommercePlatformComponent;

    @Input()
    set addClicked(value: boolean) {
        if (value) {
            this.Submit();
        }
    }

    @Input() paymentSurchargeCreditCard!: PaymentSurchargeDisplayItem;
    @Input() paymentSurchargeACH!: PaymentSurchargeDisplayItem;
    @Input() schoolName!: string;
    @Input() mode: PaymentSourceMode = PaymentSourceMode.Add;
    @Input() context!: PaymentContext;
    @Input() totalAmountToPay: number | null = null;
    @Input() invoiceDetailsGUID: string | null = null;
    @Input() onlineConvenienceFee: number | null = null;
    @Input() schoolID: string | null = null;
    @Input() parentID!: string;
    @Input() familyID!: string;
    @Input() stripeNextStepClientSecret: string | null = null;
    @Input() paymentProcessor!: PaymentProcessorDisplayItem
    @Input() forceAutoPay!: boolean;
    @Input() currencyCode!: string;
    @Input() paymentProcessorErrorMessage: string | null = null;

    @Output() paymentSourceProcessed: EventEmitter<ProcessPaymentSourceResultItem | null> = new EventEmitter();
    @Output() paymentSourceTypeChanged: EventEmitter<PaymentSourceType> = new EventEmitter();
    @Output() signupFormPaymentSourceReady: EventEmitter<PaymentSourceInputItem | null> = new EventEmitter();
    @Output() stripeReady: EventEmitter<void> = new EventEmitter();
    @Output() stripeNextStepComplete: EventEmitter<string> = new EventEmitter();
    @Output() payPalCPButtonClicked: EventEmitter<boolean> = new EventEmitter();

    PaymentSourceProcessed(result: ProcessPaymentSourceResultItem | null): void {
        this.paymentSourceProcessed.emit(result);
    }

    PaymentSourceTypeChanged(type: PaymentSourceType): void {
        this.paymentSourceTypeChanged.emit(type);
    }

    SignupFormPaymentSourceReady(item: PaymentSourceInputItem | null): void {
        this.signupFormPaymentSourceReady.emit(item);
    }

    StripeReady(): void {
        this.stripeReady.emit();
    }

    StripeNextStepComplete(intentID: string): void {
        this.stripeNextStepComplete.emit(intentID);
    }

    private Submit(): void {
        switch(this.paymentProcessor.Type) {
            case PaymentProcessorType.Test:
            case PaymentProcessorType.PayPalStandard:
                break;
            case PaymentProcessorType.PayPalPro:
                this.paypalProComponent.AddPayPalProPaymentMethod();
                break;
            case PaymentProcessorType.PayPalCommercePlatform:
                this.paypalCommercePlatformComponent.VaultPayPalCommercePlatform();
                break;
            case PaymentProcessorType.Stripe:
                this.stripeComponent.addStripePaymentMethodForm.disable();
                this.stripeComponent.VaultStripe();
                break;
        }
    }

    get PaymentProcessorType(): typeof PaymentProcessorType {
        return PaymentProcessorType;
    }

}
