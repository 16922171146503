import { HttpParams, HttpProgressEvent } from "@angular/common/http";
import { ErrorResponse } from "@library/data-models";
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { FieldOf } from "./base.definitions";
import { ColumnOf, OrderBy, PaginatorData } from "./table.definitions";

export enum RequestKind {
    Post = 'Post',
    Get = 'Get',
    Patch = 'Patch',
    Put = 'Put',
    Delete = 'Delete'
}

export interface OneParameterRequired {
    Parameter: string;
}

export interface TwoParametersRequired {
    Parameter1: string;
    Parameter2: string;
}
export interface ThreeParametersRequired {
    Parameter1: string;
    Parameter2: string;
    Parameter3: string;
}

export interface BodyRequired<T> {
    Body: T;
}

export interface PaginatorDataOptional {
    PaginatorData?: PaginatorData;
}

export interface OrderByOptional<T> {
    OrderBy?: OrderBy<T>;
}

export interface RequestedFieldsOptional<T> {
    RequestedFields?: RequestedField<T>[];
}

export interface IRequestOptions<BodyType, OrderByType, RequestedFieldsType> extends
    Partial<OneParameterRequired>, Partial<TwoParametersRequired>, Partial<ThreeParametersRequired>, Partial<BodyRequired<BodyType>>,
    PaginatorDataOptional, OrderByOptional<OrderByType>, RequestedFieldsOptional<RequestedFieldsType> {
}

export interface IApiRequest<T> {
    URL: string,
    Kind: RequestKind,
    Body?: T,
    HttpParams?: HttpParams
}

export interface CancellableObservable<T> extends Observable<T> {
    Cancel(): void;
}

export type ApiSubject<T, U> = Subject<ApiResult<T, U>>;
export type ApiReplaySubject<T, U> = ReplaySubject<ApiResult<T, U>>;
export type ApiObservable<T, U> = Observable<ApiResult<T, U>>;
export type ApiProgressSubject<T> = Subject<ApiResult<HttpProgressEvent, T>>;

export class ApiResult<Response, Request> {
    Request!: Request;
    Response!: Response;

    constructor(initializer: ApiResult<Response, Request>) {
        Object.assign(this, initializer);
    }
}

export class ApiError<Request> extends ApiResult<ErrorResponse, Request> {
    Status!: number;
    Message!: string;

    constructor(initializer: ApiError<Request>) {
        super(initializer);
    }
}

export class RequestedField<T> {
    Field!: ColumnOf<T>;
    Value?: string;

    constructor(initializer: RequestedField<T>) {
        Object.assign(this, initializer);
    }
}
