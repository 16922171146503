import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiBaseRoutes } from '@library/api';
import { BaseViewComponent, DialogEvents, Environment, ErrorMessages, Errors, FormBase, FormContainerKind, NameCodeWithID, Optional, PaymentContext, PaymentSourceMode, Required, SimpleDialogData, boolean, string } from '@library/base';
import { ErrorCode, NameCodePair, PayPalCommercePlatformCreateOrderInputItem, PayPalCommercePlatformCreateTokenInputItem, PayPalCommercePlatformCreateVaultSetupTokenInputItem, PayPalCommercePlatformOrderResultItem, PayPalCommercePlatformPaymentSourceInputItem, PaymentProcessorDisplayItem, PaymentProcessorType, PaymentSourceInputItem, PaymentSourceIntent, PaymentSourceType, PaymentSurchargeDisplayItem, ProcessPaymentSourceResultItem, SurchargeType, InvoiceDetailsDisplayItem } from '@library/data-models';
import { DialogService } from '@library/dialog';
import { CreateOrderActions, CreateOrderData, PayPalNamespace, loadScript } from "@paypal/paypal-js";
import { Observable, firstValueFrom } from 'rxjs';

class PayPalCommercePlatformAddPaymentSourceForm extends FormBase {
    AutoPay = Optional(boolean);
    SavePaymentSource = Optional(boolean);
    PostalCode = Required(string);
    Country = Required(NameCodeWithID);
    State = Required(NameCodeWithID);

    // These controls are used internally for the PayPal form fields (they are only used for error display)
    CardNumber = Optional(string);
    ExpiryDate = Optional(string);
    CVV = Optional(string);
    CardHolderName = Optional(string);
}

@Component({
    selector: 'lib-payment-sources-add-paypal-commerce-platform',
    templateUrl: './payment-sources-add-paypal-commerce-platform.component.html',
    styleUrls: ['./payment-sources-add-paypal-commerce-platform.component.scss']
})
export class PaymentSourcesAddPayPalCommercePlatformComponent extends BaseViewComponent implements OnInit {

    @ViewChild('payPalInputStyleElement', { static: true }) payPalInputStyleElement!: ElementRef<HTMLElement>;

    @Input() mode: PaymentSourceMode = PaymentSourceMode.Add;
    @Input() context!: PaymentContext;
    @Input() paymentSurchargeCreditCard!: PaymentSurchargeDisplayItem;
    @Input() paymentSurchargeACH!: PaymentSurchargeDisplayItem;
    @Input() schoolName!: string;
    @Input() latePaymentFee: number | null = null;
    @Input() schoolID: string | null = null;
    @Input() parentID!: string;
    @Input() familyID!: string;
    @Input() paymentProcessor!: PaymentProcessorDisplayItem;
    
    @Input()
    set forceAutoPay(value: boolean) {
        if (value) {
            const autoPayForPayPalCommercePlatform = this.form.controls.AutoPay;
            autoPayForPayPalCommercePlatform.setValue(true);
            autoPayForPayPalCommercePlatform.disable();
        }
    }

    private _currencyCode!: string;
    @Input()
    set currencyCode(value: string) {
        this._currencyCode = value;
    }

    @Input() totalAmountToPay: number | null = null;
    @Input() invoiceDetailsGUID: string | null = null;

    private _onlineConvenienceFee: number | null = null;
    @Input()
    set onlineConvenienceFee(value: number | null) {
        this._onlineConvenienceFee = value;
    }

    @Input() set paymentProcessorErrorMessage(error: string | null) {
        if (error !== null) {
            const message = $localize`:@@CommonAddPaymentSourcePaymentProcessorErrorDescription:The payment processor returned the following message:\n\n` + error;
            this.ShowErrorDialog(message);
        }
    }

    @Output() paymentSourceProcessed: EventEmitter<ProcessPaymentSourceResultItem | null> = new EventEmitter();
    @Output() signupFormPaymentSourceReady: EventEmitter<PaymentSourceInputItem | null> = new EventEmitter();
    @Output() payPalCPButtonClicked: EventEmitter<boolean> = new EventEmitter();

    readonly cardNumberErrors = new Errors();
    readonly expiryDateErrors = new Errors();
    readonly cvvErrors = new Errors();
    readonly cardHolderNameErrors = new Errors();

    private readonly _defaultButtonErrorDialogText = $localize`:@@CommonAddPaymentSourceAuthorizationErrorMessage:There was a problem processing your payment. Please try again or contact support for more help.`;
    private readonly _defaultCardErrorDialogText = $localize`:@@CommonAddPaymentCardAuthorizationErrorMessage:There was a problem processing your card. Please try another card or contact support for more help.`;

    readonly form = PayPalCommercePlatformAddPaymentSourceForm.Create(false);

    private _paymentSourceType: PaymentSourceType = PaymentSourceType.CreditCard;
    private _loading: boolean = true;
    private _loadingPayPalButtons: boolean = true; 
    private _loadingCardNumberField: boolean = true; 
    private _loadingExpiryField: boolean = true; 
    private _loadingCVVField: boolean = true; 
    private _loadingNameField: boolean = true;

    private _inputStyle!: CSSStyleDeclaration;
    private _cvvTooltip: string = $localize`:@@CommonAddPaymentSourceCardGenericCVVTooltip:Your CVV (Card Verification Value) is displayed on the back of your card as the last 3 digits`;

    private _countries: NameCodeWithID[] = [];
    private _states: NameCodeWithID[] = [];
    private _defaultState: NameCodeWithID = {
        ID: 'default',
        Name: $localize`:@@CommonAddPaymentSourceCountryStatesDefault:Outside US/Canada`,
        Code: null,
        Default: true
    };

    readonly zipCodeError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceZipCodeMissingError:Please enter your zip/postal code`
    };

    readonly countryError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceCountryMissingError:Please select your country`
    };

    readonly stateError: ErrorMessages = {
        required: _ => $localize`:@@CommonAddPaymentSourceStateProvinceMissingError:Please select your state or province`
    };

    private _cardFields: any = null;
    private _orderID: string | null = null;

    constructor(
        private _Environment: Environment,
        private _DialogService: DialogService,
        private _NgZone: NgZone
    ) { 
        super();

        this.cardNumberErrors.Override({
            required: _ => $localize`:@@CommonAddPaymentSourceCreditCardNumberMissingError:Please enter your card number`,
            api: _ => $localize`:@@CommonAddPaymentSourceCreditCardNumberInvalid:Please enter a valid card number`
        });

        this.expiryDateErrors.Override({
            required: _ => $localize`:@@CommonAddPaymentSourceCreditCardExpiryDateMissingError:Please enter the card's expiry date`,
            api: _ => $localize`:@@CommonAddPaymentSourceCreditCardExpiryDateInvalid:Please enter a valid expiry date`
        });

        this.cvvErrors.Override({
            required: _ => $localize`:@@CommonAddPaymentSourceCreditCardCVVMissingError:Please enter the CVV (Card Verification Value)`,
            api: _ => $localize`:@@CommonAddPaymentSourceCreditCardCVVInvalid:Please enter a valid CVV (Card Verification Value)`
        });

        this.cardHolderNameErrors.Override({
            required: _ => $localize`:@@CommonAddPaymentSourceCreditCardCardholderNameMissingError:Please enter the card holder's name as it appears on the card`,
            api: _ => $localize`:@@CommonAddPaymentSourceCreditCardCardholderNameInvalid:Please enter a valid card holder name`
        });
    }

    ngAfterViewInit(): void {
        this._inputStyle = getComputedStyle(this.payPalInputStyleElement.nativeElement);

        if (this.paymentProcessor.Type != PaymentProcessorType.PayPalCommercePlatform) {
            throw Error('Wrong payment processor type for payment-sources-add-paypal-commerce-platform.component.ts')
        }

        this.GetCountries();

        ApiBaseRoutes.PaymentProcessors.PayPalCommercePlatform.GetTokens.Call({
            Parameter1: (this.mode === PaymentSourceMode.AnonymousPayment || this.context === PaymentContext.SignupWidget) ? this.schoolID! : '',
            Parameter2: this.paymentProcessor.ID!,
            Body: new PayPalCommercePlatformCreateTokenInputItem({
                IncludeButtons: true
            })
        }).subscribe(tokens => {
            loadScript({
                clientId: this._Environment.Payment!.PayPalCommercePlatform!.ClientID!,
                components: (this.mode === PaymentSourceMode.Add || this.context === PaymentContext.SignupWidget) ? ['card-fields'] : ['buttons', 'card-fields'],
                intent: 'capture',
                currency: this._currencyCode.toUpperCase(),
                dataPartnerAttributionId: this._Environment.Payment!.PayPalCommercePlatform!.BNCode,
                dataClientToken: tokens.CardFieldsToken!,
                merchantId: tokens.MerchantID!,
                dataUserIdToken: (this.mode === PaymentSourceMode.Add || this.context === PaymentContext.SignupWidget) ? undefined : tokens.ButtonToken!,
                disableFunding: ['credit'], 
                enableFunding: ['venmo'],
                buyerCountry: this.isDebug ? 'US' : undefined         // Make Venmo testing possible on Test 443
            }).then((paypal: PayPalNamespace | null) => {
                if (!paypal || !(paypal as any).CardFields) {
                    throw Error;
                }
                 
                if (paypal.Buttons) {
                    paypal.Buttons({
                        createOrder: async (data: CreateOrderData, actions: CreateOrderActions) => {
                            this._NgZone.run(() => {
                                this.payPalCPButtonClicked.emit(true);
                                this.form.disable();
                                this.form.disable();
                            });

                            let paymentSourceType: PaymentSourceType;
                            switch (data.paymentSource) {
                                case 'paypal':
                                    paymentSourceType = PaymentSourceType.PayPal;
                                    break;
                                case 'venmo':
                                    paymentSourceType = PaymentSourceType.Venmo;
                                    break;
                                default:
                                    throw Error('Unsupported payment source type');
                            }

                            const result = await firstValueFrom(this.CreateOrder(paymentSourceType));

                            this._orderID = result.PayPalOrderID;
                            return this._orderID;
                        },
                        onApprove: async (data: any, actions: any) => {
                            this._NgZone.run(() => this.ProcessNewPaymentSource());
                        },
                        onCancel: (data: any, actions: any) => {
                            this._NgZone.run(() => {
                                this.payPalCPButtonClicked.emit(false);
                                this.form.enable();
                            });
                        },
                        onError: (error: any) => {
                            if (!this._DialogService.dialogReference) {
                                this._NgZone.run(() => {
                                    this.ShowErrorDialog(this._defaultButtonErrorDialogText);
                                });
                            }
                        },
                        onInit: async (data: any, actions: any) => {
                        }
                    } as any).render("#paypal-button-container")
                             .then(() => this.FieldLoaded('buttons'))
                             .catch((error) => {
                                throw Error('PayPal buttons could not be loaded');
                             });
                } else {
                    this._loadingPayPalButtons = false;
                }

                if ((paypal as any).CardFields) {
                    const cardStyle = {
                        'input': {
                            'color': '#000',
                            'font-family': this._inputStyle.fontFamily,
                            'font-size': this._inputStyle.fontSize,
                            'font-weight': this._inputStyle.fontWeight,
                            'letter-spacing': this._inputStyle.letterSpacing,
                            'padding': '7.4px'
                        },
                        '.invalid': {
                            'color': '#000',
                        }
                    }

                    this._cardFields = (paypal as any).CardFields({
                        style: cardStyle,

                        createOrder: this.mode === PaymentSourceMode.Add
                            ? undefined
                            : async (data: any) => {
                                const result = await firstValueFrom(this.CreateOrder(PaymentSourceType.CreditCard));

                                this._orderID = result.PayPalOrderID;
                                return this._orderID;
                            },

                        createVaultSetupToken: this.mode !== PaymentSourceMode.Add
                            ? undefined
                            : async (data: any) => {
                                const formValues = this.form.value;
                                return firstValueFrom(ApiBaseRoutes.PaymentProcessors.PayPalCommercePlatform.CreateVaultSetupToken.Call({
                                    Parameter1: (this.mode === PaymentSourceMode.AnonymousPayment || this.context === PaymentContext.SignupWidget) ? this.schoolID! : '',
                                    Parameter2: this.paymentProcessor.ID!,
                                    Body: new PayPalCommercePlatformCreateVaultSetupTokenInputItem({
                                        PostalCode: formValues.PostalCode,
                                        CountryCode: formValues.Country!.Code,
                                        ProvStateCode: formValues.State!.Code,
                                        ReturnURL: window.location.href
                                    })
                                }));
                            },

                        
                        onApprove: async (data: any) => {
                            this._NgZone.run(() => {
                                if (data.liabilityShift === 'NO' || data.liabilityShift === 'UNKNOWN') {
                                    this.ShowErrorDialog(this._defaultCardErrorDialogText);
                                } else {
                                    this.ProcessNewPaymentSource(data.vaultSetupToken);
                                }
                            });
                        },

                        onError: (error: any) => {
                            if (!this._DialogService.dialogReference) {
                                this._NgZone.run(() => {
                                    this.ShowErrorDialog(this._defaultCardErrorDialogText);
                                });
                            }
                        }
                    });

                    if (this._cardFields.isEligible()) {
                        const numberField = this._cardFields.NumberField({
                            inputEvents: {
                                onChange: (data: any) => this._NgZone.run(() => this.UpdateCardFieldValidity(data.fields.cardNumberField, this.form.controls.CardNumber, false)),
                                onBlur: (data: any) => this._NgZone.run(() => this.UpdateCardFieldValidity(data.fields.cardNumberField, this.form.controls.CardNumber, true)),
                                onFocus: (_: any) => {},
                                onInputSubmitRequest: (_: any) => {}
                            }
                        });

                        const expiryField = this._cardFields.ExpiryField({
                            inputEvents: {
                                onChange: (data: any) => this._NgZone.run(() => this.UpdateCardFieldValidity(data.fields.cardExpiryField, this.form.controls.ExpiryDate, false)),
                                onBlur: (data: any) => this._NgZone.run(() => this.UpdateCardFieldValidity(data.fields.cardExpiryField, this.form.controls.ExpiryDate, true)),
                                onFocus: (_: any) => {},
                                onInputSubmitRequest: (_: any) => {}
                            }
                        });

                        const cvvField = this._cardFields.CVVField({
                            inputEvents: {
                                onChange: (data: any) => this._NgZone.run(() => this.UpdateCardFieldValidity(data.fields.cardCvvField, this.form.controls.CVV, false)),
                                onBlur: (data: any) => this._NgZone.run(() => this.UpdateCardFieldValidity(data.fields.cardCvvField, this.form.controls.CVV, true)),
                                onFocus: (_: any) => {},
                                onInputSubmitRequest: (_: any) => {}
                            }
                        });

                        const nameField = this._cardFields.NameField({
                            inputEvents: {
                                onChange: (data: any) => this._NgZone.run(() => this.UpdateCardFieldValidity(data.fields.cardNameField, this.form.controls.CardHolderName, false)),
                                onBlur: (data: any) => this._NgZone.run(() => this.UpdateCardFieldValidity(data.fields.cardNameField, this.form.controls.CardHolderName, true)),
                                onFocus: (_: any) => {},
                                onInputSubmitRequest: (_: any) => {}
                            } 
                        });

                        numberField.setAttribute('placeholder', '4111 1111 1111 1111');
                        nameField.setAttribute('placeholder', '');

                        numberField.render('#card-number').then(() => this.FieldLoaded('number'));
                        expiryField.render('#expiry-date').then(() => this.FieldLoaded('expiry'));
                        cvvField.render('#cvv').then(() => this.FieldLoaded('cvv'));
                        nameField.render('#cardholder-name').then(() => this.FieldLoaded('name'));
                    } else {
                        throw Error('PayPal card fields could not be loaded');
                    }
                } else {
                    throw Error('PayPal card fields could not be loaded');
                }
            });
        });
    }

    private FieldLoaded(field: 'buttons' | 'number' | 'expiry' | 'cvv' | 'name') {
        switch (field) {
            case 'buttons': this._loadingPayPalButtons = false; break;
            case 'number': this._loadingCardNumberField = false; break;
            case 'expiry': this._loadingExpiryField = false; break;
            case 'cvv': this._loadingCVVField = false; break;
            default: this._loadingNameField = false; break;
        }

        if (!this._loadingPayPalButtons && !this._loadingCardNumberField && !this._loadingExpiryField && !this._loadingCVVField && !this._loadingNameField) {
            this.form.controls.SavePaymentSource.setValue(this.mode !== PaymentSourceMode.AnonymousPayment);
            setTimeout(() => this._NgZone.run(() => this._loading = false), 1000);
        }

    }

    private UpdateCardFieldValidity(field: any, formControl: FormControl<string>, blur: boolean) {
        if (blur) {
            formControl.markAsTouched();
        }
        if (!field.isEmpty) {
            if (!field.isValid) {
                formControl.SetApiError();
            } else {
                formControl.setErrors(null);
            }
        } else {
            formControl.setErrors({required: true});
        }
    }

    VaultPayPalCommercePlatform() {
        if (this.form.enabled) {
            if (this._cardFields && this.form.CheckValidity()) {
                this._cardFields.submit().catch((_: any) => this.UpdateCardFields());
            } else {
                this.UpdateCardFields();
            }
        }
    }

    private UpdateCardFields() {
        this._cardFields.getState().then((data: any) => {
            this._NgZone.run(() => {
                this.UpdateCardFieldValidity(data.fields.cardNumberField, this.form.controls.CardNumber, true);
                this.UpdateCardFieldValidity(data.fields.cardExpiryField, this.form.controls.ExpiryDate, true);
                this.UpdateCardFieldValidity(data.fields.cardCvvField, this.form.controls.CVV, true);
                this.UpdateCardFieldValidity(data.fields.cardNameField, this.form.controls.CardHolderName, true);

                if (this.context == PaymentContext.SignupWidget) {
                    this.signupFormPaymentSourceReady.emit(null);
                } else {
                    this.paymentSourceProcessed.emit(null);
                }
            });
        });
    }

    private ProcessNewPaymentSource(vaultSetupToken?: string) {
        const inputItem = new PayPalCommercePlatformPaymentSourceInputItem({
            ParentID: this.parentID,
            FamilyID: this.familyID,
            PaymentProcessorID: this.paymentProcessor.ID,
            VaultSetupToken: vaultSetupToken,
            OrderID: this._orderID
        });

        switch (this.mode) {
            case PaymentSourceMode.Add:
                inputItem.Intent = PaymentSourceIntent.Vault;
                inputItem.OrderID = this.isDebug ? this.form.controls.PostalCode.value : null;
                break;
            case PaymentSourceMode.Payment:
            case PaymentSourceMode.AnonymousPayment:
                inputItem.Intent = (this.form.controls.SavePaymentSource.value) ? PaymentSourceIntent.PayAndVault : PaymentSourceIntent.Pay;       
                inputItem.Surcharge = this._onlineConvenienceFee ?? 0;
                inputItem.Total = this.totalAmountToPay;
                inputItem.VaultSetupToken = this.isDebug ? this.form.controls.PostalCode.value : null;
                break;
        }

        if (inputItem.Intent !== PaymentSourceIntent.Pay) {
            inputItem.AutoPay = this.form.controls.AutoPay.value;
        }

        this.Process(inputItem);
    }

    private Process(inputItem: PayPalCommercePlatformPaymentSourceInputItem): void {
        if (this.context == PaymentContext.SignupWidget) {
            this.signupFormPaymentSourceReady.emit(inputItem);
        } else {
            ApiBaseRoutes.PaymentSources.Add.Call({
                Parameter: this.mode === PaymentSourceMode.AnonymousPayment ? this.schoolID! : '',
                Body: inputItem
            }).subscribe({
                next: result => {
                    this.paymentSourceProcessed.emit(result);
                },
                error: (error: typeof ApiBaseRoutes.PaymentSources.Add.Error) => {
                    if (error.Response.NewErrorCode === ErrorCode.OnlinePaymentError) {
                        const message = $localize`:@@CommonAddPaymentSourcePaymentProcessorErrorDescription:The payment processor returned the following message:\n\n` + error.Response.ErrorMessage;
                        this.ShowErrorDialog(message);
                    } else {
                        throw error;
                    }
                }
            });
        }
    }

    private ShowErrorDialog(errorMessage: string) {
        this._DialogService.CreateInfoDialog(new SimpleDialogData({
            Title: $localize`:@@CommonAddPaymentSourcePaymentProcessorError:Payment Processor Error`,
            Text: errorMessage,
        }), false).events.subscribe(event => {
            if (event === DialogEvents.PrimaryAction) {
                this.form.enable();
                if (this.context == PaymentContext.SignupWidget) {
                    this.signupFormPaymentSourceReady.emit(null);
                } else {
                    this.paymentSourceProcessed.emit(null);
                }
            }
        }); 
    }

    private CreateOrder(paymentSourceType: PaymentSourceType): Observable<PayPalCommercePlatformOrderResultItem> {
        const formValues = this.form.value;

        const body = new PayPalCommercePlatformCreateOrderInputItem({
            Total: this.totalAmountToPay!,
            Surcharge: this._onlineConvenienceFee ?? 0,
            PaymentSourceType: paymentSourceType,
            FamilyID: this.familyID,
            ParentID: this.parentID,
            InvoiceDetailsID: this.invoiceDetailsGUID,
            Vault: formValues.SavePaymentSource,
            AutoPay: formValues.AutoPay
        });

        if (paymentSourceType === PaymentSourceType.CreditCard) {
            body.PostalCode = formValues.PostalCode!;
            body.CountryCode = formValues.Country!.Code;
            body.ProvStateCode = formValues.State!.Code;
        } else {
            body.ReturnURL = window.location.href;
            body.PostalCode = this.isDebug ? formValues.PostalCode! : null;  // Need to pass in postal code to interpret debugging codes
        }

        const observable = ApiBaseRoutes.PaymentProcessors.PayPalCommercePlatform.CreateOrder.Call({
            Parameter1: (this.mode === PaymentSourceMode.AnonymousPayment || this.context === PaymentContext.SignupWidget) ? this.schoolID! : '',
            Parameter2: this.paymentProcessor.ID!,
            Body: body
        });

        observable.subscribe({
            error: (error: typeof ApiBaseRoutes.PaymentProcessors.PayPalCommercePlatform.CreateOrder.Error) => {
                if (error.Response.NewErrorCode === ErrorCode.OnlinePaymentError) {
                    const message = $localize`:@@CommonAddPaymentSourcePaymentProcessorErrorDescription:The payment processor returned the following message:\n\n` + error.Response.ErrorMessage;
                    this.ShowErrorDialog(message);
                } else {
                    throw error;
                }
            }
        });

        return observable;
    }

    private GetCountries() {
        this.form.controls.Country.isLoading = true;

        ApiBaseRoutes.Countries.GetAll.Call().subscribe(countries => {
            this._countries = countries.map(country => new NameCodeWithID({...country, ID: country.Code!}));

            let defaultCountry: NameCodeWithID | undefined;

            defaultCountry = this._countries.find(country => country.Default);

            if (!defaultCountry) {
                defaultCountry = this._countries[0];
            }

            this.form.controls.Country.setValue(defaultCountry);
            this.form.controls.Country.isLoading = false;

            this.GetStates(defaultCountry);
        });
    }

    GetStates(country: NameCodePair) {
        this.form.controls.State.isLoading = true;

        ApiBaseRoutes.Countries.GetStates.Call({Parameter: country.Code!}).subscribe(states => {
            if (states && states.length > 0) {
                this._states = states.map(state => new NameCodeWithID({...state, ID: state.Code!}));

                const defaultState = this._states.find(state => state.Default);
                if (defaultState) {
                    this.form.controls.State.setValue(defaultState);
                } else {
                    this.form.controls.State.setValue(null);
                }
            } else {
                this._states = [this._defaultState];
                this.form.controls.State.setValue(this._defaultState);
            }

            this.form.controls.State.isLoading = false;
        });
    }

    PayInNewTab() {
        if (this.isDebug) {
            this.payPalCPButtonClicked.emit(true);
            this.form.disable();
            this.form.disable();

            this.CreateOrder(PaymentSourceType.PayPal).subscribe(result => {
                window.open(result.PayPalOrderID!, '_blank');
            });
        }
    }

    get loading(): boolean {
        return this._loading;
    }

    get countries(): NameCodeWithID[] {
        return this._countries;
    }

    get states(): NameCodeWithID[] {
        return this._states;
    }

    get paymentSourceType(): PaymentSourceType {
        return this._paymentSourceType;
    }

    get cvvTooltip(): string {
        return this._cvvTooltip;
    }

    get PaymentSourceType(): typeof PaymentSourceType {
        return PaymentSourceType;
    }

    get SurchargeType(): typeof SurchargeType {
        return SurchargeType;
    }

    get PaymentSourceMode(): typeof PaymentSourceMode {
        return PaymentSourceMode;
    }

    get PaymentContext(): typeof PaymentContext {
        return PaymentContext;
    }

    get FormContainerKind(): typeof FormContainerKind {
        return FormContainerKind;
    }

    get isDebug(): boolean {
        return (this._Environment.Application ?? (this._Environment as any).APPLICATION).ProductName === "Test443";
    }

    get showNewWindowOverlay(): boolean {
        return this.isDebug && this.form.controls.PostalCode.value.includes('NEWTAB');
    }
}
