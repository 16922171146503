import { Component, ContentChild, contentChild, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import { BaseViewComponent, SubmissionBarKind } from '@library/base';
import { ButtonComponent } from '@library/button';

@Component({
    selector: 'lib-submission-bar',
    templateUrl: './submission-bar.component.html',
    styleUrl: './submission-bar.component.scss'
})
export class SubmissionBarComponent extends BaseViewComponent {

    @ContentChildren(ButtonComponent) buttonList!: QueryList<ButtonComponent>;
    @ContentChild('customAction') customActionRef: TemplateRef<any> | undefined;

    @Input({required: true}) kind!: SubmissionBarKind;

    private _primaryButton: ButtonComponent | null = null;
    private _cancelButton: ButtonComponent | null = null;
    private _altActionButton: ButtonComponent | null = null;

    ngAfterViewInit(): void {
        this.UpdateButtonReferences(this.buttonList.toArray());
        this.buttonList.changes.subscribe((buttonsQueryList: QueryList<ButtonComponent>) => {
            this.UpdateButtonReferences(buttonsQueryList.toArray());
        });
    }
    private UpdateButtonReferences(buttons: ButtonComponent[]) {
        switch (this.kind) {
            case SubmissionBarKind.DrawerBasic:
            case SubmissionBarKind.DrawerAttendance:
            case SubmissionBarKind.DialogBasic:
            //case SubmissionBarKind.WidgetBasic:
            // case SubmissionBarKind.WidgetSecondary:
                this._primaryButton = buttons[0] ?? null;
                this._cancelButton = buttons[1] ?? null;
                this._altActionButton = buttons[2] ?? null;
                break;
            case SubmissionBarKind.DrawerWizard:
            // case SubmissionBarKind.WidgetStepper:
            // case SubmissionBarKind.WidgetTertiary:
                this._primaryButton = buttons[0] ?? null;
                this._cancelButton = buttons[2] ?? null;
                this._altActionButton = buttons[1] ?? null;
                break;
        }
    }

    ButtonClicked(button: ButtonComponent): void {
        button.clicked.emit();
    }

    get primaryButton(): ButtonComponent | null {
        return this._primaryButton;
    }

    get cancelButton(): ButtonComponent | null {
        return this._cancelButton;
    }

    get altActionButton(): ButtonComponent | null {
        return this._altActionButton;
    }

}
