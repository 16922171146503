import { Component, Input, OnInit } from '@angular/core';
import { SnackbarLayout } from '@library/base';

@Component({
    selector: 'lib-snackbar-layout',
    templateUrl: './snackbar-layout.component.html',
    styleUrls: ['./snackbar-layout.component.scss']
})
export class SnackbarLayoutComponent implements OnInit {

    @Input() layout: SnackbarLayout = SnackbarLayout.A;

    constructor() { }

    ngOnInit(): void {
    }

    get SnackbarLayout(): typeof SnackbarLayout {
        return SnackbarLayout;
    }

}
