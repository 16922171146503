import { CancellableObservable, DialogEvents, ProgressDialogData } from "@library/base";
import { Observable, ObservableInputTuple, Subject, catchError, forkJoin, merge, race, timer } from "rxjs";
import { AnyCatcher } from "rxjs/internal/AnyCatcher";
import { DialogService } from "./dialog.service";
import { DialogProgressComponent } from "./progress/dialog-progress.component";

// Basic controls for dialog
const openAfterMs = 2500;     // Open the progress dialog after this many milliseconds have passed
const stayOpenForMs = 2500;   // Once the dialog is open, do not close it until at least this many milliseconds have passed


/** @internal */
export var _batchJoin: { DialogService?: DialogService } = {};

// NOTE: The following signatures are taken directly from the forkJoin implementation, and allow us to better match our operator typing to
// that of forkJoin, which we are simulating. Our own code starts on line 35.

// ------------------------------------------------ FROM FORKJOIN IMPLEMENTATION -------------------------------------------------------------

// batchJoin(any)
// We put this first because we need to catch cases where the user has supplied
// _exactly `any`_ as the argument. Since `any` literally matches _anything_,
// we don't want it to randomly hit one of the other type signatures below,
// as we have no idea at build-time what type we should be returning when given an any.

export function batchJoin<T extends AnyCatcher>(arg: T): Observable<unknown>;

// batchJoin(null | undefined)
export function batchJoin(scheduler: null | undefined): Observable<never>;

// batchJoin([a, b, c])
export function batchJoin(sources: readonly []): Observable<never>;
export function batchJoin<A extends readonly unknown[]>(sources: readonly [...ObservableInputTuple<A>], errorHandler?: (x: any) => Observable<any>): Observable<A>;

// -------------------------------------------------------------------------------------------------------------------------------------------


export function batchJoin(...args: any[]): Observable<any> {
    if (!_batchJoin.DialogService) {
        throw Error('DialogService must be injected before calling batchJoin');
    }

    let observables = args[0] as Observable<any>[];
    const errorHandler = args[1] as ((x: any) => Observable<any>) | undefined;

    const cancelObserver = new Subject<void>();
    const observablesWithCancel = observables.map(obs => race(cancelObserver, errorHandler ? obs.pipe(catchError(errorHandler)) : obs));

    const progressSubject = new Subject<number>();

    const totalCount = observables.length;
    let completedCount = 0;
    let dialogComponent: DialogProgressComponent | undefined;

    const dialogData = new ProgressDialogData({
        ProgressObservable: progressSubject
    });

    const delayedDismiss = () => timer(500).subscribe(_ => dialogComponent?.DismissAction());
    const dialogDismissSubscription = forkJoin([timer(openAfterMs + stayOpenForMs), ...observablesWithCancel]).subscribe({
        next: delayedDismiss,
        error: delayedDismiss
    });

    // Make sure dialog doesn't insta-open 
    let dialogOpenSubscription = timer(openAfterMs).subscribe(() => {
        dialogComponent = _batchJoin.DialogService!.CreateProgressDialog(dialogData);
        dialogComponent.events.subscribe(event => {
            if (event === DialogEvents.PrimaryAction) {
                cancelObserver.error({
                    ErrorCode: 'Cancelled',
                    Message: 'User cancelled batch operation'
                });
            } else if (event === DialogEvents.Dismissed) {
                cancelObserver.complete();
            }
        });

        progressSubject.next((completedCount / totalCount) * 100);
    });

    const onComplete = () => {
        progressSubject.complete();
        if (!dialogComponent) {
            dialogOpenSubscription.unsubscribe();
            dialogDismissSubscription.unsubscribe();
        }
    }

    merge(...observablesWithCancel).subscribe({
        next: _ => {
            completedCount++;
            progressSubject.next((completedCount / totalCount) * 100);
        },
        error: _ => {
            observables.forEach(observable => {
                if (IsCancellable(observable)) {
                    observable.Cancel();
                }
            });
            onComplete();
        },
        complete: onComplete
    });

    return forkJoin(observablesWithCancel);
}

function IsCancellable(observable: Observable<any>): observable is CancellableObservable<any> {
    return 'Cancel' in observable;
}