import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IconItem } from '@library/base';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
    template: '',
})
export class FormFieldInputBaseComponent<T> extends FormFieldBaseComponent<T, FormControl<string>> implements OnInit {

    private _isOptionalOverride: boolean | undefined = undefined;

    @Input()
    get isOptional(): boolean {
        return this._isOptionalOverride != undefined ? this._isOptionalOverride : !this.formControl.isRequired;
    }
    set isOptional(value: boolean) {
        this._isOptionalOverride = value;
    }

    @Input() prefixIcon!: IconItem;
    @Input() suffixIcon!: IconItem;
    @Input() autocorrect: boolean = false;
    @Input() maxCharacterLength: number = 255;
    @Input() placeholderText: string = '';

    constructor() {
        super();
        this.viewControl = new FormControl("", {nonNullable: true});
    }
}
