import { AfterViewInit, Component, EventEmitter, HostListener, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorMessages, FormBase, number, Required, Validators } from '@library/base';
import { FormFieldBaseComponent, FormFieldNumberComponent } from '@library/form-field';

class MFAForm extends FormBase {
    Digit1 = Required(number);
    Digit2 = Required(number);
    Digit3 = Required(number);
    Digit4 = Required(number);
    Digit5 = Required(number);
    Digit6 = Required(number);
}

@Component({
    selector: 'lib-mfa-input',
    templateUrl: './multi-factor-auth-mfa-input.component.html',
    styleUrls: ['./multi-factor-auth-mfa-input.component.scss']
})
export class MultiFactorAuthInputComponent extends FormFieldBaseComponent<string | null> {
    @ViewChildren(FormFieldNumberComponent) digitInputs!: QueryList<FormFieldNumberComponent>;
    @Output() containerClicked: EventEmitter<MouseEvent> = new EventEmitter();
    
    readonly mfsForm = MFAForm.Create();
    
    readonly mfaErrors: ErrorMessages = {
        required: _ => $localize`:@@CommonMFAInputLengthErrorMessage:Please enter a 6-digit code.`,
        exactlength: _ => $localize`:@@CommonMFAInputLengthErrorMessage:Please enter a 6-digit code.`,
        api: _ => $localize`:@@CommonMFAAuthenticationErrorMessage:The authentication code you entered is invalid. Please try again.`
    }

    private _currentIndex: number | null = null;

    constructor() {
        super();
        this.viewControl = new FormControl("", {nonNullable: true}); //Need this otherwise errors out when  ObservePristine()
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this.errorMessages = this.mfaErrors;
        this.formControl.setValidators([Validators.required, Validators.exactLength(6)]);
    }

    override OnBlur(): void {
        this._currentIndex = null;
        super.OnBlur(); //we dont want to emit any blur events from any of the 6 inputs
    }

    Blur(): void {
        this.WriteCodeToFormField();

        // To discuss, on blur, check if any of the other inputs have focus
        // if one does, then nothing
        // otherwise, emit the OnBlur event because we have clicked away from mfa input!
        // console.log("has focus test:");
        // console.log(this.digitInputs.get(0)?.HasFocus());
        // console.log(this.digitInputs.get(1)?.HasFocus());
    }

    override setDisabledState(state: boolean): void {
        if (state) {
            this.mfsForm.disable();
            this.mfsForm.disable();
        } else {
            this.mfsForm.enable();
        }
    }   

    WriteCodeToFormField(): void {
        let str = "";
        Object.values(this.mfsForm.value).filter(value => value !== null).forEach(value => str += value!.toString());

        this.formControl.setValue(str);
    }

    Click(control: FormControl<number | null>): void {
        this._currentIndex = Object.values(this.mfsForm.controls).indexOf(control);
        control.reset();
    }

    get hasError(): boolean {
        return this.formControl.enabled && !this.formControl.isReadOnly && !this.formControl.isLoading && this.formControl.touched && this.formControl.errors !== null && !this.hideErrors;
    }

    //Trying to get auto-advance working
    @HostListener('input', ['$event'])
    onInput(event: InputEvent) {
        if(event.data) {
            let trimmed = event.data.replace(/[^\d]/g, '');
            if(
                this.digitInputs && 
                this.digitInputs.length === 6 && 
                trimmed.length > 0 &&
                //this._currentIndex && THIS LINE ACTUALLY MEANS FALSE IF value is 0!!!!
                this._currentIndex !== null && // we need to check null explicitly
                this._currentIndex! < 5 && 
                this._currentIndex! >= 0) {
                //need to do this because blur clears the index, and then we need to advance it and set it.            
                this.digitInputs.get(this._currentIndex! + 1)?.SetFocus();
                this._currentIndex!++;
            }
        }
    }

    //Pasting a code from an authenticator app
    @HostListener('paste', ['$event'])
    onPaste(event: any) {
        let paste: string = (event.clipboardData || window.Clipboard).getData("text");
        
        //make only digits, then fill in up to the first 6 digits into our form field.
        let trimmed = paste?.replace(/[^\d]/g, '');
        if (trimmed?.length > 0) {
            const controls = Object.values(this.mfsForm.controls);
            for (let i = 0; i < trimmed.length && i < 6; ++i) {
                controls[i].setValue(parseInt(trimmed[i]));
            }
        }
    }
}
