import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ItemWithID, MenuDivider } from '@library/base';

@Component({
    selector: 'lib-input-select-item',
    templateUrl: './form-field-select-item.component.html',
})
export class FormFieldSelectItemComponent<T extends ItemWithID | string> {

    @Input() value!: T;
    @Input() hintMessage: string = "";
    @Input() menuDivider: MenuDivider = MenuDivider.None;

    @ViewChild('content', {static: true, read: TemplateRef}) content!: TemplateRef<any>;

    group: IItemGroup | null = null;
}

export interface IItemGroup {
    name: string;
}