import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { ObserversModule } from '@angular/cdk/observers';

import { BaseModule } from '@library/base';
import { ButtonModule } from '@library/button';
import { IconModule } from '@library/icon';
import { BannerComponent } from './banner.component';

@NgModule({
    declarations: [
        BannerComponent
    ],
    imports: [
        BaseModule,
        CommonModule,
        IconModule,
        ButtonModule,
        MatExpansionModule,
        ObserversModule
    ],
    exports: [
        BannerComponent
    ]
})
export class BannerModule { }
