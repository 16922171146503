import { Component, OnInit } from '@angular/core';

import { IconItem } from '@library/base';
import { FormFieldInputBaseComponent } from '../form-field-input-base.component';

@Component({
    selector: 'lib-input-secret',
    templateUrl: './form-field-password.component.html',
    styleUrls: ['./form-field-password.component.scss']
})
export class FormFieldSecretComponent extends FormFieldInputBaseComponent<string> implements OnInit {

    private _suffixViewIcon: IconItem = this.Icon.Common.VisibilityOutline;
    private _inputType: string = 'password';

    constructor() {
        super();
        this.viewControl.valueChanges.subscribe(value => {
            this.SetFormControlValue(value);
        })
    }

    ToggleView(): void {
        if(this._suffixViewIcon === this.Icon.Common.VisibilityOutline){
            this._suffixViewIcon = this.Icon.Common.VisibilityOffOutline;
            this._inputType = 'text';
        } else {
            this._suffixViewIcon = this.Icon.Common.VisibilityOutline;
            this._inputType = 'password';
        }
    }

    get suffixViewIcon(): IconItem {
        return this._suffixViewIcon;
    }

    get inputType(): string {
        return this._inputType;
    }
}

