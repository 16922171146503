import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { Orientation } from '@library/base';
import { FormFieldBaseComponent } from '../form-field-base.component';
import { FormFieldCheckboxGroupItemComponent } from './form-field-checkbox-group-item.component';

@Component({
    selector: 'lib-checkbox-group',
    templateUrl: './form-field-checkbox-group.component.html',
    styleUrls: ['./form-field-checkbox-group.component.scss'],
})
export class FormFieldCheckboxGroupComponent<T extends keyof any> extends FormFieldBaseComponent<T[], FormArray<FormControl<boolean>>> implements AfterContentInit {

    @Input() orientation: Orientation = Orientation.Vertical;
    
    @ContentChildren(FormFieldCheckboxGroupItemComponent) itemQueryList!: QueryList<FormFieldCheckboxGroupItemComponent<T>>;

    private _indexMap: Record<number, T> = {};
    private _recordMap: Record<T, number> = {} as any;
    private _disabledOverrideMap: Record<number, boolean> = {};


    constructor() {
        super();
        
        this.viewControl = new FormArray<FormControl<boolean>>([]);
    }

    ngAfterContentInit(): void {
        this.itemQueryList!.forEach((item, index) => {
            this.viewControl.Add(this.formControl.value?.includes(item.value));
            // This approach does not allow for individual checkboxes to be disabled/reenabled dynamically. If this functionality
            // is needed at some point, revisit.
            if (item.disabled) {
                this.viewControl.controls[index].disable();
                this._disabledOverrideMap[index] = true;
            } else {
                this._disabledOverrideMap[index] = false;
            }

            this._indexMap[index] = item.value;
            this._recordMap[item.value] = index;
        });
    }

    override writeValue(keys: T[]): void {
        const allKeys = Object.keys(this._recordMap) as T[];
        allKeys.forEach(key => {
            this.viewControl.controls[this._recordMap[key]].setValue(keys?.includes(key));
        });
    }

    override setDisabledState(state: boolean): void {
        Object.values(this.viewControl.controls).forEach((control, index) => {
            if (!this._disabledOverrideMap[index]) {
                if (state) {
                    control.disable();
                } else {
                    control.enable();
                }
            }
            
        });
        
    }

    override ObservePristine(): void {
        // This is done below in ChangeEvent instead
    }

    public ChangeEvent() {
        let result: T[] = [];
        
        this.viewControl.controls.forEach((control, valueIndex) => {
            if (control.value) {
                result.push(this._indexMap[valueIndex]);
            }
        })
        this.formControl.setValue(result);
        this.SetFormControlValue(result);

        this.formControl.isDirty = true;
        super.OnBlur();
    }

    public get readOnlyText(): string {
        if (this.formControl.value && this.formControl.value.length) {
            return this.formControl.value.map(item => item.toString()).join(', ');
        } else {
            return this.notSpecifiedString;
        }
    }

    public get selectedItemComponents(): FormFieldCheckboxGroupItemComponent<T>[] {
        return this.itemQueryList.filter(item => this.formControl.value.includes(item.value));
    }
}
