import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { AnnouncementModule } from '@library/announcement';
import { ButtonModule } from '@library/button';
import { IconModule } from '@library/icon';
import { BaseModule } from '@library/base';
import { ApplicationLayoutContainerComponent } from './application-layout-container/application-layout-container.component';
import { DetailViewLayoutComponent } from './detail-view/detail-view-layout.component';
import { DialogLayoutComponent } from './dialog/dialog-layout.component';
import { HeaderComponent } from './header/header.component';
import { LayoutDrawerModePipe } from './layout-drawer-mode.pipe';
import { LayoutRowComponent } from './row/layout-row.component';
import { SideBarItemComponent } from './sidebar/sidebar-item/sidebar-item.component';
import { SideBarLogoComponent } from './sidebar/sidebar-logo/sidebar-logo.component';
import { SideBarComponent } from './sidebar/sidebar.component';
import { SnackbarLayoutComponent } from './snackbar/snackbar-layout.component';
import { DrawerModule } from '@library/drawer';
import { PortalModule } from '@angular/cdk/portal';
import { DrawerLayoutComponent } from './drawer/drawer-layout.component';


@NgModule({
    declarations: [
        HeaderComponent,
        ApplicationLayoutContainerComponent,
        SideBarComponent,
        SideBarItemComponent,
        SideBarLogoComponent,
        LayoutRowComponent,
        DialogLayoutComponent,
        DetailViewLayoutComponent,
        SnackbarLayoutComponent,
        LayoutDrawerModePipe,
        DrawerLayoutComponent
    ],
    imports: [
        BaseModule,
        CommonModule,
        AnnouncementModule,
        MatSidenavModule,
        MatListModule,
        MatDividerModule,
        IconModule,
        RouterModule,
        MatDialogModule,
        MatDividerModule,
        ButtonModule,
        DrawerModule,
        PortalModule
 
    ],
    exports: [
        ApplicationLayoutContainerComponent,
        SideBarItemComponent,
        SideBarLogoComponent,
        LayoutRowComponent,
        DialogLayoutComponent,
        DetailViewLayoutComponent,
        SnackbarLayoutComponent,
        DrawerLayoutComponent
    ]
})
export class LayoutModule { }
