import {Component, Input } from '@angular/core';
import { DesignBlockImageBaseViewComponent } from '@library/base';
import { WebsiteBlockTextAndMediaType } from '@library/data-models';

@Component({
  selector: 'lib-hero-design-block',
  templateUrl: './hero-design-block.component.html',
  styleUrls: ['./hero-design-block.component.scss']
})
export class HeroDesignBlockComponent extends DesignBlockImageBaseViewComponent {

  @Input() hasHeader: boolean = true;
  @Input() hasBody: boolean = true;
  @Input() type: WebsiteBlockTextAndMediaType = WebsiteBlockTextAndMediaType.Default;
  @Input() disableImageDetails: boolean = false;
}
