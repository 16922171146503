import { Component, EventEmitter, Output } from "@angular/core";
import { DesignBlockBaseViewComponent } from "./design-block-base-view.component";
import { WebsiteGalleryItemAction } from "../definitions/website.definitions";
import { WebsiteMode } from "../definitions/design-blocks.definitions";

@Component({
    template: '',
})
export abstract class DesignBlockImageBaseViewComponent extends DesignBlockBaseViewComponent {
    @Output() imageActionClicked: EventEmitter<WebsiteGalleryItemAction> = new EventEmitter();

    private _showGeneralImageActionMenu: boolean = false;

    MouseEnterGeneralImage(): void {
        this._showGeneralImageActionMenu = true;
    }

    MouseLeaveGeneralImage(): void {
        this._showGeneralImageActionMenu = false;
    }

    ImageActionClicked(action: WebsiteGalleryItemAction): void {
        this.imageActionClicked.emit(action);
    }

    get showGeneralImageActionMenu(): boolean {
        return this.websiteMode == WebsiteMode.Preview && this._showGeneralImageActionMenu;
    }
}