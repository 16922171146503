import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { EditableCardComponent } from './editable-card/editable-card.component';
import { ButtonModule } from '@library/button';
import { LayoutModule } from '@library/layout';
import { MatDividerModule } from '@angular/material/divider';
import { DataCardComponent } from './data-card/data-card.component';

@NgModule({
    declarations: [
        CardComponent,
        EditableCardComponent,
        DataCardComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        LayoutModule,
        MatDividerModule
    ],
    exports: [
        CardComponent,
        EditableCardComponent,
        DataCardComponent
    ]
})
export class CardModule { }
