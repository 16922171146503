
import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationBaseService } from "./authentication-base.service";
import { Environment } from "@library/base";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    constructor(
        private _AuthenticationService: AuthenticationBaseService,
        private _Environment: Environment
    ) {}

    intercept<T>(req: HttpRequest<T>, next: HttpHandler) {
        let authReq = req.clone({
            url: req.url,
            headers: this.primaryHeaders
        });

        return next.handle(authReq);
    }

    private get primaryHeaders(): HttpHeaders {
        if (this._AuthenticationService.isAuthTokenAvailable) {
            return new HttpHeaders({
                'X-SchoolBox-Version': this._Environment.Version,
                Authorization: 'Bearer ' + this._AuthenticationService.authJWT
            });
        } else {
            return new HttpHeaders({
                'X-SchoolBox-Version': this._Environment.Version
            });
        }
    }
}