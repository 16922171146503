import { Component, OnInit } from '@angular/core';
import { SnackbarBaseViewComponent } from '@library/base';

@Component({
    selector: 'lib-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent extends SnackbarBaseViewComponent<string> implements OnInit {



}
