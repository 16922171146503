<div [ngSwitch]="websiteMode"
    [ngClass]="{
        'scss-website-background-color': !(kind === WebsiteBlockTextAndMediaType.AccentBackgroundDefault || kind === WebsiteBlockTextAndMediaType.AccentBackgroundTestimonial || kind === WebsiteBlockTextAndMediaType.MediaRightAccentBackgroundTestimonial),
        'scss-block-color-variable-font': kind === WebsiteBlockTextAndMediaType.AccentBackgroundDefault,
        'scss-block-background-color-variable': kind === WebsiteBlockTextAndMediaType.AccentBackgroundTestimonial || kind === WebsiteBlockTextAndMediaType.AccentBackgroundDefault || kind === WebsiteBlockTextAndMediaType.MediaRightAccentBackgroundTestimonial
        || kind === WebsiteBlockTextAndMediaType.GallerySquareColumn || kind === WebsiteBlockTextAndMediaType.GallerySquareCardColumn}">
    <ng-container *ngSwitchCase="WebsiteMode.Live">
        <div  class="d-flex justify-content-center position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.ReadOnly">
        <div  class="d-flex justify-content-center position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.Preview">
        <lib-design-blocks-action-menu
            [disableMoveUp]="disableMoveUp"
            [disableMoveDown]="disableMoveDown"
            (moveDownClicked)="MoveDownClicked()"
            (moveUpClicked)="MoveUpClicked()"
            (deleteClicked)="DeleteClicked()">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </lib-design-blocks-action-menu>
    </ng-container>
</div>

<ng-template #blockContent>
    <div class="w-100 scss-spacing-text-media-block align-items-center"
    [ngClass]="
    {'d-flex flex-sm-row flex-column scss-text-media-block-height': kind !== WebsiteBlockTextAndMediaType.Default,
    'flex-sm-row-reverse': kind | method: IsMediaRightBlock,
    'scss-spacing-text-media-block-media': kind === WebsiteBlockTextAndMediaType.MediaRight || kind === WebsiteBlockTextAndMediaType.MediaLeft || kind === WebsiteBlockTextAndMediaType.MediaLeftTwoColumn || kind === WebsiteBlockTextAndMediaType.MediaRightTwoColumn || (kind !== WebsiteBlockTextAndMediaType.Default && hasBody && hasHeader), 
    'scss-spacing-text-media-block-testimonial': (kind === WebsiteBlockTextAndMediaType.Testimonial || kind === WebsiteBlockTextAndMediaType.MediaRightTestimonial),
    'scss-spacing-text-media-block-accent-testimonial': (kind === WebsiteBlockTextAndMediaType.AccentBackgroundTestimonial || kind === WebsiteBlockTextAndMediaType.MediaRightAccentBackgroundTestimonial),
    'scss-spacing-text-media-block-media-shadow-left': kind === WebsiteBlockTextAndMediaType.MediaLeftShadow,
    'scss-spacing-text-media-block-media-shadow-right': kind === WebsiteBlockTextAndMediaType.MediaRightShadow,
    'scss-spacing-text-media-block-media-clipped-left': kind === WebsiteBlockTextAndMediaType.MediaLeftClipped,
    'scss-spacing-text-media-block-media-clipped-right': kind === WebsiteBlockTextAndMediaType.MediaRightClipped,
    'scss-spacing-text-media-block-accent': (kind === WebsiteBlockTextAndMediaType.AccentBackgroundDefault && hasBody)
    }">
        <div *ngIf="kind !== WebsiteBlockTextAndMediaType.Default && kind !== WebsiteBlockTextAndMediaType.AccentBackgroundDefault && kind !== WebsiteBlockTextAndMediaType.TwoColumn"
            [ngClass]="{'scss-image-clipped-grid': kind === WebsiteBlockTextAndMediaType.MediaLeftClipped || kind === WebsiteBlockTextAndMediaType.MediaRightClipped }">
            <div *ngIf="kind === WebsiteBlockTextAndMediaType.MediaLeftClipped || kind === WebsiteBlockTextAndMediaType.MediaRightClipped" class="scss-image-clipped-background-grid-item"></div>
            <div class="scss-image-clipped-image-grid-item"
                libScrollToView
                [currMode]="websiteMode"
                (mouseenter)="MouseEnterGeneralImage()"
                (mouseleave)="MouseLeaveGeneralImage()">

                <lib-image-action-menu-general 
                    [showActionMenu]="showGeneralImageActionMenu"
                    [disableImageDetails]="disableImageDetails"
                    (imageActionClicked)="ImageActionClicked($event)">
                </lib-image-action-menu-general>

                <ng-content select="[image]"></ng-content>
            </div>
        </div>

        <div class="d-flex flex-column w-100 h-100 justify-content-center" [ngClass]="{'scss-text-media-padding-top': kind !== WebsiteBlockTextAndMediaType.Default && kind !== WebsiteBlockTextAndMediaType.TwoColumn && kind !== WebsiteBlockTextAndMediaType.AccentBackgroundDefault && kind !== WebsiteBlockTextAndMediaType.Testimonial && kind !== WebsiteBlockTextAndMediaType.MediaRightTestimonial && kind !== WebsiteBlockTextAndMediaType.AccentBackgroundTestimonial && kind !== WebsiteBlockTextAndMediaType.MediaRightAccentBackgroundTestimonial}">
            <h2 *ngIf="hasHeader"
                [ngClass]="{'align-self-center text-center': (kind === WebsiteBlockTextAndMediaType.Default || kind === WebsiteBlockTextAndMediaType.AccentBackgroundDefault || kind === WebsiteBlockTextAndMediaType.TwoColumn) && hasBody,
                            'align-self-center heading-only-no-media': (kind === WebsiteBlockTextAndMediaType.Default || kind === WebsiteBlockTextAndMediaType.AccentBackgroundDefault) && !hasBody}">
                <ng-content select="[header]"></ng-content>
            </h2>
            <p *ngIf="hasBody" class="typography-text-media-body-font text-break"
                [ngClass]="{'two-column-body': kind === WebsiteBlockTextAndMediaType.TwoColumn || kind === WebsiteBlockTextAndMediaType.MediaRightTwoColumn || kind === WebsiteBlockTextAndMediaType.MediaLeftTwoColumn,
                'text-center': (kind === WebsiteBlockTextAndMediaType.Default || kind === WebsiteBlockTextAndMediaType.AccentBackgroundDefault) && hasHeader,
                'scss-header-body-spacing': hasBody && hasHeader}">
                <ng-content select="[body]"></ng-content>
            </p>
        </div>
    </div>

</ng-template>

