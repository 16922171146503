
export enum TextBlockElementKind {
    Header = "Header",
    Body = "Body",
    Image = "Image"
}

export enum WebsiteMode {
    Preview,
    Live,
    ReadOnly
}

