<div class="d-flex align-items-center" *ngIf="showFormFieldLabel">
    <ng-container *ngIf="allowMultiFileUpload">
        <lib-label
            [isOptional]="isOptional"><ng-container i18n="@@CommonAttachmentsLabel">Attachments</ng-container>
            <ng-content hintMessage select="[hintMessage]"></ng-content></lib-label>
    </ng-container>
    <ng-container *ngIf="!allowMultiFileUpload">
        <lib-label
            [isOptional]="isOptional">
            <ng-container i18n="@@CommonAttachmentSingleLabel">Attachments</ng-container>
            <ng-content hintMessage select="[hintMessage]"></ng-content></lib-label>
    </ng-container>
</div>

<ng-container *ngIf="!hasFiles || allowMultiFileUpload">
    <div class="d-flex flex-column justify-content-center align-items-center upload-attachment-border upload-attachment-container" *ngIf="showDragAndDrop">
        <input id="fileElem" #inputFormElement (clicked)="UploadFile(); $event.preventDefault; $event.stopPropagation();" multiple="multiple" accept="{{acceptedFormatText}}">
        <label class="w-100 d-flex flex-column justify-content-center align-items-center" for="fileElem">
            <div class="pt-1 pb-1">
                <lib-icon [icon]="Icon.Common.CloudUploadOutline"  class="base-100"></lib-icon>
            </div>
            <ng-container *ngIf="!isMobileOrTablet">
                <ng-container *ngIf="!allowMultiFileUpload">
                    <p class="typography-caption-large base-100" i18n="@@UploadComponentAddFileLabel">Drag and drop your file</p>
                </ng-container>
                <ng-container *ngIf="allowMultiFileUpload">
                    <p class="typography-caption-large base-100" i18n="@@UploadComponentAddFilesLabel">Drag and drop your file(s)</p>
                </ng-container>
                <caption class="pb-1" i18n="@@CommonOrText">or</caption>
            </ng-container>
            <ng-container *ngTemplateOutlet="uploadButton"></ng-container>
        </label>
    </div>
    
    <div *ngIf="!showDragAndDrop">
        <ng-container *ngTemplateOutlet="uploadButton"></ng-container>
    </div>    
</ng-container>

<p class="upload-attachment-section-header pt-1" *ngIf="hasFiles && showAttachmentDialogPreviewerTitle">{{attachmentDialogPreviewerTitle}}</p>
<div class="w-100 d-flex flex-row upload-attachment-preview-container gap-2">
    <div *ngFor="let file of files; let index=index;"
        class="upload-attachment-preview-file-container d-flex flex-column">
        <div [ngClass]="{'upload-attachment-preview-image-with-text': !IsFileImage(file), 'upload-attachment-preview-image-without-text': IsFileImage(file), 'd-flex':true, 'justify-content-center': true}">
            <div *ngIf="IsFileOther(file)" class="d-flex align-items-center">
                <lib-icon [icon]="Icon.Common.FileDescriptionOutline" [size]="IconSize.Large"></lib-icon>
            </div>
            <div *ngIf="IsFileImage(file)">
                <img *ngIf="filesSources[index]" class="h-100" [src]="filesSources[index]" style="object-fit: contain">
            </div>
            <div *ngIf="IsFileAudio(file)" class="d-flex align-items-center">
                <lib-icon [icon]="Icon.Common.AudioFileOutline" [size]="IconSize.Large"></lib-icon>
            </div>
            <div *ngIf="IsFileVideo(file)" class="d-flex align-items-center">
                <lib-icon [icon]="Icon.Common.VideoFileOutline" [size]="IconSize.Large"></lib-icon>
            </div>
            <div *ngIf="IsFileArchive(file)" class="d-flex align-items-center">
                <lib-icon [icon]="Icon.Common.FileArchived" [size]="IconSize.Large"></lib-icon>
            </div>
        </div>
        <p *ngIf="!IsFileImage(file)" class="upload-attachment-preview-file-container-text">
            {{file.name}}
        </p>
        <lib-button
            class="upload-attachment-preview-file-remove-button"
            [prefixIcon]="Icon.Common.CloseCircleOutline"
            [iconOnly]="true"
            [kind]="ButtonKind.Tertiary"
            (clicked)="RemoveFileAtIndex(index)">
        </lib-button>
    </div>
</div>

<ng-template #uploadButton>
    <lib-button class="pb-2"
        [kind]="ButtonKind.Primary"
        [size]="ButtonSize.Small"
        [prefixIcon]="Icon.Common.Upload"
        [disabled]="disableUploadButton"
        (clicked)="UploadFile()">
        <ng-container *ngIf="allowMultiFileUpload" label i18n="@@UploadComponentChooseFilesLabel">Choose file(s)</ng-container>
        <ng-container *ngIf="!allowMultiFileUpload" label i18n="@@UploadComponentChooseFileLabel">Choose a file</ng-container>
    </lib-button>
</ng-template>

<lib-error *ngIf="control && !hideInlineErrors"
    [control]="control"
    [errors]="errors">
</lib-error>



