<div [ngClass]="{
        'scss-website-background-and-text-color': kind !== WebsiteBlockContactFormType.AccentBackgroundDefault,
        'scss-block-color-variable-font': false && kind === WebsiteBlockContactFormType.AccentBackgroundDefault,
        'scss-block-background-color-variable': kind === WebsiteBlockContactFormType.AccentBackgroundDefault}">
    <ng-container *ngIf="websiteMode === WebsiteMode.ReadOnly">
        <div  class="d-flex position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="websiteMode === WebsiteMode.Live && renderContext.RenderMode === WebsiteRenderMode.Website">
        <div class="widget-block-live">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="websiteMode === WebsiteMode.Live && renderContext.RenderMode === WebsiteRenderMode.Widget">
        <div class="d-flex flex-column w-100 h-100 justify-content-center widget-block-live">
            <ng-container *ngTemplateOutlet="widgetBlockContent"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="websiteMode === WebsiteMode.Preview">
        <lib-design-blocks-action-menu
            [disableMoveUp]="disableMoveUp"
            [disableMoveDown]="disableMoveDown"
            [showEdit]="true"
            (moveDownClicked)="MoveDownClicked()"
            (moveUpClicked)="MoveUpClicked()"
            (deleteClicked)="DeleteClicked()"
            (editClicked)="EditClicked()">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </lib-design-blocks-action-menu>
    </ng-container>
</div>

<ng-template #blockContent>
    <div class="d-flex flex-row w-100 justify-content-center py-5 widget-block">
        <div class="scss-spacing-widget-block align-items-center"
            [ngClass]="{
            'd-flex flex-sm-row flex-column scss-widget-block-height': kind !== WebsiteBlockContactFormType.Default,
            'scss-spacing-widget-block-no-media background-base-0': kind !== WebsiteBlockContactFormType.MediaLeft && kind !== WebsiteBlockContactFormType.MediaRight,
            'scss-spacing-widget-block-media': kind === WebsiteBlockContactFormType.MediaLeft || kind === WebsiteBlockContactFormType.MediaRight,
            'scss-spacing-widget-block-accent': kind === WebsiteBlockContactFormType.AccentBackgroundDefault,
            'p-3 border shadow-sm': kind !== WebsiteBlockContactFormType.MediaLeft && kind !== WebsiteBlockContactFormType.MediaRight,
            'scss-sizing-widget-block-desktop': (kind !== WebsiteBlockContactFormType.MediaLeft && kind !== WebsiteBlockContactFormType.MediaRight) && !isMobile,
            'mx-3': isMobile,
            'scss-spacing-widget-block-top-border': kind === WebsiteBlockContactFormType.Default
            }">

            <div class="d-flex flex-column w-100 h-100 justify-content-center"
                [ngClass]="{
                    'scss-website-background-text-color-only': kind === WebsiteBlockContactFormType.MediaLeft || kind === WebsiteBlockContactFormType.MediaRight,
                    'base-300': kind !== WebsiteBlockContactFormType.MediaLeft && kind !== WebsiteBlockContactFormType.MediaRight
                }">
                <ng-container *ngTemplateOutlet="contactFormContent"></ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #contactFormContent>
    <h1 class="mb-2 align-self-center text-break">
        <ng-content select="[header]"></ng-content>
    </h1>

    <p class="mb-2 align-self-center" [ngClass]="{'mx-4': isMobileOrTablet && !inSidebar}">
        <ng-content select="[body]"></ng-content>
    </p>

    <div class="d-flex w-100 h-100 justify-content-center gap-3 scss-widget-block-content-container"
        [ngClass]="{
            'flex-row ': (!isMobileOrTablet || inSidebar) && kind !== WebsiteBlockContactFormType.MediaRight,
            'flex-column align-items-center': isMobileOrTablet && !inSidebar,
            'flex-row-reverse ': (!isMobileOrTablet || inSidebar) && kind === WebsiteBlockContactFormType.MediaRight

        }">

        <div *ngIf="kind === WebsiteBlockContactFormType.MediaLeft || kind === WebsiteBlockContactFormType.MediaRight"
            class="shadow-sm widget-media-image"
            [ngClass]="{
                'scss-sizing-widget-block-desktop-media': !isMobile,
                'w-100': isMobile
            }"
            libScrollToView
            [currMode]="websiteMode"
            (mouseenter)="MouseEnterGeneralImage()"
            (mouseleave)="MouseLeaveGeneralImage()">

            <lib-image-action-menu-general 
                [showActionMenu]="showGeneralImageActionMenu"
                [disableImageDetails]="disableImageDetails"
                (imageActionClicked)="ImageActionClicked($event)">
            </lib-image-action-menu-general>

            <ng-content select="[image]"></ng-content>
        </div>

        <ng-container *ngIf="websiteMode !== WebsiteMode.Live">
            <div class="d-flex flex-column w-100 h-100 justify-content-center background-base-0 base-300 widget-form"
                [ngClass]="{
                    'p-3 border shadow-sm': kind === WebsiteBlockContactFormType.MediaLeft || kind === WebsiteBlockContactFormType.MediaRight,
                    'w-100': kind !== WebsiteBlockContactFormType.MediaLeft && kind !== WebsiteBlockContactFormType.MediaRight,
                    'scss-sizing-widget-block-desktop-media': (kind === WebsiteBlockContactFormType.MediaLeft || kind === WebsiteBlockContactFormType.MediaRight) && !isMobile
                }">

                <ng-container *ngTemplateOutlet="contactFormFormContent"></ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="websiteMode === WebsiteMode.Live">
            <div class="d-flex flex-column w-100 h-100 justify-content-center background-base-0 base-300"
                [ngClass]="{
                    'p-3 border shadow-sm': kind === WebsiteBlockContactFormType.MediaLeft || kind === WebsiteBlockContactFormType.MediaRight,
                    'w-100': kind !== WebsiteBlockContactFormType.MediaLeft && kind !== WebsiteBlockContactFormType.MediaRight,
                    'scss-sizing-widget-block-desktop-media': (kind === WebsiteBlockContactFormType.MediaLeft || kind === WebsiteBlockContactFormType.MediaRight) && !isMobile
                }">

                <!-- WB-661 -->
                <iframe #iframe style="min-height: 395px; transition: height .1s" class="w-100" frameBorder="0" scrolling="no" [src]="data.Url! | trustUrl" (load)="Load(iframe)"></iframe>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #widgetBlockContent>
    <div class="d-flex w-100 h-100 justify-content-center widget-block-live">
        <div class="d-flex flex-column w-100 h-100 justify-content-center background-base-0 base-300 widget-form p-3">
            <ng-container *ngTemplateOutlet="contactFormFormContent"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #contactFormFormContent>
    <form *ngIf="!showThankYouMessage" [formGroup]="form"
        (dblclick)="EditClicked()">
        <lib-contact-form-custom-fields *ngIf="data"
            [fields]="data.CustomFields"
            [form]="form"
            [isViewOnly]="isViewOnly">
        </lib-contact-form-custom-fields>

        <!-- On Recaptcha Error, display message: Reload the page, or wait 2 minutes. -->
        <lib-error
            [control]="recaptchaErrorForm.controls.Captcha"
            [errors]="captchaErrors">
        </lib-error>

        <lib-error
            [control]="recaptchaNeededErrorForm.controls.CaptchaNeeded"
            [errors]="captchaNeededErrors">
        </lib-error>

        <!-- TODO: according to docs, we _should_ be able to use a formcontrol here but it doesnt seem to work with the invisible captcha, should spend more time -->
        <re-captcha *ngIf="websiteMode === WebsiteMode.Live"
            size="invisible"
            errorMode="handled"
            (resolved)="HandleCAPTCHA($event)" 
            (errored)="HandleCAPTCHAError($event)">
        </re-captcha>

        <div class="pt-2" [ngClass]="{
                'd-flex flex-row w-100 justify-content-center': !isMobile,
                'single-column-row-website-block': isMobile
            }">
            <lib-button
                class="scss-widget-primary-btn-accent-color"
                [stretch]="isMobile"
                [loading]="sendingMessage && !reCAPTCHAReady"
                [disabled]="sendingMessage"
                [isViewOnly]="isViewOnly"
                (clicked)="SendMessageButton()">
                <ng-container label i18n="@@CommonSubmitButton">Submit</ng-container>
            </lib-button>
        </div>

        <div class="pt-2" [ngClass]="{
            'd-flex flex-row w-100 justify-content-center': !isMobile,
            'single-column-row-website-block': isMobile
            }">
            <p *ngIf="websiteMode === WebsiteMode.Live" class="typography-body-small pt-2">
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </p>
        </div>     
    </form>

    <lib-widget-thank-you *ngIf="showThankYouMessage" [thankYouMessage]="data.ThankYouMessage"></lib-widget-thank-you>
</ng-template>
