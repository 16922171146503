<ng-container *ngIf="kind !== ButtonKind.Link && !urlLink">
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
</ng-container>


<a *ngIf="kind === ButtonKind.Link && !urlLink"
    role="button"
    class="border-0"
    [ngClass]="buttonClass"
    [attr.data-sb-qa]="qaTag"
    [matMenuTriggerFor]="menuTrigger"
    #optionsMenuTrigger = "matMenuTrigger"
    (menuClosed)="MenuClosed()"
    (click)="ButtonClicked($event, optionsMenuTrigger)">
    <div [ngClass]="buttonClass">
        <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    </div>
    
</a>

<a *ngIf="kind !== ButtonKind.Link && urlLink"
    [href]="urlLink | trustUrl"
    [attr.data-sb-qa]="qaTag"
    routerLinkActive="active"
    target="_blank">
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
</a>

<a *ngIf="kind === ButtonKind.Link && urlLink"
    role="button"
    class="border-0"
    [ngClass]="buttonClass"
    [attr.data-sb-qa]="qaTag"
    (click)="ButtonClicked($event)"
    [href]="urlLink | trustUrl"
    routerLinkActive="active"
    target="_blank">
    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
</a>

<ng-template #labelTemplate>
    <div class="d-flex justify-content-center align-items-center text-start user-select-auto"
        [ngClass]="{'gap-space-2': !iconOnly && size === ButtonSize.Small, 'gap-space-0': !iconOnly && (size === ButtonSize.Medium || size === ButtonSize.Large), 'flex-column gap-1': kind === ButtonKind.Block && size !== ButtonSize.Small, 'gap-space-8': kind === ButtonKind.Block && size === ButtonSize.Small }">
        <lib-icon *ngIf="prefixIcon" [ngClass]="{'':!iconOnly, 'invisible': loading}"
            [icon]="prefixIcon"[size]="iconSize">
        </lib-icon>
        <div #label *ngIf="!iconOnly" [ngClass]="{'invisible': loading, 'px-2':!iconOnly && size === ButtonSize.Large, 'padding-x-6': !iconOnly && size === ButtonSize.Medium, 'padding-x-2': !iconOnly && size === ButtonSize.Small}">
            <ng-content select="[label]"></ng-content>
        </div>
        <span *ngIf="loading" class="spinner-border spinner-border-sm position-absolute"></span>
        <lib-icon *ngIf="suffixIcon" [ngClass]="{'ps-1':!iconOnly, 'invisible': loading}"
            [icon]="suffixIcon" [size]="iconSize">
        </lib-icon>
    </div>
</ng-template>

<ng-template #buttonTemplate>
    <div 
        [matTooltip]="toolTipText" 
        matTooltipPosition="below"
        [matBadge]="badgeText"
        [matBadgeHidden]="!badgeText"
        matBadgeColor="warn"
        class="h-100">
        <button #button mat-button
            [matMenuTriggerFor]="menuTrigger"
            #optionsMenuTrigger = "matMenuTrigger"
            type="button"
            [ngClass]="buttonClass"
            [class.text-nowrap]="!allowTextWrap"
            [disabled]="disabled"
            [attr.data-sb-qa]="qaTag"
            (menuClosed)="MenuClosed()"
            (click)="ButtonClicked($event, optionsMenuTrigger)">
                <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
        </button>
    </div>
</ng-template>
