import { Pipe, PipeTransform, inject } from '@angular/core';
import { LocalizationService } from '../localization.service';
import { LocalizationNumberBasePipe } from './localization-number-base.pipe';
import { PercentFormatInfo } from '@library/data-models';

@Pipe({
    name: 'formatPercent'
})
export class LocalizationPercentPipe extends LocalizationNumberBasePipe implements PipeTransform {

    private _PercentFormatInfo!: PercentFormatInfo;

    constructor() {
        super();
        const localizationService: LocalizationService = inject(LocalizationService);
        this._PercentFormatInfo = localizationService.PercentFormatInfo;
        this._NumberFormatInfo = localizationService.PercentFormatInfo;
    }

    override transform(value: number, minNumOfDecimals = 0, maxNumOfDecimals = 16): string {
        let str = super.transform(value, minNumOfDecimals, maxNumOfDecimals);
        // Remove empty space before % symbol
        str = str.replace(' ', '');
        str = this.ReplacePercentPlaceHolderWithSymbole(str);
        return str;
    }

    protected IsValid(value: number, minNumOfDecimals: number, maxNumOfDecimals: number): boolean {
        return value != null &&
            this._PercentFormatInfo.PercentSymbol != null &&
            this._PercentFormatInfo.SymbolPlaceholder != null &&
            this._PercentFormatInfo.DecimalSeparator != null &&
            this._PercentFormatInfo.GroupSeparator != null &&
            this._PercentFormatInfo.GroupSizes != null &&
            this._PercentFormatInfo.NegativePattern != null &&
            this._PercentFormatInfo.PositivePattern != null &&
            minNumOfDecimals != null &&
            maxNumOfDecimals != null;
    }

    private ReplacePercentPlaceHolderWithSymbole(str: string) {
        return str.replace(
                this._PercentFormatInfo.SymbolPlaceholder!,
                this._PercentFormatInfo.PercentSymbol!
            );
    }
}
