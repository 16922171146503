import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ApiBaseRoutes } from '@library/api';
import { BaseViewComponent, Environment, ErrorMessages, FormBase, Optional, Required, string, ProductName } from '@library/base';
import { FormAffinity, StudentTypes, TeacherNameDisplayItem, VerifyEmailInputItem, WebsiteBlockSignupFormDisplayItem, WebsiteRenderContextDisplayItem } from '@library/data-models';
import { SBDate } from '@library/localization';
import { firstValueFrom } from 'rxjs';
import { SignupFormCustomFieldsComponent } from '../custom-fields/signup-form-custom-fields.component';
import { StudentSignupFrontendInputItem } from '../signup-form.definitions';

class AddStudentForm extends FormBase { // doesn't work if you put export class
    StudentType = Required(StudentSignupType);
    FirstName = Required(string);
    LastName = Required(string);
    Email = Required(string); // Only required if adult student
    Gender = Required(string); // Optional Fields
    Birthday =  Required(SBDate)
    LocalSchool = Optional(string);
    Subject = Required(string);
    SkillLevel = Required(string);
    FaceTimeID = Optional(string);
    SkypeUsername = Optional(string);
    PreferredTeacherID = Optional(string);
}

enum StudentSignupType {
    AdultStudent = "AdultStudent",
    ChildStudent = "ChildStudent",
}
@Component({
    selector: 'lib-signup-form-design-block-step1',
    templateUrl: './signup-form-design-block-step1.component.html',
    styleUrls: ['./signup-form-design-block-step1.component.scss']
})
export class SignupFormDesignBlockStep1Component extends BaseViewComponent implements OnInit {
    @ViewChild(SignupFormCustomFieldsComponent, {static: false}) customFields!: SignupFormCustomFieldsComponent;

    @Input()
    set data(value: WebsiteBlockSignupFormDisplayItem) {
        if (value !== undefined) {
            this._data = value;
        }
    }
    @Input() renderContext!: WebsiteRenderContextDisplayItem;    
    @Input() isViewOnly: boolean = false;
    @Output() nextClicked: EventEmitter<StudentSignupFrontendInputItem[]> = new EventEmitter<StudentSignupFrontendInputItem[]>();

    readonly MAX_STUDENTS: number = 20;
    readonly noPreferredTeacher: string = 'No-Preferred-Teacher';
    
    private _data!: WebsiteBlockSignupFormDisplayItem;
    private _studentInputItems: StudentSignupFrontendInputItem[] = [];
    private _specialMinimalDate: SBDate = SBDate.FromComponents(1900, 1, 1);
    private _todaysDate: SBDate = SBDate.Today();
    private _preferredTeachers: TeacherNameDisplayItem[] = [];
    private _studentIDCounter: number = 0;
    private _showStudentExistsWarning: boolean = false;
    private _verifyingStudent: boolean = false;

    constructor(private _Environment: Environment) {
        super();
    }

    readonly addStudentForm = AddStudentForm.Create(false);

    readonly firstNameError: ErrorMessages = {
        required: _=> $localize`:@@SUFAddStudentFirstNameMissingError:Please enter a first name`
    }

    readonly lastNameError: ErrorMessages = {
        required: _=> $localize`:@@SUFAddStudentLastNameMissingError:Please enter a last name`
    }

    readonly emailError: ErrorMessages = {
        required: _=> $localize`:@@SUFAddStudentEmailMissingError:Please enter an email address`,
        api: _=> $localize`:@@SUFAddStudentVerifyEmailAPIError:Please enter a valid email address`
    }

    override ngOnInit(): void {
        this.addStudentForm.isViewOnly = this.isViewOnly;
        
        if(!this.isViewOnly) {
            this.SetupForm();
        }
    }

    private SetupForm(): void {

        if ( this.data.AllowedStudentTypes === StudentTypes.ChildOnly ) {
            this.addStudentForm.controls.StudentType.setValue(StudentSignupType.ChildStudent);
        } else if ( this.data.AllowedStudentTypes === StudentTypes.AdultOnly ) {
            this.addStudentForm.controls.StudentType.setValue(StudentSignupType.AdultStudent);
        } else {
            switch(this._Environment.Application.ProductName) {
                case ProductName.AthletaDesk:
                    this.addStudentForm.controls.StudentType.setValue(StudentSignupType.AdultStudent);
                    break;
                case ProductName.MyMusicStaff:
                case ProductName.TutorBird:
                case ProductName.Test443:
                    this.addStudentForm.controls.StudentType.setValue(StudentSignupType.ChildStudent);
                    break;
                default:
                    this.addStudentForm.controls.StudentType.setValue(StudentSignupType.AdultStudent);
                    break;
            }
        }

        this.StudentTypeChanged(this.addStudentForm.controls.StudentType.value);
        this._preferredTeachers = this.renderContext.SignupFormRenderContext!.PreferredTeachers;
        this._preferredTeachers = this._preferredTeachers.sort((t1, t2) => t1.Name!.toLocaleLowerCase() < t2.Name!.toLocaleLowerCase() ? -1 : 1);

        if(this.data.CollectPreferredTeacher && this._preferredTeachers.length > 1){
            this.addStudentForm.controls.PreferredTeacherID.setValidators(Validators.required);
            this.addStudentForm.controls.PreferredTeacherID.isRequired = true;
            this.addStudentForm.controls.PreferredTeacherID.updateValueAndValidity();
        }

        if(!this.data.CollectBirthday){
            this.addStudentForm.controls.Birthday.disable();
        }

        if(!this.data.CollectGender) {
            this.addStudentForm.controls.Gender.disable();
        }

        if(!this.data.CollectSkillLevel) {
            this.addStudentForm.controls.SkillLevel.disable();
        }

        if(!this.data.CollectSubjectType) {
            this.addStudentForm.controls.Subject.disable();
        }
    }

    async AddAnotherStudentClicked(): Promise<void> {
        if(await this.VerifyAndAddStudent()) {

            const lastStudentType = this.addStudentForm.controls.StudentType.value;

            //reset form
            this.addStudentForm.reset();

            this.addStudentForm.controls.StudentType.setValue(lastStudentType);
        }
    }

    async NextClicked(): Promise<void> {
        this._verifyingStudent = true;
        if(await this.VerifyAndAddStudent()){
            //go to next step
            this.nextClicked.emit(this.students); // ?? pointer
            //this remove is so if you click back on the next step, the last student you entered is filled in
            this.RemoveStudentAndPatch(this.students[this.students.length-1]);
            this._studentIDCounter--;
            this._verifyingStudent = false;
        };
    }

    async EditStudent(student: StudentSignupFrontendInputItem) {
        //Do Validation on form and add student to list
        if(await this.VerifyAndAddStudent()) {
            this.RemoveStudentAndPatch(student);
        };
    }

    private RemoveStudentAndPatch(student: StudentSignupFrontendInputItem) {
        //remove selected student from list
        this._studentInputItems = this.students.filter(s => student.ID !== s.ID);
        //patch form to selected student
        this.addStudentForm.patchValue({
            StudentType: student.IsAdult? StudentSignupType.AdultStudent: StudentSignupType.ChildStudent,
            FirstName: student.FirstName!,
            LastName: student.LastName!,
            Email: student.EmailAddress!,
            Birthday: student.Birthday ? SBDate.FromISO(student.Birthday) : null,
            Gender: student.Gender!,
            LocalSchool: student.LocalSchool!,
            Subject: student.SubjectType!,
            SkillLevel: student.SkillLevel!,
            FaceTimeID: student.FaceTimeID!,
            SkypeUsername: student.SkypeUsername!,
            PreferredTeacherID: student.PreferredTeacherID ? student.PreferredTeacherID : this.noPreferredTeacher,
        })
        this.customFields.PatchCustomFieldValues(student.CustomFieldValues);
        this.StudentTypeChanged(this.addStudentForm.controls.StudentType.value);
    }

    private CheckForDuplicateStudent(inputItem: StudentSignupFrontendInputItem): boolean {
        return this.students.some(student =>
            (student.FirstName!.toLocaleLowerCase() === inputItem.FirstName!.toLocaleLowerCase()
            && student.LastName!.toLocaleLowerCase() === inputItem.LastName!.toLocaleLowerCase()));
    }

    private async VerifyAndAddStudent(): Promise<boolean> {
        this._showStudentExistsWarning = false;
        let isEmailValid: boolean = true;

        if(this.addStudentForm.controls.Email.value){
            isEmailValid = await firstValueFrom(ApiBaseRoutes.Email.VerifyEmail.Call({
                Body: new VerifyEmailInputItem({
                    EmailAddress: this.addStudentForm.controls.Email.value
                })
            }));
        }
        //CheckValidity calls UpdateValueAndValidity that removes SetApiError. So CheckValidity must happen after SetApiError
        if(this.addStudentForm.CheckValidity(false) && isEmailValid){

            const form = this.addStudentForm.controls;
            const inputItem = new StudentSignupFrontendInputItem({
                IsAdult: form.StudentType.value === StudentSignupType.AdultStudent,
                FirstName: form.FirstName.value,
                LastName: form.LastName.value,
                EmailAddress: form.Email.value,
                Birthday: form.Birthday.value ? form.Birthday.value.ToISO() : null,
                Gender: form.Gender.value,
                LocalSchool: form.LocalSchool.value,
                SubjectType: form.Subject.value ? form.Subject.value : null,
                SkillLevel: form.SkillLevel.value,
                FaceTimeID: form.FaceTimeID.value,
                SkypeUsername: form.SkypeUsername.value,
                PreferredTeacherID: form.PreferredTeacherID.value === this.noPreferredTeacher || !form.PreferredTeacherID.value? null: form.PreferredTeacherID.value,
                CustomFieldValues: this.customFields.GetCustomFieldValues()
            });
            if(this.CheckForDuplicateStudent(inputItem)){
                this._showStudentExistsWarning = true;
                this.ScrollToWaitForReportBanner();
                this._verifyingStudent = false;
                return false
            } else {
                //we increment the ID to have unique IDs all the time when adding
                const ID = this._studentIDCounter++;
                inputItem.ID = ID.toString();
                this._studentInputItems.push(inputItem);
                return true;
            }
        } else if(!isEmailValid){
            this.addStudentForm.controls.Email.SetApiError();

        }
        this._verifyingStudent = false;
        return false;
    }

    DeleteStudentClicked(): void {
        // 1. Reset/clear form
        this.addStudentForm.reset();
        // 2. Get the last student from list (most recent student)
        // 3. Patch form to last student
        // 4. Remove last student from list
        this.RemoveStudentAndPatch(this.students[this.students.length-1])
    }

    StudentTypeChanged(studentType: StudentSignupType): void {
        if(studentType === StudentSignupType.AdultStudent){
            this.addStudentForm.controls.Email.setValidators(Validators.required);
            this.addStudentForm.controls.Email.isRequired = true;
            this.addStudentForm.controls.Email.updateValueAndValidity();
        } else {
            this.addStudentForm.controls.Email.removeValidators(Validators.required);
            this.addStudentForm.controls.Email.isRequired = false;
            this.addStudentForm.controls.Email.updateValueAndValidity();
        }
    }

    SubjectInstrumentChanged(subject: string | null): void {
        if(subject){
            this._preferredTeachers = this.renderContext.SignupFormRenderContext!.PreferredTeachers.filter(teacher => teacher.Subjects.includes(subject));
        } else {
            this._preferredTeachers = this.renderContext.SignupFormRenderContext!.PreferredTeachers;
        }
    }

    private ScrollToWaitForReportBanner(): void {
        setTimeout(() => {
        let elements = document.getElementsByClassName('scss-student-exists-banner');

        if (elements.length > 0) {
            //We get the banner element
            let bannerElement = elements[0];

            //Then scroll to it
            bannerElement.scrollIntoView({behavior: "smooth"});
        }
        }, 500);
    }

    get students(): StudentSignupFrontendInputItem[] {
        return this._studentInputItems;
    }

    get specialMinimalDate(): SBDate {
        return this._specialMinimalDate;
    }

    get todaysDate(): SBDate {
        return this._todaysDate;
    }

    get StudentSignupType(): typeof StudentSignupType {
        return StudentSignupType;
    }

    get StudentTypes(): typeof StudentTypes {
        return StudentTypes;
    }

    get FormAffinity(): typeof FormAffinity {
        return FormAffinity;
    }

    get preferredTeachers() {
        return this._preferredTeachers;
    }

    get showStudentExistsWarning(): boolean {
        return this._showStudentExistsWarning;
    }

    get verifyingStudent(): boolean {
        return this._verifyingStudent;
    }

    get data() {
        return this._data;
    }
}
