export enum ButtonKind {
    Primary = "btn btn-primary",
    Secondary = "btn btn-outline-secondary",
    Tertiary = "btn btn-tertiary",
    Danger="btn btn-danger",
    Sucess ="btn btn-success",
    Link = "btn btn-link",
    DangerSecondary = "btn btn-outline-danger",
    Circle = "btn btn-circle",
    TertiaryLink = "btn btn-tertiary-link",
    Block = "btn btn-block"
}

export enum ButtonSize {
    Small = "btn-sm",
    Medium = "", // Medium is the default button size in bootstrap
    Large = "btn-lg"
}

export enum MenuButtonMode {
    Default,
    TextOnly,
    Custom,
    IconOnly
}

export enum MenuDivider {
    Above,
    Below,
    None,  // default
    Both   // not implemented yet
}

/**
 *  By default, the menu will display below (y-axis), after (x-axis) 
 *  @see {@link https://material.angular.io/components/menu/overview#customizing-menu-position}
 */
export type MenuXPosition = "before" | "after";

