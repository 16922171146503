import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { inject, Injectable } from '@angular/core';
import { DeviceTypeManager, ViewMode } from '@library/base';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WidgetManualDeviceTypeManager extends DeviceTypeManager {

    constructor () {
        // Do not use the breakpoint observer in DeviceTypeManager
        super(undefined);
    }

    SetPageSize(width: number, height: number) {
        const landscapeMode = width/height > 1;
        
        if (width >= this._desktopLandscapeMinWidth && landscapeMode) {
            this.SetViewMode(ViewMode.Desktop);
        } else if ((width >= this._desktopPortraitMinWidth && !landscapeMode)
                || (width >= this._tabletPortraitMinWidth && width < this._tabletPortraitMaxWidth && !landscapeMode)
                || (width >= this._tabletLandscapeMinWidth && width < this._tabletLandscapeMaxWidth && landscapeMode)) {
            this.SetViewMode(ViewMode.Tablet);
        } else if ((width <= this._mobilePortraitMaxWidth && !landscapeMode) || width <= this._mobileLandscapeMaxWidth && landscapeMode) {
            this.SetViewMode(ViewMode.Mobile);
        }
    }
}
