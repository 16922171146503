<div class="v3"> 
    <span mat-dialog-title *ngIf="showTitle">
        <p class="padding-x-24 padding-top-24 padding-bottom-16" 
            [ngClass]="{'typography-title-large d-flex justify-content-center w-100': isMobile, 'typography-headline-small': !isMobile}">
            <ng-content select="[title]"></ng-content>
        </p>
    </span>

    <mat-dialog-content>
            <ng-content select="[content]"></ng-content>
    </mat-dialog-content>

    <ng-container *ngIf="showActions">       
        <mat-dialog-actions>
            <ng-content select="[submissionBar]"></ng-content>
        </mat-dialog-actions>
    </ng-container>
</div>
