import { Component } from '@angular/core';
import { SnackbarBaseViewComponent, SnackbarLayout } from '@library/base';

@Component({
  selector: 'lib-action-closable-snackbar',
  templateUrl: './action-closable-snackbar.component.html',
  styleUrls: ['./action-closable-snackbar.component.scss']
})
export class ActionClosableSnackbarComponent extends SnackbarBaseViewComponent<string> {

    get SnackbarLayout(): typeof SnackbarLayout {
        return SnackbarLayout;
    }
}
