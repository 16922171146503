import { Observable } from "rxjs";

export enum DialogType {
    Small = "Small",
    Medium = "Medium",
    Large = "Large",
    Fullscreen = "Fullscreen"
}

export enum DialogEvents {
    AfterOpened,
    BeforeClosing,
    AfterClosing,
    Dismissed,
    PrimaryAction,
    SecondaryAction,
    AfterClosingFromPrimaryAction,
    BeforeClosingFromPrimaryAction,
    ClickedOutside
}

export enum RecurringDeleteOptions {
    PrimaryOption,
    SecondaryOption
}

export class SimpleDialogData {
    constructor(initializer?: Partial<SimpleDialogData>) {
        if (initializer) {
            Object.assign(this, initializer);
        }
    }

    Title?: string = "";
    Text?: string = "";
    Size?: DialogSize = DialogSize.Medium;
    PrimaryButtonText?: string | null = null;
    SecondaryButtonText?: string | null = null;
    DismissPreviousDialog?: boolean = true;
    PrimaryOptionText?: string | null = null;
    SecondaryOptionText?: string | null = null;
}

export class ProgressDialogData {
    Title?: string = $localize`:@@CommonLongOperationTitle:Please wait a moment...`;;
    Text?: string = '';
    ProgressObservable!: Observable<number>;
    DismissPreviousDialog?: boolean = false;
    CancelButtonText?: string = $localize`:@@CommonCancelButton:Cancel`;

    constructor(initializer: ProgressDialogData) {
        Object.assign(this, initializer);
    }
}


export enum DialogSize{
    Small = 'dialog-sm',
    Medium = 'dialog-md', // default dialog size
    Large = 'dialog-lg',
}