import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'lib-radio-group-item [value]',
    templateUrl: 'form-field-radio-group-item.html'
})
export class FormFieldRadioGroupItemComponent<T> {

    @Input() value!: T;
    @Input() hintMessage: string = "";
    @Input() disabled: boolean = false;
    @Input() qaTag: string = "";
    
    @ViewChild('content', {static: true, read: TemplateRef}) label!: TemplateRef<any>;
}
