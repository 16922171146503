import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatStepperModule } from '@angular/material/stepper';
import { CardModule } from '@library/card';
import { IconModule } from '@library/icon';
import { MultiStepViewComponent } from './multi-step-view.component';
import { MultiStepViewStepComponent } from './step/multi-step-view-step.component';




@NgModule({
    declarations: [
        MultiStepViewComponent,
        MultiStepViewStepComponent
    ],
    imports: [
        CommonModule,
        MatStepperModule,
        CardModule,
        IconModule
    ],
    exports: [
        MultiStepViewComponent,
        MultiStepViewStepComponent
    ]
})
export class MultiStepViewModule { }
