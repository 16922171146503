import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusLabelComponent } from './status-label.component';
import { IconModule } from '@library/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StudentStatusPipe } from './pipes/student-status.pipe';
import { RepertoireAssignmentStatusPipe } from './pipes/repertoire-assignment-status.pipe';
@NgModule({
    declarations: [
        StatusLabelComponent,
        StudentStatusPipe,
        RepertoireAssignmentStatusPipe,
    ],
    imports: [
        CommonModule,
        IconModule,
        MatTooltipModule
    ],
    exports: [
        StatusLabelComponent,
        StudentStatusPipe,
        RepertoireAssignmentStatusPipe,
    ]
})
export class StatusLabelModule { }
