import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { MatSelect } from '@angular/material/select';


@Directive({
    selector: '[matSelectTruncate]',
})
export class MatSelectTruncateDirective {
    @Input()
    set matSelectTruncate(value : MatSelect) {
        if (value) {
            const inputWidth = value._elementRef.nativeElement.clientWidth - 18;
            this._elementRef.nativeElement.setAttribute('style', "width:"+inputWidth+"px");
        }
    }
    constructor(private _elementRef: ElementRef) {}
}