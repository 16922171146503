import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseModule } from '@library/base';
import { DesignBlocksModule } from '@library/design-blocks';
import { WebsiteBlockComponent } from './block/website-block.component';
import { WebsitePageComponent } from './page/website-page.component';
import { WebsiteNavigationSidebarComponent } from './navigation-sidebar/website-navigation-sidebar.component';
import { IconModule } from '@library/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UtilityModule } from '@library/utility';
import { IframeModule } from '@library/iframe';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list'
import {ButtonModule} from "@library/button";
import { WebsiteSvgsComponent } from './svgs/website-svgs.component';

@NgModule({
    declarations: [
        WebsitePageComponent,
        WebsiteBlockComponent,
        WebsiteNavigationSidebarComponent,
        WebsiteSvgsComponent
    ],
    imports: [
        BaseModule,
        CommonModule,
        DesignBlocksModule,
        IconModule,
        DragDropModule,
        ScrollingModule,
        UtilityModule,
        IframeModule,
        MatSidenavModule,
        MatListModule,
        DesignBlocksModule,
        ButtonModule
    ],
    exports:[
        WebsitePageComponent,
        WebsiteBlockComponent,
        WebsiteNavigationSidebarComponent
    ]
})
export class WebsiteLibraryModule { }
