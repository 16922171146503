import { Component, Input } from '@angular/core';
import { WebsiteBlockTextAndMediaType } from "@library/data-models";
import { DesignBlockImageBaseViewComponent, WebsiteGalleryItemAction } from "@library/base";
import { WebsiteMode } from '@library/base';

@Component({
    selector: 'lib-gallery-column-design-block-item',
    templateUrl: './gallery-column-design-block-item.component.html',
    styleUrls: ['./gallery-column-design-block-item.component.scss'],
})
export class GalleryColumnDesignBlockItemComponent extends DesignBlockImageBaseViewComponent {
    @Input() kind: WebsiteBlockTextAndMediaType = WebsiteBlockTextAndMediaType.GallerySquareAccentColumn;
    @Input() mode: WebsiteMode = WebsiteMode.Preview;
    @Input() disableRemoveCard: boolean = false;
    @Input() disableImageDetails: boolean = false;

    get blockHasCard(): boolean {
      return this.kind == WebsiteBlockTextAndMediaType.GalleryCircleCardColumn || this.kind == WebsiteBlockTextAndMediaType.GallerySquareCardColumn;
    }

    get WebsiteGalleryItemAction(): typeof WebsiteGalleryItemAction {
      return WebsiteGalleryItemAction;
    }

    get showImageActionMenu(): boolean {
      return this.showGeneralImageActionMenu && this.mode === WebsiteMode.Preview;
    }
}
