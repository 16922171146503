<div 
    [ngClass]="[type === WebsiteBlockTextAndMediaType.GallerySquareAccentColumn || type === WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn ? 'scss-block-color-variable-font': '',
                type === WebsiteBlockTextAndMediaType.GallerySquareColumn || type === WebsiteBlockTextAndMediaType.GalleryCircleColumn ||
                type === WebsiteBlockTextAndMediaType.GallerySquareCardColumn || type === WebsiteBlockTextAndMediaType.GalleryCircleCardColumn ? 'scss-website-background-color' : '']"
    [ngSwitch]="websiteMode">
    <ng-container *ngSwitchCase="WebsiteMode.Live">
        <div class="position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.ReadOnly">
        <div class="position-relative design-block">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="WebsiteMode.Preview">
        <lib-design-blocks-action-menu
                [disableMoveUp]="disableMoveUp"
                [disableMoveDown]="disableMoveDown"
                [disableRemoveItem]="disableRemoveItem"
                [showAdd]="true"
                [showMultiSelectImage]="true"
                (moveDownClicked)="MoveDownClicked()"
                (moveUpClicked)="MoveUpClicked()"
                (deleteClicked)="DeleteClicked()"
                (addItemClicked)="AddItemClicked()"
                (removeItemClicked)="RemoveItemClicked()"
                (openMultiSelectMediaGallery)="OpenMultiSelectMediaGallery()">
            <ng-container *ngTemplateOutlet="blockContent"></ng-container>
        </lib-design-blocks-action-menu>
    </ng-container>
</div>

<ng-template #blockContent>
    <div class="d-flex flex-column gallery-column-block w-100"
        [ngClass]="{
            'scss-block-background-color-variable': type === WebsiteBlockTextAndMediaType.GallerySquareAccentColumn || 
            type === WebsiteBlockTextAndMediaType.GalleryCircleAccentColumn || 
            type === WebsiteBlockTextAndMediaType.GallerySquareCardColumn ||
            type === WebsiteBlockTextAndMediaType.GalleryCircleCardColumn}"
    >
        <ng-container *ngIf="hasHeader">
            <h2 class="text-center">
                <ng-content select="[header]"></ng-content>
            </h2>
        </ng-container>
        <div class="w-100 d-flex justify-content-center gallery-column-block-items">
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>
