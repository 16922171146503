import { Injectable } from "@angular/core";
import { FieldOf } from "../definitions/base.definitions";

export abstract class StorageKey<T> {
    name!: string;  // Should be assigned only by calling InitializeStorageKeys
    validator?: ((value: T) => boolean); 

    constructor(initializer?: Omit<SessionStorageKey<T>, 'name'>) {
        if (initializer && initializer.validator) {
            this.validator = initializer.validator;
        }
    }

    // Convenience methods below, to be used when definining storage keys
    public static Session<T>(initializer?: Omit<SessionStorageKey<T>, 'name'>): SessionStorageKey<T> {
        return new SessionStorageKey<T>(initializer);
    }

    public static Local<T>(initializer?: Omit<LocalStorageKey<T>, 'name'>): LocalStorageKey<T> {
        return new LocalStorageKey<T>(initializer);
    }
}

export class SessionStorageKey<T> extends StorageKey<T> {
    constructor(initializer?: Omit<SessionStorageKey<T>, 'name'>) {
        super(initializer);
    }
}

export class LocalStorageKey<T> extends StorageKey<T> {
    expireAfterMinutes?: number;
    expireAfterRefresh?: boolean = false;
    refreshExpiryOnRead?: boolean = true;

    constructor(initializer?: Omit<LocalStorageKey<T>, 'name'>) {
        super(initializer);

        if (initializer) {
            if (initializer.expireAfterMinutes) {
                this.expireAfterMinutes = initializer.expireAfterMinutes;
            }
            if (initializer.refreshExpiryOnRead == false) {
                this.refreshExpiryOnRead = false;
            }
            if (initializer.expireAfterRefresh){
                this.expireAfterRefresh = true;
            }
        }
    }
}

export function NotNullOrUndefined<T>() {
    return (value: T | null | undefined) => value !== null && value !== undefined
}

export function OnlyValues<T>(...values: T[]) {
    return (value: T | null | undefined) => value !== null && value !== undefined && values.includes(value);
}

export function OnlyEnumValues<T extends ArrayLike<unknown> | { [s: string]: unknown; }>(enumeration: T)  {
    return (value: T[FieldOf<T>] | null | undefined) => Object.values(enumeration).includes(value);
}

// This type mapping allows InitializeStorageKeys to map keyDefinitions properly
type OnlyStorageKeys<T> = {
    [K in keyof T]: T[K] extends StorageKey<any> | OnlyStorageKeys<any> ? T[K] : never
};

export function InitializeStorageKeys<T>(prefix: string, keyDefinitions: OnlyStorageKeys<T>): T {
    Object.keys(keyDefinitions).forEach(key => {
        const value = keyDefinitions[key as FieldOf<T>];
        if (value instanceof StorageKey) {
            keyDefinitions[key as FieldOf<T>].name = prefix + key;
        } else {
            InitializeStorageKeys(prefix + key, value);
        }
    });

    StorageManager.keysInitialized = true;
    return keyDefinitions;
}

class LocalStorageValue<T> {
    value?: T;
    expiryTimestamp?: number;

    constructor(initializer: LocalStorageValue<T>) {
        this.value = initializer.value;
        if (initializer.expiryTimestamp) {
            this.expiryTimestamp = initializer.expiryTimestamp;
        }
    }
}

class StorageCache {
    private _cache: {[key: string]: any} = {};

    GetValue<T>(key: StorageKey<T>): LocalStorageValue<T> {
        const cachedValue = this._cache[key.name];
        if (cachedValue) {
            return cachedValue;
        }
        throw Error;
    }

    SetValue<T>(key: StorageKey<T>, value: LocalStorageValue<T>) {
        this._cache[key.name] = value;
    }

    ClearValue<T>(key: StorageKey<T>) {
        this._cache[key.name] = new LocalStorageValue<T>({});
    }
}


type StorageValue<T> = T extends StorageKey<infer U> ? U : never;

@Injectable({
    providedIn: 'root'
})
export class StorageManager {
    static keysInitialized = false;

    private _storageCache = new StorageCache();

    private GetStorageValue<T>(key: StorageKey<T>): string | null {
        if (key instanceof LocalStorageKey) {
            return localStorage.getItem(key.name);
        } else {
            return sessionStorage.getItem(key.name);
        }
    }

    private SetStorageValue<T>(key: StorageKey<T>, value: string) {
        if (key instanceof LocalStorageKey) {
            localStorage.setItem(key.name, value);
        } else {
            sessionStorage.setItem(key.name, value);
        }
    }

    private DeleteStorageValue<T>(key: StorageKey<T>) {
        if (key instanceof LocalStorageKey) {
            localStorage.removeItem(key.name);
        } else {
            sessionStorage.removeItem(key.name);
        }
    }

    private GetCurrentTimestamp(): number {
        return Math.floor(new Date().getTime() / 60000);
    }

    public GetValue<T>(key: StorageKey<T>): T | undefined;
    public GetValue<T extends StorageKey<any>>(key: T, defaultValue: StorageValue<T>): StorageValue<T>;
    public GetValue<T>(key: StorageKey<T>, defaultValue?: T): T | undefined {
        this.CheckIfInitialized();

        try {
            // Try the in-memory cache

            let result = this._storageCache.GetValue(key);

            if (!result.expiryTimestamp) {
                return result.value === undefined ? defaultValue : result.value;
            }
            
            const localStorageKey = key as LocalStorageKey<T>;
            const timestamp = this.GetCurrentTimestamp();

            if (!localStorageKey.refreshExpiryOnRead) {
                if (result.expiryTimestamp >= timestamp) {
                    return result.value;
                }
                    
                // Value expired
                this._storageCache.ClearValue(key);
                localStorage.removeItem(key.name);

                return defaultValue;
            }

            const expiryTimestamp = timestamp + localStorageKey.expireAfterMinutes!

            if (result.expiryTimestamp == expiryTimestamp) {
                // No update needed (expiry timestamp hasn't changed)
                return result.value;
            }

            if (result.expiryTimestamp > timestamp) {
                // Need to update expiry timestamp in cache and local storage
                result.expiryTimestamp = expiryTimestamp;
                localStorage.setItem(key.name, JSON.stringify(result));

                return result.value;
            }

            // Value expired
            this._storageCache.ClearValue(key);
            localStorage.removeItem(key.name);

            return defaultValue;
        }
        catch {
            // Try local/session storage
            
            let storedValue = this.GetStorageValue(key);
            let timestamp = this.GetCurrentTimestamp();

            let value: T | undefined = undefined;
            let originalExpiry: number | undefined = undefined;
            if (storedValue) {
                try {
                    let parsedValue = JSON.parse(storedValue);

                    if (!parsedValue
                        || parsedValue.value == undefined
                        || key.validator && !key.validator(parsedValue.value)
                        || key instanceof LocalStorageKey && key.expireAfterMinutes && (!parsedValue.expiryTimestamp || parsedValue.expiryTimestamp < timestamp)) {

                        // Invalid or expired value
                        this.DeleteStorageValue(key);
                    } else {
                        value = parsedValue.value;
                        originalExpiry = parsedValue.expiryTimestamp;
                    }
                }
                catch {
                    // The local storage value couldn't be parsed
                    this.DeleteStorageValue(key);
                }
            }

            let cacheItem = new LocalStorageValue({ value: value, expiryTimestamp: originalExpiry });
            if (value != undefined && key instanceof LocalStorageKey && key.expireAfterMinutes && key.refreshExpiryOnRead) {
                // Update expiry timestamp in local storage
                cacheItem.expiryTimestamp = timestamp + key.expireAfterMinutes;
                localStorage.setItem(key.name, JSON.stringify(cacheItem));
            }

            this._storageCache.SetValue(key, cacheItem);
            
            return value === undefined ? defaultValue : value;
        }
    }

    public SetValue<T>(key: StorageKey<T>, value: T) {
        this.CheckIfInitialized();

        if (key.validator && !key.validator(value)) {
            throw new Error(`Invalid value ${value} for key ${key.name}.`);
        }

        let cacheItem = new LocalStorageValue({ value: value });
        if (key instanceof LocalStorageKey && key.expireAfterMinutes) {
            cacheItem.expiryTimestamp = this.GetCurrentTimestamp() + key.expireAfterMinutes;
        }

        this._storageCache.SetValue(key, cacheItem);
        this.SetStorageValue(key, JSON.stringify(cacheItem));
    }

    public DeleteValue<T>(key: StorageKey<T>) {
        this.CheckIfInitialized();

        this._storageCache.ClearValue(key);
        this.DeleteStorageValue(key);
    }

    public Clear() {
        this.CheckIfInitialized();

        this._storageCache = new StorageCache();
        localStorage.clear();
        sessionStorage.clear();
    }

    public ClearExpireOnRefreshStorageValues(keys: OnlyStorageKeys<any>): void {  
        this.CheckIfInitialized();

        const storageKeyValues = Object.values(keys); 
        storageKeyValues.forEach(key => {
            if (key instanceof LocalStorageKey && key.expireAfterRefresh) {
                this.DeleteValue(key);
            } 
        });
    }

    private CheckIfInitialized() {
        if (!StorageManager.keysInitialized) {
            throw Error('Used storage manager before initializing keys.');
        }
    }
}
