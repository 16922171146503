<mat-progress-bar *ngIf="kind != ProgressBarKind.Default" [mode]="kind" [value]="value"></mat-progress-bar>

<div *ngIf="kind == ProgressBarKind.Default" class="scss-default-progress-bar-container">
    <div class="scss-progress-bar scss-progress-moved">
        <div class="scss-progress-bar-inner" [ngStyle]="{'width': value + '%'}"></div>
    </div>
</div>

<p>
    <ng-content></ng-content>
</p>