import { Type } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormControlState, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { DialogBaseViewComponent } from "../../views/dialog-base-view.component";

type ControlType<T> = T extends AbstractControl<infer U> ? (U extends FormControlState<any> ? never : U) : never;

declare module '@angular/forms' {
    export interface AbstractControl<TValue> {
        isReadOnly: boolean;
        isViewOnly: boolean;
        isLoading: boolean;
        isDirty: boolean;
    }

    export interface FormGroup<TControl extends { [K in keyof TControl]: AbstractControl }> {
        CheckValidity(disableIfValid?: boolean, scrollToFirstError?: boolean): boolean;
        AddControl<T>(this: FormGroup<TControl>, formControlName: string, defaultValue: T): FormControl<T>;
        SetDefaultValueToCurrent(synchronous?: boolean): void;
        Reset(synchronous?: boolean): void;
        Disable(synchronous?: boolean): void;
        Enable(synchronous?: boolean): void;
        SaveSucceeded(): void;
        SaveFailed(): void;
        GetControlsInErrorState(): number;
        GetControlsInDirtyState(): number;
        autoSave: boolean;

        readonly saveMethod?: () => void;
        readonly saveSubject?: Subject<boolean>;
        readonly unsavedChangesDialog?: Type<DialogBaseViewComponent>;
    }

    export interface FormControl<TValue> {
        isRequired: boolean;
        SetApiError(): void;
        Disable(synchronous?: boolean): void;
        Enable(synchronous?: boolean): void;
        ConnectToForm(form: FormGroup): void;
    }

    export interface FormArray<TControl extends AbstractControl> {
        Add(this: FormArray<TControl>, item?: ControlType<TControl>): void;
    }
}

/** @internal */
export interface FormGroupExtraFields extends FormGroup {
    defaultValue: any;
    defaultDisabledStates: any;
    currentDisabledStates: any;
    
    saveMethod?: () => void;
    saveSubject?: Subject<boolean>;
    autoSave: boolean;
    unsavedChangesDialog?: Type<DialogBaseViewComponent>;

    connectedControl?: FormControl;

}

/** @internal */
export interface FormControlExtraFields extends FormControl {
    connectedForm?: FormGroup;
}

/** @internal */
export interface AbstractControlExtraFields extends AbstractControl {
    _isRequired: boolean;
    _isLoading: boolean;
    _isReadOnly: boolean;
    _isViewOnly: boolean;
    _defaultValue: any;
}

Object.defineProperty(FormGroup.prototype, 'isReadOnly', {
    get: function() {
        return this._isReadOnly;
    },
    set: function(value: boolean) {
        this._isReadOnly = value;
        Object.values(this.controls).forEach((control: any) => {
            control.isReadOnly = value;
        });
    }
});

Object.defineProperty(FormGroup.prototype, 'isViewOnly', {
    get: function() {
        return this._isViewOnly;
    },
    set: function(value: boolean) {
        this._isViewOnly = value;
        Object.values(this.controls).forEach((control: any) => {
            control.isViewOnly = value;            
        });

        if (value) {
            this.Disable();
        }
    }
});

Object.defineProperty(FormGroup.prototype, 'isLoading', {
    get: function() {
        return this._isLoading;
    },
    set: function(value: boolean) {
        this._isLoading = value;
        Object.values(this.controls).forEach((control: any) => {
            control.isLoading = value;
        });
    }
});


Object.defineProperty(FormGroup.prototype, 'isDirty', {
    get: function() {
        return this._isDirty && !this.disabled;
    },
    set: function(value: boolean) {
        this._isDirty = value;
        // isDirty propagates downward if false, upward if true
        if (!value) {
            Object.values(this.controls).forEach((control: any) => {
                control.isDirty = value;
            });
        } else {
            if (this.parent) {
                this.parent.isDirty = value;
            }
            if (this.connectedControl) {
                this.connectedControl.isDirty = value;
                this.connectedControl.markAsTouched();
            }
        }
    }
});

Object.defineProperty(FormArray.prototype, 'isReadOnly', {
    get: function() {
        return this._isReadOnly;
    },
    set: function(value: boolean) {
        this._isReadOnly = value;
        this.controls.forEach((control: any) => {
            control.isReadOnly = value;
        })
    }
});

Object.defineProperty(FormArray.prototype, 'isViewOnly', {
    get: function() {
        return this._isViewOnly;
    },
    set: function(value: boolean) {
        this._isViewOnly = value;
        this.controls.forEach((control: any) => {
            control.isViewOnly = value;
        })
    }
});

Object.defineProperty(FormArray.prototype, 'isLoading', {
    get: function() {
        return this._isLoading;
    },
    set: function(value: boolean) {
        this._isLoading = value;
        this.controls.forEach((control: any) => {
            control.isLoading = value;
        });
    }
});

Object.defineProperty(FormArray.prototype, 'isDirty', {
    get: function() {
        return this._isDirty && !this.disabled;
    },
    set: function(value: boolean) {
        this._isDirty = value;

        // isDirty propagates downward if false, upward if true
        if (!value) {
            this.controls.forEach((control: any) => {
                control.isDirty = value;
            });
        } else {
            if (this.parent) {
                this.parent.isDirty = value;
            }
        }
    }
});

Object.defineProperty(FormControl.prototype, 'isReadOnly', {
    get: function() {
        return this._isReadOnly;
    },
    set: function(value: boolean) {
        this._isReadOnly = value;
        if (this.connectedForm) {
            this.connectedForm.isReadOnly = value;
        }
    }
});

Object.defineProperty(FormControl.prototype, 'isViewOnly', {
    get: function() {
        return this._isViewOnly;
    },
    set: function(value: boolean) {
        this._isViewOnly = value;
        if (this.connectedForm) {
            this.connectedForm.isViewOnly = value;
        }
    }
});

Object.defineProperty(FormControl.prototype, 'isLoading', {
    get: function() {
        return this._isLoading;
    },
    set: function(value: boolean) {
        this._isLoading = value;
        if (this.connectedForm) {
            this.connectedForm.isLoading = value;
        }
    }
});

Object.defineProperty(FormControl.prototype, 'isDirty', {
    get: function() {
        return this._isDirty && !this.disabled;
    },
    set: function(value: boolean) {
        this._isDirty = value;
        // isDirty propagates downward if false, upward if true
        if (!value) {
            if (this.connectedForm) {
                this.connectedForm.isDirty = value;
            }
        } else {
            if (this.parent) {
                this.parent.isDirty = value;
            }
        }
    }
});

FormArray.prototype.Add = function<T>(this: FormArray & AbstractControlExtraFields, item?: T): FormControl<T> {
    if (item === undefined) {
        item = this._defaultValue as T;
    }

    let control: FormControl<T>;
    if (!this._isRequired) {
        control = new FormControl<T>(item, { nonNullable: true });
    } else if (typeof item === 'boolean'){
        control = new FormControl<T>(item, {nonNullable: true, validators: Validators.requiredTrue});
    } else {
        control = new FormControl<T>(item, {nonNullable: true, validators: Validators.required});
    }

    control.isRequired = this._isRequired;
    control.isLoading = this._isLoading;
    control.isReadOnly = this._isReadOnly;    
    control.isViewOnly = this._isViewOnly;
    if (this.disabled) {
        control.disable();
    }

    this.controls.push(control);
    return control;
}

FormGroup.prototype.CheckValidity = function(disableIfValid = true, scrollToFirstError = true) {
    MarkAllAsTouched(this);

    let invalid = this.invalid;
    
    if (invalid && scrollToFirstError) {
        ScrollToFirstError();
    } else if (!invalid && disableIfValid){
        this.Disable(true);
    }

    return !invalid;
}

FormGroup.prototype.AddControl = function<T>(this: FormGroup, formControlName: string, defaultValue: T): FormControl<T> {
    let control: FormControl<T>;
    control = new FormControl<T>(defaultValue, {nonNullable: true});

    control.isLoading = this.isLoading;
    control.isReadOnly = this.isReadOnly;    
    control.isViewOnly = this.isViewOnly;
    if (this.disabled) {
        control.disable();
    }

    this.addControl(formControlName, control);
    return control;
}

/** @internal */
export function ComputeDisabledStates(control: AbstractControl): any {
    if (control instanceof FormGroup) {
        const result = {} as any;
        Object.entries(control.controls).forEach(([key, control]) => {
            result[key] = ComputeDisabledStates(control);
        });
        return result;
    } else if (control instanceof FormArray) {
        return control.controls.map(control => ComputeDisabledStates(control));
    } else {
        return control.disabled;
    }
}

function ApplyDisabledStates(control: AbstractControl, state: any) {
    if (control instanceof FormGroup) {
        Object.entries(control.controls).forEach(([key, control]) => ApplyDisabledStates(control, state[key]));
    } else if (control instanceof FormArray) {
        control.controls.forEach((control, index) => ApplyDisabledStates(control, state[index]));
    } else if (state) {
        control.disable();
    } else {
        control.enable();
    }
}

FormGroup.prototype.GetControlsInErrorState = function(this: FormGroupExtraFields): number {
    return GetControlsWithErrors(this);
}

function GetControlsWithErrors(control: AbstractControl): number {
    let controlsWithErrors: number = 0;
    if (control instanceof FormGroup) {
        Object.values(control.controls).forEach(control => controlsWithErrors += GetControlsWithErrors(control));
    } else if (control instanceof FormArray) {
        control.controls.forEach((control, index) =>  controlsWithErrors += GetControlsWithErrors(control));
    } else if ((control as FormControlExtraFields).connectedForm) {
        controlsWithErrors += GetControlsWithErrors((control as FormControlExtraFields).connectedForm!);
    } else {
        if (control.errors !== null && control.enabled && control.touched) {
            controlsWithErrors++;
        }
    }
    return controlsWithErrors;
}

FormGroup.prototype.GetControlsInDirtyState = function(this: FormGroupExtraFields) {
    return GetDirtyControls(this);
}

function GetDirtyControls(control: AbstractControl): number {
    let controlsThatAreDirty: number = 0;
    if(control instanceof FormGroup) {
        Object.values(control.controls).forEach(control => controlsThatAreDirty += GetDirtyControls(control));
    } else if (control instanceof FormArray) {
        control.controls.forEach((control, index) => controlsThatAreDirty += GetDirtyControls(control));
    } else if ((control as FormControlExtraFields).connectedForm) {
        controlsThatAreDirty += GetDirtyControls((control as FormControlExtraFields).connectedForm!);
    } else {
        if (control.isDirty) {
            controlsThatAreDirty++;
        }
    }
    return controlsThatAreDirty;
}

FormGroup.prototype.SetDefaultValueToCurrent = function(this: FormGroupExtraFields, synchronous = false): void {
    if (synchronous) {
        this.defaultValue = this.value;
        this.defaultDisabledStates = ComputeDisabledStates(this);
        this.isDirty = false;
    } else {
        setTimeout(() => this.SetDefaultValueToCurrent(true));
    }
}

FormGroup.prototype.Reset = function(this: FormGroupExtraFields, synchronous = false): void {
    if (synchronous) {
        this.reset(this.defaultValue);
        ApplyDisabledStates(this, this.defaultDisabledStates);
        this.isDirty = false;
    } else {
        setTimeout(() => this.Reset(true));
    }
}

FormGroup.prototype.Enable = function(this: FormGroupExtraFields, synchronous = false): void {
    if (synchronous) {
        if (this.currentDisabledStates) {
            ApplyDisabledStates(this, this.currentDisabledStates);
        } else {
            this.enable();
        }
    } else {
        setTimeout(() => this.Enable(true));
    }
}

FormGroup.prototype.Disable = function(this: FormGroupExtraFields, synchronous = false): void {
    if (synchronous) {
        if (!this.disabled) {
            this.currentDisabledStates = ComputeDisabledStates(this);

            // Not sure why this needs to be called twice here, but the form doesn't disable properly on the first call
            this.disable();
            this.disable();
        }
    } else {
        setTimeout(() => this.Disable(true))
    }
}

FormGroup.prototype.SaveFailed = function(this: FormGroupExtraFields): void {
    this.saveSubject?.next(false);
}

FormGroup.prototype.SaveSucceeded = function(this: FormGroupExtraFields): void {
    this.saveSubject?.next(true);
}

FormControl.prototype.SetApiError = function() {
    setTimeout(() => {
        this.setErrors({api: true});
        ScrollToFirstError();
    });
}

FormControl.prototype.ConnectToForm = function(form: FormGroup) {
    const control = this as FormControlExtraFields;
    control.connectedForm = form;
    (form as FormGroupExtraFields).connectedControl = this;
}

FormControl.prototype.Enable = function(this: FormControl, synchronous = true): void {
    if (synchronous) {
        this.enable();
    } else {
        setTimeout(() => this.Enable(true));
    }
}

FormControl.prototype.Disable = function(this: FormControl, synchronous = true): void {
    if (synchronous) {
        this.disable();
        this.disable();
    } else {
        setTimeout(() => this.Disable(true))
    }
}

function MarkAllAsTouched(control: AbstractControl): void {
    if (control instanceof FormGroup || control instanceof FormArray) {
        Object.values(control.controls).forEach(MarkAllAsTouched);
    } else if ((control as FormControlExtraFields).connectedForm) {
        MarkAllAsTouched((control as FormControlExtraFields).connectedForm!);
    }

    control.markAsTouched();
    control.updateValueAndValidity();
}

export function ScrollToFirstError(): void {
    setTimeout(() => { // we set a timeout so the errors show up
        //We get the all the invalid forms
        var elements = document.querySelectorAll('mat-error');
        if (elements.length > 0) {
            //We get this first invalid form
            var requiredElement = elements[0];
            //We get the parent so, we can include the label
            //we get the error, then form field, then the form field container
            var formField = requiredElement.parentElement?.parentElement?.parentElement;
            // formField?.scrollIntoView({behavior: "smooth"});
            const rect = formField?.getBoundingClientRect();

            const drawerLayout = document.getElementsByClassName('scss-drawer-layout')[0] as HTMLElement;

            var scrollContainer: HTMLElement | null = null;

            if (drawerLayout) {
                scrollContainer = drawerLayout;
            } else {
                // Item view or other top-level component
                scrollContainer = document.getElementById('main-application-content');
            }

            if (rect && (rect.top || rect.bottom) && scrollContainer) {
                const headerBottom =  (document.getElementsByClassName('scss-drawer-header')[0] as HTMLElement)?.getBoundingClientRect()?.bottom ?? 0;
                const additionalHeaderBottom = (document.getElementsByClassName('additional-drawer-header')[0] as HTMLElement)?.getBoundingClientRect()?.bottom ?? 0;
                let headerOffset = headerBottom + additionalHeaderBottom + 60;
                let footerOffset = window.innerHeight;

                const drawerFooterRect = (document.getElementsByClassName('scss-drawer-footer')[0] as HTMLElement)?.getBoundingClientRect();
                if (drawerFooterRect) {
                    footerOffset = drawerFooterRect.top;
                }
    
                if (rect.bottom > footerOffset) {
                    scrollContainer.scrollBy({ top: rect.bottom - footerOffset, behavior: 'smooth' });
                } else if (rect.top < headerOffset) {
                    scrollContainer.scrollBy({ top: rect.top - headerOffset, behavior: 'smooth' });
                }
            }
        }
    }, 500);
}



Object.defineProperties(FormControl.prototype, {
    connectedForm: { value: undefined, writable: true }
});

Object.defineProperties(FormGroup.prototype, {
    defaultValue: { value: undefined, writable: true },
    defaultDisabledStates: { value: undefined, writable: true },
    saveMethod: { value: null, writable: true },
    saveSubject: { value: null, writable: true },
    autoSave: { value: false, writable: true },
    connectedControl: { value: undefined, writable: true }
})

Object.defineProperties(AbstractControl.prototype, {
    _isLoading: { value: false, writable: true },
    _isReadOnly: { value: false, writable: true },
    _isViewOnly: { value: false, writable: true },
    _isRequired: { value: false, writable: true },
    _defaultValue: { value: null, writable: true }
})