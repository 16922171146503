import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { BaseViewComponent, Environment, WebsiteMode } from '@library/base';
import { WebsiteBlockDisplayItem, WebsiteBlockTextAndMediaDisplayItem, WebsiteBlockTextAndMediaType, WebsiteDisplayItem, WebsitePageDisplayItem, WebsiteRenderContextDisplayItem, WebsiteRenderMode } from '@library/data-models';
import { StyleManager } from '@library/managers';
import { AppSettingsService } from './app-settings.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})

export class AppComponent extends BaseViewComponent {

    private _currentViewablePageID!: string;
    private _currentYear = new Date().getFullYear();
    private _pageNotFoundId = "pageNotFound_404";

    constructor(
        private _AppSettingsService: AppSettingsService,
        private _StyleManager: StyleManager,
        private _Location: Location,
        private _Environment: Environment
    ) {
        super();
    }

    override ngOnInit(): void {
        super.ngOnInit();

        switch (this.renderContext?.RenderMode) {
            case WebsiteRenderMode.Website:
                const path = window.location.pathname.toLowerCase();

                const pageIDFromPath = this._AppSettingsService.idByPath[path];
                if (pageIDFromPath) {
                    this.NavigationPageClicked(pageIDFromPath);
                    const [path, title] = this._AppSettingsService.pathAndTitleByID[pageIDFromPath];
                    this._Location.replaceState(path);
                    document.title = title;

                    this._currentViewablePageID = pageIDFromPath;
                } else if (path == '/') {
                    const homepage = this.website.WebsitePages.find((p) => p.IsHomepage)!;
                    const [_, title] = this._AppSettingsService.pathAndTitleByID[homepage.ID!];
                    this._Location.replaceState('/');
                    document.title = title;
                    this._currentViewablePageID = homepage.ID!;
                } else {
                    document.title = $localize`:@@WebsiteBuilderPageNotFoundTitle:Page Not Found`;
                    this.website.WebsitePages.push(this.CreateDefaultPageNotFoundPage());
                    this._currentViewablePageID = this._pageNotFoundId;
                }

                break;
            case WebsiteRenderMode.Widget:
                document.body.style.overflow = 'hidden';
                break;
        }

    }

    CreateDefaultPageNotFoundBody(): string {
        const homepage = this.website.WebsitePages.find((p) => p.IsHomepage)!;
        const description = $localize`:@@WebsiteBuilderPageNotFoundDescription:Sorry, the page you requested could not be found. Check the URL and please try again.`;
        const returnToDescription = $localize`:@@WebsiteBuilderReturnToPageDescription:Return to ${homepage.Title}`;
        const htmlLink= "<br><br><b><a href=\"/\">" + returnToDescription + "</a></b>";

        return description + htmlLink;
    }

    CreateDefaultPageNotFoundPage(): WebsitePageDisplayItem {
        const pageNotFoundDisplayItem = new WebsitePageDisplayItem();
        pageNotFoundDisplayItem.Title = $localize`:@@WebsiteBuilderPageNotFound:Page Not Found`;
        pageNotFoundDisplayItem.ID = this._pageNotFoundId;

        const textAndMediaBlock = new WebsiteBlockTextAndMediaDisplayItem({
            Header: $localize`:@@WebsiteBuilderPageNotFound:Page Not Found`,
            Body: this.CreateDefaultPageNotFoundBody(),
            TextAndMediaType: WebsiteBlockTextAndMediaType.Default,
            OrderIndex: 0,
            ID: null,
            Images: [],
        });
        pageNotFoundDisplayItem.WebsiteBlocks.push(textAndMediaBlock);
        return pageNotFoundDisplayItem;
    }

    NavigationPageClicked(ID: string): void {
        // remove Page Not Found
        if (this.website.WebsitePages.slice(-1)[0].ID == this._pageNotFoundId) {
            this.website.WebsitePages.pop();
        }

        const [path, title] = this._AppSettingsService.pathAndTitleByID[ID];

        this._Location.go(path);
        document.title = title;

        this._currentViewablePageID = ID;
    }

    get websiteBlock(): WebsiteBlockDisplayItem {
        return this._AppSettingsService.websiteBlock;
    }

    get website(): WebsiteDisplayItem {
        return this._AppSettingsService.website;
    }

    get renderContext(): WebsiteRenderContextDisplayItem | undefined {
        return this._AppSettingsService.renderContext;
    }

    get WebsiteRenderMode(): typeof WebsiteRenderMode {
        return WebsiteRenderMode;
    }

    get currentViewablePageID(): string {
        return this._currentViewablePageID;
    }

    get WebsiteMode(): typeof WebsiteMode {
        return WebsiteMode;
    }

    get currentYear(): number {
        return this._currentYear;
    }

    get hostname(): string {
        return `https://${this._Environment.Application.Hostname}`;
    }
}
